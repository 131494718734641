export const createmvscfileSrv = (param) => {
  async function createmvscfileService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/api/user/createmvscfile/', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: "include", // include, *same-origin, omit
      body: JSON.stringify({
        content: param.content,
        file: param.file,
        title: param.title
      })      
    });
     return await rawResponse.json();
  };

  return createmvscfileService()
}
