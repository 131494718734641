export const copyfilesSrv = (param) => {
  async function copyfileService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/api/user/copyfiles', {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        item_id: param.item_id, 
        item_new_location: param.item_new_location
      }),   
      credentials: 'include'
    });
     return await rawResponse.json();
  };

  return copyfileService()
}
