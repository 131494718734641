import React, { useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';

import { makeStyles, /*createMuiTheme, ThemeProvider */} from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import LinearProgress from '@material-ui/core/LinearProgress';

import { themeHlp }  from '../Helper/ThemeHelper.js'
import { logoutSrv } from '../Service/LogoutService.js'
import { getusermessageSrv } from '../AdtService/GetUserMessageService.js';
import ContactApp from '../Apps/ContactApp';
//import '../../App2.css';

/*
const theme = createMuiTheme({
  typography:{ 
    fontFamily: [
      'Inter', 
      'Helvetica',
      'Arial',
      'Sans-Serif'
    ].join(',')  
  }
});
*/

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'relative',
    zIndex: theme.zIndex.drawer + 1,    
  },
  logo: {
    color:'transparent',
    backgroundColor:'transparent', 
    backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/movesas.png)', 
    backgroundRepeat: 'no-repeat', 
    backgroundSize: 'cover',
    cursor:'pointer'
  },
  title: {
    flexGrow: 1,
  },
  process_messages_box: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'left',
    flexWrap: 'wrap',
    maxWidth: '1800px'
  },
  process_messages_effect:  {
    position: 'relative',
    padding: '0px',
    overflow: 'hidden',
  },
  linear_progress_box: {
    width: '140px',
  //  paddingTop: '2px',
    '& .MuiLinearProgress-root': {
      backgroundColor: themeHlp().palette.secondary.light
    },
    '& .MuiLinearProgress-bar': {
      backgroundColor: themeHlp().palette.primary.light
    }   
  },
  appeared_badge: {
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      top: '8px',
      right: '20px'
    }  
  }
}));

const HeaderBar = (props) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const [powering, setPowering] = useState(false);
  const [contactAppOn, setContactAppOn] = useState(false);
  const [processMessages, setProcessMessages] = useState({});
  const [newMessageCount, setNewMessageCount] = useState(0);

  const showPowering = () => {
    setPowering(true);
    setProcessMessages({});
  }

  const hidePowering = () => {
    setPowering(false);
  }

  const logOut = (param) => {
    logoutSrv(param)
    .then(response => {
      if(response && response.statusCode === 200){
        navigate('/');
      }
    })
    .catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    setProcessMessages(props?.process);
  },[props?.process]);

  useEffect(() => {
    getusermessageSrv('new').then(response => {
      setNewMessageCount(response?.data[0]?.newMessageCount);      
    }).catch(err => {
      console.log(err);
    })
  },[]);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" style={{boxShadow:'none', background: themeHlp().palette.primary.gradientHeaderFooter}}>
        <Toolbar variant="dense" style={{height:'48px', padding: '8px 16px 8px 8px'}}>
          <Typography variant="h5" className={classes.title}>
            <Avatar  
              component="span" 
              variant="rounded"  
              alt="Move into" 
              className={classes.logo} 
              onClick={() => window.location.href=process.env.REACT_APP_URL_CLIENTPORT} 
            />             
          </Typography>
          <div style={{position:'absolute', top:'14px', left: '60px'}}>
            {processMessages.state === 'loading' && 
              <>
                <Typography variant="subtitle2" component="span" style={{fontSize: '10px', color: themeHlp().palette.secondary.light, /*padding: '0 10px',*/ borderRadius: '10px'}}>
                  Processing...
                </Typography>
                <Box className={classes.linear_progress_box}>
                  <LinearProgress value={10}/>
                </Box>
              </>
            }

            <Box className={classes.process_messages_box}>
              <div className={classes.process_messages_effect} >
                <div style={{
                      position: 'absolute',
                      inset: '0',
                      background: themeHlp().palette.secondary.light,
                      borderLeft: '2px solid ' + themeHlp().palette.secondary.light,
                      borderRadius: '10px',
                      top:'6px',
                      height: '14px',
                      transform: 'translateX(0%)',
                      animation: processMessages.state === 'loaded' && 'translateOverlay 0.4s forwards cubic-bezier(0.03, 0, 0.15, 1)',
                }}></div>
                {processMessages.state === 'loaded' && <Avatar component="span" alt={processMessages.type} style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/' + processMessages.type + '.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />}
                <div style={{whiteSpace: 'nowrap', width: (window.innerWidth - 144) + 'px'}}>
                  <Typography variant="subtitle1" component="span" style={{fontWeight: 'bold', color: themeHlp().palette.secondary.light, /*padding: '0 10px',*/ borderRadius: '10px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    {processMessages.message}
                  </Typography>
                </div>
              </div>
            </Box>
          </div>
          <div>
            {!contactAppOn ?            
              <IconButton aria-label="show massages" color="inherit" onClick={() => setContactAppOn(true) }>
                <Badge badgeContent={newMessageCount} color='error'>
                  <Avatar title="Message" component="span" variant="rounded"  alt="Message" style={{width: 24, height: 24, color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/message.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
                </Badge>
              </IconButton>                
              :             
              <IconButton aria-label="show massages" color="inherit">
                <span style={{display: 'inline-block', position: 'absolute', width: '60px', }}>
                  <Badge badgeContent={newMessageCount} color='error' className={classes.appeared_badge}>
                    <ContactApp value={true} onHeader={true} />
                  </Badge>
                </span>                
              </IconButton>
            }
            { powering ?
              <>
                <IconButton edge="end" aria-label="reload this page" aria-haspopup="true" color="inherit" onClick={() => window.location.reload()}>                  
                  <Avatar title="Reload Page" component="span" variant="rounded"  alt="Reload Page" style={{width: 24, height: 24, color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/reload.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
                </IconButton>
                <IconButton edge="end" aria-label="log out" aria-haspopup="true" onClick={() => logOut('current')} color="inherit">                  
                  <Avatar title="Log Out" component="span" variant="rounded"  alt="Log Out" style={{width: 24, height: 24, color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/logout.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
                </IconButton>
                <IconButton edge="end" aria-label="hide powering partition" aria-haspopup="true" color="inherit" onClick={hidePowering}>                  
                  <Avatar title="Hide" component="span" variant="rounded"  alt="Hide" style={{width: 24, height: 24, color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/hide.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
                </IconButton>
              </> 
            :  
              <> 
                <IconButton edge="end" aria-label="show powering partition" aria-haspopup="true" color="inherit" onClick={showPowering}>                  
                  <Avatar component="span" variant="rounded"  alt="Power Off" style={{width: 24, height: 24, color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/power.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
                </IconButton>
              </> 
            }
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
 
export default HeaderBar;
