import React, { useLayoutEffect, useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import CancelSharpIcon from '@material-ui/icons/Close';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import BorderInnerIcon from '@material-ui/icons/BorderInner';
import { makeStyles} from '@material-ui/core/styles';
import { themeHlp }  from '../Helper/ThemeHelper.js';
import  appResizeWindowHlp  from '../Helper/AppResizeWindowHelper.js';
import { readcompressedfilesSrv } from '../Service/ReadCompressedFilesService.js';

const useStyles = makeStyles((theme) => ({
  menu_box:{
    width:'100%', 
    height:'340px', 
    margin:'auto auto', 
    background: 'linear-gradient(135deg, rgb(38, 93, 140) 0%, rgb(38, 93, 140) 15%, rgb(0, 140, 183) 50%)', 
    opacity:'1', 
    borderRadius:'15px', 
    border:'4px solid #f5a33e'
  },
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: themeHlp().palette.secondary.light,
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  doc_reader: {
    width: '100%',
    border: '0px'
  },
  user_avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: 'auto',
    cursor: 'pointer'
  },
  dialog_content: {
    padding: '0px',
  },
  text_box: {
    width:'160px',
    backgroundColor: 'white'
  },
  iconbutton: {
    width:'36px',
    height: '36px',
    color: themeHlp().palette.primary.light,
    '&:hover' : {
      color: themeHlp().palette.primary.main
    },
    '&:active' : {
      color: themeHlp().palette.primary.dark,    
    }
  },
  upload_file: {
    display: 'none'
  },
  linear_progress_box: {
    width: '200px',
    margin: 'auto auto',
    '& .MuiLinearProgress-root': {
      backgroundColor: themeHlp().palette.secondary.light
    },
    '& .MuiLinearProgress-bar': {
      backgroundColor: themeHlp().palette.primary.light
    }   
  },
}));

const ArchiveReaderApp = (props) => {

  const classes = useStyles();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [open, setOpen] = React.useState(false);  
  const [fileUploadStarted, setFileUploadStarted] = useState(false);
  const [toolbarHidden, setToolbarHidden] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [archiveContent, setArchiveContent] = useState([]);
  const titleDisplaySize = 620;

  function createData(path, type, size, modified) {
    return { path, type, size, modified };
  }

  const handleClickOpen = () => {

    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;

    if(allApps.indexOf('archivereader') === -1 && JSON.parse(localStorage.getItem('movwin')).appCount <2){
      allApps.push('archivereader');
      setOpen(true);
      setErrorMessage('');    
  
      if(props.fileInfo?.item_id){
        setFileUploadStarted(true);
  
        const readcompressedfilesParams = {
          item_id: props.fileInfo?.item_id
        }
  
        readcompressedfilesSrv(readcompressedfilesParams)
        .then(response => {
          if(response && response.statusCode === 200){
            for(let i=0; i<response.data.length; i++){
              setArchiveContent((oldUsersMessageList => [...oldUsersMessageList, createData(response.data[i].path, response.data[i].type, response.data[i].size, response.data[i].mtime)]));
            }
            setFileUploadStarted(false);
          }
        }).catch(err => {
          console.log(err);
        })
      }
      
      localStorage.setItem('movwin', JSON.stringify({
        windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
        apps: allApps,
        appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount + 1
      }));    
      
      setTimeout(() => {
        appsInWindows('load');
      },100);
    }
  };

  const handleClose = () => {
    setOpen(false);
    if(props.appCloses){
      props.appCloses(true);
    }

    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;
    if(allApps.indexOf('archivereader') !== -1){
      allApps.splice(allApps.indexOf('archivereader'), 1);
    }

    localStorage.setItem('movwin', JSON.stringify({
      windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
      apps: allApps,
      appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount - 1
    }));
  };

  const appsInWindows = (event) => {
    if(event === 'change'){
      if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).windowMode < 4){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode + 1,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount
        }));
      }else if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).windowMode === 4){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: 0,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount
        }));
      }

      if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).appCount === 0){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: 1
        }));
      }
    }

    setWindowWidth(windowWidth - 1);  
    appResizeWindowHlp('archiveReaderDialogBox');
  }

  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);      
    }

    window.addEventListener('resize', updateSize);
    updateSize();
   
    return () => {     
      window.removeEventListener('resize', updateSize); 
    }
  },[windowWidth, windowHeight])

  useEffect(() => {
    handleClickOpen();
    if(!localStorage.getItem('movwin')){
      localStorage.setItem('movwin', JSON.stringify({
        windowMode: 0,
        apps: [],
        appCount: 1
      }));
    }     
    
    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;

    if(allApps.indexOf('archivereader') === -1 && JSON.parse(localStorage.getItem('movwin')).appCount < 2){
      setOpen(true);
      setTimeout(() => {
        const muiBackdrop = document.querySelectorAll('.MuiBackdrop-root');
        let newApp = JSON.parse(localStorage.getItem('movwin')).apps;
        newApp.push('archivereader');
        
        if(muiBackdrop[2]){
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: newApp,
            appCount: 2
          })); 
        }else if(muiBackdrop[2] === undefined && muiBackdrop[1]) {          
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: newApp,
            appCount: 1
          }));        
        }else if(muiBackdrop[2] === undefined && muiBackdrop[1] === undefined) {        
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: [],
            appCount: 0,
          }));        
        }
        appsInWindows('load');      
      },100);
    } 
   },[])

return (
  <>
    <Avatar title="Archive Reader" component="span" variant="rounded" alt="Archive Reader" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/archivereader.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={handleClickOpen} />
    <Typography title="Archive Reader" variant="subtitle1" component="span" style={{ whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>Archive Reader</Typography>
    <Dialog id="archiveReaderDialogBox" fullScreen onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle
        style={{
          padding: '8px 16px',
          color:'#ffffff',
          background:'linear-gradient(90deg, rgba(38,93,140,1) 0%, rgba(38,93,140,1) 15%, rgba(0,140,183,1) 100%)'
        }}
        id="customized-dialog-title" onClose={handleClose}
      >
        <Avatar component="span" variant="rounded" style={{width:'24px', height: '24px', display: 'inline-block', marginRight: '8px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/archivereader.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
        <span>{(windowWidth < titleDisplaySize && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) !== 0) || (windowWidth < (titleDisplaySize/2) && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) === 0) ? '' : 'Archive Reader'}</span>
        <IconButton
          disabled={document.getElementById('shutdownDialog') ? false : true}
          aria-label="AppsInWindowsIcon"
          onClick={() => appsInWindows('change')}
          size="small"
          style={{
            background: themeHlp().palette.blueGrey.dark,
            color:'white',          
            margin:'2px 42px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px',
            opacity: document.getElementById('shutdownDialog') ? '1' : '0.3'
          }}
        >
          <BorderInnerIcon style={{padding: '2px'}} />          
        </IconButton>
        <IconButton 
          aria-label="UnfoldLessMoreIcon"
          title={!toolbarHidden ? 'Hide Toolbar' : 'Show Toolbar'}
          onClick={()=> setToolbarHidden(current => !current)}
          size="small"
          style={{
            width: '18px',
            height: '18px',
            background: themeHlp().palette.blueGrey.dark,
            color:'white',
            margin:'2px 74px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px'
          }}
        >
          {!toolbarHidden ? <UnfoldLessIcon style={{padding: '4px'}} /> : <UnfoldMoreIcon style={{padding: '4px'}} />}
        </IconButton>
        <IconButton 
          aria-label="CancelSharpIcon"
          title="Close"
          onClick={handleClose}
          size="small"
          style={{
            background: themeHlp().palette.blueGrey.dark,
            color:'white', 
            margin:'2px 10px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px'
          }}
        >
          <CancelSharpIcon style={{padding: '2px'}} /> 
        </IconButton>
      </DialogTitle>
      {!toolbarHidden && 
      <DialogActions id="p0" className={classes.dialog_content}>
        <div id="p1" className={classes.root}>          
          <List style={{ width: '100%', height: '64px', padding: '0px', overflowY: 'auto'}}>
            {/*<ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
              <label htmlFor="contained-button-file-image-browse">
                <Avatar title="Open Archive" component="span" variant="rounded" alt="Open Archive" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel_import_text.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
              </label>
            </ListItem>*/}
          </List>
        </div>
      </DialogActions>
      }
      <DialogContent style={{padding: 0}}>
        {errorMessage !== '' &&
          <Typography variant="subtitle2" component="div" style={{fontSize: '14px', marginTop: '20px', color: themeHlp().palette.primary.main, /*padding: '0 10px',*/ borderRadius: '10px', textAlign: 'center'}}>
            {errorMessage}
          </Typography>
        }
        {fileUploadStarted ?
          <>
            <Typography variant="subtitle2" component="div" style={{fontSize: '14px', marginTop: '20px', color: themeHlp().palette.primary.main, /*padding: '0 10px',*/ borderRadius: '10px', textAlign: 'center'}}>
              Opening...
            </Typography>
            <Box className={classes.linear_progress_box}>
              <LinearProgress value={10}/>
            </Box>
          </>
          :
          <>
            <Typography variant="subtitle2" component="div" style={{fontSize: '14px', margin: '10px 0 4px 4px', color: themeHlp().palette.primary.main}}>
            {props.fileInfo?.path}
            </Typography>            
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead style={{ background:'#cfa76a',  borderBottom: '2px solid ' + themeHlp().palette.greyText.light}}>
                  <TableRow>
                    <TableCell style={{fontWeight: 'bold'}}>Name</TableCell>
                    <TableCell style={{fontWeight: 'bold'}} align="right" variant="head">Type</TableCell>
                    <TableCell style={{fontWeight: 'bold'}} align="right" variant="head">Size</TableCell>
                    <TableCell style={{fontWeight: 'bold'}} align="right">Modified</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {archiveContent.map((row) => (
                    <TableRow
                      key={row.path}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                      <img alt="kfg" src={row.type === 'file' ? process.env.REACT_APP_URL_CLIENTPORT + '/icon/type/file_file.png' : process.env.REACT_APP_URL_CLIENTPORT + '/icon/type/folder_file.png'} style ={{width:"22px", float:"left", marginRight: "10px"}}/>
                        {row.path}
                      </TableCell>
                      <TableCell align="right">{row.type}</TableCell>
                      <TableCell align="right">
                        {(row.type === 'file' && (parseInt(row.size) < 1000)) && row.size + ' bytes'}
                        {(row.type === 'file' && (parseInt(row.size) >= 1000 && parseInt(row.size) < 1000000)) && (row.size / 1000).toFixed(1) + ' kB'}
                        {(row.type === 'file' && (parseInt(row.size) >= 1000000)) && (row.size / 1000000).toFixed(1) + ' MB'}
                      </TableCell>
                      <TableCell align="right">
                        {        
                        row.modified === null ?
                        ''
                        :      
                        new Date(row.modified).toLocaleString('en-US', {timeZone: timeZone,
                          dateStyle: 'short',//full, long, medium, short
                          timeStyle: 'medium', //full, long, medium, short
                          hourCycle: 'h12', //h12, h24
                        })
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        }
      </DialogContent>
    </Dialog>
  </>
)
}
 
export default ArchiveReaderApp;
