export const registerSrv = (param) => {

  async function RegisterService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/api/user/register/', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
   //   mode: 'no-cors', // no-cors, *cors, same-origin
   //   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "include", // include, *same-origin, omit
      body: JSON.stringify({
        firstName: param.firstname, 
        lastName: param.lastname,
        email: param.email,
        password: param.password
      })
    });
     return await rawResponse.json();
  };

  return RegisterService()
}
