import axios from 'axios'

export const searchfilesSrv = (param) => {

    const promise = axios({
      url: process.env.REACT_APP_URL_SERVERPORT + '/api/user/searchfiles/',
      method: 'POST', 
      data: param,
      withCredentials: true
    })
  
    // using .then, create a new promise which extracts the data
    const dataPromise = promise.then((response) => response.data)
    
    // return it
    return dataPromise
}
