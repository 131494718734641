import axios from 'axios'

export const tokenSrv = (param) => {

 /* async function tokenService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/api/user/checktoken/', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
   //   mode: 'no-cors', // no-cors, *cors, same-origin
   //   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "include", // include, *same-origin, omit
      
    });
     return await rawResponse.json();
  };

  return tokenService()*/

  const promise = axios({
    url: process.env.REACT_APP_URL_SERVERPORT + '/api/user/checktoken/',
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    withCredentials: true,
  })

  const dataPromise = promise.then((response) => response.data)

  return dataPromise

}










