import React, { useState, useLayoutEffect, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LinkMui from '@material-ui/core/Link';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { tokenSrv } from '../Service/TokenService.js';
import { loginSrv } from '../Service/LoginService.js';
import { restartSrv } from '../Service/RestartService.js';
import { getAccountDataSrv } from '../Service/GetAccountDataService.js';
import { resendActivationLinkSrv } from '../Service/ResendActivationLinkService.js';
import { sendPasswordResetLinkSrv } from '../Service/SendPasswordResetLinkService.js';
import { sendsignoutofdevicesLinkSrv } from '../Service/SendSignoutDevicesLinkService.js';
import { themeHlp } from '../Helper/ThemeHelper.js';

const useStyles = makeStyles(() => ({
  loginbar_container: {
    '& .MuiGrid-root': {
      paddingBottom: '20px'
    }
  },
  text_box: {
    width:'92%',
    margin: '4px 4%',
    backgroundColor: themeHlp().palette.whiteText.main,
    borderBottom: '1px solid ' + themeHlp().palette.blackText.light,
    '&:hover': {      
        border: '1px solid ' + themeHlp().palette.primary.light,
        borderStyle: 'none none solid none',
    }
  },
  casual_text: {
    fontFamily: 'Helvetica',
    fontSize: '0.875rem'
  },
  button_witout_icon: {
    width:'92%',
    margin: '4px 4%',
    background: themeHlp().palette.primary.light,
    textTransform: 'capitalize',
    border: '1px solid ' + themeHlp().palette.secondary.light,
    '&:hover' : {
      background: themeHlp().palette.primary.main,
      color: themeHlp().palette.secondary.light,
    },
    '&:active' : {
      background: themeHlp().palette.primary.dark,
      color: themeHlp().palette.secondary.light,
    }
  },  
  login_box:{
    width: '100%',
    maxWidth: '360px',
    padding: '0px 0px 20px 0px',
    background: themeHlp().palette.primary.main,
  },
  forgot_password_link: {
    color: themeHlp().palette.whiteText.main,
    textDecoration: 'none',
    cursor:'pointer',
    '&:focus, &:hover, &:visited, &:link, &:active' : {
      color: themeHlp().palette.secondary.light,
      textDecoration: 'none',
    }
  },
  error_message: {    
    fontFamily: 'Helvetica',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    width: '92%',
    margin: '4px 4%',
    padding: '4px 0',
    backgroundColor: themeHlp().palette.error.main,
    color: themeHlp().palette.secondary.light,
    border: '0px',
    borderRadius: '0px'
  },
  linear_progress_box: {
    width: '92%',
    margin: '4px 4%',
    padding: '4px 0',
    paddingTop: '30px',
    '& .MuiLinearProgress-root': {
      backgroundColor: themeHlp().palette.secondary.light
    },
    '& .MuiLinearProgress-bar': {
      backgroundColor: themeHlp().palette.primary.light
    }   
  },
}));

const LoginBar = () => {
  const classes = useStyles();
  let navigate = useNavigate();
  const [accountData, setAccountData] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
//  const [email, setEmail] = useState('artak4@aspirevapeco.com');
//  const [password, setPassword] = useState('password4');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] =  useState(2);
  const [errorLogin, setErrorLogin] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isActivated, setIsActivated] = useState(false);
  const [activationLinkResent, setActivationLinkResent] = useState(false);
  const [passwordResetLinkSent, setPasswordResetLinkSent] = useState(0);
  const [signoutDevicesLinkSent, setSignoutDevicesLinkSent] = useState(0);
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);  
  const [signoutDecivesMode, setSignoutDecivesMode] = useState(false);
  const [welcomeUser, setWelcomeUser] = useState();
  const [restarting, setRestarting] = useState('Restart');

  const gotoLogin = () => {    
    loginSrv({email, password}).then(response => {
      if(response && (response.statusCode === 200 && response.status === 'Success')){
        getAccountDataSrv().then(response => {
          localStorage.setItem('movacc', JSON.stringify(response));  
          setAccountData(response);
        }).catch(err => {
          console.log(err);
        })        
        //window.location.href = '/desktop';
        navigate('/desktop');
      }else if(response.error && (response.error.statusCode === 404)){
        setErrorLogin(true);
        setErrorMessage(response.error.message);
      }else if(response.error && (response.error.statusCode === 401 || response.error.statusCode === 429)){
        setErrorLogin(true);
        setErrorPassword(true);
        setErrorMessage(response.error.message);
      }else if(response.error && (response.error.statusCode === 403)){
        setErrorLogin(true);
        setErrorPassword(true);
        setErrorMessage(response.error.message);
        setIsActivated(true);
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const goToRestart = () => {
    setRestarting('Restarting...');
    restartSrv().then(response => {
      if(response && (response.statusCode === 200)){       
        setToken(0);
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const gotoResendActivationLnk = () => {
    resendActivationLinkSrv({email, password}).then(response => {
      if(response && (response.statusCode === 200)){
        setSuccessMessage(response.message)
        setActivationLinkResent(true);
      }else{
        setErrorMessage(response.error.message);
        setActivationLinkResent(false);
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const gotoForgotPasswordMode = () => {
    setForgotPasswordMode(true);
  }

  const gotoSignoutDecivesMode =() => {
    setSignoutDecivesMode(true);
  }

  const gotoSendPasswordResetLnk = () => {
    setPasswordResetLinkSent(2);
    sendPasswordResetLinkSrv({email}).then(response => {
      if(response && (response.statusCode === 200)){
        setSuccessMessage(response.message)
        setPasswordResetLinkSent(1);
      }else{
        setErrorMessage(response.error.message);
        setPasswordResetLinkSent(0);
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const gotoSendSignoutOfDevicesLnk = () => {
    setSignoutDevicesLinkSent(2);
    sendsignoutofdevicesLinkSrv({email}).then(response => {
      if(response && (response.statusCode === 200)){
        setSuccessMessage(response.message);
        setSignoutDevicesLinkSent(1);
      }else {
        setErrorMessage(response.error.message);
        setSignoutDevicesLinkSent(0);
      }
    }).catch(err => {
      console.log('error: ' + err);
    })
  }

  const cancelSendPasswordResetLnk = () => {
    setForgotPasswordMode(false);
    setSignoutDecivesMode(false);
  }


  useLayoutEffect(() => {
    document.getElementsByTagName("BODY")[0].style.overflowY = 'auto';

    function updateSize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => {
      window.removeEventListener('resize', updateSize); 
    }
  }, []);

  const keyDownHandler = (evn) => {
    const emailBox =  document.getElementById('emailBox');
    const passwordBox =  document.getElementById('passwordBox');
    const signinBox =  document.getElementById('signinBox');
    
    if(evn.key === 'Enter'){     
      if(emailBox.value.trim().length > 0 && passwordBox.value.trim().length === 0){
        passwordBox.focus();
      }else if(passwordBox.value.trim().length > 0 && emailBox.value.trim().length === 0){
        emailBox.focus();
      }else if(emailBox.value.trim().length > 0 && passwordBox.value.trim().length > 0){
        signinBox.focus();
      }  
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);

    tokenSrv().then(response => {
      if(response && (response.statusCode === 200 && response.status === 'OK')){
        setToken(1);        
        setWelcomeUser(JSON.parse(localStorage.getItem('movacc')).first_name)
      }else if(response && (response.statusCode === 401 && response.status === 'Unauthorized')){
        setToken(0);
      }
    }).catch(err => {
      console.log(err);
    })

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    }
  },[]);



  return (
    <>
      <Box className={classes.loginbar_container} sx={{ flexGrow: 1, overflow: "hidden", px:2, py:6}}> 
        <Grid container align="center">
          <Grid container spacing={0}>
            <Grid item xs={12} sm={6} align={windowWidth >= 600 ? "right" : "center"}>
              <Box component="div" sx={{width: "100%", maxWidth: "360px", verticalAlign: "middle"}}>
                <Box sx={{position: "relative", verticalAlign: "middle"}}>
                  <Typography variant="h6" component="span" gutterBottom style={{ fontSize: '14px', position: 'absolute', right:'6px', cursor: 'default'}}>
                    This
                  </Typography>
                </Box>  
                <Link to="/" ><img src={process.env.REACT_APP_URL_CLIENTPORT+ '/icon/movesas_text.png'} alt="Moves as logo" style={{width: '100%', maxWidth: '360px'}} /></Link>
                <Box sx={{position: "relative"}}>
                  <Typography variant="h6" component="span" gutterBottom style={{ fontSize: '14px', position: 'relative', float:'right', paddingRight:'6px', cursor: 'default'}}>
                    easily as possible
                  </Typography>  
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} align={windowWidth >= 600 ? "left" : "center"}>
              <Box className={classes.login_box}>
                {token === 2 || passwordResetLinkSent === 2 || signoutDevicesLinkSent === 2
                ?
                  <>
                    <Box className={classes.linear_progress_box}>
                      <LinearProgress value={10}/>
                    </Box>                      
                      <Button
                      className={classes.button_witout_icon}
                      variant="contained"
                      onClick={goToRestart}
                    >
                      {restarting}
                    </Button>
                  </>
                :
                <>
                  {token === 0 &&
                    <Typography variant="h5" component="h5" gutterBottom align = "center" style={{paddingTop:'20px'}}>
                      Log in
                    </Typography>
                  }
                  {token === 1 &&
                    <Typography variant="h5" component="h5" gutterBottom align = "center" style={{paddingTop:'20px'}}>
                      {welcomeUser}
                    </Typography>
                  }
                  {token === 0 && !activationLinkResent && passwordResetLinkSent === 0 && signoutDevicesLinkSent === 0 &&
                    <TextField
                      error={errorLogin}
                      id="emailBox"
                      label="Email"
                      type="email"
                      name="email"                    
                      variant="standard"
                      defaultValue={email}
                      className={classes.text_box}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  }
                  {token === 0 && !forgotPasswordMode && !signoutDecivesMode  && !activationLinkResent && passwordResetLinkSent === 0 && signoutDevicesLinkSent === 0 &&
                    <TextField
                      error={errorPassword}
                      id="passwordBox"
                      label="Password"                  
                      type="password"
                      name="password"
                      variant="standard"
                      defaultValue={password}                      
                      className={classes.text_box}
                      onChange={(event) => setPassword(event.target.value)}
                    />              
                  }
                  {((token === 0 && !forgotPasswordMode) && (token === 0 && !signoutDecivesMode) && (errorLogin === true || errorPassword === true)) && !activationLinkResent && passwordResetLinkSent === 0 && signoutDevicesLinkSent === 0 &&
                    <Card className={classes.error_message}>                   
                      <Typography variant="inherit" component="div" align="center">{errorMessage}</Typography>
                    </Card>
                  }
                  { (activationLinkResent || passwordResetLinkSent === 1 || signoutDevicesLinkSent === 1) &&
                    <Card className={classes.error_message} style={{backgroundColor: themeHlp().palette.success.dark}}>
                      <Typography variant="inherit" component="div" align="center">
                        {successMessage}
                      </Typography>
                    </Card>
                  }
                  {token === 0 && !activationLinkResent && passwordResetLinkSent === 0 && signoutDevicesLinkSent === 0 &&
                    <>
                      { (isActivated && !forgotPasswordMode && !signoutDecivesMode) &&
                        <Button
                          className={classes.button_witout_icon}
                          variant="contained"
                          onClick={gotoResendActivationLnk}
                          disableElevation
                        >
                          Resend Activation Link
                        </Button>  
                      } 
                      { forgotPasswordMode &&
                        <>
                          <Typography variant="inherit" component="div" align = "center" className={classes.casual_text} style={{padding:"10px 4% 10px 4%"}}>
                            Please enter your email addtess. We will send you a link to reset your password.
                          </Typography>
                          <Button
                            className={classes.button_witout_icon}
                            variant="contained"
                            onClick={gotoSendPasswordResetLnk}
                            disableElevation
                          >
                            Send Password Reset Link
                          </Button>
                          <Button
                            className={classes.button_witout_icon}
                            variant="contained"
                            onClick={cancelSendPasswordResetLnk}
                            disableElevation
                          >
                            Cancel
                          </Button>
                        </>
                      }
                      { signoutDecivesMode &&
                        <>
                          <Typography variant="inherit" component="div" align = "center" className={classes.casual_text} style={{padding:"10px 4% 10px 4%"}}>
                            Please enter your email addtess. We will send you a link to sign out of all devices.
                          </Typography>
                          <Button
                            className={classes.button_witout_icon}
                            variant="contained"
                            onClick={gotoSendSignoutOfDevicesLnk}
                            disableElevation
                          >
                            Send Sign Out Link
                          </Button>
                          <Button
                            className={classes.button_witout_icon}
                            variant="contained"
                            onClick={cancelSendPasswordResetLnk}
                            disableElevation
                          >
                            Cancel
                          </Button>
                        </>
                      }
                      { (!forgotPasswordMode && !signoutDecivesMode) &&
                        <>
                          <Button
                            id="signinBox"
                            className={classes.button_witout_icon}
                            variant="contained"
                            onClick={gotoLogin}
                            disableElevation
                          >
                            Sign in
                          </Button>                    
                          <Typography variant="inherit" component="div" align = "center" className={classes.casual_text} style={{padding:"10px 0px 0px 0px"}}>
                            <LinkMui className={classes.forgot_password_link} onClick={gotoForgotPasswordMode}>Forgot password?</LinkMui>
                          </Typography>
                          <Typography variant="inherit" component="div" align = "center" className={classes.casual_text} style={{padding:"10px 0px 10px 0px"}}>
                            <LinkMui className={classes.forgot_password_link} onClick={gotoSignoutDecivesMode}>Sign out of all devices</LinkMui>
                          </Typography>
                        </>
                      }
                    </> 
                    }      
                    { token === 1 && !activationLinkResent &&                      
                      <Button 
                        className={classes.button_witout_icon}
                        variant="contained"   
                        onClick={() => navigate('/desktop')}                
                        disableElevation
                      >
                        Desktop
                      </Button>                                  
                    }
                    {(token === 0 || token === 1) && !activationLinkResent && passwordResetLinkSent === 0 && signoutDevicesLinkSent === 0 &&
                      <Button
                        className={classes.button_witout_icon}
                        variant="contained"
                        onClick={() => navigate('/register')}
                        disableElevation
                      >
                        Sign up
                      </Button>
                    }
                  </>
                }
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
    );
  }

export default LoginBar
