export const addtopicnotesSrv = (param) => {
  async function addtopicnotesService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/user/addtopicnotes', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        topic: param.topic
      }),
      credentials: "include", // include, *same-origin, omit
    });
     return await rawResponse.json();
  };

  return addtopicnotesService()
}