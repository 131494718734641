export const updatefilesSrv = (param) => {

  async function updatefilesService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/api/user/updatefiles/', {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    //  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "include", // include, *same-origin, omit
      body: JSON.stringify({
        id: param.id,
        item_coords: param.item_coords,
        item_ext: param.item_ext,
        item_id: param.item_id,
        item_location: param.item_location,
        item_name: param.item_name,
        update_type: param.update_type
      })
    });
     return await rawResponse.json();
  };

  return updatefilesService()
}
