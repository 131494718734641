export const downwardlinksSrv = (param) => {
  async function downwardlinksService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/user/downwardlinks/' + param.item_case_id, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: "include", // include, *same-origin, omit
    });
     return await rawResponse.json();
  };

  return downwardlinksService()
}
