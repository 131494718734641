export const disconnectroomchatSrv = (param) => {
  async function disconnectroomchatService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/user/disconnectroom/' + param.room, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: "include", // include, *same-origin, omit
    });
     return await rawResponse.json();
  };

  return disconnectroomchatService()
}
