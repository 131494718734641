export const updatebookmarknotesSrv = (param) => {
  async function updatebookmarknotesService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/user/updatebookmarknotes/' + param.bookmark_state, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        topic_id: param.topic_id,
        notebook: param.bookmark_note,
        page: param.bookmark_page
      }),
      credentials: "include", // include, *same-origin, omit
    });
     return await rawResponse.json();
  };

  return updatebookmarknotesService()
}
