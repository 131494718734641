export default function(application) {
  const shutdownDialog = document.getElementById('shutdownDialog');    
  const muiBackdrop = document.querySelectorAll('.MuiBackdrop-root');
  const muiDialog = document.querySelectorAll('.MuiDialog-root');    
  let dialogIndex = 0;

  if(localStorage.getItem('movwin')){
    if(JSON.parse(localStorage.getItem('movwin')).windowMode === 0){
      for(let i=0; i<muiDialog.length ; i++){
        if(muiDialog[i].id === application){
          dialogIndex = i;
        }
      }

      if(muiDialog[dialogIndex]){
        muiDialog[dialogIndex].style.setProperty('width', '100%');
        muiDialog[dialogIndex].style.setProperty('left', '0px');
        muiDialog[dialogIndex].style.setProperty('height', '100%');
        muiDialog[dialogIndex].style.setProperty('top', '0px');

        if(JSON.parse(localStorage.getItem('movwin')).appCount === 1){
          muiDialog[dialogIndex].style.setProperty('z-index', '1300');
        }else if(JSON.parse(localStorage.getItem('movwin')).appCount === 2){
          if(dialogIndex === 1){
            muiDialog[dialogIndex].style.setProperty('z-index', '1300');
            muiDialog[dialogIndex + 1].style.setProperty('z-index', '1299');
          }else if(dialogIndex === 2){
            muiDialog[dialogIndex].style.setProperty('z-index', '1300');
            muiDialog[dialogIndex - 1].style.setProperty('z-index', '1299');
          }
        }
      }
    }else if(JSON.parse(localStorage.getItem('movwin')).windowMode === 1 && JSON.parse(localStorage.getItem('movwin')).appCount === 1){
      muiBackdrop[0].style.setProperty('width', 'calc(50% - 29px)');
      muiBackdrop[0].style.setProperty('left', 'calc(50% + 29px)');
      muiBackdrop[0].style.setProperty('height', 'calc(50% - 24px)');
      muiBackdrop[0].style.setProperty('top', '48px');

      if(shutdownDialog){
        shutdownDialog.style.setProperty('width', 'calc(50% - 29px)');
        shutdownDialog.style.setProperty('left', 'calc(50% - 29px)');
        shutdownDialog.style.setProperty('height', 'calc(50% - 24px)');
        shutdownDialog.style.setProperty('top', '0px');
      }

      if(muiBackdrop[1]){
        muiBackdrop[1].style.setProperty('width', 'calc(50% - 29px)');
        muiBackdrop[1].style.setProperty('left', '58px');
        muiBackdrop[1].style.setProperty('height', 'calc(50% - 24px)');
        muiBackdrop[1].style.setProperty('top', 'calc(50% + 24px)');
      }

      if(muiDialog[1]){
        muiDialog[1].style.setProperty('width', 'calc(50% - 29px)');
        muiDialog[1].style.setProperty('left', '58px');
        muiDialog[1].style.setProperty('height', 'calc(50% - 24px)');
        muiDialog[1].style.setProperty('top', 'calc(50% + 24px)');
        muiDialog[1].style.setProperty('z-index', '1300');
      }

    }else if(JSON.parse(localStorage.getItem('movwin')).windowMode === 1 && JSON.parse(localStorage.getItem('movwin')).appCount === 2){
      muiBackdrop[0].style.setProperty('width', 'calc(50% - 29px)');
      muiBackdrop[0].style.setProperty('left', 'calc(50% + 29px)');
      muiBackdrop[0].style.setProperty('height', 'calc(50% - 24px)');
      muiBackdrop[0].style.setProperty('top', '48px');   

      if(shutdownDialog){
        shutdownDialog.style.setProperty('width', 'calc(50% - 29px)');
        shutdownDialog.style.setProperty('left', 'calc(50% - 29px)');
        shutdownDialog.style.setProperty('height', 'calc(50% - 24px)');  
        shutdownDialog.style.setProperty('top', '0px');
      }
      
      if(muiBackdrop[1]){
        muiBackdrop[1].style.setProperty('width', 'calc(50% - 29px)');
        muiBackdrop[1].style.setProperty('left', '58px');
        muiBackdrop[1].style.setProperty('height', 'calc(50% - 24px)');
        muiBackdrop[1].style.setProperty('top', 'calc(50% + 24px)');    
      }
  
      if(muiDialog[1]){
        muiDialog[1].style.setProperty('width', 'calc(50% - 29px)');
        muiDialog[1].style.setProperty('left', '58px');
        muiDialog[1].style.setProperty('height', 'calc(50% - 24px)');
        muiDialog[1].style.setProperty('top', 'calc(50% + 24px)');
        muiDialog[1].style.setProperty('z-index', '1300');
      }

      if(muiBackdrop[2]){
        muiBackdrop[2].style.setProperty('width', 'calc(50% - 29px)');
        muiBackdrop[2].style.setProperty('left', 'calc(50% + 29px)');
        muiBackdrop[2].style.setProperty('height', 'calc(50% - 24px)');
        muiBackdrop[2].style.setProperty('top', 'calc(50% + 24px)');
      }

      if(muiDialog[2]){
        muiDialog[2].style.setProperty('width', 'calc(50% - 29px)');
        muiDialog[2].style.setProperty('left', 'calc(50% + 29px)');
        muiDialog[2].style.setProperty('height', 'calc(50% - 24px)');
        muiDialog[2].style.setProperty('top', 'calc(50% + 24px)');
        muiDialog[2].style.setProperty('z-index', '1300');
      }
    }else if(JSON.parse(localStorage.getItem('movwin')).windowMode === 2  && JSON.parse(localStorage.getItem('movwin')).appCount === 1){
      muiBackdrop[0].style.setProperty('width', 'calc(50% - 29px)');
      muiBackdrop[0].style.setProperty('left', 'calc(50% + 29px)');
      muiBackdrop[0].style.setProperty('height', 'calc(50% - 24px)');
      muiBackdrop[0].style.setProperty('top', 'calc(50% + 24px)');   

      if(shutdownDialog){
        shutdownDialog.style.setProperty('width', 'calc(50% - 29px)');
        shutdownDialog.style.setProperty('left', 'calc(50% - 29px)');
        shutdownDialog.style.setProperty('height', 'calc(50% - 24px)');  
        shutdownDialog.style.setProperty('top', 'calc(50% - 24px )');
      }
      
      if(muiBackdrop[1]){
        muiBackdrop[1].style.setProperty('width', 'calc(50% - 29px)');
        muiBackdrop[1].style.setProperty('left', '58px');
        muiBackdrop[1].style.setProperty('height', 'calc(50% - 24px)');
        muiBackdrop[1].style.setProperty('top', '48px');    
      }

      if(muiDialog[1]){
        muiDialog[1].style.setProperty('width', 'calc(50% - 29px)');
        muiDialog[1].style.setProperty('left', '58px');
        muiDialog[1].style.setProperty('height', 'calc(50% - 24px)');
        muiDialog[1].style.setProperty('top', '48px');
        muiDialog[1].style.setProperty('z-index', '1300');
      }
    }else if(JSON.parse(localStorage.getItem('movwin')).windowMode === 2  && JSON.parse(localStorage.getItem('movwin')).appCount === 2){
      muiBackdrop[0].style.setProperty('width', 'calc(50% - 29px)');
      muiBackdrop[0].style.setProperty('left', 'calc(50% + 29px)');
      muiBackdrop[0].style.setProperty('height', 'calc(50% - 24px)');
      muiBackdrop[0].style.setProperty('top', 'calc(50% + 24px)');   

      if(shutdownDialog){
        shutdownDialog.style.setProperty('width', 'calc(50% - 29px)');
        shutdownDialog.style.setProperty('left', 'calc(50% - 29px)');
        shutdownDialog.style.setProperty('height', 'calc(50% - 24px)');  
        shutdownDialog.style.setProperty('top', 'calc(50% - 24px )');
      }
      
      if(muiBackdrop[1]){
        muiBackdrop[1].style.setProperty('width', 'calc(50% - 29px)');
        muiBackdrop[1].style.setProperty('left', '58px');
        muiBackdrop[1].style.setProperty('height', 'calc(50% - 24px)');
        muiBackdrop[1].style.setProperty('top', '48px');    
      }

      if(muiDialog[1]){
        muiDialog[1].style.setProperty('width', 'calc(50% - 29px)');
        muiDialog[1].style.setProperty('left', '58px');
        muiDialog[1].style.setProperty('height', 'calc(50% - 24px)');
        muiDialog[1].style.setProperty('top', '48px');
        muiDialog[1].style.setProperty('z-index', '1300');
      }

      if(muiBackdrop[2]){
        muiBackdrop[2].style.setProperty('width', 'calc(50% - 29px)');
        muiBackdrop[2].style.setProperty('left', '58px');
        muiBackdrop[2].style.setProperty('height', 'calc(50% - 24px)');
        muiBackdrop[2].style.setProperty('top', 'calc(50% + 24px)');    
      }

      if(muiDialog[2]){
        muiDialog[2].style.setProperty('width', 'calc(50% - 29px)');
        muiDialog[2].style.setProperty('left', '58px');
        muiDialog[2].style.setProperty('height', 'calc(50% - 24px)');
        muiDialog[2].style.setProperty('top', 'calc(50% + 24px)');   
        muiDialog[2].style.setProperty('z-index', '1300');     
      }
    }else if(JSON.parse(localStorage.getItem('movwin')).windowMode === 3 && JSON.parse(localStorage.getItem('movwin')).appCount === 1){
      muiBackdrop[0].style.setProperty('width', 'calc(50% - 29px)');
      muiBackdrop[0].style.setProperty('left', '58px');
      muiBackdrop[0].style.setProperty('height', 'calc(50% - 24px)');
      muiBackdrop[0].style.setProperty('top', 'calc(50% + 24px)');   

      if(shutdownDialog){
        shutdownDialog.style.setProperty('width', 'calc(50% - 29px)');
        shutdownDialog.style.setProperty('left', '0px');
        shutdownDialog.style.setProperty('height', 'calc(50% - 24px)');  
        shutdownDialog.style.setProperty('top', 'calc(50% - 24px )');
      }
      
      if(muiBackdrop[1]){
        muiBackdrop[1].style.setProperty('width', 'calc(50% - 29px)');
        muiBackdrop[1].style.setProperty('left', 'calc(50% + 29px)');
        muiBackdrop[1].style.setProperty('height', 'calc(50% - 24px)');
        muiBackdrop[1].style.setProperty('top', '48px');    
      }

      if(muiDialog[1]){
        muiDialog[1].style.setProperty('width', 'calc(50% - 29px)');
        muiDialog[1].style.setProperty('left', 'calc(50% + 29px)');
        muiDialog[1].style.setProperty('height', 'calc(50% - 24px)');
        muiDialog[1].style.setProperty('top', '48px');
        muiDialog[1].style.setProperty('z-index', '1300');
      }
    }else if(JSON.parse(localStorage.getItem('movwin')).windowMode === 3 && JSON.parse(localStorage.getItem('movwin')).appCount === 2){
      muiBackdrop[0].style.setProperty('width', 'calc(50% - 29px)');
      muiBackdrop[0].style.setProperty('left', '58px');
      muiBackdrop[0].style.setProperty('height', 'calc(50% - 24px)');
      muiBackdrop[0].style.setProperty('top', 'calc(50% + 24px)');   

      if(shutdownDialog){
        shutdownDialog.style.setProperty('width', 'calc(50% - 29px)');
        shutdownDialog.style.setProperty('left', '0px');
        shutdownDialog.style.setProperty('height', 'calc(50% - 24px)');  
        shutdownDialog.style.setProperty('top', 'calc(50% - 24px )');
      }
      
      if(muiBackdrop[1]){
        muiBackdrop[1].style.setProperty('width', 'calc(50% - 29px)');
        muiBackdrop[1].style.setProperty('left', 'calc(50% + 29px)');
        muiBackdrop[1].style.setProperty('height', 'calc(50% - 24px)');
        muiBackdrop[1].style.setProperty('top', '48px');    
      }

      if(muiDialog[1]){
        muiDialog[1].style.setProperty('width', 'calc(50% - 29px)');
        muiDialog[1].style.setProperty('left', 'calc(50% + 29px)');
        muiDialog[1].style.setProperty('height', 'calc(50% - 24px)');
        muiDialog[1].style.setProperty('top', '48px');
        muiDialog[1].style.setProperty('z-index', '1300');
      }

      if(muiBackdrop[2]){
        muiBackdrop[2].style.setProperty('width', 'calc(50% - 29px)');
        muiBackdrop[2].style.setProperty('left', '58px');
        muiBackdrop[2].style.setProperty('height', 'calc(50% - 24px)');
        muiBackdrop[2].style.setProperty('top', '48px');    
      }

      if(muiDialog[2]){
        muiDialog[2].style.setProperty('width', 'calc(50% - 29px)');
        muiDialog[2].style.setProperty('left', '58px');
        muiDialog[2].style.setProperty('height', 'calc(50% - 24px)');
        muiDialog[2].style.setProperty('top', '48px');
        muiDialog[2].style.setProperty('z-index', '1300');
      }
    }else if(JSON.parse(localStorage.getItem('movwin')).windowMode === 4 && JSON.parse(localStorage.getItem('movwin')).appCount === 1){
      muiBackdrop[0].style.setProperty('width', 'calc(50% - 29px)');
      muiBackdrop[0].style.setProperty('left', '58px');
      muiBackdrop[0].style.setProperty('height', 'calc(50% - 24px)');
      muiBackdrop[0].style.setProperty('top', '48px');

      if(shutdownDialog){
        shutdownDialog.style.setProperty('width', 'calc(50% - 29px)');
        shutdownDialog.style.setProperty('left', '0px');
        shutdownDialog.style.setProperty('height', 'calc(50% - 24px)');  
        shutdownDialog.style.setProperty('top', '0px');
      }

      if(muiBackdrop[1]){
        muiBackdrop[1].style.setProperty('width', 'calc(50% - 29px)');
        muiBackdrop[1].style.setProperty('left', 'calc(50% + 29px)');
        muiBackdrop[1].style.setProperty('height', 'calc(50% - 24px)');
        muiBackdrop[1].style.setProperty('top', 'calc(50% + 24px)');    
      }

      if(muiDialog[1]){
        muiDialog[1].style.setProperty('width', 'calc(50% - 29px)');
        muiDialog[1].style.setProperty('left', 'calc(50% + 29px)');
        muiDialog[1].style.setProperty('height', 'calc(50% - 24px)');
        muiDialog[1].style.setProperty('top', 'calc(50% + 24px)');
        muiDialog[1].style.setProperty('z-index', '1300');
      }
    }else if(JSON.parse(localStorage.getItem('movwin')).windowMode === 4 && JSON.parse(localStorage.getItem('movwin')).appCount === 2){

      muiBackdrop[0].style.setProperty('width', 'calc(50% - 29px)');
      muiBackdrop[0].style.setProperty('left', '58px');
      muiBackdrop[0].style.setProperty('height', 'calc(50% - 24px)');
      muiBackdrop[0].style.setProperty('top', '48px');

      if(shutdownDialog){
        shutdownDialog.style.setProperty('width', 'calc(50% - 29px)');
        shutdownDialog.style.setProperty('left', '0px');
        shutdownDialog.style.setProperty('height', 'calc(50% - 24px)');  
        shutdownDialog.style.setProperty('top', '0px');
      }

      if(muiBackdrop[1]){
        muiBackdrop[1].style.setProperty('width', 'calc(50% - 29px)');
        muiBackdrop[1].style.setProperty('left', 'calc(50% + 29px)');
        muiBackdrop[1].style.setProperty('height', 'calc(50% - 24px)');
        muiBackdrop[1].style.setProperty('top', 'calc(50% + 24px)');    
      }

      if(muiDialog[1]){
        muiDialog[1].style.setProperty('width', 'calc(50% - 29px)');
        muiDialog[1].style.setProperty('left', 'calc(50% + 29px)');
        muiDialog[1].style.setProperty('height', 'calc(50% - 24px)');
        muiDialog[1].style.setProperty('top', 'calc(50% + 24px)');
        muiDialog[1].style.setProperty('z-index', '1300');
      }

      if(muiBackdrop[2]){
        muiBackdrop[2].style.setProperty('width', 'calc(50% - 29px)');
        muiBackdrop[2].style.setProperty('left', 'calc(50% + 29px)');
        muiBackdrop[2].style.setProperty('height', 'calc(50% - 24px)');
        muiBackdrop[2].style.setProperty('top', '48px');
      }

      if(muiDialog[2]){
        muiDialog[2].style.setProperty('width', 'calc(50% - 29px)');
        muiDialog[2].style.setProperty('left', 'calc(50% + 29px)');
        muiDialog[2].style.setProperty('height', 'calc(50% - 24px)');
        muiDialog[2].style.setProperty('top', '48px');
        muiDialog[2].style.setProperty('z-index', '1300');
      }
    }
  }
}
