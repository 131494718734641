import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';

import JoditEditor from 'jodit-react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import LinearProgress from '@material-ui/core/LinearProgress';
import CancelSharpIcon from '@material-ui/icons/Close';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import BorderInnerIcon from '@material-ui/icons/BorderInner';
import { makeStyles} from '@material-ui/core/styles';
import { themeHlp }  from '../Helper/ThemeHelper.js';
import  appResizeWindowHlp  from '../Helper/AppResizeWindowHelper.js';
import { getfilesSrv } from '../Service/GetFilesService.js';
import { getsharedfilesSrv } from '../Service/GetSharedFilesService.js';
import { createmvsfileSrv } from '../Service/CreateMvsFileService.js';
import { editmvsfileSrv } from '../Service/EditMvsFileService.js';
import { getmvsfileSrv } from '../Service/GetMvsFileService.js';
import { styled } from '@material-ui/core/styles';
import '../AppsStyle.css';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: themeHlp().palette.blackText.light,
    backgroundColor: themeHlp().palette.secondary.light,  
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 16,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        color: themeHlp().palette.blackText.main,        
      },
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  menu_box:{
    width:'100%', 
    height:'340px', 
    margin:'auto auto', 
    background: 'linear-gradient(135deg, rgb(38, 93, 140) 0%, rgb(38, 93, 140) 15%, rgb(0, 140, 183) 50%)', 
    opacity:'1', 
    borderRadius:'15px', 
    border:'4px solid #f5a33e'
  },
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: themeHlp().palette.secondary.light,
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  doc_reader: {
    width: '100%',
    border: '0px'
  },
  user_avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: 'auto',
    cursor: 'pointer'
  },
  dialog_content: {
    padding: '0px',
  },
  text_box: {
    width:'160px',
    backgroundColor: 'white'
  },
  iconbutton: {
    width:'36px',
    height: '36px',
    color: themeHlp().palette.primary.light,
    '&:hover' : {
      color: themeHlp().palette.primary.main
    },
    '&:active' : {
      color: themeHlp().palette.primary.dark,    
    }
  },
  file_icon: {
    width: '24px',
    height: '24px',     
    backgroundRepeat: 'no-repeat',
    backgroundSize: '22px',
    backgroundPosition: '0px 2px',
    padding: '0 24px 0 0',
  },
  upload_file: {
    display: 'none'
  },
  process_dialog_content: { 
    overflow: 'hidden', 
    border: `1px solid ${ themeHlp().palette.greyText.light}`, 
    borderStyle:'solid none solid none', 
    backgroundColor: themeHlp().palette.secondary.light,
    '&.MuiDialogContent-root':{
      flex: 'none'
    }
  },
  process_messages_box: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'left',
    flexWrap: 'wrap',
    maxWidth: '1800px'
  },
  process_messages_effect:  {
    position: 'relative',
    padding: '0px',
    overflow: 'hidden',
  },
  linear_progress_box: {
    width: '200px',
    margin: 'auto auto',
    '& .MuiLinearProgress-root': {
      backgroundColor: themeHlp().palette.secondary.light
    },
    '& .MuiLinearProgress-bar': {
      backgroundColor: themeHlp().palette.primary.light
    }   
  },
  /*jodit_editor: {
    '& .jodit-workplace': {
      width: '793.7007874px',
      height: '1122.519685px'
    }
  }*/
}));

let txtContent = '';
let timeoutAlert = {};

const DocumentReaderApp = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const zoomResetRef = useRef();
  const [fileUploadStarted, setFileUploadStarted] = useState(false);
  const [toolbarHidden, setToolbarHidden] = useState(false);
  const [txtSrc, setTxtSrc] = useState(null);
  const [txtContentLoad, setTxtContentLoad] = useState('');  
  const [readyForExport, setReadyForExport] = useState(false);
  const [docReaderParam, setDocReaderParam] = useState({});
  const [scale, setScale] = useState(1);
  const [mvsMode, setMvsMode] = useState('new');
  const [responseType, setResponseType] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [responseTime, setResponseTime] = useState(4);
  const editor = useRef(null);
  const titleDisplaySize = 670;

  const [anchorZoomEl, setAnchorZoomEl] = React.useState(null);
  const openZoom = Boolean(anchorZoomEl);
  const openZoomMenu = (event) => {
    setAnchorZoomEl(event.currentTarget);
  };
  const closeZoomMenu = () => {
    setAnchorZoomEl(null);
  };

  const [anchorExportEl, setAnchorExportEl] = React.useState(null);
  const openExport = Boolean(anchorExportEl);
  const openExportMenu = (event) => {
    setAnchorExportEl(event.currentTarget);
  };  
  const closeExportMenu = () => {
    setAnchorExportEl(null);
  };
  
  const editorConfig = {
    buttonsXS: ['cut', 'copy', 'paste', 'undo', 'redo', '|', 'paragraph', 'font', 'fontsize', '|', 'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', '|', 'align', '|', 'ul', 'ol', '|', 'outdent', 'indent', '|', 'table', 'brush', '|', 'symbols', 'hr', '|', 'find', 'source', 'print', 'fullsize'],
    buttonsSM: ['cut', 'copy', 'paste', 'undo', 'redo', '|', 'paragraph', 'font', 'fontsize', '|', 'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', '|', 'align', '|', 'ul', 'ol', '|', 'outdent', 'indent', '|', 'table', 'brush', '|', 'symbols', 'hr', '|', 'find', 'source', 'print', 'fullsize'],
    buttonsMD: ['cut', 'copy', 'paste', 'undo', 'redo', '|', 'paragraph', 'font', 'fontsize', '|', 'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', '|', 'align', '|', 'ul', 'ol', '|', 'outdent', 'indent', '|', 'table', 'brush', '|', 'symbols', 'hr', '|', 'find', 'source', 'print', 'fullsize'],
    buttons: ['cut', 'copy', 'paste', 'undo', 'redo', '|', 'paragraph', 'font', 'fontsize', '|', 'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', '|', 'align', '|', 'ul', 'ol', '|', 'outdent', 'indent', '|', 'table', 'brush', '|', 'symbols', 'hr', '|', 'find', 'source', 'print', 'fullsize'],
    minHeight: window.innerHeight - 112,
   // width: '793.7007874px',
   // height: '1122.519685px',
    toolbar: true,
  height: 200,
    editorStyle: {
      
      background: '#27272E',      
    }
  }

  const handleClickOpen = () => {


    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;

    if(allApps.indexOf('documentreader') === -1 && JSON.parse(localStorage.getItem('movwin')).appCount <2){
      allApps.push('documentreader');
      setOpen(true);
      setFileUploadStarted(false);
      setTxtSrc(null);
      setReadyForExport(false);
      if(props.fileInfo?.shared_with){
        const getsharedfilesParams = {
          item_id: props.fileInfo?.item_id
        }
        if(getsharedfilesParams.item_id !== undefined){
            setMvsMode('new');
            getsharedfilesSrv(getsharedfilesParams)
            .then(async response => {            
              if(response && response.statusCode === 200){
                if(
                  props.fileInfo?.item_ext === 'doc' ||
                  props.fileInfo?.item_ext === 'docx' ||
                  props.fileInfo?.item_ext === 'xls' ||
                  props.fileInfo?.item_ext === 'xlsx' ||
                  props.fileInfo?.item_ext === 'ppt' ||
                  props.fileInfo?.item_ext === 'pptx' ||
                  props.fileInfo?.item_ext === 'odt' ||
                  props.fileInfo?.item_ext === 'ott' ||
                  props.fileInfo?.item_ext === 'ods' ||
                  props.fileInfo?.item_ext === 'ots' ||
                  props.fileInfo?.item_ext === 'odp' ||
                  props.fileInfo?.item_ext === 'otp' ||
                  props.fileInfo?.item_ext === 'rtf' ||
                  props.fileInfo?.item_ext === 'pdf'
                ){
                  setTxtSrc(response.data + '.html');                
                }else{
                  if(props.fileInfo?.item_ext !== 'mvsa'){
                    setTxtSrc(response.data);
                  }else{
                    setMvsMode('edit');
                    setTxtContentLoad(response.data);
                  }
                  
                }
              }
            }).catch(err => {
              console.log(err);
            })       
          
        }else{
          setMvsMode('edit');
        }
      }else{
        const getfilesParams = {
          item_id: props.fileInfo?.item_id
        }      
        if(getfilesParams.item_id !== undefined){
          if(props.fileInfo?.item_ext !== 'mvsa'){
            setMvsMode('new');
            getfilesSrv(getfilesParams)
            .then(response => {
              if(
                props.fileInfo?.item_ext === 'doc' ||
                props.fileInfo?.item_ext === 'docx' ||
                props.fileInfo?.item_ext === 'xls' ||
                props.fileInfo?.item_ext === 'xlsx' ||
                props.fileInfo?.item_ext === 'ppt' ||
                props.fileInfo?.item_ext === 'pptx' ||
                props.fileInfo?.item_ext === 'odt' ||
                props.fileInfo?.item_ext === 'ott' ||
                props.fileInfo?.item_ext === 'ods' ||
                props.fileInfo?.item_ext === 'ots' ||
                props.fileInfo?.item_ext === 'odp' ||
                props.fileInfo?.item_ext === 'otp' ||
                props.fileInfo?.item_ext === 'rtf' ||
                props.fileInfo?.item_ext === 'pdf'
              ){
                setTxtSrc(response.data + '.html');
                setReadyForExport(true);     
              }else{            
                setTxtSrc(response.data);               
              }
            }).catch(err => {
              console.log(err);
            })
          }else{
            setMvsMode('edit');
            const getmvsfileParams = {
              item_id: props.fileInfo?.item_id
            }
            getmvsfileSrv(getmvsfileParams)
            .then(response => {
              setTxtContentLoad(response.data)
            }).catch(err => {
              console.log(err);
            })
          }
        }else{
          setMvsMode('edit');
        }
      }
      
      localStorage.setItem('movwin', JSON.stringify({
        windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
        apps: allApps,
        appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount + 1
      }));    
      
      setTimeout(() => {
        appsInWindows('load');
      },100);
    }    
  };

  const handleClose = () => {
    setOpen(false);
    if(props.appCloses){
      props.appCloses(true);
    }

    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;
    if(allApps.indexOf('documentreader') !== -1){
      allApps.splice(allApps.indexOf('documentreader'), 1);
    }

    localStorage.setItem('movwin', JSON.stringify({
      windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
      apps: allApps,
      appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount - 1
    }));
  };

  const appsInWindows = (event) => {
    if(event === 'change'){
      if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).windowMode < 4){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode + 1,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount
        }));
      }else if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).windowMode === 4){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: 0,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount
        }));
      }

      if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).appCount === 0){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: 1
        }));
      }
    }

    setWindowWidth(windowWidth - 1);  
    appResizeWindowHlp('documentReaderDialogBox');
  }

  const goToEditFile = (fileExtension) => {       
    if(props.fileInfo?.item_ext === 'mvsa' && fileExtension === 'mvsa'){      
      const editmvsfileParams = {
        item_id: props.fileInfo?.item_id,
        content: txtContent
      }
      editmvsfileSrv(editmvsfileParams)
      .then(response => {
        if(response && response.statusCode === 200){
          setResponseTime(responseTime + 1);
          setResponseType('report');
          setResponseMessage(response.message);
        }else if(response.error && response.error.statusCode === 400){
          setResponseTime(responseTime + 1);
          setMvsMode('new');
          setResponseMessage(response.error.message);
        }else if(response.error && response.error.statusCode === 403){
          setResponseTime(responseTime + 1);
          setResponseType('error');          
          setResponseMessage(response.error.message);
        }        
      }).catch(err => {
        console.log(err);
      })
    }else{
      const parser = new DOMParser();
      const htmlString = txtContent;
      const exportedTxtContent = parser.parseFromString(htmlString, "text/html");
      const createmvsfileParams = {
        item_location: window.location.pathname,
        item_ext: fileExtension,
        content: fileExtension === 'mvsa' ? txtContent : exportedTxtContent.body.textContent
      }
      createmvsfileSrv(createmvsfileParams)
      .then(response => {
        if(response && response.statusCode === 201){
          window.location.reload();
        }else if(response.error && response.error.statusCode === 400){
          setMvsMode('new');          
        }
      }).catch(err => {
        console.log(err);
      })      
    }   
  }

  const uploadFileFnc = (event) => {    
    const file = event.target.files[0];
    setResponseType('');
    setResponseMessage('');
    if(
      file?.type === 'application/pdf' || //pdf
      file?.type === 'text/x-log' || //log
      file?.type === 'text/plain' || //txt      
      file?.type === 'text/html' || //html, htm
      file?.type === 'text/javascript' || //js
      file?.type === 'application/json' || //json
      file?.type === 'text/css' //css
    ){  
      setFileUploadStarted(true);
      setTxtSrc(null);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const uploaded_text = reader.result;
        setTxtSrc(uploaded_text);
        setFileUploadStarted(false);
      });      
      reader.readAsDataURL(file);
    }else if(
      file?.type === 'application/msword' || //doc
      file?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || //docx
      file?.type === 'application/vnd.ms-excel' || //xls
      file?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || //xlsx
      file?.type === 'application/vnd.ms-powerpoint' || //ppt
      file?.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || //pptx
      file?.type === 'application/vnd.oasis.opendocument.text' || //odt
      file?.type === 'application/vnd.oasis.opendocument.text-template' || //ott
      file?.type === 'application/vnd.oasis.opendocument.spreadsheet' || //ods
      file?.type === 'application/vnd.oasis.opendocument.spreadsheet-template' || //ots
      file?.type === 'application/vnd.oasis.opendocument.presentation' || //odp
      file?.type === 'application/vnd.oasis.opendocument.presentation-template' || //doc
      file?.type === 'application/rtf' || //rtf
      file?.type === 'application/pdf' //pdf
    ){
      //setResponseTime(responseTime + 1);
      setResponseType('warning');
      setResponseMessage('In order to read this file format, upload the file in the usual way to your storage, and then open it.');
    }else{
      if(file?.type !== undefined){  
        //setResponseTime(responseTime + 1);
        setResponseType('warning');
        setResponseMessage('File format "' + file?.type + '" not supported.');             
      }
    }
    setMvsMode('new');
  }

  const zoomReset = () => {
    setScale(1);
  }

  const zoomIn = () => {    
    if(scale < 1.7){
      setScale(scale + 0.1);
    }
  }

  const zoomOut = () => {    
    if(scale > 0.2){
      setScale(scale - 0.1);
    }
  }  

  const AlertBox = () => {    
    clearTimeout(timeoutAlert);
    if(responseType === 'error' || responseType === 'report'){
      timeoutAlert = setTimeout(() => {
        setResponseType('');
      }, 4000);
    }

    if(responseType === 'warning'){
      return (
        <DialogContent className={classes.process_dialog_content}>
          <Box className={classes.process_messages_box}>          
            <div className={classes.process_messages_effect}>
              <div style={{
                position: 'absolute',
                inset: '0',
                background: themeHlp().palette.secondary.light,
                borderLeft: '2px solid ' + themeHlp().palette.secondary.light,
                borderRadius: '10px',
                top:'6px',
                height: '14px',
                transform: 'translateX(0%)',
                animation:  'translateOverlay 0.4s forwards cubic-bezier(0.03, 0, 0.15, 1)',
              }}></div>
              <Avatar component="span" alt="Warning" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/warning.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} /> 
              <Typography variant="subtitle1" component="span" style={{ cursor: 'default'}}>{responseMessage}</Typography>
            </div>
          </Box>
        </DialogContent>
      )
    }else if(responseType === 'error'){
      return (
        <DialogContent className={classes.process_dialog_content}>
          <Box className={classes.process_messages_box}>          
            <div className={classes.process_messages_effect}>
              <div style={{
                position: 'absolute',
                inset: '0',
                background: themeHlp().palette.secondary.light,
                borderLeft: '2px solid ' + themeHlp().palette.secondary.light,
                borderRadius: '10px',
                top:'6px',
                height: '14px',
                transform: 'translateX(0%)',
                animation:  'translateOverlay 0.4s forwards cubic-bezier(0.03, 0, 0.15, 1)',
              }}></div>
              <Avatar component="span" alt="Error" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/error.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} /> 
              <Typography variant="subtitle1" component="span" style={{ cursor: 'default'}}>{responseMessage}</Typography>
            </div>
          </Box>
        </DialogContent>
      )
    }else if(responseType === 'report'){
      return (
        <DialogContent className={classes.process_dialog_content}>
          <Box className={classes.process_messages_box}>
            <div className={classes.process_messages_effect}>
              <div style={{
                position: 'absolute',
                inset: '0',
                background: themeHlp().palette.secondary.light,
                borderLeft: '2px solid ' + themeHlp().palette.secondary.light,
                borderRadius: '10px',
                top:'6px',
                height: '14px',
                transform: 'translateX(0%)',
                animation:  'translateOverlay 0.4s forwards cubic-bezier(0.03, 0, 0.15, 1)',
              }}></div>
              <Avatar component="span" alt="Report" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/report.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} /> 
              <Typography variant="subtitle1" component="span" style={{ cursor: 'default'}}>{responseMessage}</Typography>
            </div>
          </Box>
        </DialogContent>
      )
    }
  }

  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);

      setDocReaderParam({
        position: 'fixed',
        width: (windowWidth / scale) + 'px',
        height: ((windowHeight -118) / scale) + 'px',
        transform: `scale(${scale})`,
        transformOrigin: '0 0',
      });
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => {     
      window.removeEventListener('resize', updateSize); 
    }
  }, [scale, windowWidth, windowHeight]);

  useEffect(() => {
    handleClickOpen();
    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;

    if(allApps.indexOf('documentreader') === -1 && JSON.parse(localStorage.getItem('movwin')).appCount < 2){
      setOpen(true);
      setTimeout(() => {
        const muiBackdrop = document.querySelectorAll('.MuiBackdrop-root');
        let newApp = JSON.parse(localStorage.getItem('movwin')).apps;
        newApp.push('documentreader');
        
        if(muiBackdrop[2]){
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: newApp,
            appCount: 2
          })); 
        }else if(muiBackdrop[2] === undefined && muiBackdrop[1]) {          
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: newApp,
            appCount: 1
          }));        
        }else if(muiBackdrop[2] === undefined && muiBackdrop[1] === undefined) {        
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: [],
            appCount: 0,
          }));        
        }
        appsInWindows('load');      
      },100);
    }
   },[])

return (
  <>
    <Avatar title="Document Reader" component="span" variant="rounded" alt="Document Reader" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/documentreader.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={handleClickOpen} />
    <Typography title="Document Reader" variant="subtitle1" component="span" style={{ whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>Document Reader</Typography>
    <Dialog id="documentReaderDialogBox" fullScreen onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle
        style={{
          padding: '8px 16px',
          color:'#ffffff', 
          background:'linear-gradient(90deg, rgba(38,93,140,1) 0%, rgba(38,93,140,1) 15%, rgba(0,140,183,1) 100%)'
        }}
        id="customized-dialog-title" onClose={handleClose}
      >
        <Avatar component="span" variant="rounded" style={{width:'24px', height: '24px', display: 'inline-block', marginRight: '8px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/documentreader.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
        <span>{(windowWidth < titleDisplaySize && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) !== 0) || (windowWidth < (titleDisplaySize/2) && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) === 0) ? '' : 'Document Reader'}</span>
        <IconButton
          disabled={document.getElementById('shutdownDialog') ? false : true}
          aria-label="AppsInWindowsIcon"
          onClick={() => appsInWindows('change')}
          size="small"
          style={{
            background: themeHlp().palette.blueGrey.dark,
            color:'white',          
            margin:'2px 42px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px',
            opacity: document.getElementById('shutdownDialog') ? '1' : '0.3'          
          }}
        >
          <BorderInnerIcon style={{padding: '2px'}} />          
        </IconButton>
        <IconButton 
          aria-label="UnfoldLessMoreIcon"
          title={!toolbarHidden ? 'Hide Toolbar' : 'Show Toolbar'}
          onClick={()=> setToolbarHidden(current => !current)}
          size="small"
          style={{
            width: '18px',
            height: '18px',
            background: themeHlp().palette.blueGrey.dark,
            color:'white',  
            margin:'2px 74px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px'
          }}
        >
          {!toolbarHidden ? <UnfoldLessIcon style={{padding: '4px'}} /> : <UnfoldMoreIcon style={{padding: '4px'}} />}
        </IconButton>
        <IconButton 
          aria-label="CancelSharpIcon"
          title="Close"
          onClick={handleClose}
          size="small"
          style={{
            background: themeHlp().palette.blueGrey.dark,
            color:'white', 
            margin:'2px 10px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px'
          }}
        >
          <CancelSharpIcon style={{padding: '2px'}} /> 
        </IconButton>       
      </DialogTitle>
      {!toolbarHidden && 
      <DialogActions id="p0" className={classes.dialog_content}>
        <div id="p1" className={classes.root}>
          <input className={classes.upload_file} id="contained-button-file-image-browse" type="file" onChange={uploadFileFnc} />
          <List style={{ width: '100%', height: '64px', padding: '0px', overflowY: 'auto'}}>
            <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
              <label htmlFor="contained-button-file-image-browse">
                <Avatar title="Import Text" component="span" variant="rounded" alt="Import Text" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_import_text.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
              </label>
            </ListItem>
            {mvsMode === 'new' &&               
              <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
                <label>
                  <Avatar title="Create Document" component="span" variant="rounded" alt="Create Document" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_write_text_mode.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={() => {setMvsMode('edit'); setResponseType('');}} />
                </label>
              </ListItem>
            }
            {mvsMode === 'edit' && 
              <>
                {/*props.fileInfo?.item_ext !== undefined &&*/}
                {typeof(txtSrc) === 'string' && responseMessage === '' &&
                  <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
                    <label>
                      <Avatar title="Read Mode" component="span" variant="rounded" alt="Read Mode" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_read_text_mode.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={() => {setMvsMode('new'); setResponseType('');}} />
                    </label>
                  </ListItem>
                }
                <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
                  <label>
                    <Avatar title="Save" component="span" variant="rounded" alt="Save Mvsa" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_save_mvs.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={() => goToEditFile('mvsa')} />
                  </label>
                </ListItem>            
                <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
                  <label>
                    <Avatar title="Export as" component="span" variant="rounded" alt="Export as" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_export_as.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={openExportMenu} />
                  </label>                    
                </ListItem>
                <div>
                  <StyledMenu
                    id="document-export-menu"
                    MenuListProps={{
                      'aria-labelledby': 'document-export-menu',
                    }}
                    anchorEl={anchorExportEl}
                    open={openExport}
                    onClose={closeExportMenu}
                  >
                    <MenuItem onClick={() => goToEditFile('txt')}>
                      <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_export_as_txt.png)'}}></span>
                      Export as TXT
                    </MenuItem>
                    <MenuItem onClick={() => goToEditFile('log')}>
                      <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_export_as_log.png)'}}></span>
                      Export as LOG
                    </MenuItem>
                    <MenuItem onClick={() => goToEditFile('html')}>
                      <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_export_as_html.png)'}}></span>
                      Export as HTML
                    </MenuItem>  
                    <MenuItem onClick={() => goToEditFile('css')}>
                      <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_export_as_css.png)'}}></span>
                      Export as CSS
                    </MenuItem>
                  </StyledMenu>
                </div>
              </>
            }
            {(txtSrc !== null && mvsMode === 'new') &&
              <>
                <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
                  <label>
                    <Avatar title="Zoom" component="span" variant="rounded" alt="Zoom" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_zoom_reset.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={openZoomMenu}/>
                  </label>
                </ListItem>
                <div>
                  <StyledMenu
                    id="image-zoom-menu"
                    MenuListProps={{
                      'aria-labelledby': 'image-zoom-menu',
                    }}
                    anchorEl={anchorZoomEl}
                    open={openZoom}
                    onClose={closeZoomMenu}
                  >
                    <MenuItem ref={zoomResetRef} onClick={zoomReset}>
                      <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_zoom_reset.png)'}}></span>
                      Zoom Reset
                    </MenuItem>
                    <MenuItem onClick={zoomIn}>
                      <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_zoom_in.png)'}}></span>
                      Zoom In
                    </MenuItem>
                    <MenuItem onClick={zoomOut}>
                      <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_zoom_out.png)'}}></span>
                      Zoom Out
                    </MenuItem>
                  </StyledMenu>
                </div>
              </>
            }
            {readyForExport && 
            <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
              <label htmlFor="contained-button-file-image-browse">
                <Link href={txtSrc.includes('pdf') ? txtSrc.replace('.html', '') : txtSrc.replace('.html', '.pdf')} underline='none' style={{color:'white'}} target='_blank' download>
                  <Avatar title="Export as PDF" component="span" variant="rounded" alt="Export as PDF" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_export_as_pdf.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
                </Link>
              </label>
            </ListItem>
            }            
          </List>          
        </div>
      </DialogActions>
      }
      {responseType !== '' &&  <AlertBox />}
      <DialogContent style={{padding: 0}}>
        {fileUploadStarted &&
          <>
            <Typography variant="subtitle2" component="div" style={{fontSize: '14px', marginTop: '20px', color: themeHlp().palette.primary.main, /*padding: '0 10px',*/ borderRadius: '10px', textAlign: 'center'}}>
              Opening...
            </Typography>
            <Box className={classes.linear_progress_box}>
              <LinearProgress value={10}/>
            </Box>
          </>
        }
        {mvsMode === 'edit' &&
          <JoditEditor            
            ref={editor}
            className={classes.jodit_editor}
            config={editorConfig}            
            value={txtContentLoad}
            onChange={value => {txtContent = value}}
          />        
        }
        {mvsMode === 'new' && typeof(txtSrc) === 'string' && responseMessage === '' &&
          <div style={{position: 'relative'}}>
            <iframe id="docReader" className={classes.doc_reader} src={txtSrc} style={docReaderParam} />
          </div>        
        }
      </DialogContent>
    </Dialog>
  </>
)
}
 
export default DocumentReaderApp;
