import React, { useState, useEffect } from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import HomeIcon from '@material-ui/icons/Home';
import { themeHlp } from '../Helper/ThemeHelper.js';

const theme = createMuiTheme({
  typography:{
    fontFamily: [
      'Inter', 
      'Helvetica',
      'Arial',
      'Sans-Serif'
    ]
  }
});

const useStyles = makeStyles(() => ({
  page_container: {
    position: 'relative',
    height: '0px',
    minHeight: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  page_container_for_welcomepage: {
    position: 'relative'
  },
  headline: {
    fontSize: '28px'
  },
  subHeadline: {
    color: themeHlp().palette.primary.dark
  },
  screenshot: {
    paddingTop: '15px', 
    width:'90%', 
    maxWidth: '960px', 
    display: 'block', 
    margin: 'auto auto'
  },  
  screenshot_sm: {
    paddingTop: '15px', 
    width:'90%', 
    maxWidth: '200px', 
    display: 'block', 
    margin: 'auto auto'
  },
  screenshot_md: {
    paddingTop: '15px', 
    width:'90%', 
    maxWidth: '560px', 
    display: 'block', 
    margin: 'auto auto'
  },
  screenshot_lg: {
    paddingTop: '15px', 
    width:'90%', 
    maxWidth: '360px', 
    display: 'block', 
    margin: 'auto auto'
  },
  casual_text: {
    fontFamily: 'Helvetica',
    fontSize: '1.065rem'
  },
  helpButtonStyle: {
    width: '30px',
    height: '30px',
    margin: '10px 2px 0 2px',
    color:'#fff',
    background: themeHlp().palette.primary.gradientRadial,
    '&:hover': {
      background: themeHlp().palette.primary.gradientRadialHover,
   },
  } 
}))


const AppsGuide = () => {
  const classes = useStyles();
  let navigate = useNavigate();
  let location = useLocation();  
  const [expanded, setExpanded] = useState('');
  const [historyButtons, setHistoryButtons] = useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
 
  useEffect(() => {
    if(location.pathname.includes('help')){
      setHistoryButtons(true);
    }
  },[])

  return (
    <>
      <ThemeProvider theme={theme} >
        <Box sx={{ flexGrow: 1 }} className={historyButtons ? classes.page_container : classes.page_container_for_welcomepage}>
          <Grid container>
            <Grid container align="center" spacing={2} justify="center" style={{margin: 'auto'}}>
              <Grid item xs={12} style={{ backgroundImage: 'linear-gradient(to bottom, rgba(255,0,0,0), rgba(255,255,255,1))'}}>
              {historyButtons && 
                  <Box>
                    <IconButton title="Back" aria-label="fingerprint" color="secondary" className={classes.helpButtonStyle} onClick={() => navigate(-1)}>
                      <ArrowBackIcon />
                    </IconButton>
                    <IconButton title="Home" aria-label="fingerprint" color="secondary" className={classes.helpButtonStyle} onClick={() => navigate('/help')}>
                      <HomeIcon />
                    </IconButton>
                    <IconButton title="Forward" aria-label="fingerprint" color="secondary" className={classes.helpButtonStyle} onClick={() => navigate(1)}>
                      <ArrowForwardIcon />
                    </IconButton>
                  </Box>
                }
                <Typography className={classes.headline}  variant="subtitle1" component="span">
                  Apps
                </Typography>
              </Grid>
            </Grid>
            <Accordion expanded={expanded === 'panel1'} style={{margin:0, width:'100%'}} onChange={handleChange('panel1')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"          
              >
                <Typography className={classes.subHeadline}>
                  Account
                </Typography>          
              </AccordionSummary>
              <AccordionDetails>
              <Box sx={{width:'100%'}}>
                <Typography variant="inherit" className={classes.casual_text} >
                  Account information instantly appears here once you open "Account" application.
                  "Account Id" will be generated automatically and provided to you as a unique id.
                  Login(username) shows your email address through which you have registered.
                </Typography>
                <Divider light />   
                <img className={classes.screenshot} alt="apps screenshot 01" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/apps/apps_screenshot_01.jpg'}`}/>
              </Box>
              </AccordionDetails>
            </Accordion>      
            <Accordion expanded={expanded === 'panel2'} style={{margin:0, width:'100%'}} onChange={handleChange('panel2')}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography className={classes.subHeadline}>
                  File Finder
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{width:'100%'}}>
                  <Typography variant="inherit" className={classes.casual_text}>
                    - Are you having trouble finding files? 
                    <br />
                    "File finder" app comes to the rescue! 
                    By choosing "Find own files" from the drop-down list, type keyword that you know appear in the file name, and it will be shown instantly.
                    Even if you type in a few case-insensitive letters that will match your filenames, they will be shown as below image.
                    <br />
                    There are two more options for shared files. 
                    "Find your own shared files" shows your public items.
                    "Find shared files addressed to me" shows the files of other users who have shared with you.
                  </Typography>
                  <Divider light />
                  <Typography variant="subtitle2" style={{textAlign: 'center', marginTop: '10px'}}>
                    Find own files
                  </Typography> 
                  <img className={classes.screenshot} alt="apps screenshot 02-1" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/apps/apps_screenshot_02_1.jpg'}`}/>
                  <Typography variant="subtitle2" style={{textAlign: 'center', marginTop: '10px'}}>
                    Find own shared files
                  </Typography> 
                  <img className={classes.screenshot} alt="apps screenshot 02-2" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/apps/apps_screenshot_02_2.jpg'}`}/>
                  <Typography variant="subtitle2" style={{textAlign: 'center', marginTop: '10px'}}>
                    Find shared files addressed to me
                  </Typography> 
                  <img className={classes.screenshot} alt="apps screenshot 02-3" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/apps/apps_screenshot_02_3.jpg'}`}/>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} style={{margin:0, width:'100%'}} onChange={handleChange('panel3')}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography className={classes.subHeadline}>
                  Contact
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{width:'100%'}}>
                  <Typography variant="inherit" className={classes.casual_text}>
                  If in the list of users you have already added a user with whom you want to connect, click on the appropriate name to send and receive messages,
                  otherwise see
                  </Typography>
                  <Link href="/help/users" underline="none"> Users/Find other users</Link>.
                  <Divider light />
                  <Typography variant="subtitle2" style={{textAlign: 'center', marginTop: '10px'}}>
                    Message
                  </Typography> 
                  <img className={classes.screenshot} alt="apps screenshot 03-1" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/apps/apps_screenshot_03_1.jpg'}`}/>
                  <Typography variant="subtitle2" style={{textAlign: 'center', marginTop: '10px'}}>
                    Chat Group
                  </Typography> 
                  <Typography variant="inherit" className={classes.casual_text}>
                    Unlike "Message" tab, where you need to find a user to connect, "Chat Group" allows you to create a room using room ID and password.
                    If that room ID already exists, you need to enter the correct password which was set at creation in order to connect to the existing room.
                  </Typography>
                  <img className={classes.screenshot} alt="apps screenshot 03-2" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/apps/apps_screenshot_03_2.jpg'}`}/>
                  <img className={classes.screenshot} alt="apps screenshot 03-3" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/apps/apps_screenshot_03_3.jpg'}`}/>
                  <Typography variant="subtitle2" style={{textAlign: 'center', marginTop: '10px'}}>
                    Video Meeting
                  </Typography> 
                  <Typography variant="inherit" className={classes.casual_text}>
                    "Video Meeting" is a real opportunity to discuss issues that might be related to own files, shared files and more.
                    <br />
                    Let's see how to join:
                    <br />
                    In the "Contact" app, go to the "Video Meeting" tab. 
                    As below image, you will see video images from your device's camera.
                    If you don't see anything like this, make sure your device is connected and working properly.
                    After that, copy your connection ID and send to the partner. See
                  </Typography>
                  <Link underline="none" style={{cursor: 'pointer'}} onClick={() => {setExpanded(''); setTimeout(() => {setExpanded('panel3')},600) }}> how to send a message</Link>.
                  <br />
                  <Typography variant="inherit" className={classes.casual_text}>
                    Having received the connection ID, your partner needs to insert it into the "ID to call" field and press the right connect button. Enjoy your video conference!
                  </Typography>
                  <img className={classes.screenshot} alt="apps screenshot 03-4" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/apps/apps_screenshot_03_4.jpg'}`}/>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} style={{margin:0, width:'100%'}} onChange={handleChange('panel4')}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography className={classes.subHeadline}>
                  Image Viewer
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{width:'100%'}}>
                  <Typography variant="inherit" className={classes.casual_text}>
                    The MovesAs has integrated programs like "Image Viewer" to help the user to know about the file in more detail.
                    The "Image Viewer" can be opened from the "Properties" panel, from application menu, and via the "File Finder" application.
                    <br />
                    The following graphic files are allowed to be imported: "avif", "ico", "cur", "bmp", "gif", "png", "jpeg", "jpg", "svg", "webp".
                    <br />
                    On the toolbar you can see the following commands:
                    <ol>
                      <li>Import Image</li>
                      <li>Zoom Reset</li>
                      <li>Zoom In</li>
                      <li>Zoom Out</li>
                    </ol>
                  </Typography>
                  <Divider light />
                  <img className={classes.screenshot} alt="apps screenshot 04" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/apps/apps_screenshot_04.jpg'}`}/>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel5'} style={{margin:0, width:'100%'}} onChange={handleChange('panel5')}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5bh-content"
                id="panel5bh-header"
              >
                <Typography className={classes.subHeadline}>
                  Media Player
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{width:'100%'}}>
                  <Typography variant="inherit" className={classes.casual_text}>
                    Media player is designed to play video and audio files. Supports the following audio and video file formats: "aac", "m4a", "mp3", "oga", "ogg", "opus", "3gp", "wav", "mkv", "mov", "mp4", "webm".
                    <br />
                    <ol>
                      <li>Play, pause</li>
                      <li>Move forward 1 frame</li>
                      <li>Move backward 1 frame</li>
                      <li>Jump forward 5 seconds</li>
                      <li>Jump backward 5 seconds</li>
                      <li>Show audio settings</li>
                      <li>Volume</li>
                      <li>Timeline</li>
                      <li>Screen modes</li>
                      <li>Import audio or video</li>
                    </ol>
                  </Typography>
                  <Divider light />
                  <img className={classes.screenshot} alt="apps screenshot 06" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/apps/apps_screenshot_06.jpg'}`}/>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel6'} style={{margin:0, width:'100%'}} onChange={handleChange('panel6')}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel6bh-content"
                id="panel10bh-header"
              >
                <Typography className={classes.subHeadline}>
                  Document Reader
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{width:'100%'}}>
                  <Typography variant="inherit" className={classes.casual_text}>
                    In the current version, "doc", "docx", "xls", "xlsx", "ppt", "pptx", "odt", "ott", "ods", "ots", "odp", "otp", "txt", "log", "pdf", "rtf", "html", "htm"
                    file formats are supported by "Document Reader".
                    The above files can be opened from the property if they are already uploaded to the MovesAs.
                    Some files cannot be opened directly via "Import text".
                    <br />
                    Below are the commands that can be found on the toolbar.
                    <ol>
                    <li>Import text</li>
                    <li>Export as PDF file</li>
                    <li>Zoom Reset</li>
                    <li>Zoom In</li>
                    <li>Zoom Out</li>
                  </ol>
                  </Typography>
                  <Divider light />
                  <img className={classes.screenshot} alt="apps screenshot 06" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/apps/apps_screenshot_06.jpg'}`}/>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel10'} style={{margin:0, width:'100%'}} onChange={handleChange('panel10')}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel10bh-content"
                id="panel7bh-header"
              >
                <Typography className={classes.subHeadline}>
                  Link Storage
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{width:'100%'}}>
                  <Typography variant="inherit" className={classes.casual_text}>
                    Link Storage is designed to save links from different sources and view them in the same window.                   
                  </Typography>                  
                  <div><img className={classes.screenshot} alt="apps screenshot 10-1" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/apps/apps_screenshot_10_1.jpg'}`}/></div>
                  <Typography variant="inherit" className={classes.casual_text}>
                    In order to view link content, paste the url into the "URL" field and then click the "Play Link" button.
                    <br />
                    The url can easily be added to the list by clicking the "Add Link" button. 
                    You can also write a comment on that link to make the search easier․
                  </Typography>                  
                  <div><img className={classes.screenshot_md} style={{ display: 'inline'}} alt="apps screenshot 10-2" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/apps/apps_screenshot_10_2.jpg'}`}/></div>
                  <br />
                  <Divider light />
                  <Typography variant="inherit" className={classes.casual_text}>
                    As soon as you want to change the url or comment, buttons open below the link where you can save or cancel.
                  </Typography>
                  <div><img className={classes.screenshot_sm} style={{ display: 'inline'}} alt="apps screenshot 10-3" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/apps/apps_screenshot_10_3.jpg'}`}/></div>
                  <br />
                  <Divider light />
                  <Typography variant="inherit" className={classes.casual_text}>
                    By clicking the "Search" button on the tab bar, you can enter a keyword by URL or comment. And from a large list the links you need will be displayed.
                  </Typography>
                  <div><img className={classes.screenshot_md} style={{ display: 'inline'}} alt="apps screenshot 10-4" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/apps/apps_screenshot_10_4.jpg'}`}/></div>
                  <br />
                  <Divider light />
                  <Typography variant="inherit" className={classes.casual_text}>
                    Here is the "Resize" menu to control the width and height of the page.
                  </Typography>
                  <div><img className={classes.screenshot_lg} style={{ display: 'inline'}} alt="apps screenshot 10-5" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/apps/apps_screenshot_10_5.jpg'}`}/></div>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {historyButtons &&
          <Grid container spacing={2} style={{margin: 'auto'}}>
            <Grid item xs={12} style={{height: '76px',  backgroundImage: 'linear-gradient(to top, rgba(255,0,0,0), rgba(255,255,255,1))'}}></Grid>
          </Grid>
          }
        </Box>
      </ThemeProvider>
    </>      
  )
} 
 
export default AppsGuide;
