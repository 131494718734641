import React, {useState, useEffect, useContext} from 'react';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import { themeHlp }  from './Helper/ThemeHelper.js'

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ShareIcon from '@material-ui/icons/Share';
import PropertyWindowMaximizeIcon from '@material-ui/icons/Pages';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { getfilesSrv } from './Service/GetFilesService.js';
import { getfilesinfoSrv } from './Service/GetFilesInfoService.js';
import { updatefilesSrv } from './Service/UpdateFilesService.js';
import { deletefilesSrv } from './Service/DeleteFilesService.js';
import { loadfilesSrv } from './Service/LoadFilesService.js';
import { movefilesSrv } from './Service/MoveFilesService.js';
import { copyfilesSrv } from './Service/CopyFilesService.js';
import { lockfilesSrv } from './Service/LockFilesService.js';
import { unlockfilesSrv } from './Service/UnlockFilesService.js';
import { loadsharedfilesSrv } from './Service/LoadSharedFilesService.js';
import { createsharedfilesSrv } from './Service/CreateSharedFilesService.js';
import { updatesharedfilesSrv } from './Service/UpdateSharedFilesService.js';
import { compressfilesSrv } from './Service/CompressFilesService.js';
import { decompressfilesSrv } from './Service/DecompressFilesService.js';
import { deletesharedfilesSrv } from './Service/DeleteSharedFilesService.js';
import { getusermessageSrv } from './AdtService/GetUserMessageService.js';
import {PropertiesContext} from './Fields';
import FileFinderApp from './Apps/FileFinderApp';
import ImageViewerApp from './Apps/ImageViewerApp';
import MediaPlayerApp from './Apps/MediaPlayerApp';
import DocumentReaderApp from './Apps/DocumentReaderApp';
import ArchiveReaderApp from './Apps/ArchiveReaderApp';
import CodeBuilderApp from './Apps/CodeBuilderApp';
import LinkStorageApp from './Apps/LinkStorageApp';

const CssTextField = withStyles({
  root: {   
    '& label.Mui-focused': {
      color: themeHlp().palette.primary.main,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: themeHlp().palette.primary.main,
    }
  },
})(TextField);



const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  buttonStyle: {
    width:'100%', 
    color:'#fff', 
    background: themeHlp().palette.primary.gradientRadial,
    '&:hover': {
      fontWeight:'bold',
      background: themeHlp().palette.primary.gradientRadialHover,
   },
  },
  folder_tree: {
    backgroundColor: themeHlp().palette.whiteText.main,
    /*backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/folder_file.png)', */
    backgroundRepeat: 'no-repeat',
    backgroundSize: '20px',
    backgroundPosition: '0 4px',
    /*color:'red'*/
    padding: '1px 1px 1px 24px',
    margin: '1px 0px',
    cursor: 'pointer',
    overflow: 'hidden',
  },
  narrow_button: {
    width:'100%', 
    height: '16px',
    color:'#fff', 
    marginBottom: '8px',
    background: themeHlp().palette.primary.gradientRadial,
    '&:hover': {
      background: themeHlp().palette.primary.gradientRadialHover,
   },
  },
  file_info_hint: {
    border: 0, 
    height: 0,
    color: 'rgba(0, 0, 0, 0.54)',
    '& > span': {
      padding: 0
    }
  }
});






const Properties = (props) => {
  


  const fieldsContext = useContext(PropertiesContext);
  const classes = useStyles();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [extendMoveTo, setExtendMoveTo] = useState(false);
  const [extendCopyTo, setExtendCopyTo] = useState(false);
  const [extendShareWith, setExtendShareWith] = useState(false);
  const [extendDelete, setExtendDelete] = useState(false);
  const [newFileName, setNewFileName] = useState(fieldsContext?.name);
  const [newFileItemId, setNewFileItemId] = useState(fieldsContext?.item_id);
  const [downloadLinkSrc, setDownloadLinkSrc] = useState(null);
  const [fileFinderAppOn, setFileFinderAppOn] = useState(false);
  const [imageViewerAppOn, setImageViewerAppOn] = useState(false);
  const [mediaPlayerAppOn, setMediaPlayerAppOn] = useState(false);
  const [documentReaderAppOn, setDocumentReaderAppOn] = useState(false);
  const [archiveReaderAppOn, setArchiveReaderAppOn] = useState(false);
  const [codeBuilderAppOn, setCodeBuilderAppOn] = useState(false);
  const [linkStorageAppOn, setLinkStorageAppOn] = useState(false);
  const [infoExpandMore, setInfoExpandMore] = useState(false);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: true,
  });
  const filesExtensions = [
    'desktop', 
    'folder',
    'avif',
    'ico',
    'cur',
    'bmp',
    'gif',
    'png',
    'jpeg',
    'jpg',
    'svg',
    'webp',
    'aac',
    'm4a',
    'mp3',
    'oga',
    'ogg',
    'opus',
    '3gp',
    'wav',
    'mkv',
    'mov',
    'mp4',             
    'webm',
    'txt', 
    'log',
    'pdf',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'ppt',
    'pptx',
    'odt',
    'ott',
    'ods',
    'ots',
    'odp',
    'otp',
    'rtf',
    'mvsa',
    'mvsls',
    'mvsc',
    'mvsl',
    'html',
    'htm',
    'css', 
    'js', 
    'jsx',
    'json',
    '7z',
    'gz',
    'gzip',
    'rar',
    'tar',
    'zip'
  ];
  const [folderStr, setFolderStr]  = useState({
    name: "Desktop",
    location: '/desktop',
    extension: 'desktop',
    isFolder: true,
    items: []
  });
  const [folderOpened, setFolderOpened] = useState(false);
  const [folderTree, setFolderTree] = useState([]);
  const [moveReady, setMoveReady] = useState(true);
  const [copyReady, setCopyReady] = useState(true);
  const [shareReady, setShareReady] = useState(true);
  let fld = [];
  const [selectedFolder, setSelectedFolder] = useState('');
  const [usersMessageList, setUsersMessageList] = useState([]);

  const [sharedUserChecked, setSharedUserChecked] = useState([]);
  const [initialSharedChecked, setInitialSharedChecked] = useState([]);
  let initialShared = false;
  const [appCloses, setAppCloses] = useState(false);
  const [fileStats, setFileStats] = useState({});




  const closeProperties = (value) => {    
    setState({right:value})    
  }

  const renameFileName = (key) => {
    props.changePropertiesFnc('someKey', 'someParams', 'processing');

    const updatefilesParams = {
      item_id: fieldsContext.item_id,
      id: fieldsContext.id,
      item_location: fieldsContext.item_location,
      item_name: newFileName,
      item_ext: fieldsContext.item_ext,
      item_coords: fieldsContext.item_coords,
      update_type: 'name'
    }

    updatefilesSrv(updatefilesParams)
    .then(response => {
    //  if(response.data.statusCode === 200){
        props.changePropertiesFnc(key, updatefilesParams, response);
        setState({right:false});
    //  }
    })
    .catch(err => {
      console.log(err)
    })
  }

  function openCloseMoveFolderPart(){
    setExtendMoveTo(true);
    cancelCopyToFolderPart();
  }

  function cancelMoveToFolderPart(){
    setExtendMoveTo(false); 
    setMoveReady(true); 
  }

  function openCloseCopyFolderPart(){
    setExtendCopyTo(true);
    cancelMoveToFolderPart();
  }

  function cancelCopyToFolderPart(){
    setExtendCopyTo(false); 
    setCopyReady(true); 
  }

  function openShareWithUserPart(){
    setExtendShareWith(true);
    setUsersMessageList([]);
    let exactMessageList = [];
 
    getusermessageSrv('all').then(response => {
      for(let i=0; i<response.data.length; i++){
        if(response.data[i].messageTo){
          if(exactMessageList.includes(response.data[i].email) === false){
            setUsersMessageList(oldUsersMessageList => [...oldUsersMessageList, response.data[i].email]);
            exactMessageList.push(response.data[i].email);
          }
        }else if(response.data[i].messageFrom){
          if(exactMessageList.includes(response.data[i].messageFrom.email) === false){
            setUsersMessageList(oldUsersMessageList => [...oldUsersMessageList, response.data[i].messageFrom.email]);
            exactMessageList.push(response.data[i].messageFrom.email);
          }
        }        
      }
    })
    .catch(err => {
      console.log(err)
    })

    const loadSharedFilesParam = {
      item_id: fieldsContext.item_id
    }
    loadsharedfilesSrv(loadSharedFilesParam).then(response => {
      if(response && response.statusCode === 200){
        if(response.data?.sharedWith){
          setSharedUserChecked(response.data.sharedWith);
          setInitialSharedChecked(response.data.sharedWith);
        }
      }
    })
    .catch(err => {
      console.log(err)
    })
  }

  function cancelShareWithUserPart(){
    setExtendShareWith(false); 
    setShareReady(true);
  }

  function compressFile(key){
    const compressfilesParam = {
      item_id: fieldsContext.item_id
    }  
    compressfilesSrv(compressfilesParam)
    .then(response => {      
      if(response && response.statusCode === 201){
        props.changePropertiesFnc(key, compressfilesParam, response);
        setState({right:false});
      }else if(response.error){
        props.changePropertiesFnc(key, compressfilesParam, response);
      }
    })
    .catch(err => {
      console.log(err)
    })    
  }

  function decompressFile(key){
    const decompressfilesParam = {
      item_id: fieldsContext.item_id
    }  
    decompressfilesSrv(decompressfilesParam)
    .then(response => {      
      if(response && response.statusCode === 201){
        props.changePropertiesFnc(key, decompressfilesParam, response);
        setState({right:false});
      }else if(response.error){
        props.changePropertiesFnc(key, decompressfilesParam, response);
      }
    })
    .catch(err => {
      console.log(err)
    })    
  }

  function openDeletePart(){
    setExtendDelete(true);
  }

  function cancelDeletePart(){
    setExtendDelete(false);
  }
  



//\\GoToMoveCopyTo
  function GoToMoveCopyTo({folderStr}){



    const loadfilesParams = {
      location: {pathname: folderStr.location}
    }


    const goToExpand = (event) => {

      loadfilesSrv(loadfilesParams)
      .then(response => {       

        if(event.target.dataset.extension === 'desktop' || event.target.dataset.extension === 'folder'){ 
          extendMoveTo ? setMoveReady(false) : setMoveReady(true);
          extendCopyTo ? setCopyReady(false) : setCopyReady(true);
        }else{
          setMoveReady(true);
          setCopyReady(true);
        }

        if(response.data.length !== 0){       
          setFolderOpened(true);
          
          for(let i=0; i<response.data.length; i++){

            if((event.target.dataset.location.match(new RegExp("/", "g")) || []).length === 1){
              fld.push(
                {
                  name: response.data[i].name,
                  location: response.data[i].location + '/' + response.data[i].name,
                  extension: response.data[i].extension,
                  isFolder: true,
                  items: []
                }
              )
            }else if((event.target.dataset.location.match(new RegExp("/", "g")) || []).length === 2){
              fld = folderTree.map(obj => {
                if(obj.location === event.target.dataset.location){
                  if(!obj.items[i]){
                    obj.items.push({
                      name: response.data[i].name,
                      location: response.data[i].location + '/' + response.data[i].name,
                      extension: response.data[i].extension,
                      isFolder: true,
                      items: []
                    })            
                  }
                }else {
                  obj.items = []
                }
                return obj 
              })
            }else if((event.target.dataset.location.match(new RegExp("/", "g")) || []).length === 3){     
              fld = folderTree.map(obj => {  
                if(obj.items.length > 0){
                  for(let j=0; j< obj.items.length; j++){
                    if(obj.items[j].location === event.target.dataset.location){
                      if(!obj.items[j].items[i]){
                        obj.items[j].items.push({
                          name: response.data[i].name,
                          location: response.data[i].location + '/' + response.data[i].name,
                          extension: response.data[i].extension,
                          isFolder: true,
                          items: []
                        })               
                      }
                    }else{
                      obj.items[j].items = []
                    }
                  }
                }
                return obj 
              })
            }else if((event.target.dataset.location.match(new RegExp("/", "g")) || []).length === 4){
              fld = folderTree.map(obj => {
                if(obj.items.length > 0){
                  for(let j=0; j< obj.items.length; j++){           
                    if(obj.items[j].items.length > 0){
                      for(let j2=0; j2< obj.items[j].items.length; j2++){
                        if(obj.items[j].items[j2].location === event.target.dataset.location){
                          if(!obj.items[j].items[j2].items[i]){
                            obj.items[j].items[j2].items.push({
                              name: response.data[i].name,
                              location: response.data[i].location + '/' + response.data[i].name,
                              extension: response.data[i].extension,
                              isFolder: true,
                              items: []
                            })          
                          }    
                        }else {
                          obj.items[j].items[j2].items = []
                        }
                      }
                    }}
                }
                return obj 
              })
            }else if((event.target.dataset.location.match(new RegExp("/", "g")) || []).length === 5){
              fld = folderTree.map(obj => {
                if(obj.items.length > 0){
                  for(let j=0; j< obj.items.length; j++){           
                    if(obj.items[j].items.length > 0){
                      for(let j2=0; j2< obj.items[j].items.length; j2++){
                        if(obj.items[j].items[j2].items.length > 0){
                          for(let j3=0; j3< obj.items[j].items[j2].items.length; j3++){
                            if(obj.items[j].items[j2].items[j3].location === event.target.dataset.location){
                              if(!obj.items[j].items[j2].items[j3].items[i]){
                                obj.items[j].items[j2].items[j3].items.push({
                                  name: response.data[i].name,
                                  location: response.data[i].location + '/' + response.data[i].name,
                                  extension: response.data[i].extension,
                                  isFolder: true,
                                  items: []
                                })          
                              }    
                            }else {
                              obj.items[j].items[j2].items[j3].items = []
                            }
                          }
                        }}}}
                }
                return obj 
              })
            }else if((event.target.dataset.location.match(new RegExp("/", "g")) || []).length === 6){
              fld = folderTree.map(obj => {
                if(obj.items.length > 0){
                  for(let j=0; j< obj.items.length; j++){           
                    if(obj.items[j].items.length > 0){
                      for(let j2=0; j2< obj.items[j].items.length; j2++){
                        if(obj.items[j].items[j2].items.length > 0){
                          for(let j3=0; j3< obj.items[j].items[j2].items.length; j3++){
                            if(obj.items[j].items[j2].items[j3].items.length > 0){
                              for(let j4=0; j4< obj.items[j].items[j2].items[j3].items.length; j4++){

                                if(obj.items[j].items[j2].items[j3].items[j4].location === event.target.dataset.location){
                                  if(!obj.items[j].items[j2].items[j3].items[j4].items[i]){
                                    obj.items[j].items[j2].items[j3].items[j4].items.push({
                                      name: response.data[i].name,
                                      location: response.data[i].location + '/' + response.data[i].name,
                                      extension: response.data[i].extension,
                                      isFolder: true,
                                      items: []
                                    })          
                                  }    
                                }else {
                                  obj.items[j].items[j2].items[j3].items[j4].items = []
                                }
                              }
                            }}}}}}
                }
                return obj 
              })
            }else if((event.target.dataset.location.match(new RegExp("/", "g")) || []).length === 7){
              fld = folderTree.map(obj => {
                if(obj.items.length > 0){
                  for(let j=0; j< obj.items.length; j++){           
                    if(obj.items[j].items.length > 0){
                      for(let j2=0; j2< obj.items[j].items.length; j2++){
                        if(obj.items[j].items[j2].items.length > 0){
                          for(let j3=0; j3< obj.items[j].items[j2].items.length; j3++){
                            if(obj.items[j].items[j2].items[j3].items.length > 0){
                              for(let j4=0; j4< obj.items[j].items[j2].items[j3].items.length; j4++){
                                if(obj.items[j].items[j2].items[j3].items[j4].items.length > 0){
                                  for(let j5=0; j5< obj.items[j].items[j2].items[j3].items[j4].items.length; j5++){

                                    if(obj.items[j].items[j2].items[j3].items[j4].items[j5].location === event.target.dataset.location){
                                      if(!obj.items[j].items[j2].items[j3].items[j4].items[j5].items[i]){
                                        obj.items[j].items[j2].items[j3].items[j4].items[j5].items.push({
                                          name: response.data[i].name,
                                          location: response.data[i].location + '/' + response.data[i].name,
                                          extension: response.data[i].extension,
                                          isFolder: true,
                                          items: []
                                        })          
                                      }    
                                    }else {
                                      obj.items[j].items[j2].items[j3].items[j4].items[j5].items = []
                                    }
                                  }
                                }}}}}}}}
                }
                return obj 
              })
            }else if((event.target.dataset.location.match(new RegExp("/", "g")) || []).length === 8){
              fld = folderTree.map(obj => {
                if(obj.items.length > 0){
                  for(let j=0; j< obj.items.length; j++){           
                    if(obj.items[j].items.length > 0){
                      for(let j2=0; j2< obj.items[j].items.length; j2++){
                        if(obj.items[j].items[j2].items.length > 0){
                          for(let j3=0; j3< obj.items[j].items[j2].items.length; j3++){
                            if(obj.items[j].items[j2].items[j3].items.length > 0){
                              for(let j4=0; j4< obj.items[j].items[j2].items[j3].items.length; j4++){
                                if(obj.items[j].items[j2].items[j3].items[j4].items.length > 0){
                                  for(let j5=0; j5< obj.items[j].items[j2].items[j3].items[j4].items.length; j5++){
                                    if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items.length > 0){
                                      for(let j6=0; j6< obj.items[j].items[j2].items[j3].items[j4].items[j5].items.length; j6++){

                                    if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].location === event.target.dataset.location){
                                      if(!obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[i]){
                                        obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items.push({
                                          name: response.data[i].name,
                                          location: response.data[i].location + '/' + response.data[i].name,
                                          extension: response.data[i].extension,
                                          isFolder: true,
                                          items: []
                                        })          
                                      }    
                                    }else {
                                      obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items = []
                                    }
                                  }
                                }}}}}}}}}}
                }
                return obj 
              })
            }else if((event.target.dataset.location.match(new RegExp("/", "g")) || []).length === 9){
              fld = folderTree.map(obj => {
                if(obj.items.length > 0){
                  for(let j=0; j< obj.items.length; j++){           
                    if(obj.items[j].items.length > 0){
                      for(let j2=0; j2< obj.items[j].items.length; j2++){
                        if(obj.items[j].items[j2].items.length > 0){
                          for(let j3=0; j3< obj.items[j].items[j2].items.length; j3++){
                            if(obj.items[j].items[j2].items[j3].items.length > 0){
                              for(let j4=0; j4< obj.items[j].items[j2].items[j3].items.length; j4++){
                                if(obj.items[j].items[j2].items[j3].items[j4].items.length > 0){
                                  for(let j5=0; j5< obj.items[j].items[j2].items[j3].items[j4].items.length; j5++){
                                    if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items.length > 0){
                                      for(let j6=0; j6< obj.items[j].items[j2].items[j3].items[j4].items[j5].items.length; j6++){
                                        if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items.length > 0){
                                          for(let j7=0; j7< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items.length; j7++){

                                            if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].location === event.target.dataset.location){
                                              if(!obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[i]){
                                                obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items.push({
                                                  name: response.data[i].name,
                                                  location: response.data[i].location + '/' + response.data[i].name,
                                                  extension: response.data[i].extension,
                                                  isFolder: true,
                                                  items: []
                                                })          
                                              }    
                                            }else {
                                              obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items = []
                                            }
                                          }
                                        }}}}}}}}}}}}
                }
                return obj 
              })
            }else if((event.target.dataset.location.match(new RegExp("/", "g")) || []).length === 10){
              fld = folderTree.map(obj => {
                if(obj.items.length > 0){
                  for(let j=0; j< obj.items.length; j++){           
                    if(obj.items[j].items.length > 0){
                      for(let j2=0; j2< obj.items[j].items.length; j2++){
                        if(obj.items[j].items[j2].items.length > 0){
                          for(let j3=0; j3< obj.items[j].items[j2].items.length; j3++){
                            if(obj.items[j].items[j2].items[j3].items.length > 0){
                              for(let j4=0; j4< obj.items[j].items[j2].items[j3].items.length; j4++){
                                if(obj.items[j].items[j2].items[j3].items[j4].items.length > 0){
                                  for(let j5=0; j5< obj.items[j].items[j2].items[j3].items[j4].items.length; j5++){
                                    if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items.length > 0){
                                      for(let j6=0; j6< obj.items[j].items[j2].items[j3].items[j4].items[j5].items.length; j6++){
                                        if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items.length > 0){
                                          for(let j7=0; j7< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items.length; j7++){
                                            if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items.length > 0){
                                              for(let j8=0; j8< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items.length; j8++){

                                                if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].location === event.target.dataset.location){
                                                  if(!obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[i]){
                                                    obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items.push({
                                                      name: response.data[i].name,
                                                      location: response.data[i].location + '/' + response.data[i].name,
                                                      extension: response.data[i].extension,
                                                      isFolder: true,
                                                      items: []
                                                    })          
                                                  }    
                                                }else {
                                                  obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items = []
                                                }
                                              }
                                            }}}}}}}}}}}}}}
                }
                return obj 
              })
            }else if((event.target.dataset.location.match(new RegExp("/", "g")) || []).length === 11){
              fld = folderTree.map(obj => {
                if(obj.items.length > 0){
                  for(let j=0; j< obj.items.length; j++){           
                    if(obj.items[j].items.length > 0){
                      for(let j2=0; j2< obj.items[j].items.length; j2++){
                        if(obj.items[j].items[j2].items.length > 0){
                          for(let j3=0; j3< obj.items[j].items[j2].items.length; j3++){
                            if(obj.items[j].items[j2].items[j3].items.length > 0){
                              for(let j4=0; j4< obj.items[j].items[j2].items[j3].items.length; j4++){
                                if(obj.items[j].items[j2].items[j3].items[j4].items.length > 0){
                                  for(let j5=0; j5< obj.items[j].items[j2].items[j3].items[j4].items.length; j5++){
                                    if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items.length > 0){
                                      for(let j6=0; j6< obj.items[j].items[j2].items[j3].items[j4].items[j5].items.length; j6++){
                                        if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items.length > 0){
                                          for(let j7=0; j7< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items.length; j7++){
                                            if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items.length > 0){
                                              for(let j8=0; j8< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items.length; j8++){
                                                if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items.length > 0){
                                                  for(let j9=0; j9< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items.length; j9++){

                                                    if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].location === event.target.dataset.location){
                                                      if(!obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[i]){
                                                        obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items.push({
                                                          name: response.data[i].name,
                                                          location: response.data[i].location + '/' + response.data[i].name,
                                                          extension: response.data[i].extension,
                                                          isFolder: true,
                                                          items: []
                                                        })          
                                                      }    
                                                    }else {
                                                      obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items = []
                                                    }
                                                  }
                                                }}}}}}}}}}}}}}}}
                }
                return obj 
              })
            }else if((event.target.dataset.location.match(new RegExp("/", "g")) || []).length === 12){
              fld = folderTree.map(obj => {
                if(obj.items.length > 0){
                  for(let j=0; j< obj.items.length; j++){           
                    if(obj.items[j].items.length > 0){
                      for(let j2=0; j2< obj.items[j].items.length; j2++){
                        if(obj.items[j].items[j2].items.length > 0){
                          for(let j3=0; j3< obj.items[j].items[j2].items.length; j3++){
                            if(obj.items[j].items[j2].items[j3].items.length > 0){
                              for(let j4=0; j4< obj.items[j].items[j2].items[j3].items.length; j4++){
                                if(obj.items[j].items[j2].items[j3].items[j4].items.length > 0){
                                  for(let j5=0; j5< obj.items[j].items[j2].items[j3].items[j4].items.length; j5++){
                                    if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items.length > 0){
                                      for(let j6=0; j6< obj.items[j].items[j2].items[j3].items[j4].items[j5].items.length; j6++){
                                        if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items.length > 0){
                                          for(let j7=0; j7< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items.length; j7++){
                                            if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items.length > 0){
                                              for(let j8=0; j8< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items.length; j8++){
                                                if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items.length > 0){
                                                  for(let j9=0; j9< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items.length; j9++){
                                                    if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items.length > 0){
                                                      for(let j10=0; j10< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items.length; j10++){

                                                        if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].location === event.target.dataset.location){
                                                          if(!obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[i]){
                                                            obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items.push({
                                                              name: response.data[i].name,
                                                              location: response.data[i].location + '/' + response.data[i].name,
                                                              extension: response.data[i].extension,
                                                              isFolder: true,
                                                              items: []
                                                            })          
                                                          }    
                                                        }else {
                                                          obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items = []
                                                        }
                                                      }
                                                    }}}}}}}}}}}}}}}}}}
                }
                return obj 
              })
            }else if((event.target.dataset.location.match(new RegExp("/", "g")) || []).length === 13){
              fld = folderTree.map(obj => {
                if(obj.items.length > 0){
                  for(let j=0; j< obj.items.length; j++){           
                    if(obj.items[j].items.length > 0){
                      for(let j2=0; j2< obj.items[j].items.length; j2++){
                        if(obj.items[j].items[j2].items.length > 0){
                          for(let j3=0; j3< obj.items[j].items[j2].items.length; j3++){
                            if(obj.items[j].items[j2].items[j3].items.length > 0){
                              for(let j4=0; j4< obj.items[j].items[j2].items[j3].items.length; j4++){
                                if(obj.items[j].items[j2].items[j3].items[j4].items.length > 0){
                                  for(let j5=0; j5< obj.items[j].items[j2].items[j3].items[j4].items.length; j5++){
                                    if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items.length > 0){
                                      for(let j6=0; j6< obj.items[j].items[j2].items[j3].items[j4].items[j5].items.length; j6++){
                                        if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items.length > 0){
                                          for(let j7=0; j7< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items.length; j7++){
                                            if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items.length > 0){
                                              for(let j8=0; j8< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items.length; j8++){
                                                if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items.length > 0){
                                                  for(let j9=0; j9< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items.length; j9++){
                                                    if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items.length > 0){
                                                      for(let j10=0; j10< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items.length; j10++){
                                                        if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items.length > 0){
                                                          for(let j11=0; j11< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items.length; j11++){

                                                            if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].location === event.target.dataset.location){
                                                              if(!obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items[i]){
                                                                obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items.push({
                                                                  name: response.data[i].name,
                                                                  location: response.data[i].location + '/' + response.data[i].name,
                                                                  extension: response.data[i].extension,
                                                                  isFolder: true,
                                                                  items: []
                                                                })          
                                                              }    
                                                            }else {
                                                              obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items = []
                                                            }
                                                          }
                                                        }}}}}}}}}}}}}}}}}}}}
                }
                return obj 
              })
            }else if((event.target.dataset.location.match(new RegExp("/", "g")) || []).length === 14){
              fld = folderTree.map(obj => {
                if(obj.items.length > 0){
                  for(let j=0; j< obj.items.length; j++){           
                    if(obj.items[j].items.length > 0){
                      for(let j2=0; j2< obj.items[j].items.length; j2++){
                        if(obj.items[j].items[j2].items.length > 0){
                          for(let j3=0; j3< obj.items[j].items[j2].items.length; j3++){
                            if(obj.items[j].items[j2].items[j3].items.length > 0){
                              for(let j4=0; j4< obj.items[j].items[j2].items[j3].items.length; j4++){
                                if(obj.items[j].items[j2].items[j3].items[j4].items.length > 0){
                                  for(let j5=0; j5< obj.items[j].items[j2].items[j3].items[j4].items.length; j5++){
                                    if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items.length > 0){
                                      for(let j6=0; j6< obj.items[j].items[j2].items[j3].items[j4].items[j5].items.length; j6++){
                                        if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items.length > 0){
                                          for(let j7=0; j7< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items.length; j7++){
                                            if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items.length > 0){
                                              for(let j8=0; j8< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items.length; j8++){
                                                if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items.length > 0){
                                                  for(let j9=0; j9< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items.length; j9++){
                                                    if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items.length > 0){
                                                      for(let j10=0; j10< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items.length; j10++){
                                                        if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items.length > 0){
                                                          for(let j11=0; j11< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items.length; j11++){
                                                            if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items.length > 0){
                                                              for(let j12=0; j12< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items.length; j12++){

                                                                if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items[j12].location === event.target.dataset.location){
                                                                  if(!obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items[j12].items[i]){
                                                                    obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items[j12].items.push({
                                                                      name: response.data[i].name,
                                                                      location: response.data[i].location + '/' + response.data[i].name,
                                                                      extension: response.data[i].extension,
                                                                      isFolder: true,
                                                                      items: []
                                                                    })          
                                                                  }    
                                                                }else {
                                                                  obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items[j12].items = []
                                                                }
                                                              }
                                                            }}}}}}}}}}}}}}}}}}}}}}
                }
                return obj 
              })
            }else if((event.target.dataset.location.match(new RegExp("/", "g")) || []).length === 15){
              fld = folderTree.map(obj => {
                if(obj.items.length > 0){
                  for(let j=0; j< obj.items.length; j++){           
                    if(obj.items[j].items.length > 0){
                      for(let j2=0; j2< obj.items[j].items.length; j2++){
                        if(obj.items[j].items[j2].items.length > 0){
                          for(let j3=0; j3< obj.items[j].items[j2].items.length; j3++){
                            if(obj.items[j].items[j2].items[j3].items.length > 0){
                              for(let j4=0; j4< obj.items[j].items[j2].items[j3].items.length; j4++){
                                if(obj.items[j].items[j2].items[j3].items[j4].items.length > 0){
                                  for(let j5=0; j5< obj.items[j].items[j2].items[j3].items[j4].items.length; j5++){
                                    if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items.length > 0){
                                      for(let j6=0; j6< obj.items[j].items[j2].items[j3].items[j4].items[j5].items.length; j6++){
                                        if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items.length > 0){
                                          for(let j7=0; j7< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items.length; j7++){
                                            if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items.length > 0){
                                              for(let j8=0; j8< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items.length; j8++){
                                                if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items.length > 0){
                                                  for(let j9=0; j9< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items.length; j9++){
                                                    if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items.length > 0){
                                                      for(let j10=0; j10< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items.length; j10++){
                                                        if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items.length > 0){
                                                          for(let j11=0; j11< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items.length; j11++){
                                                            if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items.length > 0){
                                                              for(let j12=0; j12< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items.length; j12++){
                                                                if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items[j12].items.length > 0){
                                                                  for(let j13=0; j13< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items[j12].items.length; j13++){

                                                                if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items[j12].items[j13].location === event.target.dataset.location){
                                                                  if(!obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items[j12].items[j13].items[i]){
                                                                    obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items[j12].items[j13].items.push({
                                                                      name: response.data[i].name,
                                                                      location: response.data[i].location + '/' + response.data[i].name,
                                                                      extension: response.data[i].extension,
                                                                      isFolder: true,
                                                                      items: []
                                                                    })          
                                                                  }    
                                                                }else {
                                                                  obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items[j12].items[j13].items = []
                                                                }
                                                              }
                                                            }}}}}}}}}}}}}}}}}}}}}}}}
                }
                return obj 
              })
            }else if((event.target.dataset.location.match(new RegExp("/", "g")) || []).length === 16){
              fld = folderTree.map(obj => {
                if(obj.items.length > 0){
                  for(let j=0; j< obj.items.length; j++){           
                    if(obj.items[j].items.length > 0){
                      for(let j2=0; j2< obj.items[j].items.length; j2++){
                        if(obj.items[j].items[j2].items.length > 0){
                          for(let j3=0; j3< obj.items[j].items[j2].items.length; j3++){
                            if(obj.items[j].items[j2].items[j3].items.length > 0){
                              for(let j4=0; j4< obj.items[j].items[j2].items[j3].items.length; j4++){
                                if(obj.items[j].items[j2].items[j3].items[j4].items.length > 0){
                                  for(let j5=0; j5< obj.items[j].items[j2].items[j3].items[j4].items.length; j5++){
                                    if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items.length > 0){
                                      for(let j6=0; j6< obj.items[j].items[j2].items[j3].items[j4].items[j5].items.length; j6++){
                                        if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items.length > 0){
                                          for(let j7=0; j7< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items.length; j7++){
                                            if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items.length > 0){
                                              for(let j8=0; j8< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items.length; j8++){
                                                if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items.length > 0){
                                                  for(let j9=0; j9< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items.length; j9++){
                                                    if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items.length > 0){
                                                      for(let j10=0; j10< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items.length; j10++){
                                                        if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items.length > 0){
                                                          for(let j11=0; j11< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items.length; j11++){
                                                            if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items.length > 0){
                                                              for(let j12=0; j12< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items.length; j12++){
                                                                if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items[j12].items.length > 0){
                                                                  for(let j13=0; j13< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items[j12].items.length; j13++){
                                                                    if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items[j12].items[j13].items.length > 0){
                                                                      for(let j14=0; j14< obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items[j12].items[j13].items.length; j14++){

                                                                        if(obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items[j12].items[j13].items[j14].location === event.target.dataset.location){
                                                                          if(!obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items[j12].items[j13].items[j14].items[i]){
                                                                            obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items[j12].items[j13].items[j14].items.push({
                                                                              name: response.data[i].name,
                                                                              location: response.data[i].location + '/' + response.data[i].name,
                                                                              extension: response.data[i].extension,
                                                                              isFolder: true,
                                                                              items: []
                                                                            })          
                                                                          }    
                                                                        }else {
                                                                          obj.items[j].items[j2].items[j3].items[j4].items[j5].items[j6].items[j7].items[j8].items[j9].items[j10].items[j11].items[j12].items[j13].items[j14].items = []
                                                                        }
                                                                      }
                                                                    }}}}}}}}}}}}}}}}}}}}}}}}}}
                }
                return obj 
              })
            }

          }

          setFolderTree(fld)
    
        }
        setSelectedFolder(event.target.dataset.location)
      })
      .catch(err => {
        console.log(err);
        if(document.getElementById('propertiesWindow')){
          document.getElementById('propertiesWindow').style.display = 'none';
        }
      })

      setTimeout(() => {
        for(let i = 0; i<document.querySelectorAll('.folder_name').length; i++){
          document.querySelectorAll('.folder_name')[i].style.color = themeHlp().palette.blackText.main;
          document.querySelectorAll('.folder_name')[i].style.borderTop = '0px';
          document.querySelectorAll('.folder_name')[i].style.borderBottom = '0px';
          document.querySelectorAll('.folder_name')[i].style.fontWeight = 'normal';
        }
        if(event.target.dataset.extension === 'desktop' || event.target.dataset.extension === 'folder'){
          document.querySelectorAll('[data-location="' + event.target.dataset.location + '"]')[0].style.color = themeHlp().palette.primary.main;
          document.querySelectorAll('[data-location="' + event.target.dataset.location + '"]')[0].style.borderTop = '1px solid ' + themeHlp().palette.primary.main;
          document.querySelectorAll('[data-location="' + event.target.dataset.location + '"]')[0].style.borderBottom = '1px solid ' + themeHlp().palette.primary.main;
          document.querySelectorAll('[data-location="' + event.target.dataset.location + '"]')[0].style.fontWeight = 'bold';
        }
      },400)  

    }

    return (
      <Box component="div">
        <Box component="div" className={classes.folder_tree}>
          <span className="folder_name" style={{
                width: '100%', 
                display: 'inline-block',
                backgroundImage: filesExtensions.find(element => element === folderStr.extension.toLowerCase()) ? `url(${process.env.REACT_APP_URL_CLIENTPORT}/icon/type/${folderStr.extension.toLowerCase()}_file.png)`: `url(${process.env.REACT_APP_URL_CLIENTPORT}/icon/type/unknown_file.png)`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '20px',
                backgroundPosition: '0 4px',
                padding: '1px 1px 1px 24px',
                cursor: 'pointer',
                overflow: 'hidden',
            }} 
            data-location={folderStr.location}
            data-extension={folderStr.extension}
            title={folderStr.name} 
            onClick={goToExpand}
          >
            {folderStr.name}
          </span>
          <br />
          <div>
            {folderStr.items.map((explore, key) => (
              <GoToMoveCopyTo key={key} folderStr={explore} />
            ))}
          </div>
        </Box>
      </Box>
    );
  }
//\\GoToMoveCopyTo END


const goToGetInfo = () => {
  setInfoExpandMore(true);
  const getfilesinfoParams = {
    item_id: fieldsContext.item_id
  } 
  getfilesinfoSrv(getfilesinfoParams)
  .then(response => {
    if(response && response.statusCode === 200){
      setFileStats(response.data[0]);
    }
  })
  .catch(err => {
    console.log(err)
  })
  
}


  const deleteFile = (key) => {
    props.changePropertiesFnc('someKey', 'someParams', 'processing');

    const deletefilesParams = {
      item_id: fieldsContext.item_id  
    }
    deletefilesSrv(deletefilesParams)
    .then(response => {
  //   if(response.data.statusCode === 200){
        props.changePropertiesFnc(key, deletefilesParams, response);
        setState({right:false});
    //  }
    })
    .catch(err => {
      console.log(err)
    })
  }


  const moveToFolder = (key) => {
    props.changePropertiesFnc('someKey', 'someParams', 'processing');

    const movefilesParams = {
      item_id: newFileItemId,
      item_new_location: selectedFolder,
      replace: false
    }  
    
    movefilesSrv(movefilesParams)
      .then(response => {        
        if(response && response.statusCode === 200){          
          props.history(props.location.pathname);
          props.changePropertiesFnc(key, movefilesParams, response);
          setState({right:false});
        }else if(response.error && (response.error.statusCode === 409)){
          props.changePropertiesFnc(key, movefilesParams, response);
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const copyToFolder = (key) => {
    props.changePropertiesFnc('someKey', 'someParams', 'processing');

    const copyfilesParams = {
      item_id: newFileItemId,
      item_new_location: selectedFolder,
      replace: false
    }

    copyfilesSrv(copyfilesParams)
    .then(response => {      
      if(response && response.statusCode === 201){
        props.history(props.location.pathname);
        props.changePropertiesFnc(key, copyfilesParams, response);
        setState({right:false});
      }else if(response.error){
        props.changePropertiesFnc(key, copyfilesParams, response);
      }
    })
    .catch(err => {
      console.log(err)
    })
  }

  const sharedUserHandleToggle = (value) => () => {
    const currentIndex = sharedUserChecked.indexOf(value);
    const newChecked = [...sharedUserChecked];

    if (currentIndex === -1) {
      newChecked.push(value);      
    } else {
      newChecked.splice(currentIndex, 1);      
    }

    newChecked.length === 0 ? setShareReady(true) : setShareReady(false);
    setSharedUserChecked(newChecked);
  };

  const shareWithUser = (key) => {
    const createsharedfilesParam = {
      item_id: fieldsContext.item_id,
      shared_username: sharedUserChecked 
    }
    createsharedfilesSrv(createsharedfilesParam)
    .then(response => {      
      if(response && response.statusCode === 201){
        props.changePropertiesFnc(key, createsharedfilesParam, response);
        setState({right:false});
      }else if(response.error){
        props.changePropertiesFnc(key, createsharedfilesParam, response);
      }
    })
    .catch(err => {
      console.log(err)
    })
  }

  const updateWithUser = (key) => {
    const updatesharedfilesParam = {
      item_id: fieldsContext.item_id,
      shared_username: sharedUserChecked 
    }  
    updatesharedfilesSrv(updatesharedfilesParam)
    .then(response => {      
      if(response && response.statusCode === 200){
        props.changePropertiesFnc(key, updatesharedfilesParam, response);
        setState({right:false});
      }
    })
    .catch(err => {
      console.log(err)
    })
  }

  const deleteWithUser = (key) => {
    const deletesharedfilesParam = {
      item_id: fieldsContext.item_id
    }  
    deletesharedfilesSrv(deletesharedfilesParam)
    .then(response => {      
      if(response && response.statusCode === 200){
        props.changePropertiesFnc(key, deletesharedfilesParam, response);
        setState({right:false});
      }else if(response.error){
        props.changePropertiesFnc(key, deletesharedfilesParam, response);
      }
    })
    .catch(err => {
      console.log(err)
    })
  }

  const goToLock = (key) => {
    const lockfilesParam = {
      item_id: fieldsContext.item_id
    }  
    lockfilesSrv(lockfilesParam)
    .then(response => {      
      if(response && response.statusCode === 200){
        props.changePropertiesFnc(key, lockfilesParam, response);
        setState({right:false});
      }else if(response.error){
        props.changePropertiesFnc(key, lockfilesParam, response);
      }
    })
    .catch(err => {
      console.log(err)
    })
  }

  const goToUnlock = (key) => {
    const unlockfilesParam = {
      item_id: fieldsContext.item_id
    }  
    unlockfilesSrv(unlockfilesParam)
    .then(response => {      
      if(response && response.statusCode === 200){
        props.changePropertiesFnc(key, unlockfilesParam, response);
        setState({right:false});
      }else if(response.error){
        props.changePropertiesFnc(key, unlockfilesParam, response);
      }
    })
    .catch(err => {
      console.log(err)
    })
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });    
  };

  useEffect(() => {   
    fieldsContext.num = 1
    setState({right: true})
    setNewFileName(fieldsContext.name)
    setNewFileItemId(fieldsContext.item_id)
    
    state.right === true && extendMoveTo === true ? setExtendMoveTo(true) : setExtendMoveTo(false);
    state.right === true && extendCopyTo === true ? setExtendCopyTo(true) : setExtendCopyTo(false);
    state.right === true && extendDelete === true ? setExtendDelete(true) : setExtendDelete(false)
    
    getfilesSrv(fieldsContext)
    .then(response => {
      setDownloadLinkSrc(response.data);
    }).catch(err => {
      console.log(err)
    })
    
    if(folderOpened === true){
      setFolderStr(current => {
        return {
          ...current, 
          items: folderTree
        }
      });
    }

    if(appCloses === true){
      setImageViewerAppOn(false);
      setMediaPlayerAppOn(false);
      setDocumentReaderAppOn(false);
      setArchiveReaderAppOn(false);
      setCodeBuilderAppOn(false);
      setLinkStorageAppOn(false);
      setAppCloses(false);
    }

    if(fieldsContext.open_method === 'openDirectly'){
      setFileFinderAppOn(true);
    }
  },[extendDelete, folderTree, folderOpened, appCloses])

  const changeFileName = (event) => {
    setNewFileName(event.target.value);
  }




        

//\\list function
  const list = (newFileName, id) => (
    <Card id="propertyBox" style={{width:'264px', height:'100%', overflowY: 'visible', backgroundColor:themeHlp().palette.secondary.light}}>
      <CardHeader
        action={
          <>
            <IconButton 
              aria-label="PropertyWindowMaximizeIcon"
              title="Resize"
              onClick={() => {document.getElementById('propertyBox').style.width === '264px' ? document.getElementById('propertyBox').style.width = '100%' : document.getElementById('propertyBox').style.width = '264px'}}
              size="small"
              style={{
                color:'#323a32',
                margin:'8px -8px 0 0',
                padding:'8px'
              }}
            >
              <PropertyWindowMaximizeIcon />       
            </IconButton>
            <IconButton 
              aria-label="CancelSharpIcon"
              title="Close"
              onClick={() => {setState({right:false})}}
              size="small"
              style={{
                color:'#323a32',
                margin:'8px -8px 0 0',
                padding:'8px'
              }}
            >
              <CancelSharpIcon />      
            </IconButton>
          </>
        }
        title='Properties'
        style={{
          padding: '4px 16px',
          color:'#ffffff', 
          background:'linear-gradient(90deg, rgba(38,93,140,1) 0%, rgba(38,93,140,1) 15%, rgba(0,140,183,1) 100%)',
          cursor: 'default'
        }}    
      />
      <CardContent>
        {(fieldsContext.item_ext === 'folder' && !fileFinderAppOn) &&
          <>
            <Avatar title="File Finder" component="span" variant="rounded" alt="File Finder" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/filefinder.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={() => setFileFinderAppOn(true)} />
            <Typography title="File Finder" variant="subtitle1" component="span" style={{ width: '120px', whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>File Finder</Typography>
          </>
        }
        { fileFinderAppOn &&
          <FileFinderApp value={true} appCloses={(appCloses) => setAppCloses(appCloses)} fileInfo={fieldsContext} changePropertiesFnc={props.changePropertiesFnc} closeProperties={closeProperties} />
        }
        {((
            fieldsContext.item_ext.toLowerCase() === 'avif' || 
            fieldsContext.item_ext.toLowerCase() === 'ico' || 
            fieldsContext.item_ext.toLowerCase() === 'cur' || 
            fieldsContext.item_ext.toLowerCase() === 'bmp' || 
            fieldsContext.item_ext.toLowerCase() === 'gif' || 
            fieldsContext.item_ext.toLowerCase() === 'png' || 
            fieldsContext.item_ext.toLowerCase() === 'jpeg' ||   
            fieldsContext.item_ext.toLowerCase() === 'jpg' || 
            fieldsContext.item_ext.toLowerCase() === 'svg' || 
            fieldsContext.item_ext.toLowerCase() === 'webp') && !imageViewerAppOn) &&          
          <>
            <Avatar title="Image Viewer" component="span" variant="rounded" alt="Image Viewer" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/imageviewer.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={() => {setImageViewerAppOn(true)}} />
            <Typography title="Image Viewer" variant="subtitle1" component="span" style={{ width: '120px', whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>Image Viewer</Typography>
          </>  
        }
        { imageViewerAppOn &&
          <ImageViewerApp value={true} appCloses={(appCloses) => setAppCloses(appCloses)} fileInfo={fieldsContext} />
        }

        {((
          fieldsContext.item_ext.toLowerCase() === 'aac' ||
          fieldsContext.item_ext.toLowerCase() === 'm4a' ||
          fieldsContext.item_ext.toLowerCase() === 'mp3' ||
          fieldsContext.item_ext.toLowerCase() === 'oga' ||
          fieldsContext.item_ext.toLowerCase() === 'ogg' ||
          fieldsContext.item_ext.toLowerCase() === 'opus' ||
          fieldsContext.item_ext.toLowerCase() === '3gp' || 
          fieldsContext.item_ext.toLowerCase() === 'wav' || 
          fieldsContext.item_ext.toLowerCase() === 'mkv' || 
          fieldsContext.item_ext.toLowerCase() === 'mov' || 
          fieldsContext.item_ext.toLowerCase() === 'mp4' || 
          fieldsContext.item_ext.toLowerCase() === 'webm') && !mediaPlayerAppOn) &&
          <>
            <Avatar title="Media Player" component="span" variant="rounded" alt="Media Player" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/mediaplayer.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={() => setMediaPlayerAppOn(true)} />
            <Typography title="Media Player" variant="subtitle1" component="span" style={{ width: '120px', whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>Media Player</Typography>
          </>
        }
        { mediaPlayerAppOn &&
          <MediaPlayerApp value={true} appCloses={(appCloses) => setAppCloses(appCloses)} fileInfo={fieldsContext} />
        } 
        {((
          fieldsContext.item_ext.toLowerCase() === 'txt' || 
          fieldsContext.item_ext.toLowerCase() === 'log' || 
          fieldsContext.item_ext.toLowerCase() === 'pdf' || 
          fieldsContext.item_ext.toLowerCase() === 'doc' || 
          fieldsContext.item_ext.toLowerCase() === 'docx' ||           
          fieldsContext.item_ext.toLowerCase() === 'xls' || 
          fieldsContext.item_ext.toLowerCase() === 'xlsx' || 
          fieldsContext.item_ext.toLowerCase() === 'ppt' || 
          fieldsContext.item_ext.toLowerCase() === 'pptx' || 
          fieldsContext.item_ext.toLowerCase() === 'odt' ||           
          fieldsContext.item_ext.toLowerCase() === 'ott' || 
          fieldsContext.item_ext.toLowerCase() === 'ods' || 
          fieldsContext.item_ext.toLowerCase() === 'ots' || 
          fieldsContext.item_ext.toLowerCase() === 'odp' || 
          fieldsContext.item_ext.toLowerCase() === 'otp' ||           
          fieldsContext.item_ext.toLowerCase() === 'rtf' ||   
          fieldsContext.item_ext.toLowerCase() === 'mvsa' ||   
          fieldsContext.item_ext.toLowerCase() === 'html' || 
          fieldsContext.item_ext.toLowerCase() === 'htm' || 
          fieldsContext.item_ext.toLowerCase() === 'css' || 
          fieldsContext.item_ext.toLowerCase() === 'json' || 
          fieldsContext.item_ext.toLowerCase() === 'js' || 
          fieldsContext.item_ext.toLowerCase() === 'jsx') && !documentReaderAppOn) &&          
          <>
            <Avatar title="Document Reader" component="span" variant="rounded" alt="Document Reader" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/documentreader.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={() => setDocumentReaderAppOn(true)} />
            <Typography title="Document Reader" variant="subtitle1" component="span" style={{ width: '120px', whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>Document Reader</Typography>
          </>  
        }
        { documentReaderAppOn &&
          <DocumentReaderApp value={true} appCloses={(appCloses) => setAppCloses(appCloses)} fileInfo={fieldsContext} />
        }  

        {(
          fieldsContext.item_ext.toLowerCase() === 'zip' && !archiveReaderAppOn) &&          
          <>
            <Avatar title="Archive Reader" component="span" variant="rounded" alt="Archive Reader" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/archivereader.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={() => setArchiveReaderAppOn(true)} />
            <Typography title="Archive Reader" variant="subtitle1" component="span" style={{ width: '120px', whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>Archive Reader</Typography>
          </>  
        }
        { archiveReaderAppOn &&
          <ArchiveReaderApp value={true} appCloses={(appCloses) => setAppCloses(appCloses)} fileInfo={fieldsContext} />
        } 

        {(
          fieldsContext.item_ext.toLowerCase() === 'mvsc' && !codeBuilderAppOn) &&          
          <>
            <Avatar title="Code Builder" component="span" variant="rounded" alt="Code Builder" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/codebuilder.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={() => setCodeBuilderAppOn(true)} />
            <Typography title="Code Builder" variant="subtitle1" component="span" style={{ width: '120px', whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>Code Builder</Typography>
          </>  
        }
        { codeBuilderAppOn &&
          <CodeBuilderApp value={true} appCloses={(appCloses) => setAppCloses(appCloses)} fileInfo={fieldsContext} />
        } 

        {(
          fieldsContext.item_ext.toLowerCase() === 'mvsls' && !linkStorageAppOn) &&          
          <>
            <Avatar title="Link Storage" component="span" variant="rounded" alt="Link Storage" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/linkstorage.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={() => setLinkStorageAppOn(true)} />
            <Typography title="Link Storage" variant="subtitle1" component="span" style={{ width: '120px', whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>Link Storage</Typography>
          </>  
        }
        { linkStorageAppOn &&
          <LinkStorageApp value={true} appCloses={(appCloses) => setAppCloses(appCloses)} fileInfo={fieldsContext} />
        } 






        <Divider style={{margin: '20px 0', background: themeHlp().palette.primary.gradient}} />
        <CssTextField          
          name={id}
          label="Filename"
          fullWidth
          defaultValue={newFileName} 
          onChange={(event) => changeFileName(event)} 
        />
        { !infoExpandMore ?
        <Button  
          className={classes.narrow_button}        
          onClick={() => goToGetInfo()}
          startIcon={<ExpandMoreIcon />}
          title="Show File Information"
        >
        </Button>
        :
        <>
          <Chip label="Original File Format" size="small"  variant="outlined" className={classes.file_info_hint} />
          <Typography style={{cursor:'default', fontSize: '12px'}}>{fieldsContext.item_ext}</Typography>
          <Chip label={fieldsContext.item_ext === 'folder' ? 'Contents' : 'Size'} size="small"  variant="outlined" className={classes.file_info_hint} />
          <Typography style={{cursor:'default', fontSize: '12px'}}>              
            {fileStats.itemCount === 0 ? 
              'nothing'
            :
              <>
              {fieldsContext.item_ext === 'folder' && fileStats.itemCount + ' items, totalling '}
              {(parseInt(fileStats.itemSize) < 1000) && fileStats.itemSize + ' bytes'}
              {(parseInt(fileStats.itemSize) >= 1000 && parseInt(fileStats.itemSize) < 1000000) && (fileStats.itemSize / 1000).toFixed(1) + ' kB'} {((parseInt(fileStats.itemSize) >= 1000 && parseInt(fileStats.itemSize) < 1000000) && fieldsContext.item_ext !== 'folder') && ' (' + fileStats.itemSize + ' bytes)'}
              {(parseInt(fileStats.itemSize) >= 1000000) && (fileStats.itemSize / 1000000).toFixed(1) + ' MB'} {(parseInt(fileStats.itemSize) >= 1000000 && fieldsContext.item_ext !== 'folder') && ' (' + fileStats.itemSize + ' bytes)'}
              </>
            }
          </Typography>
          <Chip label="Created" size="small"  variant="outlined" className={classes.file_info_hint} />
          <Typography style={{cursor:'default', fontSize: '12px'}}>{new Date(fieldsContext.created_at).toLocaleString('en-US', {timeZone: timeZone,
                  dateStyle: 'short',//full, long, medium, short
                  timeStyle: 'medium', //full, long, medium, short
                  hourCycle: 'h12', //h12, h24
                })}</Typography>
          <Chip label="Modified" size="small"  variant="outlined" className={classes.file_info_hint} />
          <Typography style={{cursor:'default', fontSize: '12px'}}>{new Date(fieldsContext.updated_at).toLocaleString('en-US', {timeZone: timeZone,
                  dateStyle: 'short',//full, long, medium, short
                  timeStyle: 'medium', //full, long, medium, short
                  hourCycle: 'h12', //h12, h24
                })}</Typography>
          <Chip label="Storage" size="small"  variant="outlined" className={classes.file_info_hint} />
          <Typography style={{cursor:'default', fontSize: '12px'}}>{'free space '}{ parseFloat(fileStats.freeSpace / (1000000)).toFixed(1) + ' MB'}</Typography>
          <Typography style={{cursor:'default', fontSize: '12px'}}>{'used space '}{ parseFloat(fileStats.usedSpace / (1000000)).toFixed(1) + ' MB'}</Typography>

          <Button
            className={classes.narrow_button}
            onClick={() => setInfoExpandMore(false)}
            startIcon={<ExpandLessIcon />}
            title="Hide File Information"
          >
          </Button>
        </>
        }
        {fieldsContext.item_ext !== 'mvsl' &&
        <>
          <Button
            autoFocus={false}
            className={classes.buttonStyle}
            onClick={() => renameFileName('update', null)}
            startIcon={
              <Avatar component="span" variant="rounded" alt="Rename" style={{width: 24, height: 24, color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/rename.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
            }
          >
            Rename
          </Button> 
          <Divider style={{margin: '20px 0', background: themeHlp().palette.primary.gradient}} />
        </>
        }
        {fieldsContext.item_ext !== 'mvsl' &&    
        <>
        {
          extendMoveTo === false ?   
          <Button  
            className={classes.buttonStyle}        
            onClick={openCloseMoveFolderPart}
            startIcon={       
              <Avatar component="span" variant="rounded" alt="Move to" style={{width: 24, height: 24, color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/move_to.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
            }
          >
            Move to
          </Button>
          :
          <Fade in={extendMoveTo} timeout={1000}>
            <div>
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Avatar component="span" alt="move to" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/move_to.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />     
                <Typography variant="subtitle1" component="span" style={{fontWeight: 'bold', cursor: 'default'}}>Move this item?</Typography>
              </Box>
              <Typography align='center'>In order to move the selected item, select the appropriate folder where you want to move.</Typography>
              <GoToMoveCopyTo folderStr={folderStr} />
              <ButtonGroup fullWidth>            
                <Button
                  className={classes.buttonStyle}
                  onClick={() => moveToFolder('moveTo')}
                  startIcon={<CheckIcon />}
                  disabled={moveReady}
                >
                  Move
                </Button>
                <Button 
                  className={classes.buttonStyle}
                  onClick={cancelMoveToFolderPart}
                  startIcon={<ClearIcon />}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </div>

          </Fade>
        }
        </>
        }
        {fieldsContext.item_ext !== 'mvsl' &&    
        <>
        <Divider style={{margin: '20px 0', background: themeHlp().palette.primary.gradient}} />
        {
          extendCopyTo === false ?   
          <Button  
            className={classes.buttonStyle}        
            onClick={openCloseCopyFolderPart}
            startIcon={       
              <Avatar component="span" variant="rounded" alt="Copy to" style={{width: 24, height: 24, color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/copy_to.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
            }
          >
            Copy to
          </Button>
          :
          <Fade in={extendCopyTo} timeout={1000}>
            <div>
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Avatar component="span" alt="copy to" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/copy_to.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />     
                <Typography variant="subtitle1" component="span" style={{fontWeight: 'bold', cursor: 'default'}}>Copy this item?</Typography>
              </Box>
              <Typography align='center'>In order to copy the selected item, select the appropriate folder where you want to copy.</Typography>
              <GoToMoveCopyTo folderStr={folderStr} />
              <ButtonGroup fullWidth>
                <Button
                  className={classes.buttonStyle}
                  onClick={() => copyToFolder('copyTo')}
                  startIcon={<CheckIcon />}
                  disabled={copyReady}
                >
                  Copy
                </Button>
                <Button 
                  className={classes.buttonStyle}
                  onClick={cancelCopyToFolderPart}
                  startIcon={<ClearIcon />}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </div>

          </Fade>          
        }
        </>
        }        
        {fieldsContext.item_ext !== 'mvsl' &&    
        <>
        <Divider style={{margin: '20px 0', background: themeHlp().palette.primary.gradient}} />
        {          
          extendShareWith === false ?
          <Button  
            className={classes.buttonStyle}        
            onClick={openShareWithUserPart}
            startIcon={       
              <Avatar component="span" variant="rounded" alt="Share with" style={{width: 24, height: 24, color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/share_with.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
            }
          >
            Share with
          </Button>
          :
          <Fade in={extendShareWith} timeout={1000}>
            <div>
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Avatar component="span" alt="Share with" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/share_with.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />     
                <Typography variant="subtitle1" component="span" style={{fontWeight: 'bold', cursor: 'default'}}>Share this item?</Typography>
              </Box>
              <Typography align='center'>In order to share your item(s) with other users of MovesAs, add them from Contact's Message tab.</Typography>
              <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {usersMessageList.map((value, key) => {
                  if(initialSharedChecked.indexOf(value) !== -1){
                    initialShared = true;
                  } 
                  const labelId = `checkbox-list-secondary-label-${value}`;
                  return (             
                    <ListItem key={key}>
                      <Checkbox
                        edge="start"                          
                        onChange={sharedUserHandleToggle(value)}
                        checked={sharedUserChecked.indexOf(value) !== -1}
                        style={{
                          color: themeHlp().palette.primary.light,
                          padding: '2px',                            
                        }}
                      />
                      {/*
                        <ListItemText id={labelId} primary={`${value}`} 
                        style={{width: '50px',
                          whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'
                        }}

                        />
                      */}
                      <Typography id={labelId} title={`${value}`} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{`${value}`}</Typography>      
                    </ListItem> 
                  );
                })}
              </List>          
              <ButtonGroup fullWidth>
                {!initialShared &&
                <Button
                  className={classes.buttonStyle}
                  onClick={() => shareWithUser('shareWith')}
                  startIcon={<ShareIcon />}
                  disabled={shareReady}
                >
                  Share
                </Button>
                }
                {(initialShared && sharedUserChecked.length > 0) &&
                <Button
                  className={classes.buttonStyle}
                  onClick={() => updateWithUser('updateWith')}
                  startIcon={<CheckIcon />}
                >
                  Update
                </Button>                
                }
                {(initialShared && sharedUserChecked.length === 0) &&
                <Button
                  className={classes.buttonStyle}
                  onClick={() => deleteWithUser('deleteWith')}
                  startIcon={<ShareIcon />}
                >
                  Unshare
                </Button>                
                }
                <Button 
                  className={classes.buttonStyle}
                  onClick={cancelShareWithUserPart}
                  startIcon={<ClearIcon />}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </div>
          </Fade>
        }
        </>        
        }
        {fieldsContext.item_ext !== 'mvsl' &&        
        <>
          <Divider style={{margin: '20px 0', background: themeHlp().palette.primary.gradient}} />
          {fieldsContext.item_ext.toLowerCase() === 'zip' ?
            <>
            <Button 
              className={classes.buttonStyle}
              onClick={() => decompressFile('decompress')}
              startIcon={
                <Avatar component="span" variant="rounded" alt="Decompress" style={{width: 24, height: 24, color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/decompress.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
              }
            >
              Extract
            </Button>            
            </>
            :
            <>
            <Button
              className={classes.buttonStyle}
              onClick={() => compressFile('compress')}
              startIcon={
                <Avatar component="span" variant="rounded" alt="Compress" style={{width: 24, height: 24, color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/compress.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
              }
            >
              Compress
            </Button>            
            </>
          }        
        </>
        }
        {fieldsContext.item_ext !== 'mvsl' &&
        <>
          <Divider style={{margin: '20px 0', background: themeHlp().palette.primary.gradient}} />
          {extendDelete === false ?         
            <Button  
              className={classes.buttonStyle}
              onClick={openDeletePart}
              startIcon={       
                <Avatar component="span" variant="rounded" alt="Delete" style={{width: 24, height: 24, color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/delete.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
              }
            >
              Delete
            </Button>        
            :
            <Fade in={extendDelete} timeout={1000}>
              <div>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <Avatar component="span" alt="warning" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/warning.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />     
                  <Typography variant="subtitle1" component="span" style={{fontWeight: 'bold', cursor: 'default'}}>Delete this item?</Typography>
                </Box>
                <Typography align='center'>This item and its subitems will be permanently deleted.</Typography>
                <ButtonGroup fullWidth>     
                  <Button 
                    className={classes.buttonStyle}
                    onClick={() => deleteFile('delete')}
                    startIcon={<CheckIcon />}
                  >
                    Yes
                  </Button>
                  <Button 
                    className={classes.buttonStyle}
                    onClick={cancelDeletePart}
                    startIcon={<ClearIcon />}
                  >
                    No
                  </Button>
                </ButtonGroup>
              </div>
            </Fade>          
          }
        </> 
        }
        {
          fieldsContext.item_ext !== 'folder' &&
          <div>
            <Divider style={{margin: '20px 0', background: themeHlp().palette.primary.gradient}} />
              {fieldsContext.item_private ?
              <Button
                  className={classes.buttonStyle}
                  startIcon={
                    <Avatar component="span" variant="rounded" alt="Unlock" style={{width: 24, height: 24, color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/unlock.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
                  }
                  onClick={() => goToUnlock('unlock')}
                >
                Unlock
              </Button>
            :
              <Button
                className={classes.buttonStyle}
                startIcon={
                  <Avatar component="span" variant="rounded" alt="Lock" style={{width: 24, height: 24, color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/lock.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
                }
                onClick={() => goToLock('lock')}
              >
              Lock
              </Button>
            }
          </div>
        }
        {
          fieldsContext.item_ext !== 'folder' && fieldsContext.item_ext !== 'mvsl' &&
          <div>
            <Divider style={{margin: '20px 0', background: themeHlp().palette.primary.gradient}} />
            <CopyToClipboard text={downloadLinkSrc}>
              <Button
                className={classes.buttonStyle}
                startIcon={
                  <Avatar component="span" variant="rounded" alt="Copy Link" style={{width: 24, height: 24, color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/downloadlink.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
                }
                >
                Copy Link
              </Button>
            </CopyToClipboard>
            <Divider style={{margin: '20px 0', background: themeHlp().palette.primary.gradient}} />
            <Button  
              className={classes.buttonStyle}
              startIcon={
                <Avatar component="span" variant="rounded" alt="Download" style={{width: 24, height: 24, color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/download.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
              }
              >
              <Link href={downloadLinkSrc} underline='none' style={{color:'white'}} target='_blank' download>Download</Link>
            </Button>
          </div>
        }
      </CardContent>
    </Card>
  )
//\\list function END

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <SwipeableDrawer
            id="propertyBoxGroup"
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
        {list(newFileName, fieldsContext.id)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );

}
export default Properties;
