export const loginSrv = (param) => {

  async function loginService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/api/user/login/', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
   //   mode: 'no-cors', // no-cors, *cors, same-origin
   //   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "include", // include, *same-origin, omit
      body: JSON.stringify({
        email: param.email, 
        password: param.password,
        movesas: true
      })
    });
     return await rawResponse.json();
  };

  return loginService()
}
