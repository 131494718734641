export const getusermessageSrv = (param) => {
  async function getusermessageService() {
    const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/user/getusermessage/' + param, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
      credentials: "include", // include, *same-origin, omit
    });
     return await rawResponse.json();
  };

  return getusermessageService()
}
