import React, { useState, useEffect } from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from "@material-ui/core/IconButton";
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import HomeIcon from '@material-ui/icons/Home';
import { themeHlp } from '../Helper/ThemeHelper.js';

const theme = createMuiTheme({
  typography:{
    fontFamily: [
      'Inter', 
      'Helvetica',
      'Arial',
      'Sans-Serif'
    ]
  }
});

const useStyles = makeStyles(() => ({
  page_container: {
    position: 'relative',
    height: '0px',
    minHeight: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  page_container_for_welcomepage: {
    position: 'relative',
  },
  headline: {
    fontSize: '28px'
  },
  subHeadline: {
    color: themeHlp().palette.primary.dark
  },
  screenshot: {
    paddingTop: '15px', 
    width:'90%', 
    maxWidth: '960px', 
    display: 'block', 
    margin: 'auto auto'
  },  
  casual_text: {
    fontFamily: 'Helvetica',
    fontSize: '1.065rem'
  },
  helpButtonStyle: {
    width: '30px',
    height: '30px',
    margin: '10px 2px 0 2px',
    color:'#fff',
    background: themeHlp().palette.primary.gradientRadial,
    '&:hover': {
      background: themeHlp().palette.primary.gradientRadialHover,
   },
  } 
}))


const FeaturesGuide = () => {
  const classes = useStyles();
  let navigate = useNavigate();
  let location = useLocation();  
  const [expanded, setExpanded] = useState('');
  const [historyButtons, setHistoryButtons] = useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
 
  useEffect(() => {
    if(location.pathname.includes('help')){
      setHistoryButtons(true);
    }
  },[])

  return (
    <>
      <ThemeProvider theme={theme} >
        <Box sx={{ flexGrow: 1 }} className={historyButtons ? classes.page_container : classes.page_container_for_welcomepage}>
          <Grid container>
            <Grid container align="center" spacing={2} justify="center" style={{margin: 'auto'}}>
              <Grid item xs={12} style={{ backgroundImage: 'linear-gradient(to bottom, rgba(255,0,0,0), rgba(255,255,255,1))'}}>
              {historyButtons && 
                  <Box>
                    <IconButton title="Back" aria-label="fingerprint" color="secondary" className={classes.helpButtonStyle} onClick={() => navigate(-1)}>
                      <ArrowBackIcon />
                    </IconButton>
                    <IconButton title="Home" aria-label="fingerprint" color="secondary" className={classes.helpButtonStyle} onClick={() => navigate('/help')}>
                      <HomeIcon />
                    </IconButton>
                    <IconButton title="Forward" aria-label="fingerprint" color="secondary" className={classes.helpButtonStyle} onClick={() => navigate(1)}>
                      <ArrowForwardIcon />
                    </IconButton>
                  </Box>
                }
                <Typography className={classes.headline}  variant="subtitle1" component="span">
                  Features
                </Typography>
              </Grid>
            </Grid>
            <Accordion expanded={expanded === 'panel1'} style={{margin:0, width:'100%'}} onChange={handleChange('panel1')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.subHeadline}>
                  Device usage
                </Typography>          
              </AccordionSummary>
              <AccordionDetails>
              <Box sx={{width:'100%'}}>
                <Typography variant="inherit" className={classes.casual_text} >
                  There is a range of different devices at various screen sizes. 
                  In contrast to the generally accepted design, where the website can look different on different devices, MovesAs allows you to use the same functionality with the same interface.
                  <br />
                  Here are some screenshots of popular devices (Desktop, laptop, tablet, and smartphone) that show how the website of MovesAs looks on different devices.
                </Typography>
                <Divider light />
                <Typography variant="subtitle2" style={{textAlign: 'center', marginTop: '10px'}}>
                  Desktop Computer
                </Typography>             
                <img className={classes.screenshot} style={{width:'80%', maxWidth: '960px'}} alt="features screenshot 01" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/features/features_screenshot_01.jpg'}`}/>
                <Typography variant="subtitle2" style={{textAlign: 'center', marginTop: '10px'}}>
                  Laptop
                </Typography>   
                <img className={classes.screenshot} style={{width:'60%', maxWidth: '720px'}} alt="features screenshot 02" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/features/features_screenshot_02.jpg'}`}/>
                <Typography variant="subtitle2" style={{textAlign: 'center', marginTop: '10px'}}>
                  Tablet
                </Typography>   
                <img className={classes.screenshot} style={{width:'30%', maxWidth: '360px'}} alt="features screenshot 03" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/features/features_screenshot_03.jpg'}`}/>
                <Typography variant="subtitle2" style={{textAlign: 'center', marginTop: '10px'}}>
                  Smartphone
                </Typography>   
                <img className={classes.screenshot} style={{width:'20%', maxWidth: '232px'}} alt="features screenshot 04" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/features/features_screenshot_04.jpg'}`}/>
              </Box>
              </AccordionDetails>
            </Accordion>      
            <Accordion expanded={expanded === 'panel2'} style={{margin:0, width:'100%'}} onChange={handleChange('panel2')}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography className={classes.subHeadline}>
                  Files in one place
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{width:'100%'}}>
                  <Typography variant="inherit" className={classes.casual_text}>
                    It's very convenient to have all important files in one place. Being registered in MovesAs, you can easily get them by searching, regardless of when and where they were stored in the MovesAs.
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} style={{margin:0, width:'100%'}} onChange={handleChange('panel3')}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography className={classes.subHeadline}>
                  Features of storage
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{width:'100%'}}>
                  <Typography variant="inherit" className={classes.casual_text}>
                    Each account owner can use 300 MB of disk space. Maximum file upload size up to 50 MB.
                  </Typography>
                  <Divider light />
                  <Card raised style={{ marginTop: '15px' }}>
                    <CardContent>                    
                      <Avatar component="span" alt="warning" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/warning.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />     
                      <Typography variant="subtitle1" component="span" style={{fontWeight: 'bold', cursor: 'default'}}>Warning!</Typography>
                    </CardContent>
                    <CardActions>
                      Deleted items will be permanently lost.
                    </CardActions>
                  </Card>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {historyButtons &&
          <Grid container spacing={2} style={{margin: 'auto'}}>
            <Grid item xs={12} style={{height: '76px',  backgroundImage: 'linear-gradient(to top, rgba(255,0,0,0), rgba(255,255,255,1))'}}></Grid>
          </Grid>
          }
        </Box>
      </ThemeProvider>
    </>      
  )
} 
 
export default FeaturesGuide;
