export const addlinksSrv = (param) => {
  async function addlinksService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/user/addlinks', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        item_id: param.item_id,
        content: param.content
      }),
      credentials: "include", // include, *same-origin, omit
    });
     return await rawResponse.json();
  };

  return addlinksService()
}