export const getconfigSrv = () => {
  async function getconfigService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/api/user/getconfig', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: "include", // include, *same-origin, omit
    });
     return await rawResponse.json();
  };

  return getconfigService()
}
