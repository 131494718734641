export const setconfigSrv = (param) => {

  async function setconfigService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/api/user/setconfig/', {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: "include", // include, *same-origin, omit
      body: JSON.stringify({
        session_age: param.session_age,
        max_upload_size: param.max_upload_size
      })
    });
     return await rawResponse.json();
  };

  return setconfigService()
}
