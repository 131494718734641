export const resendActivationLinkSrv = (param) => {

  async function resendActivationLink() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/api/user/resend-activation-link/', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
   //   mode: 'no-cors', // no-cors, *cors, same-origin
   //   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "include", // include, *same-origin, omit
      body: JSON.stringify({
        email: param.email, 
        password: param.password
      })
    });
     return await rawResponse.json();
  };

  return resendActivationLink()
}
