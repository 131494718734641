export const getmvscfileSrv = (param) => {
  async function getmvscfileService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/api/user/getmvscfile/' + param, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },   
      credentials: "include", // include, *same-origin, omit   
    });
     return await rawResponse.json();
  };

  return getmvscfileService()
}
