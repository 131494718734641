import React, {useLayoutEffect, useState, useEffect} from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import VolumeUp from '@material-ui/icons/VolumeUp';
import Avatar from '@material-ui/core/Avatar';
import Link from '@material-ui/core/Link';
import FooterBar  from './Bars/FooterBar';
import LoginBar from './Bars/LoginBar';
import { makeStyles } from '@material-ui/core/styles';
import { themeHlp } from './Helper/ThemeHelper.js';
import DesktopGuide from "./Guide/DesktopGuide";
import UsersGuide from "./Guide/UsersGuide";
import FilesGuide from "./Guide/FilesGuide";
import FeaturesGuide from "./Guide/FeaturesGuide";
import AppsGuide from "./Guide/AppsGuide";
import GetMoreGuide from "./Guide/GetMoreGuide";

const useStyles = makeStyles(() => ({
  page_container: {
    position: 'relative',
    minHeight: '100vh',
  },
  welcome_container: {
    '& .MuiGrid-root': {
      paddingBottom: '60px'
    }
  },
  paragraph_text: {
    fontFamily: 'Helvetica',
    fontSize: '1rem',
    color: themeHlp().palette.blackText.light, 
    margin: '10px 0 24px 0'
  },
  media_button: {
    width: '30px',
    height: '30px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '30px',
    backgroundPosition: '0',
    display: 'inline-block',
    cursor: 'pointer'
  },
  media_top_button: {
    width: '30px',
    height: '30px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '30px',
    backgroundPosition: '0',
    display: 'inline-block',
    cursor: 'pointer',
    zIndex: 1
  },
  video_time_slider: {
    height: 0,
    padding: 0,    
    '& .MuiSlider-thumb' : {
      display: 'none'
    },
    '& .MuiSlider-track, .MuiSlider-rail': {   
      backgroundImage : `url(${process.env.REACT_APP_URL_CLIENTPORT}/icon/mediaplayer_slider.png)`,
      backgroundSize: '22px 22px',
      height: '22px !important',
      marginTop: '0px !important',
      opacity: 0.9
    }
  },
  video_time_text: {
    width: '104px',
    height: '22px',
    display: 'inline-flex',  
    paddingBottom: '20px',
    fontSize: '0.95rem',    
    alignItems: 'center',  
    verticalAlign: 'middle',
    justifyContent: 'center',
    cursor: 'default',
    color: themeHlp().palette.whiteText.main
  },
  video_sound_slider: {
    color: themeHlp().palette.whiteText.main,
  },
  video_controller: {
    width: '100%',
    maxWidth: '720px',
    border: '1px solid #3e3221',
    borderRadius: '0 0 15px 15px',
    background: themeHlp().palette.whiteText.main,
  },
  screen_cover: {
    background: themeHlp().palette.blackText.dark,  
    backgroundImage : `url(${process.env.REACT_APP_URL_CLIENTPORT}/icon/mediaplayer_screen_play.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100px',
    backgroundPosition: 'center',  
    position: 'absolute',
    marginLeft: 'auto',    
    marginRight: 'auto',
    top: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    opacity: '1',
  },
}))

let playPauseInterval = {};

const Welcome = () => {
  const classes = useStyles();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [vid, setVid] = useState({});
  const [mediaLoadTime, setMediaLoadTime] = useState(1);
  const [playing, setPlaying] = useState(2);
  const [muted, setMuted] = useState(false);
  const [mediaSoundDisabled, setMediaSoundDisabled] = useState(false);
  const [mediaSoundValue, setMediaSoundValue] = useState(1);
  const mediaStep = 5;
  const mediaAspectRatio = 1.777777778;
  const mobileNotMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const [randomGuideNumber, setRandomGuideNumber] = useState(1);
  const [mediaTimelineEnabled, setMediaTimelineEnabled] = useState(false);
  
  window.history.forward();

  const mediaPlay = () => {  
    setPlaying(1);

    const videoElm = document.getElementById("mediaFrame"); 
    if(videoElm.duration){      
      videoElm.play();
      let videoScreen = document.getElementById("screen");
      videoScreen.style.opacity = '0';
    
      let durationSec = parseInt(videoElm.duration % 60) > 9 ? parseInt(videoElm.duration % 60) : '0' + parseInt(videoElm.duration % 60);
      let durationMin = parseInt((videoElm.duration / 60) % 60);
      let currenttimeSec = parseInt(videoElm.currentTime % 60) > 9 ? parseInt(videoElm.currentTime % 60) : '0' + parseInt(videoElm.currentTime % 60);
      let currenttimeMin = parseInt((videoElm.currentTime / 60) % 60);

      setVid({
        width: videoElm.videoWidth, 
        height: videoElm.videoHeight, 
        duration: currenttimeMin + ':' + currenttimeSec + ' / ' + durationMin + ':' + durationSec,
        max: parseInt(videoElm.duration)
      })


      setMediaTimelineEnabled(true);
      
      playPauseInterval = setInterval(() =>{
        let durationSec = parseInt(videoElm.duration % 60) > 9 ? parseInt(videoElm.duration % 60) : '0' + parseInt(videoElm.duration % 60);
        let durationMin = parseInt((videoElm.duration / 60) % 60);
        let currenttimeSec = parseInt(videoElm.currentTime % 60) > 9 ? parseInt(videoElm.currentTime % 60) : '0' + parseInt(videoElm.currentTime % 60);
        let currenttimeMin = parseInt((videoElm.currentTime / 60) % 60);
        
        setVid({
          width: videoElm.videoWidth, 
          height: videoElm.videoHeight, 
          duration: currenttimeMin + ':' + currenttimeSec + ' / ' + durationMin + ':' + durationSec,
          max: parseInt(videoElm.duration)
        })

        let location = window.location.href.split('/');     
        if(location[location.length-1] !== '' || videoElm.ended){
          setPlaying(0);
          videoElm.pause();
          clearInterval(playPauseInterval);
        }
      }, 400);
    }else{
      setVid({
        width: '300px', 
        height: videoElm.videoHeight, 
        duration: 'Not supported',
        max: 0
      })
    }
  }

  const mediaPause = () => {
    setPlaying(0)
    const videoElm = document.getElementById("mediaFrame");
    videoElm.pause();
    clearInterval(playPauseInterval);
  }

  const mediaPlayPause = () => {
    (playing === 0 || playing === 2) ? mediaPlay() : mediaPause();
  }

  const mediaForward = () => {
    if(playing !== 2){
      const videoElm = document.getElementById("mediaFrame"); 
      let durationSec = parseInt(videoElm.duration % 60) > 9 ? parseInt(videoElm.duration % 60) : '0' + parseInt(videoElm.duration % 60);
      let durationMin = parseInt((videoElm.duration / 60) % 60);
      let currenttimeSec = parseInt((videoElm.currentTime % 60) + mediaStep) > 9 ? parseInt((videoElm.currentTime % 60) + mediaStep) : '0' + parseInt((videoElm.currentTime % 60) + mediaStep);
      let currenttimeMin = parseInt((videoElm.currentTime / 60) % 60)

      videoElm.currentTime += mediaStep;

      if(currenttimeSec === 60 && (videoElm.currentTime + mediaStep) <= videoElm.duration){
        currenttimeMin +=1;
        currenttimeSec = '00';
      }else if(currenttimeSec > 60 && (videoElm.currentTime + mediaStep) <= videoElm.duration){
        currenttimeMin +=1;
        currenttimeSec = '0' + ((currenttimeSec - 60));
      }else if(currenttimeSec > 60 && (videoElm.currentTime) + mediaStep > videoElm.duration){
        currenttimeSec = parseInt((videoElm.currentTime % 60)) > 9 ? parseInt((videoElm.currentTime % 60)) : '0' + parseInt((videoElm.currentTime % 60));
        currenttimeMin +=1;
      }else if(currenttimeSec <= 60 && (videoElm.currentTime) + mediaStep > videoElm.duration){
        currenttimeSec = parseInt((videoElm.currentTime % 60)) > 9 ? parseInt((videoElm.currentTime % 60)) : '0' + parseInt((videoElm.currentTime % 60));
      }

      setVid({
        width: videoElm.videoWidth, 
        height: videoElm.videoHeight, 
        duration: currenttimeMin + ':' + currenttimeSec + ' / ' + durationMin + ':' + durationSec,
        max: parseInt(videoElm.duration)
      })
    }
  }

  const mediaBackward = () => {
    if(playing !== 2){
      const videoElm = document.getElementById("mediaFrame");        
      let durationSec = parseInt(videoElm.duration % 60) > 9 ? parseInt(videoElm.duration % 60) : '0' + parseInt(videoElm.duration % 60);
      let durationMin = parseInt((videoElm.duration / 60) % 60);
      let currenttimeSec = parseInt((videoElm.currentTime % 60) - mediaStep)
      let currenttimeMin = parseInt((videoElm.currentTime / (60 + mediaStep)) % 60);

      if(currenttimeMin === 0 && parseInt(currenttimeSec) < mediaStep){
        videoElm.currentTime = 0;
        currenttimeSec = '00';
      }else if(currenttimeMin === 0 && parseInt(currenttimeSec) >= mediaStep && parseInt(currenttimeSec)>9){
        videoElm.currentTime -= mediaStep;      
      }else if(currenttimeMin === 0 && parseInt(currenttimeSec) >= mediaStep && parseInt(currenttimeSec)<=9){
        videoElm.currentTime -= mediaStep;
        currenttimeSec = '0' + currenttimeSec
      }else if(currenttimeMin > 0 && currenttimeSec < mediaStep){
        videoElm.currentTime -= mediaStep;
        currenttimeMin -= 1
        currenttimeSec = (60 + currenttimeSec) - mediaStep
      }

      setVid({
        width: videoElm.videoWidth, 
        height: videoElm.videoHeight, 
        duration: currenttimeMin + ':' + currenttimeSec + ' / ' + durationMin + ':' + durationSec,
        max: parseInt(videoElm.duration)
      }) 
    }
  }

  const mediaSound = () => {
    const videoElm = document.getElementById("mediaFrame");    
    setMediaSoundValue(videoElm.volume);
    setMediaTimelineEnabled(false);
    setTimeout(() =>{
      setMediaTimelineEnabled(true);
    },5000);
  }

  const mediaChengeSound = (event, newValue) => {
    const videoElm = document.getElementById("mediaFrame");
    videoElm.volume = newValue
    if(newValue === 0){
      setMuted(true);
    }else{
      setMuted(false);
    }
  }
  
  const mediaChengeTime = (event, newValue) => {
    const videoElm = document.getElementById("mediaFrame");

    if(typeof newValue === 'number') {
      videoElm.currentTime = parseInt((videoElm.duration * newValue) / videoElm.duration);
      let durationSec = parseInt(videoElm.duration % 60) > 9 ? parseInt(videoElm.duration % 60) : '0' + parseInt(videoElm.duration % 60);
      let durationMin = parseInt((videoElm.duration / 60) % 60);
      let currenttimeSec = parseInt(videoElm.currentTime % 60) > 9 ? parseInt(videoElm.currentTime % 60) : '0' + parseInt(videoElm.currentTime % 60);
      let currenttimeMin = parseInt((videoElm.currentTime / 60) % 60);

      setTimeout(() => {
        if(videoElm.paused){
             setPlaying(0);
           }else{
             setPlaying(1);
           }
      },200)

      setVid({
        width: videoElm.videoWidth, 
        height: videoElm.videoHeight, 
        duration: currenttimeMin + ':' + currenttimeSec + ' / ' + durationMin + ':' + durationSec,
        max: parseInt(videoElm.duration)
      })     
    }    
  }

  function randomGuideAppearance(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  useLayoutEffect(() => {
    document.getElementsByTagName("BODY")[0].style.overflowY = 'auto';

    function updateSize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => {     
      window.removeEventListener('resize', updateSize); 
    }
  }, [windowWidth, windowHeight]);

  useEffect(() => {
    setRandomGuideNumber(randomGuideAppearance(1, 6));
    let propertyBoxGroup = document.getElementById('propertyBoxGroup');
    let dialogBox = document.getElementById('dialogBox');
    if(propertyBoxGroup){
      propertyBoxGroup.remove();
    }
    if(dialogBox){
      dialogBox.remove();
    }
  }, [mediaLoadTime]);

  return (
    <>
      <Box className={classes.page_container}>
        <LoginBar />
        <Box className={classes.welcome_container}>
          <Grid container align="center">
            <Grid container spacing={0} align="center">            
              <Grid item xs={12} style={{width:'100%', maxWidth:'720px', margin: 'auto',padding: '0px 40px'}}>
                <Typography variant="h5" component="h5" style={{color: themeHlp().palette.blackText.main}}>
                  Make file management easier by creating, moving, copying, and sharing wherever you are.
                </Typography>
                <Typography variant="inherit" component="div" className={classes.paragraph_text}>
                  MovesAs helps you store and use your files no matter what device you use. There is no need for another tool to get them and control them.
                </Typography>
              </Grid>
              <Grid item xs={12} style={{padding: 0}}>
                <Box style={{position:'relative'}}>
                  <video id="mediaFrame" autobuffer="true" x-webkit-airplay="allow" controlsList="nodownload" disablePictureInPicture=""  controls="" playinfullscreen="false" playsInline={true} src={process.env.REACT_APP_URL_CLIENTPORT+ '/video/movesas_presentation.mp4'}
                    style={{
                      width: `${windowWidth - 20}px`,
                      height: `${(windowWidth - 20) / mediaAspectRatio}px`,
                      maxWidth: '720px',
                      maxHeight: '405px',
                    }}
                  ></video>
                  <div id="screen" className={classes.screen_cover} onClick={mediaPlayPause} onContextMenu={e => e.preventDefault()} 
                    style={{
                      width: `${windowWidth - 20}px`,
                      height: `${(windowWidth - 20) / mediaAspectRatio}px`,
                      maxWidth: '720px',
                      maxHeight: '405px',
                    }}
                  ></div>
                </Box>
                <Box style={{position: 'relative', height: '40px', maxWidth: '720px'}}>
                  <Slider
                    className={classes.video_time_slider}    
                    style={{ width: '100%', maxWidth: '720px', marginLeft: '0px'}}          
                    size="small"
                    min={0}
                    step={1}
                    max={vid.max}                      
                    defaultValue={0}
                    aria-label="Small"
                    valueLabelDisplay="on"
                    onChange={mediaChengeTime}
                    disabled={mediaTimelineEnabled ? false : true}
                  />
                  {playing !== 2 && !mobileNotMobile && !mediaTimelineEnabled &&
                    <div style={{position: 'absolute',top: '11px', pointerEvents: !mediaTimelineEnabled ? 'auto' : 'none'}}>   
                      <VolumeUp style={{color: themeHlp().palette.whiteText.main, margin: '3px 2px 3px 0', padding: '0px 4px'}} />
                      <Slider
                        className={classes.video_sound_slider}
                        style={{ width: '50px', margin: '0 20px 0 0', maxWidth: vid.width, marginLeft: '0px', height: '16px', padding: '14px 0 0 0'}}
                        min={0}
                        step={0.01}
                        max={1}
                        defaultValue={mediaSoundValue}
                        aria-label="Small"
                        onChange={mediaChengeSound}
                        disabled={mediaSoundDisabled}
                      />
                    </div>
                  }
                  {playing !== 2 && 
                    <div style={{position: 'absolute',top: '14px',  width: '100%', pointerEvents: 'none'}}>
                      <div className={classes.video_time_text}><span>{vid.duration}</span></div>
                    </div>
                  }
                </Box>  
                <Box className={classes.video_controller} style={{ height: '40px'}}>
                  <div style={{display: windowWidth < 420 ? 'block': 'inline'}}>
                    <div style={{position: 'relative', display: 'inline-block', margin: '0 30px 0 0', height:'30px'}}>
                      <div title="Mute" className={classes.media_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/mediaplayer_unmuted.png)', position: 'absolute', zIndex: (!muted) ? 99 : 0}} onClick={mediaSound}></div>
                      <div title="Unmute" className={classes.media_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/mediaplayer_muted.png)', position: 'absolute', zIndex: (muted) ? 99 : 0}} onClick={mediaSound}></div>
                    </div>
                    <div title="Jump backward 5 seconds" className={classes.media_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/mediaplayer_backward.png)'}} onClick={mediaBackward}></div>
                    <div style={{position: 'relative', display: 'inline-block', margin: '0 30px 0 0',  height:'30px'}}>
                      <div title="Play" className={classes.media_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/mediaplayer_play.png)', position: 'absolute', zIndex: (playing === 0 || playing === 2) ? 99 : 0}} onClick={mediaPlay}></div>
                      <div title="Pause" className={classes.media_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/mediaplayer_pause.png)', position: 'absolute', zIndex: (playing === 1) ? 99 : 0}} onClick={mediaPause}></div>
                    </div>
                    <div title="Jump forward 5 seconds" className={classes.media_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/mediaplayer_forward.png)'}} onClick={mediaForward}></div>
                  </div>
                </Box>
              </Grid>              
            </Grid>            
          </Grid>
        </Box>
        <Box style={{padding: '0 0 50px 0', }}>
          <Link href="/help" target="_blank" style={{width: '54px', height: '54px', display: 'block', margin: 'auto'}}>
            <Avatar title="Help" component="span" variant="rounded" alt="Help" style={{width: '54px', height: '54px', color:'transparent',backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/help.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
          </Link>
          {randomGuideNumber === 1 && <DesktopGuide />}
          {randomGuideNumber === 2 && <UsersGuide />}
          {randomGuideNumber === 3 && <FilesGuide />}
          {randomGuideNumber === 4 && <GetMoreGuide />}
          {randomGuideNumber === 5 && <AppsGuide />}
          {randomGuideNumber === 6 && <FeaturesGuide />}
        </Box>
        <FooterBar />
      </Box>     
    </>
  )
}

export default Welcome;
