import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FooterBar  from '../Bars/FooterBar';
import { themeHlp } from '../Helper/ThemeHelper.js';

const useStyles = makeStyles(() => ({
  page_container: {
    position: 'relative',
    minHeight: '100vh',
  }
}));

const NotFound = () => {
  const classes = useStyles();

  useEffect(() => {
    let propertyBoxGroup = document.getElementById('propertyBoxGroup');
    let dialogBox = document.getElementById('dialogBox');
    if(propertyBoxGroup){
      propertyBoxGroup.remove();
    }
    if(dialogBox){
      dialogBox.remove();
    }
  }, []);

  return (
    <>
      <Box className={classes.page_container}>
        <Grid container align="center">
          <Grid container spacing={0}>
            <Grid item xs={12} align="center">
              <Box component="div" sx={{width: "100%", maxWidth: "140px", padding: '40px 40px 20px 40px', verticalAlign: "middle"}}>
                <Link to="/" ><img src={process.env.REACT_APP_URL_CLIENTPORT+ '/icon/movesas.png'} alt="Moves as logo" style={{width: '100%', maxWidth: '140px'}} /></Link>
              </Box>
              <Box component="div" sx={{padding: '0px 40px', verticalAlign: "middle"}}>
              <Typography variant="h5" component="h5" style={{color: themeHlp().palette.blackText.main}}>
                The page you are looking for cannot be found.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <FooterBar />
      </Box>
    </>
  );
}

export default NotFound;
