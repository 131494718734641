export const connectroomchatSrv = (param) => {
  async function connectroomchatService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/user/connectroom', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        room: param.room,
        password: param.password,
        user: param.user
      }),
      credentials: "include", // include, *same-origin, omit
    });
     return await rawResponse.json();
  };

  return connectroomchatService()
}
