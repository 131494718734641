export const loadsharedfilesSrv = (param) => {
  async function loadsharedfileService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/api/user/loadsharedfiles/' + param.item_id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
     return await rawResponse.json();
  };

  return loadsharedfileService()
}
