import React, { useState, useEffect } from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import HomeIcon from '@material-ui/icons/Home';
import { themeHlp } from '../Helper/ThemeHelper.js';

const theme = createMuiTheme({
  typography:{
    fontFamily: [
      'Inter', 
      'Helvetica',
      'Arial',
      'Sans-Serif'
    ]
  }
});

const useStyles = makeStyles(() => ({
  page_container: {
    position: 'relative',
    height: '0px',
    minHeight: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  page_container_for_welcomepage: {
    position: 'relative',
  },
  headline: {
    fontSize: '28px'
  },
  subHeadline: {
    color: themeHlp().palette.primary.dark
  },
  screenshot: {
    paddingTop: '15px', 
    width:'90%', 
    maxWidth: '960px', 
    display: 'block', 
    margin: 'auto auto'
  },  
  casual_text: {
    fontFamily: 'Helvetica',
    fontSize: '1.065rem'
  },
  helpButtonStyle: {
    width: '30px',
    height: '30px',
    margin: '10px 2px 0 2px',
    color:'#fff',
    background: themeHlp().palette.primary.gradientRadial,
    '&:hover': {
      background: themeHlp().palette.primary.gradientRadialHover,
   },
  } 
}))


const UsersGuide = () => {
  const classes = useStyles();
  let navigate = useNavigate();
  let location = useLocation();  
  const [expanded, setExpanded] = useState('');
  const [historyButtons, setHistoryButtons] = useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
 
  useEffect(() => {
    if(location.pathname.includes('help')){
      setHistoryButtons(true);
    }
  },[])

  return (
    <>
      <ThemeProvider theme={theme} >
        <Box sx={{ flexGrow: 1 }} className={historyButtons ? classes.page_container : classes.page_container_for_welcomepage}>
          <Grid container>
            <Grid container align="center" spacing={2} justify="center" style={{margin: 'auto'}}>
              <Grid item xs={12} style={{ backgroundImage: 'linear-gradient(to bottom, rgba(255,0,0,0), rgba(255,255,255,1))'}}>
              {historyButtons && 
                  <Box>
                    <IconButton title="Back" aria-label="fingerprint" color="secondary" className={classes.helpButtonStyle} onClick={() => navigate(-1)}>
                      <ArrowBackIcon />
                    </IconButton>
                    <IconButton title="Home" aria-label="fingerprint" color="secondary" className={classes.helpButtonStyle} onClick={() => navigate('/help')}>
                      <HomeIcon />
                    </IconButton>
                    <IconButton title="Forward" aria-label="fingerprint" color="secondary" className={classes.helpButtonStyle} onClick={() => navigate(1)}>
                      <ArrowForwardIcon />
                    </IconButton>
                  </Box>
                }
                <Typography className={classes.headline}  variant="subtitle1" component="span">
                  Users
                </Typography>
              </Grid>
            </Grid>
            <Accordion expanded={expanded === 'panel1'} style={{margin:0, width:'100%'}} onChange={handleChange('panel1')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"          
              >
                <Typography className={classes.subHeadline}>
                  Create an account
                </Typography>          
              </AccordionSummary>
              <AccordionDetails>
              <Box sx={{width:'100%'}}>
                <Typography variant="inherit" className={classes.casual_text} >
                  Each person who wants to have an account with MovesAs has to have a valid email address.
                  In order to create an account, go to <Link href="https://movesas.com/register" target="_blank"> https://movesas.com/register</Link> or on the welcome page, click on the "Sign Up" button.
                  <br />
                  Let's look at the fields that need to be filled.   
                </Typography>
                <ol>
                  <li>"First name" is an important field because your name will be displayed to other users who contact you</li>
                  <li>Last name</li>
                  <li>Email address to be used as username</li>
                  <li>Set your password</li>
                  <li>Confirm your password</li>
                  <li>Click "Sign Up" to send an email with an activation ID to your email address</li>
                </ol>
                <Divider light />   
                <img className={classes.screenshot} style={{width: '60%', maxWidth: '420px'}} alt="users screenshot 01-1" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/users/users_screenshot_01_1.jpg'}`}/>
                <br />
                <Typography variant="inherit" className={classes.casual_text} >                  
                  After all, go to your email account and click on the activation link that you received from MovesAs.
                  The following message will appear.
                </Typography>
                <br />
                <Divider light />
                <img className={classes.screenshot} style={{width: '60%', maxWidth: '420px'}} alt="users screenshot 01-2" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/users/users_screenshot_01_2.jpg'}`}/>
              </Box>
              </AccordionDetails>
            </Accordion>      
            <Accordion expanded={expanded === 'panel2'} style={{margin:0, width:'100%'}} onChange={handleChange('panel2')}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography className={classes.subHeadline}>
                  Log in
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{width:'100%'}}>
                  <Typography variant="inherit" className={classes.casual_text}>
                    By going through the activation, you can log in (see below) at any time.
                  </Typography>
                  <ol>
                    <li>Fill in your email (username)</li>
                    <li>Fill in your password</li>
                    <li>Login to your account</li>
                    <li>Attempting to set a new password</li>
                    <li>Goes to registration page</li>
                  </ol>
                  <Divider light />
                  <img className={classes.screenshot} style={{width: '60%', maxWidth: '420px'}} alt="users screenshot 02" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/users/users_screenshot_02.jpg'}`}/>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel3'} style={{margin:0, width:'100%'}} onChange={handleChange('panel3')}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography className={classes.subHeadline}>
                  Reset password
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{width:'100%'}}>
                  <Typography variant="inherit" className={classes.casual_text}>
                  To reset your password, click "Forgot password" on the login page. 
                  Fill in your email address in the "Email" field and click "Send Password Reset Link".
                  Go to your email account and click on the link that you received from MovesAs.
                  </Typography>
                  <Divider light />
                  <img className={classes.screenshot} style={{width: '60%', maxWidth: '420px'}} alt="users screenshot 03-1" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/users/users_screenshot_03_1.jpg'}`}/>
                  <br />
                  <Typography variant="inherit" className={classes.casual_text}>                    
                    In the window that appears, set a password and save as a new password.
                  </Typography>
                  <Divider light />
                  <img className={classes.screenshot} style={{width: '40%', maxWidth: '280px'}} alt="users screenshot 03-2" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/users/users_screenshot_03_2.jpg'}`}/>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel4'} style={{margin:0, width:'100%'}} onChange={handleChange('panel4')}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography className={classes.subHeadline}>
                  Account information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{width:'100%'}}>
                  <Typography variant="inherit" className={classes.casual_text}>
                    See your account information via the "Account" application by clicking "Show Applications" in the sidebar.
                  </Typography>
                  <Divider light />
                  <img className={classes.screenshot} alt="users screenshot 04" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/users/users_screenshot_04.jpg'}`}/>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel5'} style={{margin:0, width:'100%'}} onChange={handleChange('panel5')}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5bh-content"
                id="panel5bh-header"
              >
                <Typography className={classes.subHeadline}>
                  Find other users
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{width:'100%'}}>
                  <Typography variant="inherit" className={classes.casual_text}>
                    Before you can share your item, open the integrated "Contact" app either from the application menu or from the header bar.
                    And then, in the "Message" tab, search for who you want to contact. 
                    After that, when you see the person you were looking for, click the "Add" button.                    
                  </Typography>
                  <br />
                  <br />
                  <Link href="/help/apps" underline="none">Learn more about "Contact" application</Link>.
                  <Divider light />
                  <img className={classes.screenshot} alt="users screenshot 05" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/users/users_screenshot_05.jpg'}`}/>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {historyButtons &&
          <Grid container spacing={2} style={{margin: 'auto'}}>
            <Grid item xs={12} style={{height: '76px',  backgroundImage: 'linear-gradient(to top, rgba(255,0,0,0), rgba(255,255,255,1))'}}></Grid>
          </Grid>
          }
        </Box>
      </ThemeProvider>
    </>      
  )
} 
 
export default UsersGuide;
