import React, { useLayoutEffect, useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import VolumeUp from '@material-ui/icons/VolumeUp';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import CancelSharpIcon from '@material-ui/icons/Close';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import BorderInnerIcon from '@material-ui/icons/BorderInner';
import { styled } from '@material-ui/core/styles';
import { makeStyles} from '@material-ui/core/styles';
import { themeHlp }  from '../Helper/ThemeHelper.js';
import  appResizeWindowHlp  from '../Helper/AppResizeWindowHelper.js';
import { getfilesSrv } from '../Service/GetFilesService.js';
import { getsharedfilesSrv } from '../Service/GetSharedFilesService.js';
import { exportfilesSrv } from '../Service/ExportFilesService.js';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),    
    minWidth: 180,
    color: themeHlp().palette.blackText.light,
    backgroundColor: themeHlp().palette.secondary.light,  
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      width: '380px',
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 16,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        color: themeHlp().palette.blackText.main,        
      },
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  menu_box:{
    width:'100%', 
    height:'340px', 
    margin:'auto auto', 
    background: 'linear-gradient(135deg, rgb(38, 93, 140) 0%, rgb(38, 93, 140) 15%, rgb(0, 140, 183) 50%)', 
    opacity:'1', 
    borderRadius:'15px', 
    border:'4px solid #f5a33e',
  },
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: themeHlp().palette.secondary.light,
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  user_avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: 'auto',
    cursor: 'pointer'
  },
  dialog_action: {
    padding: '0px'
  },
  dialog_content:{
    padding: '0px'    
  },
  text_box: {
    width:'160px',
    backgroundColor: 'white'
  },
  param_box: {
    width:'64px',    
    backgroundColor: 'white',
    border: '1px solid ' + themeHlp().palette.blackText.light,        
    margin: '0 2px 0 6px',
    '&:hover': {
        border: '1px solid ' + themeHlp().palette.primary.light,        
    }
  },
  param_time_box: {
    width:'74px',    
    backgroundColor: 'white',
    border: '1px solid ' + themeHlp().palette.blackText.light,        
    margin: '0 2px 0 6px',
    '&:hover': {
        border: '1px solid ' + themeHlp().palette.primary.light,        
    }
  },
  iconbutton: {
    width:'36px',
    height: '36px',
    color: themeHlp().palette.primary.light,
    '&:hover' : {
      color: themeHlp().palette.primary.main
    },
    '&:active' : {
      color: themeHlp().palette.primary.dark,    
    }
  },
  file_icon: {
    width: '24px',
    height: '24px',     
    backgroundRepeat: 'no-repeat',
    backgroundSize: '22px',
    backgroundPosition: '0px 2px',
    padding: '0 24px 0 0',
  },
  upload_file: {
    display: 'none'
  },
  media_button: {
    width: '30px',
    height: '30px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '30px',
    backgroundPosition: '0',
    display: 'inline-block',
    cursor: 'pointer'
  },
  media_screenmode_button: {
    width: '30px',
    height: '30px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '30px',
    backgroundPosition: '0',
    display: 'inline-block',
    cursor: 'pointer',
    zIndex: 2
  },
  media_backandforth_button: {
    position: 'absolute',
    width: '30px',
    height: '30px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '30px',
    backgroundPosition: '0',
    display: 'inline-block',
    cursor: 'pointer',
    zIndex: 1
  },
  video_time_slider: {
    height: 0,
    padding: 0,    
    '& .MuiSlider-thumb' : {
      display: 'none'
    },
    '& .MuiSlider-track, .MuiSlider-rail': {   
      backgroundImage : `url(${process.env.REACT_APP_URL_CLIENTPORT}/icon/mediaplayer_slider.png)`,
      backgroundSize: '22px 22px',
      height: '22px !important',
      marginTop: '0px !important',
      opacity: 0.9
    }
  },
  video_time_text: {
    width: '144px',
    height: '22px',
    display: 'inline-flex',  
    paddingBottom: '20px',
    fontSize: '0.95rem',    
    alignItems: 'center',  
    verticalAlign: 'middle',
    justifyContent: 'center',
    cursor: 'default',
    color: themeHlp().palette.whiteText.main
  },
  video_sound_slider: {
    color: themeHlp().palette.whiteText.main,
  },
  video_controller: {
    width: '100%',
    maxWidth: '720px',
    border: '1px solid #3e3221',
    borderRadius: '0 0 15px 15px',
    background: themeHlp().palette.whiteText.main,
  },
  screen_cover: {
    background: themeHlp().palette.blackText.dark,  
    backgroundImage : `url(${process.env.REACT_APP_URL_CLIENTPORT}/icon/mediaplayer_screen_play.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100px',
    backgroundPosition: 'center',  
    position: 'absolute',
    marginLeft: 'auto',    
    marginRight: 'auto',
    top: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    opacity: '0.8'
  },
  process_dialog_content: { 
    overflow: 'hidden', 
    border: `1px solid ${ themeHlp().palette.greyText.light}`, 
    borderStyle:'solid none solid none', 
    backgroundColor: themeHlp().palette.secondary.light,
    '&.MuiDialogContent-root':{
      flex: 'none'
    }
  },
  process_messages_box: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'left',
    flexWrap: 'wrap',
    maxWidth: '1800px'
  },
  process_messages_effect:  {
    position: 'relative',
    padding: '0px',
    overflow: 'hidden',
  },
  linear_progress_box: {
    width: '200px',
    margin: 'auto auto',
    '& .MuiLinearProgress-root': {
      backgroundColor: themeHlp().palette.secondary.light
    },
    '& .MuiLinearProgress-bar': {
      backgroundColor: themeHlp().palette.primary.light
    }   
  }
}));

let playPauseInterval = {};
let timeoutAlert = {};

const MediaPlayerApp = (props) => {
  const classes = useStyles();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [open, setOpen] = React.useState(false);
  const [vid, setVid] = useState({});
  const [mediaLoadTime, setMediaLoadTime] = useState(1);
  const [playing, setPlaying] = useState(2);
  const [muted, setMuted] = useState(false);
  const [loop, setLoop] = useState(false);
  const [mediaSoundDisabled, setMediaSoundDisabled] = useState(false);
  const [mediaSoundValue, setMediaSoundValue] = useState(1);
  const [mediaScreenMode, setMediaScreenMode] = useState(0);
  const mediaStep = 5;
  const mediaFrameStep = 1 / 29.97;
  const mediaAspectRatio = 1.777777778;
  const mobileNotMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const [fileUploadStarted, setFileUploadStarted] = useState(false);
  const [toolbarHidden, setToolbarHidden] = useState(false);
  const [responseType, setResponseType] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [responseTime, setResponseTime] = useState(4);  
  const [mediaSrc, setMediaSrc] = useState(null);
  const [mediaWidth, setMediaWidth] = useState(null);
  const [mediaHeight, setMediaHeight] = useState(null);
  const [audioVideo, setAudioVideo] = useState('video');
  const [mediaTimelineEnabled, setMediaTimelineEnabled] = useState(false);


  const [resizeWidth, setResizeWidth] = useState(0);
  const [resizeHeight, setResizeHeight] = useState(0);
  const [startTime, setStartTime] = useState('00:00:00');
  const [durationTime, setDurationTime] = useState('00:00:00');
  const [quality, setQuality] = useState(100);
  const titleDisplaySize = 578;

  function defineImageParams(){    
    let videoPlace = document.getElementById('mediaFrame');
    if((videoPlace && ((resizeWidth === 0 || resizeHeight === 0 || isNaN(resizeWidth) || isNaN(resizeHeight)) && fileUploadStarted === false)) || fileUploadStarted === true){
      setResizeWidth(videoPlace.videoWidth); 
      setResizeHeight(videoPlace.videoHeight);
    }
  }

  function defineDurationParams(){
    const videoPlace = document.getElementById("mediaFrame");
    let durationSec = parseInt(videoPlace.duration % 60) > 9 ? parseInt(videoPlace.duration % 60) : '0' + parseInt(videoPlace.duration % 60);
    let durationMin = parseInt((videoPlace.duration / 60) % 60) > 9 ? parseInt((videoPlace.duration / 60) % 60) : '0' + parseInt((videoPlace.duration / 60) % 60);
    let durationHour = parseInt(((videoPlace.duration / 60) / 60) % 60);

    if((videoPlace && ((durationTime === '00:00:00') && fileUploadStarted === false)) || fileUploadStarted === true){
      setDurationTime(durationHour + ':' + durationMin + ':' + durationSec)
    }
  }




  const [anchorResizeEl, setAnchorResizeEl] = React.useState(null);
  const openResize = Boolean(anchorResizeEl);
  const openResizeMenu = (event) => {
    setAnchorResizeEl(event.currentTarget);
    defineImageParams();
    defineDurationParams();
  };
  const closeResizeMenu = () => {
    setAnchorResizeEl(null);
  };

  const [anchorExportEl, setAnchorExportEl] = React.useState(null);
  const openExport = Boolean(anchorExportEl);
  const openExportMenu = (event) => {
    setAnchorExportEl(event.currentTarget);
    defineImageParams();
    defineDurationParams();
  };
  const closeExportMenu = () => {
    setAnchorExportEl(null);
  };


  const resizeImageByWidth = (event) => {
    let width = event.target.value
    let videoPlace = document.getElementById('mediaFrame');
    setResizeWidth(parseInt(width));
    setResizeHeight(parseInt(((videoPlace.videoHeight/ videoPlace.videoWidth) * width).toFixed(0)));
  }

  const resizeImageByHeight = (event) => {    
    let height = event.target.value
    let videoPlace = document.getElementById('mediaFrame');
    setResizeHeight(parseInt(height));
    setResizeWidth(parseInt((height / (videoPlace.videoHeight / videoPlace.videoWidth)).toFixed(0)));
  }

  const changeStartTime = (event) => {  
    let start = event.target.value;
    setStartTime(start);
  }

  const changeDuration = (event) => {
    let duration = event.target.value;
    setDurationTime(duration);
  }

  const goToExportFiles = (fileExtension) => {
    const exportfilesParams = {
      item_id: props.fileInfo?.item_id,
      item_location: window.location.pathname,
      item_ext: fileExtension,
      options: {
        resize: audioVideo === 'video' ? [resizeWidth, resizeHeight] : [0, 0],
        start_time: startTime,
        duration: durationTime
      }
    }    
    exportfilesSrv(exportfilesParams)
    .then(response => {
      if(response && response.statusCode === 201){
        window.location.reload();
      }else if(response.error){
        setResponseTime(responseTime + 1);
        setResponseType('error');
        setResponseMessage(response.error.message);        
      }
    }).catch(err => {
      console.log(err);
    })
  }

  const handleClickOpen = () => {

    setFileUploadStarted(false);
    setMediaSrc(null);   
    
    if(props.fileInfo?.shared_with){
      const getsharedfilesParams = {
        item_id: props.fileInfo?.item_id
      }
      getsharedfilesSrv(getsharedfilesParams)
      .then(async response => {        
        setMediaSrc(response.data);        
      }).catch(err => {
        console.log(err)
      //  history.push("/desktop");
      })
    }else{
      const getfilesParams = {
        item_id: props.fileInfo?.item_id
      }
      if(getfilesParams.item_id !== undefined){
        getfilesSrv(getfilesParams)
        .then(response => {
          setMediaSrc(response.data);
        }).catch(err => {
          console.log(err)
        //  history.push("/desktop");
        })
      }
    }
  
    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;

    if(allApps.indexOf('mediaplayer') === -1 && JSON.parse(localStorage.getItem('movwin')).appCount <2){
      allApps.push('mediaplayer');
      setOpen(true);
      
      localStorage.setItem('movwin', JSON.stringify({
        windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
        apps: allApps,
        appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount + 1
      }));    
      
      setTimeout(() => {
        appsInWindows('load');
      },100);
    }
  };

  const handleClose = () => {
    const mediaFrame = document.getElementById("mediaFrame");
    if(mediaFrame) {
      mediaPause();      
    }
    setOpen(false);
    
    if(props.appCloses){      
      props.appCloses(true);
    }

    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;
    if(allApps.indexOf('mediaplayer') !== -1){
      allApps.splice(allApps.indexOf('mediaplayer'), 1);
    }

    localStorage.setItem('movwin', JSON.stringify({
      windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
      apps: allApps,
      appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount - 1
    }));
  };

  const appsInWindows = (event) => {
    if(event === 'change'){
      if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).windowMode < 4){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode + 1,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount
        }));
      }else if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).windowMode === 4){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: 0,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount
        }));
      }

      if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).appCount === 0){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: 1
        }));
      }
    }

    setWindowWidth(windowWidth - 1);
    appResizeWindowHlp('mediaPlayerDialogBox');
  }

  const keyShortcuts = (event) => {
    switch(event.key){
      case 'ArrowRight':
        mediaForward(mediaStep);
        break;
      case '.': 
        mediaForward(mediaFrameStep);
        break;
      case 'ArrowLeft': 
        mediaBackward(mediaStep);
        break;
      case ',': 
        mediaBackward(mediaFrameStep);
        break;
      case ' ' : 
        mediaPlayPause();
        break;
      case 'ArrowDown':
        if(parseFloat(mediaSoundValue) >= 0.2){
          setMediaSoundValue(current => current - 0.2);
        }else{
          setMediaSoundValue(0);
        }         
        mediaChengeSound(event.key, mediaSoundValue);
        break;  
      case 'ArrowUp':
        if(parseFloat(mediaSoundValue) < 1){
          setMediaSoundValue(current => current + 0.2);
        }else{
          setMediaSoundValue(1);
        }        
        mediaChengeSound(event.key, mediaSoundValue);
        break;
      case 'm':        
        if(mediaSoundValue !== 0){
          mediaChengeSound(event.key, 0);          
          setMediaSoundValue(0);
        }else{          
          mediaChengeSound(event.key, 1);
          setMediaSoundValue(1);
        }       
        break;
      case 'e':
          mediaScreenModeFnc(1);
        break;
      case 't':
          mediaScreenModeFnc(2);
        break;
      case 'n':
          mediaScreenModeFnc(3);
        break;
    }
  }

  const uploadFileFnc = (event) => {
    const file = event.target.files[0];
    setResponseType('');
    setResponseMessage(''); 
    
    if(
      file?.type === 'video/x-matroska' || //mkv       
      file?.type === 'video/quicktime' || //mov
      file?.type === 'video/mp4' || //mp4, f4v
      file?.type === 'video/3gpp' || //3gp
      file?.type === 'video/webm' //webm
    ){
      setAudioVideo('video');
      setFileUploadStarted(true);
      setMediaSrc(null);
      const reader = new FileReader();
      
      reader.addEventListener("load", () => {
        if(reader.result.length < 19800000){
          const uploaded_media = reader.result;
          setMediaSrc(uploaded_media);
        }
      });      
      reader.readAsDataURL(file);
    }else if(
      file?.type === 'audio/x-m4a' || //m4a
      file?.type === 'audio/mpeg' || //mp3
      file?.type === 'audio/ogg' || //oga, ogg, opus
      file?.type === 'audio/wav' || //wav
      file?.type === 'audio/aac' //aac
    ){
      setAudioVideo('audio');
      setFileUploadStarted(true);
      setMediaSrc(null);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        if(reader.result.length < 19800000){
          const uploaded_media = reader.result;
          setMediaSrc(uploaded_media);
        }else{          
          setResponseTime(responseTime + 1);
          setResponseType('error');
          setResponseMessage('File size does not allow to upload the file in this way. In order to play this audio file, upload the file in the usual way to your storage, and then open it.');
        }
      });      
      reader.readAsDataURL(file);
    }else{
      
      if(file?.type !== undefined){
        setResponseTime(responseTime + 1);
        setResponseType('error');
        setResponseMessage('File format "' + file?.type + '" not supported.');
      }
    }
  }

  const mediaPlay = () => {
    setPlaying(1);
    const videoElm = document.getElementById("mediaFrame");






    if(videoElm.duration){  
      videoElm.play();
      let videoScreen = document.getElementById("screen");
      videoScreen.style.opacity = '0';

      let durationSec = parseInt(videoElm.duration % 60) > 9 ? parseInt(videoElm.duration % 60) : '0' + parseInt(videoElm.duration % 60);
      let durationMin = parseInt((videoElm.duration / 60) % 60) > 9 ? parseInt((videoElm.duration / 60) % 60) : '0' + parseInt((videoElm.duration / 60) % 60);
      let durationHour = parseInt(((videoElm.duration / 60) / 60) % 60);
      let currenttimeSec = parseInt(videoElm.currentTime % 60) > 9 ? parseInt(videoElm.currentTime % 60) : '0' + parseInt(videoElm.currentTime % 60);
      let currenttimeMin = parseInt((videoElm.currentTime / 60) % 60) > 9 ? parseInt((videoElm.currentTime / 60) % 60): '0' + parseInt((videoElm.currentTime / 60) % 60);
      let currenttimeHour = parseInt(((videoElm.currentTime / 60) / 60) % 60);

      setVid({
        width: videoElm.videoWidth, 
        height: videoElm.videoHeight, 
        duration: currenttimeHour + ':' + currenttimeMin + ':' + currenttimeSec + ' / ' + durationHour + ':' + durationMin + ':' + durationSec,
        max: parseInt(videoElm.duration)
      });
      setMediaTimelineEnabled(true);
      
      playPauseInterval = setInterval(() =>{

        let durationSec = parseInt(videoElm.duration % 60) > 9 ? parseInt(videoElm.duration % 60) : '0' + parseInt(videoElm.duration % 60);
        let durationMin = parseInt((videoElm.duration / 60) % 60) > 9 ? parseInt((videoElm.duration / 60) % 60) : '0' + parseInt((videoElm.duration / 60) % 60);
        let durationHour = parseInt(((videoElm.duration / 60) / 60) % 60);
        let currenttimeSec = parseInt(videoElm.currentTime % 60) > 9 ? parseInt(videoElm.currentTime % 60) : '0' + parseInt(videoElm.currentTime % 60);
        let currenttimeMin = parseInt((videoElm.currentTime / 60) % 60) > 9 ? parseInt((videoElm.currentTime / 60) % 60): '0' + parseInt((videoElm.currentTime / 60) % 60);
        let currenttimeHour = parseInt(((videoElm.currentTime / 60) / 60) % 60);
        
        setVid({
          width: videoElm.videoWidth, 
          height: videoElm.videoHeight, 
          duration: currenttimeHour + ':' + currenttimeMin + ':' + currenttimeSec + ' / ' + durationHour + ':' + durationMin + ':' + durationSec,
          max: parseInt(videoElm.duration)
        })

        if(videoElm.ended){
          setPlaying(0);
          videoElm.pause();
          clearInterval(playPauseInterval);
        }      
      }, 400);
    }else{
      setVid({
        width: '300px', 
        height: videoElm.videoHeight, 
        duration: 'LOADING...',
        max: 0
      })
    }
  }

  const mediaPause = () => {
    setPlaying(0)
    const videoElm = document.getElementById("mediaFrame");
    videoElm.pause();
    clearInterval(playPauseInterval);
  }

  const mediaPlayPause = () => {
    (playing === 0 || playing === 2) ? mediaPlay() : mediaPause();
  }

  const mediaForward = (step) => {
    if(playing !== 2){
      const videoElm = document.getElementById("mediaFrame");
      let durationSec = parseInt(videoElm.duration % 60) > 9 ? parseInt(videoElm.duration % 60) : '0' + parseInt(videoElm.duration % 60);
      let durationMin = parseInt((videoElm.duration / 60) % 60) > 9 ? parseInt((videoElm.duration / 60) % 60) : '0' + parseInt((videoElm.duration / 60) % 60);
      let durationHour = parseInt(((videoElm.duration / 60) / 60) % 60);
      let currenttimeSec = parseInt((videoElm.currentTime % 60) + step);
      let currenttimeMin = parseInt((videoElm.currentTime / 60) % 60);
      let currenttimeHour = parseInt(((videoElm.currentTime / 60) / 60) % 60);

      videoElm.currentTime += step;

      if(currenttimeMin < 60 && currenttimeSec >= 60 && (videoElm.currentTime + step) <= videoElm.duration){
        if(currenttimeMin === 59 && currenttimeSec >= 60){
          currenttimeHour += 1;
          currenttimeMin = 0;
        }else{
          currenttimeMin += 1;
        }        
        currenttimeSec = currenttimeSec - 60;
      }else if(currenttimeSec > 60 && (videoElm.currentTime) + step > videoElm.duration){
        currenttimeSec = parseInt((videoElm.currentTime % 60));
        currenttimeMin +=1;
      }else if(currenttimeSec <= 60 && (videoElm.currentTime) + step > videoElm.duration){
        currenttimeSec = parseInt((videoElm.currentTime % 60));
      }

      setVid({
        width: videoElm.videoWidth, 
        height: videoElm.videoHeight, 
        duration: currenttimeHour + ':' + (currenttimeMin > 9 ? currenttimeMin : '0' + currenttimeMin) + ':' + (currenttimeSec > 9 ? currenttimeSec : '0' + currenttimeSec) + ' / ' + durationHour + ':' + durationMin + ':' + durationSec,
        max: parseInt(videoElm.duration)
      })
    }
  }

  const mediaBackward = (step) => {
    if(playing !== 2){
      const videoElm = document.getElementById("mediaFrame");
      let durationSec = parseInt(videoElm.duration % 60) > 9 ? parseInt(videoElm.duration % 60) : '0' + parseInt(videoElm.duration % 60);
      let durationMin = parseInt((videoElm.duration / 60) % 60) > 9 ? parseInt((videoElm.duration / 60) % 60) : '0' + parseInt((videoElm.duration / 60) % 60);
      let durationHour = parseInt(((videoElm.duration / 60) / 60) % 60);
      let currenttimeSec = parseInt((videoElm.currentTime % 60) - step);
      let currenttimeMin = parseInt((videoElm.currentTime / 60) % 60);
      let currenttimeHour = parseInt(((videoElm.currentTime / 60) / 60) % 60);

      videoElm.currentTime -= step;

      if(currenttimeMin >= 0 && currenttimeSec < 0 && videoElm.currentTime  >= step){
        if(currenttimeHour > 0 && currenttimeMin <= 0 && currenttimeSec < 0){
          currenttimeHour -= 1;
          currenttimeMin = 60 - 1;
        }else if(currenttimeHour > 0 && currenttimeMin > 0 && currenttimeSec < 0){
          currenttimeMin -= 1;
        }
        currenttimeSec = currenttimeSec + 60;
      }else if(currenttimeSec < 0 && (videoElm.currentTime)  === 0){
        currenttimeSec = 0;
      }

      setVid({
        width: videoElm.videoWidth, 
        height: videoElm.videoHeight, 
        duration: currenttimeHour + ':' + (currenttimeMin > 9 ? currenttimeMin : '0' + currenttimeMin) + ':' + (currenttimeSec > 9 ? currenttimeSec : '0' + currenttimeSec) + ' / ' + durationHour + ':' + durationMin + ':' + durationSec,
        max: parseInt(videoElm.duration)
      })
    }
  }
  
  const mediaJumpBackandforth = (value) => {
    value === 'back' ? mediaBackward(mediaStep) : mediaForward(mediaStep);
  }

  const mediaSound = () => {
    const videoElm = document.getElementById("mediaFrame");
    setMediaSoundValue(videoElm.volume);
    setMediaTimelineEnabled(false);
    setTimeout(() =>{
      setMediaTimelineEnabled(true);
    },5000);
  }

  const mediaChengeSound = (event, newValue) => {
    const videoElm = document.getElementById("mediaFrame");
    videoElm.volume = newValue
    if(newValue === 0){
      setMuted(true);
    }else{
      setMuted(false);
    }
  }

  const mediaScreenModeFnc = (value) => {
    let mediaPlace = document.getElementById('mediaFrame');
    if(value === 1){
      setMediaWidth(`${windowWidth-20}`);
      if(mediaPlace.videoWidth >= mediaPlace.videoHeight ){
        setMediaHeight(`${windowWidth / (mediaPlace.videoWidth/mediaPlace.videoHeight)}`);
      }else{
        setMediaHeight(`${windowWidth * (mediaPlace.videoHeight/mediaPlace.videoWidth)}`);
      }
    }else if(value === 2){
      if(!toolbarHidden){
        setMediaHeight(`${windowHeight - 112}`);
        if(mediaPlace.videoWidth >= mediaPlace.videoHeight ){
          setMediaWidth(`${(windowHeight-112) * (mediaPlace.videoWidth/mediaPlace.videoHeight)}`);
        }else{
          setMediaWidth(`${(windowHeight-112) / (mediaPlace.videoHeight/mediaPlace.videoWidth)}`);
        }
      }else{
        setMediaHeight(`${windowHeight - 48}`);
        if(mediaPlace.videoWidth >= mediaPlace.videoHeight ){
          setMediaWidth(`${(windowHeight-48) * (mediaPlace.videoWidth/mediaPlace.videoHeight)}`);
        }else{
          setMediaWidth(`${(windowHeight-48) / (mediaPlace.videoHeight/mediaPlace.videoWidth)}`);
        }
      }  
    }else if(value === 3){
      setMediaWidth(`${windowWidth-20}`);
      setMediaHeight(`${windowWidth / mediaAspectRatio}`);
    }   
    setMediaScreenMode(value);
  }

  const mediaChengeTime = (event, newValue) => {
    const videoElm = document.getElementById("mediaFrame");

    if(typeof newValue === 'number') {
      videoElm.currentTime = parseInt((videoElm.duration * newValue) / videoElm.duration);
      let durationSec = parseInt(videoElm.duration % 60) > 9 ? parseInt(videoElm.duration % 60) : '0' + parseInt(videoElm.duration % 60);
      let durationMin = parseInt((videoElm.duration / 60) % 60) > 9 ? parseInt((videoElm.duration / 60) % 60) : '0' + parseInt((videoElm.duration / 60) % 60);
      let durationHour = parseInt(((videoElm.duration / 60) / 60) % 60);
      let currenttimeSec = parseInt(videoElm.currentTime % 60) > 9 ? parseInt(videoElm.currentTime % 60) : '0' + parseInt(videoElm.currentTime % 60);
      let currenttimeMin = parseInt((videoElm.currentTime / 60) % 60) > 9 ? parseInt((videoElm.currentTime / 60) % 60): '0' + parseInt((videoElm.currentTime / 60) % 60);
      let currenttimeHour = parseInt(((videoElm.currentTime / 60) / 60) % 60);

      setTimeout(() => {
        if(videoElm.paused){
          setPlaying(0);
        }else{
          setPlaying(1);
        }
      },200)

      setVid({
        width: videoElm.videoWidth, 
        height: videoElm.videoHeight, 
        duration: currenttimeHour + ':' + currenttimeMin + ':' + currenttimeSec + ' / ' + durationHour + ':' + durationMin + ':' + durationSec,
        max: parseInt(videoElm.duration)
      })
    }    
  }

  const AlertBox = () => {
    clearTimeout(timeoutAlert);
    if(responseType === 'error'){
      timeoutAlert = setTimeout(() => { 
        setResponseType('errorFinished');
        setResponseMessage('');
        closeExportMenu();
      }, 4000);
    }else if(responseType === 'report'){
      timeoutAlert = setTimeout(() => { 
        setResponseType('');
        setResponseMessage('');
        closeExportMenu();
      }, 4000);
    }

    if(responseType === 'warning'){
      return (
        <DialogContent className={classes.process_dialog_content}>
          <Box className={classes.process_messages_box}>          
            <div className={classes.process_messages_effect}>
              <div style={{
                position: 'absolute',
                inset: '0',
                background: themeHlp().palette.secondary.light,
                borderLeft: '2px solid ' + themeHlp().palette.secondary.light,
                borderRadius: '10px',
                top:'6px',
                height: '14px',
                transform: 'translateX(0%)',
                animation:  'translateOverlay 0.4s forwards cubic-bezier(0.03, 0, 0.15, 1)',
              }}></div>
              <Avatar component="span" alt="Warning" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/warning.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} /> 
              <Typography variant="subtitle1" component="span" style={{ cursor: 'default'}}>{responseMessage}</Typography>
            </div>
          </Box>
        </DialogContent>
      )
    }else if(responseType === 'error'){
      return (
        <DialogContent className={classes.process_dialog_content}>
          <Box className={classes.process_messages_box}>          
            <div className={classes.process_messages_effect}>
              <div style={{
                position: 'absolute',
                inset: '0',
                background: themeHlp().palette.secondary.light,
                borderLeft: '2px solid ' + themeHlp().palette.secondary.light,
                borderRadius: '10px',
                top:'6px',
                height: '14px',
                transform: 'translateX(0%)',
                animation:  'translateOverlay 0.4s forwards cubic-bezier(0.03, 0, 0.15, 1)',
              }}></div>
              <Avatar component="span" alt="Error" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/error.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} /> 
              <Typography variant="subtitle1" component="span" style={{ cursor: 'default'}}>{responseMessage}</Typography>
            </div>
          </Box>
        </DialogContent>
      )
    }else if(responseType === 'report'){
      return (
        <DialogContent className={classes.process_dialog_content}>
          <Box className={classes.process_messages_box}>
            <div className={classes.process_messages_effect}>
              <div style={{
                position: 'absolute',
                inset: '0',
                background: themeHlp().palette.secondary.light,
                borderLeft: '2px solid ' + themeHlp().palette.secondary.light,
                borderRadius: '10px',
                top:'6px',
                height: '14px',
                transform: 'translateX(0%)',
                animation:  'translateOverlay 0.4s forwards cubic-bezier(0.03, 0, 0.15, 1)',
              }}></div>
              <Avatar component="span" alt="Report" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/report.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} /> 
              <Typography variant="subtitle1" component="span" style={{ cursor: 'default'}}>{responseMessage}</Typography>
            </div>
          </Box>
        </DialogContent>
      )
    }
  }

  useLayoutEffect(() => { 
    function updateSize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);

      /*if(mediaScreenMode === 1 || mediaScreenMode === 2 || mediaScreenMode === 3){
        mediaScreenModeFnc(mediaScreenMode);
      }*/
      if(mediaScreenMode === 1){
        mediaScreenModeFnc(1);
      }else if(mediaScreenMode === 2){
        mediaScreenModeFnc(2);
      }else if(mediaScreenMode === 3){
        mediaScreenModeFnc(3);
      }
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => {     
      window.removeEventListener('resize', updateSize); 
    }
  }, [windowWidth, windowHeight]);

  useEffect(() => {

    
    //window.location.assign(process.env.REACT_APP_URL_CLIENTPORT + '/#' + location);


    handleClickOpen();
    
  //  clearInterval(playPauseInterval);
    if(props && props.fileInfo && props.fileInfo.item_ext){
      let fileType = props.fileInfo.item_ext;
      if(
        fileType === 'mkv' || 
        fileType === 'mov' || 
        fileType === 'mp4' || 
        fileType === 'f4v' || 
        fileType === '3gp' || 
        fileType === 'webm'
      ){        
        setAudioVideo('video');
      }else if(
        props.fileInfo.item_ext === 'aac' || 
        props.fileInfo.item_ext === 'm4a' || 
        props.fileInfo.item_ext === 'mp3' || 
        props.fileInfo.item_ext === 'oga' || 
        props.fileInfo.item_ext === 'ogg' || 
        props.fileInfo.item_ext === 'opus' || 
        props.fileInfo.item_ext === 'wav'
      ){
        setAudioVideo('audio');
      }
    }

    setMediaWidth(`${windowWidth-20}`);
    setMediaHeight(`${windowWidth / mediaAspectRatio}`);

    if(!localStorage.getItem('movwin')){
      localStorage.setItem('movwin', JSON.stringify({
        windowMode: 0,
        apps: [],
        appCount: 1
      }));
    }     
    
    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;

    if(allApps.indexOf('mediaplayer') === -1 && JSON.parse(localStorage.getItem('movwin')).appCount < 2){
      setOpen(true);
      setTimeout(() => {
        const muiBackdrop = document.querySelectorAll('.MuiBackdrop-root');
        let newApp = JSON.parse(localStorage.getItem('movwin')).apps;
        newApp.push('mediaplayer');
        
        if(muiBackdrop[2]){
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: newApp,
            appCount: 2
          })); 
        }else if(muiBackdrop[2] === undefined && muiBackdrop[1]) {          
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: newApp,
            appCount: 1
          }));        
        }else if(muiBackdrop[2] === undefined && muiBackdrop[1] === undefined) {        
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: [],
            appCount: 0,          
          }));        
        }
        appsInWindows('load');
      },100);
    } 

   },[mediaLoadTime])

return (
  <>
    <Avatar title="Media Player" component="span" variant="rounded" alt="Media Player" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/mediaplayer.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={handleClickOpen} />
    <Typography title="Media Player" variant="subtitle1" component="span" style={{ whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>Media Player</Typography>
    <Dialog id="mediaPlayerDialogBox" tabIndex={-1} fullScreen onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} onKeyDown={keyShortcuts}>
      <DialogTitle
        style={{
          padding: '8px 16px',
          color:'#ffffff',
          background:'linear-gradient(90deg, rgba(38,93,140,1) 0%, rgba(38,93,140,1) 15%, rgba(0,140,183,1) 100%)'
        }}
        id="customized-dialog-title" onClose={handleClose}
      >
        <Avatar component="span" variant="rounded" style={{width:'24px', height: '24px', display: 'inline-block', marginRight: '8px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/mediaplayer.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
        {windowWidth >264 && <span>{(windowWidth < titleDisplaySize && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) !== 0) || (windowWidth < (titleDisplaySize/2) && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) === 0) ? '' : 'Media Player'}</span> }
        <IconButton
          disabled={document.getElementById('shutdownDialog') ? false : true}
          aria-label="AppsInWindowsIcon"
          onClick={() => appsInWindows('change')}
          size="small"
          style={{
            background: themeHlp().palette.blueGrey.dark,
            color:'white',          
            margin:'2px 42px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px',
            opacity: document.getElementById('shutdownDialog') ? '1' : '0.3'
          }}
        >
          <BorderInnerIcon style={{padding: '2px'}} />          
        </IconButton>
        <IconButton 
          aria-label="UnfoldLessMoreIcon"
          title={!toolbarHidden ? 'Hide Toolbar' : 'Show Toolbar'}
          onClick={()=> setToolbarHidden(current => !current)}
          size="small"
          style={{
            width: '18px',
            height: '18px',
            background: themeHlp().palette.blueGrey.dark,
            color:'white',  
            margin:'2px 74px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px'
          }}
        >
          {!toolbarHidden ? <UnfoldLessIcon style={{padding: '4px'}} /> : <UnfoldMoreIcon style={{padding: '4px'}} />}
        </IconButton>
        <IconButton 
          aria-label="CancelSharpIcon"
          title="Close"
          onClick={handleClose}
          size="small"
          style={{
            background: themeHlp().palette.blueGrey.dark,
            color:'white', 
            margin:'2px 10px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px'
          }}
        >
          <CancelSharpIcon style={{padding: '2px'}} /> 
        </IconButton>
      </DialogTitle>
      {!toolbarHidden && 
      <DialogActions id="p0" className={classes.dialog_action}>
        <div id="p1" className={classes.root}>
          <input className={classes.upload_file} id="contained-button-file-media-browse" type="file" onChange={uploadFileFnc} />
          <List style={{ width: '100%', height: '64px', padding: '0px', overflowY: 'auto'}}>
            <ListItem button style={{width:'64px', height: '64px', float:'left', padding:'0px 12px'}}>
              <label htmlFor="contained-button-file-media-browse">
                <Avatar title="Import Video or Audio" component="span" variant="rounded"  alt="Import Video or Audio" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_import_media.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
              </label>
            </ListItem>
            {(mediaSrc !== null && ((responseType !== 'error' ))) &&
            <>
              <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
                <label>
                  <Avatar title="Resize" component="span" variant="rounded" alt="Resize" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_resize.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={openResizeMenu}/>
                </label>
              </ListItem>
              <div>
                <StyledMenu
                  id="image-resize-menu"
                  MenuListProps={{
                    'aria-labelledby': 'image-resize-menu',
                  }}
                  anchorEl={anchorResizeEl}
                  open={openResize}
                  onClose={closeResizeMenu}
                >  
                  { audioVideo === 'video' &&
                  <MenuItem style={{ pointerEvents: 'none' }}>        
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={4} style={{padding: '0px 0px'}}>
                        <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_image_size.png)'}}></span>
                        Video Size:
                        </Grid>
                      <Grid item xs={12} sm={8} style={{padding: '0px 0px'}}>
                        <TextField                    
                          type="number"
                          title="Width"
                          size="small"
                          variant="standard"
                          inputProps={{
                            min: "0",
                            step: "1"
                          }}
                          className={classes.param_box}
                          value={resizeWidth}
                          disabled={fileUploadStarted ? true : false}
                          onChange={(evn) => {resizeImageByWidth(evn)}}
                          style={{ pointerEvents: 'auto' }}
                        />
                        px
                        <TextField
                          type="number"
                          title="Height"
                          size="small"
                          variant="standard"
                          inputProps={{
                            min: "0",
                            step: "1"
                          }}
                          className={classes.param_box}
                          value={resizeHeight}
                          disabled={fileUploadStarted ? true : false}
                          onChange={(evn) => resizeImageByHeight(evn)}
                          style={{ pointerEvents: 'auto' }}
                        />
                        px
                      </Grid>
                    </Grid>
                  </MenuItem>
                  }
                  <MenuItem style={{ pointerEvents: 'none' }}>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={4} style={{padding: '0px 0px'}}>
                        <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_time.png)'}}></span>
                        Start Time:
                      </Grid>
                      <Grid item xs={12} sm={8} style={{padding: '0px 0px'}}>
                        <TextField
                          type="text"
                          title="Width"
                          size="small"
                          variant="standard"
                          className={classes.param_time_box}
                          value={startTime}
                          disabled={fileUploadStarted ? true : false}
                          onChange={(evn) => {changeStartTime(evn)}}
                          style={{ pointerEvents: 'auto' }}
                        />
                      </Grid>
                    </Grid>     
                  </MenuItem>
                  <MenuItem style={{ pointerEvents: 'none' }}>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={4} style={{padding: '0px 0px'}}>
                        <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_time.png)'}}></span>
                        Duration
                      </Grid>  
                      <Grid item xs={12} sm={8} style={{padding: '0px 0px'}}>
                        <TextField                    
                          type="text"
                          title="Width"
                          size="small"
                          variant="standard"
                          className={classes.param_time_box}
                          value={durationTime}
                          disabled={fileUploadStarted ? true : false}
                          onChange={(evn) => {changeDuration(evn)}}
                          style={{ pointerEvents: 'auto' }}
                        />  
                      </Grid>
                    </Grid>                  
                  </MenuItem>
                  {/*
                  <MenuItem style={{ pointerEvents: 'none' }}>
                    <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_quality.png)'}}></span>
                    Quality
                    <TextField
                      type="number"
                      size="small"
                      variant="standard"
                      inputProps={{
                        min: "0",
                        max: "100",
                        step: "10"
                      }}
                      className={classes.param_box}
                      value={quality}
                      disabled={fileUploadStarted ? true : false}
                      onChange={(e) => setQuality(e.target.value)}
                      style={{ pointerEvents: 'auto', width: '56px' }}
                    />
                    %
                  </MenuItem>
                */}
                </StyledMenu>
              </div>
              <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
                <label>
                  <Avatar title="Export as" component="span" variant="rounded" alt="Export as" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_export_as.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={openExportMenu} />
                </label>                    
              </ListItem>
              <div>
                {audioVideo === 'video' &&
                <StyledMenu
                  id="image-export-menu"
                  MenuListProps={{
                    'aria-labelledby': 'image-export-menu',
                  }}
                  anchorEl={anchorExportEl}
                  open={openExport}
                  onClose={closeExportMenu}
                >
                  <MenuItem onClick={() => goToExportFiles('mp4')}>
                    <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_export_as_mp4.png)'}}></span>
                    Export as MP4
                  </MenuItem>
                  <MenuItem onClick={() => goToExportFiles('mov')}>
                    <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_export_as_mov.png)'}}></span>
                    Export as MOV
                  </MenuItem>
                  <MenuItem onClick={() => goToExportFiles('mkv')}>
                    <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_export_as_mkv.png)'}}></span>
                    Export as MKV
                  </MenuItem>
                  <MenuItem onClick={() => goToExportFiles('webm')}>
                    <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_export_as_webm.png)'}}></span>
                    Export as WEBM
                  </MenuItem>
                </StyledMenu>
                }
                {audioVideo === 'audio' &&
                  <StyledMenu
                    id="image-export-menu"
                    MenuListProps={{
                      'aria-labelledby': 'image-export-menu',
                    }}
                    anchorEl={anchorExportEl}
                    open={openExport}
                    onClose={closeExportMenu}
                  >            
                    <MenuItem onClick={() => goToExportFiles('mp3')}>
                      <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_export_as_mp3.png)'}}></span>
                      Export as MP3
                    </MenuItem>
                    <MenuItem onClick={() => goToExportFiles('m4a')}>
                      <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_export_as_m4a.png)'}}></span>
                      Export as M4A
                    </MenuItem>
                    <MenuItem onClick={() => goToExportFiles('oga')}>
                      <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_export_as_oga.png)'}}></span>
                      Export as OGA
                    </MenuItem>
                    <MenuItem onClick={() => goToExportFiles('ogg')}>
                      <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_export_as_ogg.png)'}}></span>
                      Export as OGG
                    </MenuItem>
                    <MenuItem onClick={() => goToExportFiles('wav')}>
                      <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_export_as_wav.png)'}}></span>
                      Export as WAV
                    </MenuItem>
                  </StyledMenu>
                }
              </div>
            </>
            }
          </List>   
        </div>
      </DialogActions>
      }      
      {(responseType !== '' && responseType !== 'errorFinished') &&  <AlertBox />}
      <DialogContent className={classes.dialog_content}>
        {(fileUploadStarted && mediaSrc === null && responseMessage === '') &&
          <>
            <Typography variant="subtitle2" component="div" style={{fontSize: '14px', marginTop: '20px', color: themeHlp().palette.primary.main, /*padding: '0 10px',*/ borderRadius: '10px', textAlign: 'center'}}>
              Opening...
            </Typography>
            <Box className={classes.linear_progress_box}>
              <LinearProgress value={10}/>
            </Box>
          </>
        }
        {typeof(mediaSrc) === 'string' && (responseMessage === '') &&
          <Box style={{ marginBottom: '100px' }}> 
            <Grid container align="center">
              <Grid container spacing={0} align="center">            
                <Grid item xs={12} style={{padding: '0px 0px'}}>
                  <Box style={{position:'relative'}}>
                    {audioVideo === 'video' &&
                      <>
                        <video id="mediaFrame" autobuffer="true" x-webkit-airplay="allow" controlsList="nodownload" disablePictureInPicture=""  controls="" playinfullscreen="false" playsInline={true} src={mediaSrc} loop={loop}
                          style={{
                            width: `${mediaWidth}px`, 
                            height: `${mediaHeight}px`,
                            maxWidth: (mediaScreenMode === 1 || mediaScreenMode === 2) ? 'unset' : '720px', 
                            maxHeight: (mediaScreenMode === 1 || mediaScreenMode === 2) ? 'unset' : '405px', 
                          }}
                        ></video>
                        <div style={{top: 0, left: 0, position: 'absolute'}}>
                          {(mediaScreenMode === 0 || mediaScreenMode === 3) && <div title="Edge to Edge Mode" className={classes.media_screenmode_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/mediaplayer_edgetoedge_mode.png)', position: 'absolute'}} onClick={() => mediaScreenModeFnc(1)}></div>}
                          {(mediaScreenMode === 1) && <div title="Top to Bottom Mode" className={classes.media_screenmode_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/mediaplayer_toptobottom_mode.png)', position: 'absolute'}} onClick={() => mediaScreenModeFnc(2)}></div>}
                          {(mediaScreenMode === 2) && <div title="Normal Mode" className={classes.media_screenmode_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/mediaplayer_default_mode.png)', position: 'absolute'}} onClick={() => mediaScreenModeFnc(3)}></div>}
                        </div>
                        <div style={{top: '30px', left: 0, position: 'absolute'}}>
                          <div className={classes.media_backandforth_button} onDoubleClick={() => mediaJumpBackandforth('back')}></div>
                        </div>  
                        <div style={{top: '60px', left: 0, position: 'absolute'}}>
                          <div className={classes.media_backandforth_button} onDoubleClick={() => mediaJumpBackandforth('forth')}></div>
                        </div>
                        <div id="screen" className={classes.screen_cover} onClick={mediaPlayPause} onContextMenu={e => e.preventDefault()} 
                          style={{
                            width: `${mediaWidth}px`, 
                            height: `${mediaHeight}px`,
                            maxWidth: (mediaScreenMode === 1 || mediaScreenMode === 2) ? 'unset' : '720px', 
                            maxHeight: (mediaScreenMode === 1 || mediaScreenMode === 2) ? 'unset' : '405px',
                          }}
                        ></div>
                      </>
                    }
                    {audioVideo === 'audio' &&
                      <>
                        <audio id="mediaFrame" title="Media Player" loop={loop} style={{ width: '100%', maxWidth: '720px', height: ((windowWidth / mediaAspectRatio)-26) + 'px', maxHeight: '405px', border: '0px', overflow: 'hidden'}}>
                          <source src={mediaSrc} type="audio/ogg" />
                          <source src={mediaSrc} type="audio/mpeg" />
                          Your browser does not support the audio tag.
                        </audio>  
                        <div id="screen" onContextMenu={e => e.preventDefault()}></div>
                      </>
                    }
                  </Box>
                  <Box style={{position: 'relative', height: '40px', maxWidth: '720px'}}>
                    <Slider
                      className={classes.video_time_slider}    
                      style={{ width: '100%', maxWidth: '720px', marginLeft: '0px'}}          
                      size="small"
                      min={0}
                      step={1}
                      max={vid.max}                      
                      defaultValue={0}
                      aria-label="Small"
                      valueLabelDisplay="on"
                      onChange={mediaChengeTime}
                      disabled={mediaTimelineEnabled ? false : true}
                    />
                    {playing !== 2 && !mobileNotMobile && !mediaTimelineEnabled &&
                      <div style={{position: 'absolute',top: '11px', pointerEvents: !mediaTimelineEnabled ? 'auto' : 'none'}}>   
                        <VolumeUp style={{color: themeHlp().palette.whiteText.main, margin: '3px 2px 3px 0', padding: '0px 4px'}} />
                        <Slider
                          className={classes.video_sound_slider}
                          style={{ width: '50px', margin: '0 20px 0 0', maxWidth: vid.width, marginLeft: '0px', height: '16px', padding: '14px 0 0 0'}}
                          min={0}
                          step={0.01}
                          max={1}
                          defaultValue={mediaSoundValue}
                          aria-label="Small"
                          onChange={mediaChengeSound}
                          disabled={mediaSoundDisabled}
                        />
                      </div>
                    }
                    {playing !== 2 && 
                      <div style={{position: 'absolute',top: '24px',  width: '100%', pointerEvents: 'none'}}>
                        <div className={classes.video_time_text}><span>{vid.duration}</span></div>
                      </div>
                    }
                  </Box>
                  <Box className={classes.video_controller} style={{ height: '40px'}}>
                    <div style={{display: windowWidth < 420 ? 'block': 'inline'}}>
                      {!mobileNotMobile &&
                      <div style={{position: 'relative', display: 'inline-block', margin: '0 30px 0 0', height: '30px'}}>
                        <div className={classes.media_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/mediaplayer_unmuted.png)', position: 'absolute', zIndex: (!muted) ? 99 : 0}} onClick={mediaSound}></div>
                        <div className={classes.media_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/mediaplayer_muted.png)', position: 'absolute', zIndex: (muted) ? 99 : 0}} onClick={mediaSound}></div>
                      </div>
                      }
                      <div title="Jump backward 5 seconds" className={classes.media_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/mediaplayer_backward.png)'}} onClick={() => mediaBackward(mediaStep)}></div>
                      {audioVideo === 'video' && <div title="Move backward 1 frame" className={classes.media_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/mediaplayer_frame_backward.png)'}} onClick={() => mediaBackward(mediaFrameStep)}></div>}
                      <div style={{position: 'relative', display: 'inline-block', margin: '0 30px 0 0', height: '30px'}}>
                      
                        <div title="Play" className={classes.media_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/mediaplayer_play.png)', position: 'absolute', zIndex: (playing === 0 || playing === 2) ? 99 : 0}} onClick={mediaPlay}></div>
                        <div title="Pause" className={classes.media_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/mediaplayer_pause.png)', position: 'absolute', zIndex: (playing === 1) ? 99 : 0}} onClick={mediaPause}></div>
                      </div>
                      {audioVideo === 'video' && <div title="Move forward 1 frame" className={classes.media_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/mediaplayer_frame_forward.png)'}} onClick={() => mediaForward(mediaFrameStep)}></div>}
                      <div title="Jump forward 5 seconds" className={classes.media_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/mediaplayer_forward.png)'}} onClick={() => mediaForward(mediaStep)}></div>

                      <div style={{position: 'relative', display: 'inline-block', margin: '0 30px 0 0', height: '30px'}}>                        
                        <div className={classes.media_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/mediaplayer_loop.png)', position: 'absolute', zIndex: (loop === true) ? 99 : true}} onClick={() => setLoop(false)}></div>                        
                        <div className={classes.media_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/mediaplayer_no_loop.png)', position: 'absolute', zIndex: (loop === false) ? 99 : false}} onClick={() => setLoop(true)}></div>
                      </div>                      
                    </div>
                  </Box>                  
                </Grid>
              </Grid>
            </Grid>
          </Box>
        }        
      </DialogContent>
    </Dialog>
  </>
);
}
 
export default MediaPlayerApp;
