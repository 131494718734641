export const getusercallrequestSrv = (param) => {
  async function getusercallrequestService() {
    const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/user/getcallrequest', {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
      credentials: "include", // include, *same-origin, omit
    });
     return await rawResponse.json();
  };

  return getusercallrequestService()
}
