import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { abcdef, abcdefInit } from '@uiw/codemirror-theme-abcdef';
import { bbedit, bbeditInit } from '@uiw/codemirror-theme-bbedit';
import { vscodeDark, vscodeDarkInit } from '@uiw/codemirror-theme-vscode';
import { html } from '@codemirror/lang-html';
import { css } from '@codemirror/lang-css';
import { javascript } from '@codemirror/lang-javascript';
import { tags as t } from '@lezer/highlight';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import LinearProgress from '@material-ui/core/LinearProgress';
import CancelSharpIcon from '@material-ui/icons/Close';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import BorderInnerIcon from '@material-ui/icons/BorderInner';
import { makeStyles} from '@material-ui/core/styles';
import { themeHlp }  from '../Helper/ThemeHelper.js';
import  appResizeWindowHlp  from '../Helper/AppResizeWindowHelper.js';
import { getfilesSrv } from '../Service/GetFilesService.js';
import { getsharedfilesSrv } from '../Service/GetSharedFilesService.js';
import { createmvsfileSrv } from '../Service/CreateMvsFileService.js';
import { editmvsfileSrv } from '../Service/EditMvsFileService.js';
import { getmvsfileSrv } from '../Service/GetMvsFileService.js';
import { createmvscfileSrv } from '../Service/CreateMvscFileService.js';
import { getmvscfileSrv } from '../Service/GetMvscFileService.js';
import { styled } from '@material-ui/core/styles';
import '../AppsStyle.css';

/*
const myTheme = createTheme({
  theme: 'light',
  settings: {
    background: '#ffffff',
    foreground: '#75baff',
    caret: '#5d00ff',
    selection: '#036dd626',
    selectionMatch: '#036dd626',
    lineHighlight: '#8a91991a',
    gutterBackground: '#fff',
    gutterForeground: '#8a919966',
    
  },
  styles: [
    { tag: t.comment, color: '#787b8099' },
    { tag: t.variableName, color: '#0080ff' },
    { tag: [t.string, t.special(t.brace)], color: '#5c6166' },
    { tag: t.number, color: '#5c6166' },
    { tag: t.bool, color: '#5c6166' },
    { tag: t.null, color: '#5c6166' },
    { tag: t.keyword, color: '#5c6166' },
    { tag: t.operator, color: '#5c6166' },
    { tag: t.className, color: '#5c6166' },
    { tag: t.definition(t.typeName), color: '#5c6166' },
    { tag: t.typeName, color: '#5c6166' },
    { tag: t.angleBracket, color: '#5c6166' },
    { tag: t.tagName, color: '#5c6166' },
    { tag: t.attributeName, color: '#5c6166' },
  ],
});
*/

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: themeHlp().palette.blackText.light,
    backgroundColor: themeHlp().palette.secondary.light,  
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 16,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        color: themeHlp().palette.blackText.main,        
      },
    },
  }  
}));

const useStyles = makeStyles((theme) => ({
  menu_box:{
    width:'100%', 
    height:'340px', 
    margin:'auto auto', 
    background: 'linear-gradient(135deg, rgb(38, 93, 140) 0%, rgb(38, 93, 140) 15%, rgb(0, 140, 183) 50%)', 
    opacity:'1', 
    borderRadius:'15px', 
    border:'4px solid #f5a33e'
  },
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: themeHlp().palette.secondary.light,
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialog_content: {
    padding: '0px',
  },
  text_box: {
    width:'160px',
    backgroundColor: 'white'
  },
  iconbutton: {
    width:'36px',
    height: '36px',
    color: themeHlp().palette.primary.light,
    '&:hover' : {
      color: themeHlp().palette.primary.main
    },
    '&:active' : {
      color: themeHlp().palette.primary.dark,    
    }
  },
  file_icon: {
    width: '24px',
    height: '24px',     
    backgroundRepeat: 'no-repeat',
    backgroundSize: '22px',
    backgroundPosition: '0px 2px',
    padding: '0 24px 0 0',
  },
  process_dialog_content: { 
    overflow: 'hidden', 
    border: `1px solid ${ themeHlp().palette.greyText.light}`, 
    borderStyle:'solid none solid none', 
    backgroundColor: themeHlp().palette.secondary.light,
    '&.MuiDialogContent-root':{
      flex: 'none'
    }
  },
  process_messages_box: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'left',
    flexWrap: 'wrap',
    maxWidth: '1800px'
  },
  process_messages_effect:  {
    position: 'relative',
    padding: '0px',
    overflow: 'hidden',
  },
  linear_progress_box: {
    width: '200px',
    margin: 'auto auto',
    '& .MuiLinearProgress-root': {
      backgroundColor: themeHlp().palette.secondary.light
    },
    '& .MuiLinearProgress-bar': {
      backgroundColor: themeHlp().palette.primary.light
    }   
  },
  font_badge: {
    '& .MuiBadge-colorPrimary': {
      background: themeHlp().palette.primary.gradient,
      color: themeHlp().palette.secondary.light
    }
  },
  code_box: {
    '& .cm-scroller':{
      fontSize: '16px',
    },
    '& .cm-content' : {
      whiteSpace: 'break-spaces',
      wordBreak: 'break-word',
      overflowWrap: 'anywhere',
      flexShrink: 1
    }    
  },
  window_mode_panel: {
    width: '22px', 
    height: '22px',
    color:'transparent', 
    backgroundColor:'transparent',  
    backgroundRepeat: 'no-repeat', 
    backgroundSize: 'cover',
    cursor: 'pointer',
    float: 'right'
  }  
}));


let timeoutAlert = {};

const CodeBuilderApp = (props) => {
  const classes = useStyles();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [open, setOpen] = React.useState(false);
  const fontResetRef = useRef();
  const [fileUploadStarted, setFileUploadStarted] = useState(false);
  const [toolbarHidden, setToolbarHidden] = useState(false);
  const [fontSize, setFontSize] = useState(16);
  const [windowTheme, setWindowTheme] = useState(vscodeDarkInit({
    settings: {
      caret: '#ffffff',
      fontFamily: 'monospace',
    },
    styles: [
      { tag: t.comment, color: '#6272a4' },
      { tag: t.variableName, color: '#0080ff' },
    ]
  }));
  const [wordWrap, setWordWrap] = useState(true);
  const [codeWindowHeightSize, setCodeWindowHeightSize] = useState(400);
  const [responseType, setResponseType] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [responseTime, setResponseTime] = useState(4);
  const [htmlCode, setHtmlCode] = useState('');
  const [cssCode, setCssCode] = useState('');
  const [jsCode, setJsCode] = useState('');
  const [codeContent, setCodeContent] = useState({});
  const [openedFilename, setOpenedFilename] = useState('');
  const [windowMode, setWindowMode] = useState(0);
  const titleDisplaySize = 576;
  let iframeWriter = document.getElementById('iframeWriter');
  let codeMirrorScroller = document.querySelectorAll(".cm-scroller");
  let codeMirrorContent = document.querySelectorAll(".cm-content");  

  const [anchorViewEl, setAnchorViewEl] = React.useState(null);
  const openView = Boolean(anchorViewEl);
  const openViewMenu = (event) => {
    setAnchorViewEl(event.currentTarget);
  };
  const closeViewMenu = () => {
    setAnchorViewEl(null);
  };

  const [anchorFontSizeEl, setAnchorFontSizeEl] = React.useState(null);
  const openFontSize = Boolean(anchorFontSizeEl);
  const openFontSizeMenu = (event) => {
    setAnchorFontSizeEl(event.currentTarget);
  };
  const closeFontSizeMenu = () => {
    setAnchorFontSizeEl(null);
  };

  const [anchorThemeEl, setAnchorThemeEl] = React.useState(null);
  const openTheme = Boolean(anchorThemeEl);
  const openThemeMenu = (event) => {
    setAnchorThemeEl(event.currentTarget);
  };
  const closeThemeMenu = () => {
    setAnchorThemeEl(null);
  };

  const [anchorExportEl, setAnchorExportEl] = React.useState(null);
  const openExport = Boolean(anchorExportEl);
  const openExportMenu = (event) => {
    setAnchorExportEl(event.currentTarget);
  };  
  const closeExportMenu = () => {
    setAnchorExportEl(null);
  };
  
  const changeHtmlCode = (value) => {
    setHtmlCode(value);
    iframeWriter.contentDocument.body.innerHTML = value + '<style>' + cssCode + '</style>' + '<script>' + jsCode + '</script>';
    const htmlFromWriter = new XMLSerializer().serializeToString(iframeWriter.contentDocument);
    const contentFromWriter = {
      htmlCode: value,
      cssCode: cssCode,
      jsCode: jsCode
    };
    setCodeContent(contentFromWriter);
    const createmvscfileParams = {
      content : contentFromWriter,
      file: htmlFromWriter,
      title: (openedFilename === undefined || openedFilename === '') ? false : true 
    }
    createmvscfileSrv(createmvscfileParams)
    .then(() => {
    }).catch(err => {
      console.log(err);
    })
  }

  const changeCssCode = (value) => {
    setCssCode(value);
    iframeWriter.contentDocument.body.innerHTML = htmlCode + '<style>' + value + '</style>' + '<script>' + jsCode + '</script>';
    const htmlFromWriter = new XMLSerializer().serializeToString(iframeWriter.contentDocument);
    const contentFromWriter = {
      htmlCode: htmlCode,
      cssCode: value,
      jsCode: jsCode
    };
    setCodeContent(contentFromWriter);
    const createmvscfileParams = {
      content : contentFromWriter,
      file: htmlFromWriter,
      title: (openedFilename === undefined || openedFilename === '') ? false : true 
    }
    createmvscfileSrv(createmvscfileParams)
    .then(() => {
    }).catch(err => {
      console.log(err);
    })
  }

  const changeJavascriptCode = (value) => {
    setJsCode(value);
    iframeWriter.contentDocument.body.innerHTML = htmlCode + '<style>' + cssCode + '</style>' + '<script>' + value + '</script>';
    const htmlFromWriter = new XMLSerializer().serializeToString(iframeWriter.contentDocument);
    const contentFromWriter = {
      htmlCode: htmlCode,
      cssCode: cssCode,
      jsCode: value
    };
    setCodeContent(contentFromWriter);
    const createmvscfileParams = {
      content : contentFromWriter,
      file: htmlFromWriter,
      title: (openedFilename === undefined || openedFilename === '') ? false : true
    }
    createmvscfileSrv(createmvscfileParams)
    .then(() => {      
    }).catch(err => {
      console.log(err);
    })
  }

  const handleClickOpen = () => {

    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;

    if(allApps.indexOf('codebuilder') === -1 && JSON.parse(localStorage.getItem('movwin')).appCount <2){
      allApps.push('codebuilder');
      setOpen(true);
      setFileUploadStarted(false);      

      if(props.fileInfo?.shared_with){
        const getsharedfilesParams = {
          item_id: props.fileInfo?.item_id
        }
        if(getsharedfilesParams.item_id !== undefined){          
            getsharedfilesSrv(getsharedfilesParams)
            .then(async response => {
              if(response && response.statusCode === 200){              
                if(props.fileInfo?.item_ext === 'mvsa'){
                  setHtmlCode(JSON.parse(response.data).htmlCode)
                  setCssCode(JSON.parse(response.data).cssCode)
                  setJsCode(JSON.parse(response.data).jsCode)
                }
              }
            }).catch(err => {
              console.log(err);
            })
        }
      }else{
        if(props.fileInfo && props.fileInfo?.item_id){
          setOpenedFilename(props.fileInfo?.name)
          const getfilesParams = {
            item_id: props.fileInfo?.item_id
          }
          if(getfilesParams.item_id !== undefined){        
            const getmvsfileParams = {
              item_id: props.fileInfo?.item_id
            }
            getmvsfileSrv(getmvsfileParams)
            .then(response => {
                setHtmlCode(JSON.parse(response.data).htmlCode);
                setCssCode(JSON.parse(response.data).cssCode);
                setJsCode(JSON.parse(response.data).jsCode);
            }).catch(err => {
              console.log(err);
            })        
          }
        }else{
          let codeTitle = (openedFilename === undefined || openedFilename === '') ? 'codeuntitled' : 'codetitled';

          getmvscfileSrv(codeTitle)
            .then(response => {
              setHtmlCode(JSON.parse(response.data).htmlCode)
              setCssCode(JSON.parse(response.data).cssCode)
              setJsCode(JSON.parse(response.data).jsCode)
              setCodeContent({
                htmlCode: JSON.parse(response.data).htmlCode,
                cssCode: JSON.parse(response.data).cssCode,
                jsCode: JSON.parse(response.data).jsCode
              });
            }).catch(err => {
              console.log(err);
            })
        }
      }
      
      localStorage.setItem('movwin', JSON.stringify({
        windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
        apps: allApps,
        appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount + 1
      }));    
      
      setTimeout(() => {
        appsInWindows('load');
      },100);
    }
  };

  const handleClose = () => {
    setOpen(false);
    if(props.appCloses){
      props.appCloses(true);
    }

    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;
    if(allApps.indexOf('codebuilder') !== -1){
      allApps.splice(allApps.indexOf('codebuilder'), 1);
    }

    localStorage.setItem('movwin', JSON.stringify({
      windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
      apps: allApps,
      appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount - 1
    }));
  };

  const appsInWindows = (event) => {
    if(event === 'change'){
      if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).windowMode < 4){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode + 1,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount
        }));
      }else if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).windowMode === 4){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: 0,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount
        }));
      }

      if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).appCount === 0){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: 1
        }));
      }
    }

    setWindowWidth(windowWidth - 1);  
    appResizeWindowHlp('codeBuilderDialogBox');
  }

  const runCode = () => {    
    let iframeReader = document.getElementById('iframeReader');

    const getfilesParams = {
      item_id: (openedFilename === undefined || openedFilename === '') ? 'codeuntitled' : 'codetitled'
    }
    getfilesSrv(getfilesParams)
    .then(response => {
      iframeReader.src = response.data;
    }).catch(err => {
      console.log(err)
    }) 
  }

  const goToEditFile = (fileExtension) => {
    if(props.fileInfo?.item_ext === 'mvsc' && fileExtension === 'mvsc'){
      const editmvsfileParams = {
        item_id: props.fileInfo?.item_id,
        content: codeContent
      }
      editmvsfileSrv(editmvsfileParams)
      .then(response => {
        if(response && response.statusCode === 200){
          setResponseTime(responseTime + 1);
          setResponseType('report');
          setResponseMessage(response.message);
        }else if(response.error && response.error.statusCode === 400){
          setResponseTime(responseTime + 1);
          
          setResponseMessage(response.error.message);
        }else if(response.error && response.error.statusCode === 403){
          setResponseTime(responseTime + 1);
          setResponseType('error');          
          setResponseMessage(response.error.message);
        }        
      }).catch(err => {
        console.log(err);
      })
    }else{
      iframeWriter.contentDocument.body.innerHTML = htmlCode + '<style>' + cssCode + '</style>' + '<script>' + jsCode + '</script>';
      const htmlFromWriter = new XMLSerializer().serializeToString(iframeWriter.contentDocument);

      const createmvsfileParams = {
        item_location: window.location.pathname,
        item_ext: fileExtension,       
        content: fileExtension === 'mvsc' ? codeContent : htmlFromWriter
      }
      createmvsfileSrv(createmvsfileParams)
      .then(response => {
        if(response && response.statusCode === 201){          
          window.location.reload();
        }else if(response.error && response.error.statusCode === 400){
                
        }
      }).catch(err => {
        console.log(err);
      })
    }    
  }

  const wordWrapChange = () => {
    if(wordWrap){
      for(let i=0; i<codeMirrorContent.length; i++){
        codeMirrorContent[i].style.whiteSpace = 'nowrap';
        codeMirrorContent[i].style.wordBreak = 'normal';
        codeMirrorContent[i].style.overflowWrap = 'normal';
      }
    }else{
      for(let i=0; i<codeMirrorContent.length; i++){
        codeMirrorContent[i].style.whiteSpace = 'break-spaces';
        codeMirrorContent[i].style.wordBreak = 'break-word';
        codeMirrorContent[i].style.overflowWrap = 'anywhere';
      }
    }
    setWordWrap(!wordWrap);
  }

  const codeWindowHeightReset = () => {  
    setCodeWindowHeightSize(400);
  } 

  const codeWindowHeightIncrease = () => {  
    if(codeWindowHeightSize <= 1000){
      setCodeWindowHeightSize(codeWindowHeightSize + 100);
    }
  }  

  const codeWindowHeightDecrease = () => {
    if(codeWindowHeightSize > 190){
      setCodeWindowHeightSize(codeWindowHeightSize - 100);
    }
  }

  const fontReset = () => {
    for(let i=0; i<codeMirrorScroller.length; i++){
      codeMirrorScroller[i].style.fontSize = '16px';
    }
    setFontSize(16);
  }

  const fontIncrease = () => {
    for(let i=0; i<codeMirrorScroller.length; i++){
      codeMirrorScroller[i].style.fontSize = (fontSize  + 1) + 'px';
    }
    setFontSize(fontSize + 1);
  }

  const fontDecrease = () => {
    for(let i=0; i<codeMirrorScroller.length; i++){
      codeMirrorScroller[i].style.fontSize = (fontSize - 1) + 'px';
    }
    if(fontSize > 8){
      setFontSize(fontSize - 1);
    }
  } 
  
  const changeWindowTheme = (theme) => {
    switch(theme){
      case 'abcdef':
      setWindowTheme(abcdefInit({
        settings: {
          caret: '#ffffff',
          fontFamily: 'monospace',
        },
        styles: [
          { tag: t.comment, color: '#6272a4' },
        ]
      }))
      break;
      case 'bbedit':
      setWindowTheme(bbeditInit({
        settings: {
          caret: '#000',
          fontFamily: 'monospace',
        },
        styles: [
          { tag: t.comment, color: '#6272a4' },
        ]
      }))
      break;
      case 'vscodeDark':
        setWindowTheme(vscodeDarkInit({
          settings: {
            caret: '#ffffff',
            fontFamily: 'monospace',
          },
          styles: [
            { tag: t.comment, color: '#6272a4' },
            { tag: t.variableName, color: '#0080ff' },
          ]
        }))
      break;
      default:
        console.log('There is not a such theme');
        break;
    }
  }

  const changeWindowMode = (value) => {
    setWindowMode(value);
    if(value !== 0 ){
      setCodeWindowHeightSize(window.innerHeight - 144);
    }else{
      setCodeWindowHeightSize((window.innerHeight - 166) / 2);
    }
  }


  const AlertBox = () => {    
    clearTimeout(timeoutAlert);
    if(responseType === 'error' || responseType === 'report'){
      timeoutAlert = setTimeout(() => {
        setResponseType('');
      }, 4000);
    }

    if(responseType === 'warning'){
      return (
        <DialogContent className={classes.process_dialog_content}>
          <Box className={classes.process_messages_box}>          
            <div className={classes.process_messages_effect}>
              <div style={{
                position: 'absolute',
                inset: '0',
                background: themeHlp().palette.secondary.light,
                borderLeft: '2px solid ' + themeHlp().palette.secondary.light,
                borderRadius: '10px',
                top:'6px',
                height: '14px',
                transform: 'translateX(0%)',
                animation:  'translateOverlay 0.4s forwards cubic-bezier(0.03, 0, 0.15, 1)',
              }}></div>
              <Avatar component="span" alt="Warning" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/warning.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} /> 
              <Typography variant="subtitle1" component="span" style={{ cursor: 'default'}}>{responseMessage}</Typography>
            </div>
          </Box>
        </DialogContent>
      )
    }else if(responseType === 'error'){
      return (
        <DialogContent className={classes.process_dialog_content}>
          <Box className={classes.process_messages_box}>          
            <div className={classes.process_messages_effect}>
              <div style={{
                position: 'absolute',
                inset: '0',
                background: themeHlp().palette.secondary.light,
                borderLeft: '2px solid ' + themeHlp().palette.secondary.light,
                borderRadius: '10px',
                top:'6px',
                height: '14px',
                transform: 'translateX(0%)',
                animation:  'translateOverlay 0.4s forwards cubic-bezier(0.03, 0, 0.15, 1)',
              }}></div>
              <Avatar component="span" alt="Error" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/error.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} /> 
              <Typography variant="subtitle1" component="span" style={{ cursor: 'default'}}>{responseMessage}</Typography>
            </div>
          </Box>
        </DialogContent>
      )
    }else if(responseType === 'report'){
      return (
        <DialogContent className={classes.process_dialog_content}>
          <Box className={classes.process_messages_box}>
            <div className={classes.process_messages_effect}>
              <div style={{
                position: 'absolute',
                inset: '0',
                background: themeHlp().palette.secondary.light,
                borderLeft: '2px solid ' + themeHlp().palette.secondary.light,
                borderRadius: '10px',
                top:'6px',
                height: '14px',
                transform: 'translateX(0%)',
                animation:  'translateOverlay 0.4s forwards cubic-bezier(0.03, 0, 0.15, 1)',
              }}></div>
              <Avatar component="span" alt="Report" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/report.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} /> 
              <Typography variant="subtitle1" component="span" style={{ cursor: 'default'}}>{responseMessage}</Typography>
            </div>
          </Box>
        </DialogContent>
      )
    }
  }

  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);      
    }

    window.addEventListener('resize', updateSize);
    updateSize();
   
    return () => {     
      window.removeEventListener('resize', updateSize); 
    }
  },[windowWidth, windowHeight])
  
  useEffect(() => {
    handleClickOpen();
    if(!localStorage.getItem('movwin')){
      localStorage.setItem('movwin', JSON.stringify({
        windowMode: 0,
        apps: [],
        appCount: 1
      }));
    }     
    
    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;

    if(allApps.indexOf('codebuilder') === -1 && JSON.parse(localStorage.getItem('movwin')).appCount < 2){
      setOpen(true);
      setTimeout(() => {
        const muiBackdrop = document.querySelectorAll('.MuiBackdrop-root');
        let newApp = JSON.parse(localStorage.getItem('movwin')).apps;
        newApp.push('codebuilder');
        
        if(muiBackdrop[2]){
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: newApp,
            appCount: 2
          })); 
        }else if(muiBackdrop[2] === undefined && muiBackdrop[1]) {          
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: newApp,
            appCount: 1
          }));        
        }else if(muiBackdrop[2] === undefined && muiBackdrop[1] === undefined) {        
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: [],
            appCount: 0,
          }));        
        }
        appsInWindows('load');      
      },100);
    }
   },[])

return (
  <>
    <Avatar title="Code Builder" component="span" variant="rounded" alt="Code Builder" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/codebuilder.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={handleClickOpen} />
    <Typography title="Code Builder" variant="subtitle1" component="span" style={{ whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>Code Builder</Typography>
    <Dialog id="codeBuilderDialogBox" fullScreen onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle
        style={{
          padding: '8px 16px',
          color:'#ffffff', 
          background:'linear-gradient(90deg, rgba(38,93,140,1) 0%, rgba(38,93,140,1) 15%, rgba(0,140,183,1) 100%)'
        }}
        id="customized-dialog-title" onClose={handleClose}
      >
        <Avatar component="span" variant="rounded" style={{width:'24px', height: '24px', display: 'inline-block', marginRight: '8px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/codebuilder.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
        <span>{(windowWidth < titleDisplaySize && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) !== 0) || (windowWidth < (titleDisplaySize/2) && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) === 0) ? '' : 'Code Builder'}</span>
        <IconButton
          disabled={document.getElementById('shutdownDialog') ? false : true}
          aria-label="AppsInWindowsIcon"
          onClick={() => appsInWindows('change')}
          size="small"
          style={{
            background: themeHlp().palette.blueGrey.dark,
            color:'white',          
            margin:'2px 42px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px',
            opacity: document.getElementById('shutdownDialog') ? '1' : '0.3'
          }}
        >
          <BorderInnerIcon style={{padding: '2px'}} />          
        </IconButton>
        <IconButton 
          aria-label="UnfoldLessMoreIcon"
          title={!toolbarHidden ? 'Hide Toolbar' : 'Show Toolbar'}
          onClick={()=> setToolbarHidden(current => !current)}
          size="small"
          style={{
            width: '18px',
            height: '18px',
            background: themeHlp().palette.blueGrey.dark,
            color:'white',  
            margin:'2px 74px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px'
          }}
        >
          {!toolbarHidden ? <UnfoldLessIcon style={{padding: '4px'}} /> : <UnfoldMoreIcon style={{padding: '4px'}} />}
        </IconButton>
        <IconButton 
          aria-label="CancelSharpIcon"
          title="Close"
          onClick={handleClose}
          size="small"
          style={{
            background: themeHlp().palette.blueGrey.dark,
            color:'white', 
            margin:'2px 10px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px'
          }}
        >
          <CancelSharpIcon style={{padding: '2px'}} /> 
        </IconButton>
      </DialogTitle>
      {!toolbarHidden && 
      <DialogActions id="p0" className={classes.dialog_content}>
        <div id="p1" className={classes.root}>          
          <List style={{ width: '100%', height: '64px', padding: '0px', overflowY: 'auto'}}>
            <>
              <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
                <label>
                  <Avatar title="Run Code" component="span" variant="rounded" alt="Run Code" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_run.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={() => runCode()} />
                </label>
              </ListItem>
              <>
                <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
                  <label>
                    <Avatar title="View" component="span" variant="rounded" alt="View" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_view_option.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={openViewMenu}/>
                  </label>
                </ListItem>
                <div>
                  <StyledMenu
                    id="view-option"
                    MenuListProps={{
                      'aria-labelledby': 'view-option',
                    }}
                    anchorEl={anchorViewEl}
                    open={openView}
                    onClose={closeViewMenu}
                  >
                    <MenuItem onClick={wordWrapChange}>
                      <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_word_wrap.png)'}}></span>
                      Word Wrap/Unwrap
                    </MenuItem>
                    <MenuItem onClick={codeWindowHeightReset}>
                      <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_window_height_reset.png)'}}></span>
                      Reset Window Height
                    </MenuItem>
                    <MenuItem onClick={codeWindowHeightIncrease}>
                      <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_window_height_increase.png)'}}></span>
                      Increase Window Height
                    </MenuItem>
                    <MenuItem onClick={codeWindowHeightDecrease}>
                      <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_window_height_decrease.png)'}}></span>
                      Decrease Window Height
                    </MenuItem>
                  </StyledMenu>
                </div>
              </>
              <>
                <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
                  <label>
                    <Badge badgeContent={fontSize} color="primary" className={classes.font_badge}>
                      <Avatar title="Font Size" component="span" variant="rounded" alt="Font Size" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_font_reset.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={openFontSizeMenu}/>
                    </Badge>
                  </label>
                </ListItem>
                <div>
                  <StyledMenu
                    id="image-fontsize-menu"
                    MenuListProps={{
                      'aria-labelledby': 'image-fontsize-menu',
                    }}
                    anchorEl={anchorFontSizeEl}
                    open={openFontSize}
                    onClose={closeFontSizeMenu}
                  >
                    <MenuItem ref={fontResetRef} onClick={fontReset}>
                      <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_font_reset.png)'}}></span>
                      Reset Font Size
                    </MenuItem>
                    <MenuItem onClick={fontIncrease}>
                      <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_font_up.png)'}}></span>
                      Increase Font Size
                    </MenuItem>
                    <MenuItem onClick={fontDecrease}>
                      <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_font_down.png)'}}></span>
                      Decrease Font Size
                    </MenuItem>
                  </StyledMenu>
                </div>
              </>
              <>
                <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
                  <label>
                    <Avatar title="Theme" component="span" variant="rounded" alt="Theme" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_theme.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={openThemeMenu}/>
                  </label>
                </ListItem>
                <div>
                  <StyledMenu
                    id="theme-option"
                    MenuListProps={{
                      'aria-labelledby': 'image-theme-option',
                    }}
                    anchorEl={anchorThemeEl}
                    open={openTheme}
                    onClose={closeThemeMenu}
                  >
                    <MenuItem onClick={() => changeWindowTheme('abcdef')}>
                      <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_theme.png)'}}></span>
                      abcdef
                    </MenuItem>
                    <MenuItem onClick={() => changeWindowTheme('bbedit')}>
                      <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_theme.png)'}}></span>
                      bbedit
                    </MenuItem>
                    <MenuItem onClick={() => changeWindowTheme('vscodeDark')}>
                      <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_theme.png)'}}></span>
                      vscodeDark
                    </MenuItem>
                  </StyledMenu>
                </div>
              </>
              <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
                <label>
                  <Avatar title="Save Code" component="span" variant="rounded" alt="Save Mvsc" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_save_mvs.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={() => goToEditFile('mvsc')} />
                </label>
              </ListItem>
              <>
                <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
                  <label>
                    <Avatar title="Export as" component="span" variant="rounded" alt="Export as" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_export_as.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={openExportMenu} />
                  </label>                    
                </ListItem>
                <div>              
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorExportEl}
                    open={openExport}
                    onClose={closeExportMenu}
                  >
                    <MenuItem onClick={() => goToEditFile('html')}>
                      <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_export_as_html.png)'}}></span>
                      Export as HTML
                    </MenuItem>
                  </StyledMenu>
                </div>
              </>
            </>
          </List>          
        </div>
      </DialogActions>
      }
      {responseType !== '' && <AlertBox />}
      <DialogContent style={{padding: 0}}>
        {fileUploadStarted &&
          <>
            <Typography variant="subtitle2" component="div" style={{fontSize: '14px', marginTop: '20px', color: themeHlp().palette.primary.main, /*padding: '0 10px',*/ borderRadius: '10px', textAlign: 'center'}}>
              Opening...
            </Typography>
            <Box className={classes.linear_progress_box}>
              <LinearProgress value={10}/>
            </Box>
          </>
        }
        <Box sx={{ flexGrow: 1, overflow: 'hidden' }}> 
          <Grid container style={{ marginTop:'4px'}}>
            <Grid container spacing={0}>
              {(windowMode === 0 || windowMode === 1 || windowMode === 2) &&
              <Grid item xs={12} sm={(windowMode === 0 || windowMode === 1) ? 6 : 12} lg={windowMode === 0 && 4}>
                <Box>
                  <Typography variant="subtitle2" component="div" style={{width: '100%', fontWeight: 'bold', borderRadius: '0px 20px 0 0', backgroundColor: '#333333', color: '#ff8c8c'}}>
                    HTML
                    <Avatar title="Full Screen" component="span" variant="rounded" alt="Full Screen" className={classes.window_mode_panel} style={{ margin: '0 16px 0 2px', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/codebuilder/codebuilder_window_mode3.png)'}} onClick={() => changeWindowMode(2)} />
                    <Avatar title="Show With Result Window" component="span" variant="rounded" alt="Show With Result Window" className={classes.window_mode_panel} style={{ margin: '0 0px 0 2px', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/codebuilder/codebuilder_window_mode2.png)'}} onClick={() => changeWindowMode(1)} />
                    <Avatar title="Show All Windows" component="span" variant="rounded" alt="Show All Windows" className={classes.window_mode_panel} style={{ margin: '0', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/codebuilder/codebuilder_window_mode1.png)'}} onClick={() => changeWindowMode(0)} />
                  </Typography>
                </Box>
                <CodeMirror                
                  className={classes.code_box}        
                  value={htmlCode}
                  height={`${codeWindowHeightSize}px`}
                  width="100%"
                  theme={windowTheme}
                  extensions={[html()]}
                  onChange={changeHtmlCode}
                />              
              </Grid>
              }              
              {(windowMode === 0 || windowMode === 3 || windowMode === 4) &&
              <Grid item xs={12} sm={(windowMode === 0 || windowMode === 3) ? 6 : 12} lg={windowMode === 0 && 4}>       
                <Box>
                  <Typography variant="subtitle2" component="div" style={{width: '100%', fontWeight: 'bold', borderRadius: '0px 20px 0 0', backgroundColor: '#333333', color: '#7bbba9de'}}>
                    CSS
                    <Avatar title="Full Screen" component="span" variant="rounded" alt="Full Screen" className={classes.window_mode_panel} style={{ margin: '0 16px 0 2px', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/codebuilder/codebuilder_window_mode3.png)'}} onClick={() => changeWindowMode(4)} />
                    <Avatar title="Show With Result Window" component="span" variant="rounded" alt="Show With Result Window" className={classes.window_mode_panel} style={{ margin: '0 0px 0 2px', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/codebuilder/codebuilder_window_mode2.png)'}} onClick={() => changeWindowMode(3)} />
                    <Avatar title="Show All Windows" component="span" variant="rounded" alt="Show All Windows" className={classes.window_mode_panel} style={{ margin: '0', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/codebuilder/codebuilder_window_mode1.png)'}} onClick={() => changeWindowMode(0)} />
                  </Typography>
                </Box>
                <CodeMirror
                  className={classes.code_box}        
                  value={cssCode}      
                  height={`${codeWindowHeightSize}px`}
                  width="100%"
                  theme={windowTheme}
                  extensions={[css()]}
                  onChange={changeCssCode}
                />
              </Grid>
              }
              {(windowMode === 0 || windowMode === 5 || windowMode === 6) &&
              <Grid item xs={12} sm={(windowMode === 0 || windowMode === 5) ? 6 : 12} lg={windowMode === 0 && 4}>    
                <Box>
                  <Typography variant="subtitle2" component="div" style={{width: '100%', fontWeight: 'bold', borderRadius: '0px 20px 0 0', backgroundColor: '#333333', color: '#ffeb00de'}}>
                    JS
                    <Avatar title="Full Screen" component="span" variant="rounded" alt="Full Screen" className={classes.window_mode_panel} style={{ margin: '0 16px 0 2px', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/codebuilder/codebuilder_window_mode3.png)'}} onClick={() => changeWindowMode(6)} />
                    <Avatar title="Show With Result Window" component="span" variant="rounded" alt="Show With Result Window" className={classes.window_mode_panel} style={{ margin: '0 0px 0 2px', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/codebuilder/codebuilder_window_mode2.png)'}} onClick={() => changeWindowMode(5)} />
                    <Avatar title="Show All Windows" component="span" variant="rounded" alt="Show All Windows" className={classes.window_mode_panel} style={{ margin: '0', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/codebuilder/codebuilder_window_mode1.png)'}} onClick={() => changeWindowMode(0)} />   
                  </Typography>             
                </Box>     
                <CodeMirror
                  className={classes.code_box}            
                  value={jsCode}
                  height={`${codeWindowHeightSize}px`}
                  width="100%"
                  theme={windowTheme}
                  extensions={[javascript({ jsx: true })]}
                  onChange={changeJavascriptCode}                  
                />
              </Grid>
              }
              {(windowMode === 0 || windowMode === 1 || windowMode === 3 || windowMode === 5 || windowMode === 7) &&
              <Grid item xs={12} sm={(windowMode === 0 || windowMode === 1 || windowMode === 3 || windowMode === 5) ? 6 : 12} lg={(windowMode === 1 || windowMode === 3 || windowMode === 5) ? 6 : 12}>
                <Box>
                  <Typography variant="subtitle2" component="div" style={{width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold', borderRadius: '0px 20px 0 0', background: themeHlp().palette.primary.gradient, color: themeHlp().palette.whiteText.main}}>
                    Result ({openedFilename})
                    <Avatar title="Full Screen" component="span" variant="rounded" alt="Full Screen" className={classes.window_mode_panel} style={{ margin: '0 16px 0 2px', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/codebuilder/codebuilder_window_mode3.png)'}} onClick={() => changeWindowMode(7)} />                    
                    <Avatar title="Show All Windows" component="span" variant="rounded" alt="Show All Windows" className={classes.window_mode_panel} style={{ margin: '0', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/codebuilder/codebuilder_window_mode1.png)'}} onClick={() => changeWindowMode(0)} /> 
                  </Typography>   
                </Box>
                <iframe
                  id="iframeWriter"
                  frameBorder="0"
                  sandbox="allow-same-origin allow-scripts allow-modals"
                  style={{width: '100%', height: 'auto', display: 'none'}}
                ></iframe>
                <iframe 
                  id="iframeReader" 
                  frameBorder="0"                  
                  sandbox="allow-same-origin allow-scripts allow-modals"
                  style={{width: '100%', height: `${codeWindowHeightSize}px`, border: '1px solid ' + themeHlp().palette.primary.main}}
                ></iframe>
              </Grid>
              }              
            </Grid>
          </Grid>
        </Box>            
      </DialogContent>
    </Dialog>
  </>
)
}
 
export default CodeBuilderApp;
