import React from 'react';
import { makeStyles, createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import { themeHlp } from '../Helper/ThemeHelper.js';
import Typography from '@material-ui/core/Typography';


const theme = createMuiTheme({
  typography:{
    fontFamily: [
      'Inter', 
      'Helvetica',
      'Arial',
      'Sans-Serif'
    ]
  }
});

const useStyles = makeStyles(() => ({
  footer_text: {
    position: 'absolute',
    fontSize: '14px',
    background: themeHlp().palette.primary.gradientHeaderFooter,
    width: '100%',
    height: '46px',
    bottom: '0px',
    paddingTop: '2px',
    textAlign: 'center',
    verticalAlign: 'middle',
    color: themeHlp().palette.whiteText.main,
  }
}));

const FooterBar = () => {
  const classes = useStyles();

  return (
    <>
      <footer>
        <ThemeProvider theme={theme}>
          <Typography className={classes.footer_text}  variant="subtitle1" component="span">
            Copyright &copy; {new Date().getFullYear()} MovesAs
          </Typography>
        </ThemeProvider>
      </footer>
    </>
  );
}

export default FooterBar;
