export const sendmessageSrv = (param) => {
  async function sendmessageService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/user/sendmessage', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        message_to: param.message_to,
        email: param.email,
        message: param.message
      }),
      credentials: "include", // include, *same-origin, omit
    });
     return await rawResponse.json();
  };

  return sendmessageService()
}
