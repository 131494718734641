import React from 'react';
import {HashRouter, Switch, BrowserRouter, Routes, Route} from "react-router-dom";

import Welcome from "./Template/Welcome";
import Desktop from "./Template/Desktop";
import Login from "./Template/Auth/Login";
import Register from "./Template/Auth/Register";
import Activation from "./Template/Auth/Activation";
import PasswordReset from "./Template/Auth/PasswordReset";
import SignoutOfDevices from "./Template/Auth/SignoutOfDevices";
import NotFound from "./Template/Auth/NotFound";
import Guide from "./Template/Guide/Guide";
import DesktopGuide from "./Template/Guide/DesktopGuide";
import UsersGuide from "./Template/Guide/UsersGuide";
import FilesGuide from "./Template/Guide/FilesGuide";
import GetMoreGuide from "./Template/Guide/GetMoreGuide";
import AppsGuide from "./Template/Guide/AppsGuide";
import FeaturesGuide from "./Template/Guide/FeaturesGuide";

const App = () => {

  if(window.location.protocol === 'http:' && window.location.host === 'www.movesas.com'){
    window.location.href = 'https://movesas.com';
  }

  return (
    <>
    {/*   <BrowserRouter basename="/#"
      getUserConfirmation={(message, callback) => {
        // this is the default behavior
        const allowTransition = window.confirm(message);
        callback(allowTransition);
      }}
     >
        <Switch>
          <Desktop component={Desktop} title="Hi" path="/desktop" />
          <Login component={Login} path="/login" />
          <Register component={Register} path="/register" />
          <Activation component={Activation} path="/activation" />
          <Welcome component={Welcome} path="/" />
        </Switch>   
      </BrowserRouter>*/}
    {/*
      <HashRouter hashType="slash">
        <Switch>
          <Desktop component={Desktop} path="/desktop" />
          <Login component={Login} path="/login" />
          <Register component={Register} path="/register" />
          <Activation component={Activation} path="/activation" />
          <PasswordReset component={PasswordReset} path="/password-reset" />
          <SignoutOfDevices component={SignoutOfDevices} path="/signout-of-devices" />
          <GuideRouter component={GuideRouter} path="/help" />
          <Welcome component={Welcome} path="/" />
        </Switch>   
      </HashRouter>
    */}

      <BrowserRouter>
        <Routes>
          <Route element={<Desktop />} path="/desktop" >
            <Route path=":id">
              <Route path=":id">
                <Route path=":id">
                  <Route path=":id">
                    <Route path=":id">
                      <Route path=":id">      
                        <Route path=":id">                
                          <Route path=":id">                
                            <Route path=":id">                
                              <Route path=":id">                
                                <Route path=":id">
                                  <Route path=":id">
                                    <Route path=":id">
                                      <Route path=":id">
                                        <Route path=":id">
                                        </Route>
                                      </Route>
                                    </Route>
                                  </Route>
                                </Route>
                              </Route>
                            </Route>
                          </Route>
                        </Route>          
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
          <Route element={<Login />} path="/login" />
          <Route element={<Register />} path="/register" />
          <Route element={<Activation />} path="/activation/:id" />          
          <Route element={<PasswordReset />} path="/password-reset/:id" />
          <Route element={<SignoutOfDevices />} path="/signout-of-devices/:id" />
          <Route element={<DesktopGuide />} path="/help/desktop" />
          <Route element={<UsersGuide />} path="/help/users" />
          <Route element={<FilesGuide />} path="/help/files" />
          <Route element={<FeaturesGuide />} path="/help/features" />
          <Route element={<AppsGuide />} path="/help/apps" />
          <Route element={<GetMoreGuide />} path="/help/more" />
          <Route element={<Guide />} path="/help" />          
          <Route element={<NotFound />} path="*" />
          <Route element={<Welcome />} path="/" />          
        </Routes> 
      </BrowserRouter>
    </>
  )  
}

export default App;
