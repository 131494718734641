export const setAccountDataSrv = (param) => {  
  async function setAccountDataService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/api/user/change-account-name', {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: "include", // include, *same-origin, omit
      body: JSON.stringify({
        first_name: param.first_name,
        last_name: param.last_name
      })      
    });
     return await rawResponse.json();
  };

  return setAccountDataService()
}
