import React, { useState, useEffect } from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from "@material-ui/core/IconButton";
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import HomeIcon from '@material-ui/icons/Home';
import { themeHlp } from '../Helper/ThemeHelper.js';

const theme = createMuiTheme({
  typography:{
    fontFamily: [
      'Inter', 
      'Helvetica',
      'Arial',
      'Sans-Serif'
    ]
  }
});

const useStyles = makeStyles(() => ({
  page_container: {
    position: 'relative',
    height: '0',
    minHeight: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  page_container_for_welcomepage: {
    position: 'relative',
  },
  headline: {
    fontSize: '28px'
  },
  subHeadline: {
    color: themeHlp().palette.primary.dark
  },
  screenshot: {
    paddingTop: '15px', 
    width:'90%', 
    maxWidth: '1080px', 
    display: 'block', 
    margin: 'auto auto'
  },  
  casual_text: {
    fontFamily: 'Helvetica',
    fontSize: '1.065rem'
  },
  helpButtonStyle: {
    width: '30px',
    height: '30px',
    margin: '10px 2px 0 2px',
    color:'#fff',
    background: themeHlp().palette.primary.gradientRadial,
    '&:hover': {
      background: themeHlp().palette.primary.gradientRadialHover,
   },
  } 
}))


const FilesGuide = () => {
  const classes = useStyles();
  let navigate = useNavigate();
  let location = useLocation();  
  const [expanded, setExpanded] = useState('');
  const [historyButtons, setHistoryButtons] = useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
 
  useEffect(() => {
    if(location.pathname.includes('help')){
      setHistoryButtons(true);
    }
  },[])

  return (
    <>
      <ThemeProvider theme={theme} >
        <Box sx={{ flexGrow: 1 }} className={historyButtons ? classes.page_container : classes.page_container_for_welcomepage}>
          <Grid container>
            <Grid container align="center" spacing={2} justify="center" style={{margin: 'auto'}}>
              <Grid item xs={12} style={{ backgroundImage: 'linear-gradient(to bottom, rgba(255,0,0,0), rgba(255,255,255,1))'}}>
              {historyButtons && 
                  <Box>
                    <IconButton title="Back" aria-label="fingerprint" color="secondary" className={classes.helpButtonStyle} onClick={() => navigate(-1)}>
                      <ArrowBackIcon />
                    </IconButton>
                    <IconButton title="Home" aria-label="fingerprint" color="secondary" className={classes.helpButtonStyle} onClick={() => navigate('/help')}>
                      <HomeIcon />
                    </IconButton>
                    <IconButton title="Forward" aria-label="fingerprint" color="secondary" className={classes.helpButtonStyle} onClick={() => navigate(1)}>
                      <ArrowForwardIcon />
                    </IconButton>
                  </Box>
                }
                <Typography className={classes.headline}  variant="subtitle1" component="span">
                  Files and folders
                </Typography>
              </Grid>
            </Grid>
            <Accordion expanded={expanded === 'panel1'} style={{margin:0, width:'100%'}} onChange={handleChange('panel1')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"          
              >
                <Typography className={classes.subHeadline}>
                  Create a new folder
                </Typography>          
              </AccordionSummary>
              <AccordionDetails>
              <Box sx={{width:'100%'}}>
                <Typography variant="inherit" className={classes.casual_text} >
                  In order to create a folder in your store, go to the directory where you want to create, and click "Create Folder" button in the sidebar.
                  The very first folder can be created only on the desktop directory.
                  Folders are automatically numbered and if, for example, exists a folder with name "folder1", will be created folder with name "folder2" and so on.
                </Typography>
                <Divider light />   
                <img className={classes.screenshot} alt="files screenshot 01" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/files/files_screenshot_01.jpg'}`}/>
              </Box>
              </AccordionDetails>
            </Accordion>      
            <Accordion expanded={expanded === 'panel2'} style={{margin:0, width:'100%'}} onChange={handleChange('panel2')}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography className={classes.subHeadline}>
                  Upload your files
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{width:'100%'}}>
                  <Typography variant="inherit" className={classes.casual_text}>
                    By clicking "Upload file" in the sidebar, select the file you want to upload.
                    A file with the same name, that already exists in that directory, won't be allowed to be uploaded.
                    <br />
                    <Link href="/help/features" underline="none">Learn more about storage</Link>.
                  </Typography>
                  <Divider light />
                  <img className={classes.screenshot} alt="files screenshot 02" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/files/files_screenshot_02.jpg'}`}/>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} style={{margin:0, width:'100%'}} onChange={handleChange('panel3')}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography className={classes.subHeadline}>
                  Search personal and shared files
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{width:'100%'}}>
                  <Typography variant="inherit" className={classes.casual_text}>
                  See <Link href="/help/apps" underline="none"> File Finder</Link>.
                  </Typography>                  
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} style={{margin:0, width:'100%'}} onChange={handleChange('panel4')}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography className={classes.subHeadline}>
                  Open the item
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{width:'100%'}}>
                  <Typography variant="inherit" className={classes.casual_text}>
                    Depending on the selected item extension, the corresponding application will appear at the very top of the opened properties panel.
                  </Typography>
                  <Divider light />
                  <img className={classes.screenshot} alt="files screenshot 04" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/files/files_screenshot_04.jpg'}`}/>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel5'} style={{margin:0, width:'100%'}} onChange={handleChange('panel5')}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5bh-content"
                id="panel5bh-header"
              >
                <Typography className={classes.subHeadline}>
                  Rename the item
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{width:'100%'}}>
                  <Typography variant="inherit" className={classes.casual_text}>
                    In the appeared panel, you can type above the "Rename" button a new name for the selected item by clicking the appropriate button.
                  </Typography>
                  <Divider light />
                  <img className={classes.screenshot} alt="files screenshot 05" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/files/files_screenshot_05.jpg'}`}/>
                  <Card raised style={{ marginTop: '15px' }}>
                    <CardContent>                    
                      <Avatar component="span" alt="warning" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/warning.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />     
                      <Typography variant="subtitle1" component="span" style={{fontWeight: 'bold', cursor: 'default'}}>Warning!</Typography>
                    </CardContent>
                    <CardActions>
                      Be careful when changing filename not to remove file extension!
                    </CardActions>
                  </Card>

                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel6'} style={{margin:0, width:'100%'}} onChange={handleChange('panel6')}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel6bh-content"
                id="panel6bh-header"
              >
                <Typography className={classes.subHeadline}>
                  Move the item
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{width:'100%'}}>
                  <Typography variant="inherit" className={classes.casual_text}>
                    Moving a file, like copying, is very easy to do.
                    Select the directory where you want to move the item along with its contents and then click the "Move" button.
                  </Typography>
                  <Divider light />
                  <img className={classes.screenshot} alt="files screenshot 06" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/files/files_screenshot_06.jpg'}`}/>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel7'} style={{margin:0, width:'100%'}} onChange={handleChange('panel7')}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel7bh-content"
                id="panel7bh-header"
              >
                <Typography className={classes.subHeadline}>
                  Copy the item
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{width:'100%'}}>
                  <Typography variant="inherit" className={classes.casual_text}>
                    If you want to copy your item along with its contents to another folder, in the properties panel that opens, click on the "Copy to" button and select the folder where you want to copy. 
                    After activating the "Copy" button, click on it.
                  </Typography>
                  <Divider light />
                  <img className={classes.screenshot} alt="files screenshot 07" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/files/files_screenshot_07.jpg'}`}/>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel8'} style={{margin:0, width:'100%'}} onChange={handleChange('panel8')}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel8bh-content"
                id="panel8bh-header"
              >
                <Typography className={classes.subHeadline}>
                  Share the item
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{width:'100%'}}>
                  <Typography variant="inherit" className={classes.casual_text}>
                    The picture below demonstrates how you can share with a file.
                    <br />
                    If you don't see users with whom you want to share your item, see
                  </Typography>
                  <Link href="/help/users" underline="none"> Users/Find other users</Link>.
                  <Divider light />
                  <img className={classes.screenshot} alt="files screenshot 08" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/files/files_screenshot_08.jpg'}`}/>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel9'} style={{margin:0, width:'100%'}} onChange={handleChange('panel9')}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel9bh-content"
                id="panel9bh-header"
              >
                <Typography className={classes.subHeadline}>
                  Delete the item
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{width:'100%'}}>
                  <Typography variant="inherit" className={classes.casual_text}>
                    The delete command is also available in the properties panel list.
                    If you want to delete the selected item from your storage, in the collapsible content, click "Yes", otherwise "No".
                  </Typography>
                  <Divider light />
                  <img className={classes.screenshot} alt="files screenshot 09" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/files/files_screenshot_09.jpg'}`}/>
                  <Card raised style={{ marginTop: '15px' }}>
                    <CardContent>                    
                      <Avatar component="span" alt="warning" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/warning.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />     
                      <Typography variant="subtitle1" component="span" style={{fontWeight: 'bold', cursor: 'default'}}>Warning!</Typography>
                    </CardContent>
                    <CardActions>
                      Deleted items will be permanently lost.
                    </CardActions>
                  </Card>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel10'} style={{margin:0, width:'100%'}} onChange={handleChange('panel10')}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel10bh-content"
                id="panel10bh-header"
              >
                <Typography className={classes.subHeadline}>
                  Download the file
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{width:'100%'}}>
                  <Typography variant="inherit" className={classes.casual_text}>
                    Easily download the file from MovesAs to your hardware by clicking the "Download" button after selecting.
                    Once downloaded, the file can be saved in different locations depending on the operating system and settings.
                    <br />
                    Folders cannot be downloaded.
                  </Typography>
                  <Divider light />
                  <img className={classes.screenshot} alt="files screenshot 10" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/files/files_screenshot_10.jpg'}`}/>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {historyButtons &&
          <Grid container spacing={2} style={{margin: 'auto'}}>
            <Grid item xs={12} style={{height: '76px',  backgroundImage: 'linear-gradient(to top, rgba(255,0,0,0), rgba(255,255,255,1))'}}></Grid>
          </Grid>
          }
        </Box>
      </ThemeProvider>
    </>      
  )
} 
 
export default FilesGuide;
