
import { createMuiTheme } from '@material-ui/core/styles';

export const themeHlp = () => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#008cb7',
        main: '#1474a1',
        dark: '#265d8c',
        contrastText: '#fff',
        gradient: 'linear-gradient(90deg, rgba(38,93,140,1) 0%, rgba(38,93,140,1) 15%, rgba(0,140,183,1) 100%)',
        gradientReverse: 'linear-gradient(270deg, rgba(38,93,140,1) 0%, rgba(38,93,140,1) 15%, rgba(0,140,183,1) 100%)',
        gradientRadial: 'radial-gradient(circle, rgba(0,140,183,1) 0%, rgba(20,116,161,1) 100%)',
        gradientRadialHover: 'radial-gradient(circle, rgba(0,140,183,1) 0%, rgba(38,93,140,1) 100%)',
        gradientHeaderFooter: 'linear-gradient(90deg, rgba(38,93,140,1) 0%, rgba(38,93,140,1) 15%, rgba(0,140,183,1) 100%)',
      },
      secondary: {
        light: '#ffebd2',
        main: '#fee5ce',
        dark: '#f5a33e',
        contrastText: '#000',
        gradient: 'linear-gradient(-45deg,#fee5ce, #ffebd2, #265d8c)',
      },
      tertiary: {
        light: '#c3f9af',
        main: '#79ac67',
        dark: '#2f5f1f',
        contrastText: '#fff',
        gradient: 'linear-gradient(180deg, rgba(195,249,175,1) 0%, rgba(47,95,31,1) 100%)',
      },
      quaternary: {
        light: '#ffebd2',
        main: '#fee5ce',
        dark: '#f5a33e',
        contrastText: '#000'
      },
      fogGrey: {
        main: '#e0e0e0'
      },
      blueGrey: {
        main: '#0a6b8b',
        dark: '#054a60'
      },
      greyText: {
        light: '#747474',
        main: '#5a5a5a',
        dark: '#404040'
      },
      blackText: {
        light: '#757575',
        main: '#1e1d1a',
        dark: '#000000'
      },
      whiteText: {
        main: '#ffffff'
      },
      success: {
        main: '#00ff00',
        dark: '#238523'
      },
      error: {
        light: '#FF7276',
        main: '#f44336',
        dark: '#AB2328'
      },
      warning: {
        light: '#f8d293',
        main: '#f5b849',
        dark: '#f79845'
      },
      report: {
        light: '#6b8aca',
        main: '#4b6ab9',
        dark: '#1f3b97'
      }
    },
  });

  return theme
}
