import React, { useLayoutEffect, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CancelSharpIcon from '@material-ui/icons/Close';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import BorderInnerIcon from '@material-ui/icons/BorderInner';
import { makeStyles} from '@material-ui/core/styles';
import { themeHlp }  from '../Helper/ThemeHelper.js';
import  appResizeWindowHlp  from '../Helper/AppResizeWindowHelper.js';
import { getAccountDataSrv } from '../Service/GetAccountDataService.js';
import { setAccountDataSrv } from '../Service/SetAccountDataService.js';
import { getconfigSrv } from '../Service/GetConfigService.js';
import { setconfigSrv } from '../Service/SetConfigService.js';
import { sendPasswordResetLinkSrv } from '../Service/SendPasswordResetLinkService.js';
import { logoutSrv } from '../Service/LogoutService.js';
import '../../App.css';

const useStyles = makeStyles((theme) => ({
  menu_box:{
    width:'100%', 
    height:'340px', 
    margin:'auto auto', 
    background: 'linear-gradient(135deg, rgb(38, 93, 140) 0%, rgb(38, 93, 140) 15%, rgb(0, 140, 183) 50%)', 
    opacity:'1', 
    borderRadius:'15px', 
    border:'4px solid #f5a33e'
  },
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  user_avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: 'auto',
    cursor: 'pointer'
  },
  dialog_content: {
    height: '400px',
    padding: '0px',    
  },
  tab_group:{
    color: 'black',
    backgroundColor: themeHlp().palette.secondary.light,    
    '& .MuiTab-wrapper': {
      fontSize:'12px',
      textTransform: 'capitalize'
    },   
  },
  text_box: {
    width:'100%',
    border: '1px solid ' + themeHlp().palette.fogGrey.main,
    padding: '14.8px 12px',
    '&:hover': {    
      borderBottom: '1px solid ' + themeHlp().palette.primary.light      
    }
  },
  disabled_text_box: {
    width:'100%',
    border: '1px solid ' + themeHlp().palette.fogGrey.main,
    padding: '14.8px 12px',
    '& input.Mui-disabled': {
      color: themeHlp().palette.blackText.main
    }
  },
  account_info_key: {
    color: themeHlp().palette.primary.main,    
    padding: '16px',
    textAlign: 'left'
  },
  account_info_value: {
    color: themeHlp().palette.greyText.main,    
    border: '1px solid ' + themeHlp().palette.fogGrey.main,
    padding: '16px',
    textAlign: 'left',
    overflow: 'hidden'
  },
  device_info_key: {
    color: themeHlp().palette.primary.main,    
    padding: '16px',
    textAlign: 'left'
  },
  device_info_value: {
    color: themeHlp().palette.greyText.main,    
    border: '1px solid ' + themeHlp().palette.fogGrey.main,
    padding: '16px',
    textAlign: 'left'
  },
  button_witout_icon: {
    width:'100%',    
    margin: '7px 0 6px 0',
    background: themeHlp().palette.primary.light,
    textTransform: 'capitalize',
    border: '1px solid ' + themeHlp().palette.secondary.light,
    '&:hover' : {
      background: themeHlp().palette.primary.main,
      color: themeHlp().palette.secondary.light,
    },
    '&:active' : {
      background: themeHlp().palette.primary.dark,
      color: themeHlp().palette.secondary.light,
    }
  },  
  error_message: {    
    fontFamily: 'Helvetica',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    width: '100%',
    margin: '7px 0 6px 0',
    padding: '4px 0',
    backgroundColor: themeHlp().palette.error.main,
    color: themeHlp().palette.secondary.light,
    border: '0px',
    borderRadius: '0px'
  },
  tab_panel_content: {
    '& .MuiBox-root': {
      padding: '2px 14px'
    }
  },
  settings_tab:{
    display: 'block',
    color: themeHlp().palette.primary.main,
    textTransform: 'capitalize',
  },
  pie_chart: {
    display: 'block', 
    float:'left',
    minWidth: '100px', 
    minHeight: '100px', 
    maxWidth: '300px',  
    maxHeight: '300px', 
    borderRadius: '50%', 
    border: '2px solid white', 
    margin: '0 20px 20px 0'
  },
  pie_chart_text: {
    float:'left',
    minWidth: '100px', 
    minHeight: '100px', 
    maxWidth: '300px', 
    textAlign: 'left', 
    border: '2px solid white',
    '& strong': {
      display: 'block', 
      padding: '7px'
    }
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
         {children}
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

function TabSettingsPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
         {children}
        </Box>
      )}
    </div>
  );
}
TabSettingsPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11ySettingsProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


const AccountApp = (props) => {
//  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const classes = useStyles();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = useState(0);
  const [settingsValue, setSettingsValue] = useState(0);
  const [accountData, setAccountData] = useState({});
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');  
  const [toolbarHidden, setToolbarHidden] = useState(false);  
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordResetLinkSent, setPasswordResetLinkSent] = useState(0);
  const [config, setConfig] = useState([]);
  const [deviceInfo, setDeviceInfo] = useState([]);
  const sessionTimes = [
    {duration: '5 minutes', timestamp: 300000},
    {duration: '10 minutes', timestamp: 600000},
    {duration: '20 minutes', timestamp: 1200000},
    {duration: '30 minutes', timestamp: 1800000},
    {duration: '1 hour', timestamp: 3600000},
    {duration: '2 hours', timestamp: 7200000},
    {duration: '6 hours', timestamp: 21600000},
    {duration: '12 hours', timestamp: 43200000},
    {duration: '1 day', timestamp: 86400000},
    {duration: '2 days', timestamp: 172800000},
    {duration: '4 days', timestamp: 345600000},
    {duration: '7 days', timestamp: 604800000}
  ];
  const dividedWindowSize = 750; //375 iphone 7
  const titleDisplaySize = 488;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSettingsChange = (event, newValue) => {
    setSettingsValue(newValue);
  };  

  const handleClickOpen = () => {
    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;

    if(allApps.indexOf('account') === -1 && JSON.parse(localStorage.getItem('movwin')).appCount <2){
      allApps.push('account');
      setOpen(true);
      
      localStorage.setItem('movwin', JSON.stringify({
        windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
        apps: allApps,
        appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount + 1
      }));    
      
      setTimeout(() => {
        appsInWindows('load');
      },100);
    }
  };

  const handleClose = () => {
    setOpen(false);

    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;
    if(allApps.indexOf('account') !== -1){
      allApps.splice(allApps.indexOf('account'), 1);
    }

    localStorage.setItem('movwin', JSON.stringify({
      windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
      apps: allApps,
      appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount - 1
    }));
  };

  const appsInWindows = (event) => {
    if(event === 'change'){
      if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).windowMode < 4){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode + 1,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount
        }));
      }else if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).windowMode === 4){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: 0,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount
        }));
      }

      if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).appCount === 0){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: 1
        }));
      }
    }

    setWindowWidth(windowWidth - 1);  
    appResizeWindowHlp('accountDialogBox');
  }

  const gotoSendPasswordResetLnk = () => {
    setPasswordResetLinkSent(2);
    sendPasswordResetLinkSrv({email: accountData.account_email}).then(response => {      
      if(response && (response.statusCode === 200)){
        setSuccessMessage(response.message);
        setPasswordResetLinkSent(1);
      }else{
        setErrorMessage(response.error.message);
        setPasswordResetLinkSent(0);
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const goToGetConfig = () => {
    if(!config.hasOwnProperty('freeSpace')){
      getconfigSrv().then(response => {
        if(response && response.statusCode === 200){
          setConfig(response.data[0]);
        }
      }).catch(err => {
        console.log(err);
      })
    }
  }

  const goToSetConfig = (value) => {
   
    const setconfigSrvParams = {
      session_age: value,
    }
    setconfigSrv(setconfigSrvParams)
    .then(response => {
      if(response && response.statusCode === 200){
        setConfig([{
          freeSpace: config.freeSpace,
          usedSpace: config.usedSpace,
          storageCapacity: config.storageCapacity,
          maxUploadSize: config.maxUploadSize,
          maxAge: value
        }])        
      }
    }).catch(err => {
      console.log(err);
    })
  }

  const logOut = (param) => {
    logoutSrv(param)
    .then(response => {
      if(response && response.statusCode === 200){
      //  navigate('/');
      }
    })
    .catch(err => {
      console.log(err)
    })
  }

  const goToGetDeviceInfo = () => {
    //let platform = navigator;
    //document.getElementById("demo").innerHTML = "Platform: " + platform;
    //console.log(platform.mediaDevices)
    if(deviceInfo.length === 0){
      let canvas = document.createElement('canvas');
      let gl;
      let debugInfo;
      let vendor;
      let renderer;

        //gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
      gl = canvas.getContext('webgl', { powerPreference: "high-performance" }) || canvas.getContext('experimental-webgl', { powerPreference: "high-performance" });

      if (gl) {
        debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
        vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
        renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
      }

      async function getIp(){
        try {
          const responseIpify = await fetch('https://api.ipify.org/?format=json', {
            method: 'GET',
            headers: {
              accept: 'application/json',
            },
          });
          const data = await responseIpify.json();
          return data;
        } catch (err) {
          console.log(err)
        }
      }

      getIp().then(data => {   
        setDeviceInfo([{      
          userAgent: navigator.userAgent,
          graphicsCard: renderer,
          coresNumber: navigator.hardwareConcurrency,
          ipAddress: data !== undefined ? data.ip : 'IP Address is unavailable.'
        }]);
      })   
    }
  }

  const goToSetAccountData = (value) => {
    const accountdataParams = {
      first_name: value.firstName,
      last_name: value.lastName
    }
    setAccountDataSrv(accountdataParams).then(response => {      
      if(response && (response.statusCode === 200)){
        localStorage.setItem('movacc', JSON.stringify({
          account_email: accountData.account_email,
          account_id: accountData.account_id,
          first_name: value.firstName,
          last_name: value.lastName
        })); 
      }else if(response.error){
        setFirstName((JSON.parse(localStorage.getItem('movacc'))).first_name);
        setLastName((JSON.parse(localStorage.getItem('movacc'))).last_name);
        //setAccountData(JSON.parse(localStorage.getItem('movacc')))
        //response.error.message
      }
    }).catch(err => {
      console.log(err);
    })
  }

  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);      
    }

    window.addEventListener('resize', updateSize);
    updateSize();
   
    return () => {     
      window.removeEventListener('resize', updateSize); 
    }
  },[windowWidth, windowHeight])

  useEffect(() => {    
    if(!localStorage.getItem('movacc')){
      getAccountDataSrv().then(response => {
        localStorage.setItem('movacc', JSON.stringify(response));
        setAccountData(response);
      }).catch(err => {
        console.log(err);
      })
    }else{
      setAccountData(JSON.parse(localStorage.getItem('movacc')));
      setFirstName((JSON.parse(localStorage.getItem('movacc'))).first_name);
      setLastName((JSON.parse(localStorage.getItem('movacc'))).last_name);
    }
    
    if(!localStorage.getItem('movwin')){
      localStorage.setItem('movwin', JSON.stringify({
        windowMode: 0,
        apps: [],
        appCount: 1
      }));
    }     
    
    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;

    if(allApps.indexOf('account') === -1 && JSON.parse(localStorage.getItem('movwin')).appCount < 2){
      setOpen(true);
      setTimeout(() => {
        const muiBackdrop = document.querySelectorAll('.MuiBackdrop-root');
        let newApp = JSON.parse(localStorage.getItem('movwin')).apps;
        newApp.push('account');
        
        if(muiBackdrop[2]){
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: newApp,
            appCount: 2
          })); 
        }else if(muiBackdrop[2] === undefined && muiBackdrop[1]) {          
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: newApp,
            appCount: 1
          }));        
        }else if(muiBackdrop[2] === undefined && muiBackdrop[1] === undefined) {        
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: [],
            appCount: 0,
          }));        
        }
        appsInWindows('load');      
      },100);
    }  

    goToGetConfig();

  },[config.maxAge])

  return (
    <>
      <Avatar title="Account" component="span" variant="rounded" alt="Account" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/account.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={handleClickOpen} />
      <Typography title="Account" variant="subtitle1" component="span" style={{ whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>Account</Typography>
      <Dialog id="accountDialogBox" fullScreen onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle 
          style={{
            padding: '8px 16px',
            color:'#ffffff', 
            background:'linear-gradient(90deg, rgba(38,93,140,1) 0%, rgba(38,93,140,1) 15%, rgba(0,140,183,1) 100%)',
          }}
          id="customized-dialog-title" onClose={handleClose}
        >
          <Avatar component="span" variant="rounded" style={{width:'24px', height: '24px', display: 'inline-block', marginRight: '8px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/account.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
          <span>{(windowWidth < titleDisplaySize && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) !== 0) || (windowWidth < (titleDisplaySize/2) && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) === 0) ? '' : 'Account'}</span>  
          <IconButton            
            aria-label="AppsInWindowsIcon"
            onClick={() => appsInWindows('change')}
            size="small"
            style={{
              background: themeHlp().palette.blueGrey.dark,
              color:'white',          
              margin:'2px 42px 0 0',
              padding:'2px',
              position: 'absolute',
              right: '0px',
              opacity: document.getElementById('shutdownDialog') ? '1' : '0.3'
            }}
          >
            <BorderInnerIcon style={{padding: '2px'}} />          
          </IconButton>
          <IconButton 
            aria-label="UnfoldLessMoreIcon"
            title={!toolbarHidden ? 'Hide Toolbar' : 'Show Toolbar'}
            onClick={()=> setToolbarHidden(current => !current)}
            size="small"
            style={{
              width: '18px',
              height: '18px',
              background: themeHlp().palette.blueGrey.dark,
              color:'white',  
              margin:'2px 74px 0 0',
              padding:'2px',
              position: 'absolute',
              right: '0px'
            }}
          >
            {!toolbarHidden ? <UnfoldLessIcon style={{padding: '4px'}} /> : <UnfoldMoreIcon style={{padding: '4px'}} />}
          </IconButton>
          <IconButton 
            aria-label="CancelSharpIcon"
            title="Close"
            onClick={handleClose}
            size="small"
            style={{
              background: themeHlp().palette.blueGrey.dark,
              color:'white', 
              margin:'2px 10px 0 0',
              padding:'2px',
              position: 'absolute',
              right: '0px'
            }}
          >
            <CancelSharpIcon style={{padding: '2px'}} /> 
          </IconButton>
        </DialogTitle>
        <DialogContent id="p0" className={classes.dialog_content}>
          <div id="p1" className={classes.root}>
            {!toolbarHidden && 
            <AppBar id="p2" position="static" color="default">
              <Tabs id="p3"
                className={classes.tab_group}
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                aria-label="scrollable force tabs example"
              >
                <Tab label="User" icon={<Avatar component="span" variant="rounded" style={{  color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_user.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />} {...a11yProps(2)}  />
                <Tab label="Settings" icon={<Avatar component="span" variant="rounded" style={{  color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_settings.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />} {...a11yProps(3)}  />
                <Tab label="Device Info" icon={<Avatar component="span" variant="rounded" style={{  color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_device_info.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />} {...a11yProps(4)} onClick={ goToGetDeviceInfo } />
              </Tabs>
            </AppBar>
            }
            <TabPanel value={value} index={0} className={classes.tab_panel_content}>
              <Box >
                <Grid container align="center" style={{ marginTop:'10px'}}>
                  <Grid container spacing={0} align = "center" className={classes.login_box}>
                    <Grid item xs={12} sm={4}>
                      <Box className={classes.account_info_key}>{windowWidth < dividedWindowSize && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) !== 0 ? 'A. Id:' : 'Account Id:'}</Box>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <TextField
                        type="text"
                        size="small"
                        disabled
                        variant="standard"
                        className={classes.disabled_text_box}
                        InputProps={{style: {fontSize: '0.875rem'}}}
                        value={accountData.account_id}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box className={classes.account_info_key}>{windowWidth < dividedWindowSize && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) !== 0 ? 'F.N.:' : 'First Name:'}</Box>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <TextField                        
                        type="text"
                        size="small"
                        variant="standard"
                        className={classes.text_box}
                        InputProps={{style: {fontSize: '0.875rem'}}}
                        value={firstName}                        
                        onChange={(e) => {setFirstName(e.target.value); goToSetAccountData({firstName: e.target.value, lastName: lastName})}}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box className={classes.account_info_key}>{windowWidth < dividedWindowSize && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) !== 0 ? 'L.N.:' : 'Last Name:'}</Box>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <TextField                        
                        type="text"
                        size="medium"
                        variant="standard"
                        className={classes.text_box}
                        InputProps={{style: {fontSize: '0.875rem'}}}
                        value={lastName}
                        onChange={(e) => {setLastName(e.target.value); goToSetAccountData({firstName: firstName, lastName: e.target.value})}}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box className={classes.account_info_key}>{windowWidth < dividedWindowSize && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) !== 0 ? 'Lgn:' : 'Login:'}</Box>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <TextField                        
                        type="text"
                        size="small"
                        disabled
                        variant="standard"
                        className={classes.disabled_text_box}
                        InputProps={{style: {fontSize: '0.875rem'}}}
                        value={accountData.account_email}
                      />                      
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box className={classes.account_info_key} style={{border: 0}}>{windowWidth < dividedWindowSize && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) !== 0 ? 'C.P.:' : 'Change Password:'}</Box>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Box className={classes.account_info_value} style={{padding: 0, border: 0}}>
                        { (passwordResetLinkSent === 1) ?
                        <Card className={classes.error_message} style={{backgroundColor: themeHlp().palette.success.dark}}>
                          <Typography variant="inherit" component="div" align="center">
                            {successMessage}
                          </Typography>
                        </Card>
                        :
                        <Button
                          className={classes.button_witout_icon}
                          variant="contained"
                          onClick={gotoSendPasswordResetLnk}
                        >
                          Send Password Reset Link
                        </Button>
                        }
                      </Box>
                    </Grid>                    
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tab_panel_content}>              
              <Box style={{padding: 0}}>
                <Grid container align="center">
                  <Grid container spacing={0} align = "left">
                    <Grid item xs={12} >
                      <Box sx={{ width: '100%', padding: 0, margin: 0 }}>                        
                        <Tabs
                          orientation="horizontal"
                          variant="scrollable"
                          value={settingsValue}
                          onChange={handleSettingsChange}
                          aria-label="horizontal tabs"                          
                          sx={{ borderRight: 1, borderColor: 'divider' }}                          
                        >
                          <Tab label="Storage" {...a11ySettingsProps(0)} className={classes.settings_tab} />
                          <Tab label="Session" {...a11ySettingsProps(1)} className={classes.settings_tab} />
                        </Tabs>                      
                        <TabSettingsPanel value={settingsValue} index={0} style={{width: '100%', minWidth: '192px'}}>
                          <Box>
                            <Typography variant="subtitle1" component="span" style={{fontSize: '14px', margin: '0 0 4px 4px'}}>
                              Disk Managment
                            </Typography>
                          </Box>
                          <Box style={{ border: (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) === 0 ? `${'2px solid ' + themeHlp().palette.primary.light}` : '0px', backgroundColor: (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) === 0 ? themeHlp().palette.fogGrey.main : 'transparent', borderRadius: '15px',  padding: '12px 20px'}}>
                            <Grid container align = "center" >
                              <Grid container spacing={0} align = "center" >
                                <Grid item xs={12} >
                                  <div className={classes.pie_chart}
                                    style={{
                                      width: `${(windowWidth - 200)/2}px`,
                                      height: `${(windowWidth - 200)/2}px`,
                                      background: `conic-gradient(
                                        from 0deg,
                                        ${themeHlp().palette.primary.dark} 0deg ${parseInt((360/config.storageCapacity) * config.usedSpace)}deg,
                                        ${themeHlp().palette.secondary.main} ${parseInt((360/config.storageCapacity) * config.usedSpace)}deg 360deg
                                      )`
                                    }}
                                  ></div>
                                  <div className={classes.pie_chart_text} style={{ width: `${(windowWidth - 200)/2}px`}}>
                                    <strong style={{backgroundColor: `${themeHlp().palette.primary.dark}`, color: `${themeHlp().palette.whiteText.main}`}}>Used Space: {(config.usedSpace / 1000000).toFixed(1)}MB  ({((((360/config.storageCapacity) * config.usedSpace) * 100) / 360).toFixed(1)}%)</strong>
                                    <strong style={{backgroundColor: `${themeHlp().palette.secondary.main}`, color: `${themeHlp().palette.blackText.main}`}}>Free Space: {(config.freeSpace / 1000000).toFixed(1)}MB ({((((360/config.storageCapacity) * config.freeSpace) * 100) / 360).toFixed(1)}%)</strong>
                                    <strong style={{backgroundColor: `${themeHlp().palette.greyText.main}`, color: `${themeHlp().palette.whiteText.main}`}}>Storage Capacity: {config.storageCapacity / 1000000}MB</strong>
                                    <strong style={{backgroundColor: `${themeHlp().palette.greyText.light}`, color: `${themeHlp().palette.whiteText.main}`}}>Maximum Upload Size: {config.maxUploadSize / 1000000}MB</strong>
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>
                        </TabSettingsPanel>
                        <TabSettingsPanel value={settingsValue} index={1} style={{width: '100%'}}>
                          <Box>
                            <Typography variant="subtitle1" component="span" style={{fontSize: '14px', margin: '0 0 4px 4px'}}>
                             Session Time
                            </Typography>
                          </Box>
                          <Box style={{  border: `${'2px solid ' + themeHlp().palette.primary.light}`, backgroundColor: themeHlp().palette.fogGrey.main, borderRadius: '15px',  padding: '12px 20px', minWidth: '120px'}}>
                            <Grid container align = "center">
                              <Grid container spacing={0} align = "center">
                                {sessionTimes.map(({duration, timestamp}, key) => (
                                  <Grid key={key} item xs={12} sm={6} mg={4} lg={3} xl={2}>
                                    <Button
                                      disabled={config.maxAge === timestamp ? true : false}
                                      title={duration}
                                      key={key}
                                      className={classes.button_witout_icon}
                                      style={{ backgroundColor: config.maxAge === timestamp && themeHlp().palette.success.dark}}
                                      variant="contained"
                                      onClick={() => goToSetConfig(timestamp)}
                                    >
                                      {duration}
                                    </Button>
                                  </Grid>
                                ))}
                              </Grid>
                            </Grid>
                          </Box>


                          <Box mt={2}>
                            <Typography variant="subtitle1" component="span" style={{fontSize: '14px', margin: '0 0 4px 4px'}}>
                              Clear All Sessions
                            </Typography>
                          </Box>
                          <Box style={{  border: `${'2px solid ' + themeHlp().palette.primary.light}`, backgroundColor: themeHlp().palette.fogGrey.main, borderRadius: '15px',  padding: '12px 20px', minWidth: '120px'}}>                            
                            <Grid container align = "center">
                              <Grid container spacing={0} align = "center">
                                <Grid item xs={12} sm={6} mg={4} lg={3} xl={2}>
                                  <Button
                                    title="Clear Except The Current One"
                                    className={classes.button_witout_icon}                                    
                                    variant="contained"
                                    onClick={() => logOut('allexceptcurrent')}
                                  >
                                    Except This
                                  </Button>
                                </Grid>
                                <Grid item xs={12} sm={6} mg={4} lg={3} xl={2}>
                                  <Button
                                    title="Clear Including The Current One"
                                    className={classes.button_witout_icon}                                    
                                    variant="contained"
                                    onClick={() => logOut('allincludingcurrent')}
                                  >
                                    Including This
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>



                        </TabSettingsPanel>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={2} className={classes.tab_panel_content}>
              <Box>
                <Grid container align = "center" style={{ marginTop:'10px'}}>
                  <Grid container spacing={0} align = "center" className={classes.login_box}>
                    <Grid item xs={12} sm={4}>
                      <Box className={classes.device_info_key}>{windowWidth < dividedWindowSize && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) !== 0 ? 'U. A.:' : 'User Agent:'}</Box>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Box className={classes.device_info_value}>{ deviceInfo[0]?.userAgent}</Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box className={classes.device_info_key}>{windowWidth < dividedWindowSize && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) !== 0 ? 'G. C. N.:' : 'Graphics Card Name:'}</Box>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Box className={classes.device_info_value}>{ deviceInfo[0]?.graphicsCard}</Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box className={classes.device_info_key}>{windowWidth < dividedWindowSize && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) !== 0 ? 'NoC:' : 'Number of Cores:'}</Box>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Box className={classes.device_info_value}>{ deviceInfo[0]?.coresNumber}</Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box className={classes.device_info_key}>{windowWidth < dividedWindowSize && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) !== 0 ? 'IP A.:' : 'IP Address (IPv4):'}</Box>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Box className={classes.device_info_value}>{ deviceInfo[0]?.ipAddress}</Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
          </div>
        </DialogContent>
          <DialogActions></DialogActions>
      </Dialog>
    </>
  );
}
 
export default AccountApp;
