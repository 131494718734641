import axios from 'axios'

export const uploadfilesSrv = (param) => {
    
    const promise = axios({
      url: process.env.REACT_APP_URL_SERVERPORT + '/api/user/uploadfiles/',
      method: 'POST',
      data: param,
      withCredentials: true,
    })
  
    const dataPromise = promise.then((response) => response.data)

    return dataPromise
}
