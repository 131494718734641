export const addbookmarknotesSrv = (param) => {
  async function addbookmarknotesService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/user/addbookmarknotes', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        topic_id: param.topic_id,
        notebook: param.bookmark_note,
        page: param.bookmark_page,
        state: param.bookmark_state
      }),
      credentials: "include", // include, *same-origin, omit
    });
     return await rawResponse.json();
  };

  return addbookmarknotesService()
}
