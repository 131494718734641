export const getAccountDataSrv = () => {
  async function getAccountDataService() {
    const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/api/user/account', {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: "include", // include, *same-origin, omit
  });
   return await rawResponse.json();
};

return getAccountDataService()
}
