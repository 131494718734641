export const compressfilesSrv = (param) => {

  async function compressfilesService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/api/user/compressfiles/', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    //  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'include', // include, *same-origin, omit
      body: JSON.stringify({
        item_id: param.item_id
      })
    });
     return await rawResponse.json();
  };

  return compressfilesService()
}
