import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import {useNavigate} from 'react-router-dom';
//import Peer from 'simple-peer';
import Peer from 'peerjs';
import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import md5 from 'blueimp-md5';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import CancelSharpIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Slider from '@material-ui/core/Slider';
import VolumeUp from '@material-ui/icons/VolumeUp';
import DirectionsIcon from '@material-ui/icons/Directions';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg';
import CallEndIcon from '@material-ui/icons/CallEnd';
import RefreshIcon from '@material-ui/icons/Refresh';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import BorderInnerIcon from '@material-ui/icons/BorderInner';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import SearchIcon from '@material-ui/icons/Search';
import CancelPresentation from '@material-ui/icons/CancelPresentation';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { makeStyles } from '@material-ui/core/styles';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { getAccountDataSrv } from '../Service/GetAccountDataService.js';
import { searchusermessageSrv } from '../AdtService/SearchUserMessageService.js';
import { getusermessageSrv } from '../AdtService/GetUserMessageService.js';
import { sendmessageSrv } from '../AdtService/SendMessageService.js';
import { removemessageSrv } from '../AdtService/RemoveMessageService.js';
import { removeusermessageSrv } from '../AdtService/RemoveUserMessageService.js';
import { sendusercallrequestSrv } from '../AdtService/SendUserCallRequestService.js';
import { getusercallrequestSrv } from '../AdtService/GetUserCallRequestService.js';
import { removeusercallrequestSrv } from '../AdtService/RemoveUserCallRequestService.js';
import { getlastvisitSrv } from '../AdtService/GetLastVisitService.js';
import { indicatelastvisitSrv } from '../AdtService/IndicateLastVisitService.js';

import { connectroomchatSrv } from '../AdtService/ConnectRoomChatService.js';
import { disconnectroomchatSrv } from '../AdtService/DisconnectRoomChatService.js';
import { sendtextchatSrv } from '../AdtService/SendTextChatService.js';
import { deletetextchatSrv } from '../AdtService/DeleteTextChatService.js';

import { themeHlp }  from '../Helper/ThemeHelper.js';
import  appResizeWindowHlp  from '../Helper/AppResizeWindowHelper.js';

import '../../App.css';

const useStyles = makeStyles((theme) => ({  
  menu_box:{
    width:'100%', 
    height:'340px', 
    margin:'auto auto', 
    background: 'linear-gradient(135deg, rgb(38, 93, 140) 0%, rgb(38, 93, 140) 15%, rgb(0, 140, 183) 50%)', 
    opacity:'1', 
    borderRadius:'15px', 
    border:'4px solid #f5a33e'
  },
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  user_avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: 'auto',
    cursor: 'pointer'
  },
  dialog_content: {
    height: '400px',
    padding: '0px',    
  },
  tab_group:{
    color: 'black',
    backgroundColor: themeHlp().palette.secondary.light,    
    '& .MuiTab-wrapper': {
      fontSize:'12px',
      textTransform: 'capitalize'
    },   
  },
  text_box: {
    width:'96%',
    margin: '4px 2%',
    borderBottom: '1px solid ' + themeHlp().palette.blackText.light,
    backgroundColor: 'white',
    '&:hover': {      
        border: '1px solid ' + themeHlp().palette.primary.light,
        borderStyle: 'none none solid none',
    }
  },
  textarea_box: {
    width: '96%',
    height: '140px !important',
    margin: '4px 2%',
    boxSizing: 'border-box',    
    border: '0',  
    borderBottom: '1px solid ' + themeHlp().palette.blackText.light,  
    backgroundColor: 'white', 
    fontFamily: 'Helvetica',
    fontSize: '1rem',
    resize: 'none',
    overflow: 'auto !important',
    '&:hover': {      
      border: '1px solid ' + themeHlp().palette.primary.light,
      borderStyle: 'none none solid none',
    },
    '&:focus': {      
      outline: 'none !important'
    }
  },
  text_list: {
    width:'96%',
    margin: '4px 2%',
    //borderBottom: 0 solid ,
    backgroundColor: 'white',
    '&:hover': {              
        borderStyle: 'none',
    }
  },
  emoji_button:{   
    padding: '2px',
    textAlign: 'center',
    fontSize: '24px',
    cursor: 'pointer'
  },
  narrow_button: {
    width:'100%', 
    height: '16px',
    color:'#fff', 
    background: themeHlp().palette.primary.light,
    '&:hover': {
      background: themeHlp().palette.primary.main,
   },
  },
  casual_text: {
    fontFamily: 'Helvetica',
    fontSize: '0.875rem'
  },
  text_icon: {
    width:'20px',
    height:'20px',
    float:'right',
    margin: '1px 4px 0 4px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '20px',
    backgroundPosition: '0 4px 0 0',
    cursor: 'pointer'
  },
  empty_icon: {
    width:'20px',
    height:'20px',
    float:'right',
    margin: '1px 4px 0 4px',
  },
  message_icon: {
    width:'120px',
    height:'20px',
    float:'right',
    margin: '1px 4px 0 4px',
    color: themeHlp().palette.whiteText.main
  },
  iconbutton_connect: {
    width:'36px',
    height: '36px',
    padding: '3px',
    color: themeHlp().palette.primary.light,
    '&:hover' : {
      color: themeHlp().palette.primary.main
    },
    '&:active' : {
      color: themeHlp().palette.primary.dark,    
    }    
  },
  iconbutton_disconnect: {
    width:'36px',
    height: '36px',
    padding: '3px',
    color: themeHlp().palette.error.light,
    '&:hover' : {
      color: themeHlp().palette.error.main
    },
    '&:active' : {
      color: themeHlp().palette.error.dark,    
    }
  },
  iconbutton_reload: {
    width:'24px',
    height: '24px',
    cursor: 'pointer',
    color: themeHlp().palette.primary.light,
    '&:hover' : {
      color: themeHlp().palette.primary.main
    },
    '&:active' : {
      color: themeHlp().palette.primary.dark,    
    }
  },
  search_icon: {
    color: themeHlp().palette.blackText.light
  },
  found_users_data_row: {
    width: "100%",
    height: '30px',
    cursor:'pointer',
    '&:nth-child(even)': {
      backgroundColor: themeHlp().palette.secondary.main,
    },
    '&:nth-child(odd)': {
      backgroundColor: themeHlp().palette.secondary.dark,
    }
  },
  added_users_data_row: {
    width: "100%",
    cursor:'pointer',
    textAlign: 'left',
    paddingLeft: '10px',
    color: themeHlp().palette.secondary.light,
    '&:nth-child(even)': {
      backgroundColor: themeHlp().palette.greyText.light,
    },
    '&:nth-child(odd)': {
      backgroundColor: themeHlp().palette.greyText.main,
    }
  },
  messages_container: {
    '& .MuiTableCell-alignRight': {
      width: '106px !important',
      padding: 0
    }
  },
  chat_group_container: {
    '& .MuiTableCell-alignRight': {
      width: '112px !important',            
      padding: 0
    }
  },
  video_meeting_button: {
    width: '48px',
    height: '48px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '48px',
    backgroundPosition: '0',
    marginTop: '10px',
    display: 'inline-block',
    cursor: 'pointer',
  },
  camera_screenmode_button: {
    width: '30px',
    height: '30px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '30px',
    backgroundPosition: '0',
    display: 'inline-block',
    cursor: 'pointer',
    zIndex: 100
  },
  camera_volume_slider: {
    position: 'absolute',
    top: '16px', 
    left: '24px',
    color: themeHlp().palette.primary.light,
    width: '50px', 
    height: '16px',   
    margin: '0 20px 0 0',
    padding: '14px 0 0 0',
  },
  camera_volume_icon: {
    position: 'absolute',
    top: '16px', 
    left: 0, 
    color: themeHlp().palette.primary.light, 
    margin: '3px 2px 3px 0', 
    padding: '0px 4px',
  },
  camera_volume_label: {
    position: 'absolute',
    top: 0, 
    left: 0, 
    fontSize: '9px', 
    padding: '4px 0 0 4px'
  },
  video_meeting_panel:{
    '& .MuiBox-root': {      
      padding: '4px 0 0 0'
    }
  },
  users_list_container:{
    width: '100%'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
         {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

/*EmojiTabPanel*/ 
function EmojiTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`emoji-tabpanel-${index}`}
      aria-labelledby={`emoji-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

EmojiTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function EmojiA11yProps(index) {
  return {
    id: `emoji-tab-${index}`,
    'aria-controls': `emoji-tabpanel-${index}`,
  };
}
/*EmojiTabPanel END*/

let chatInterval = {};
let screenStream = {};

const ContactApp = (props) => {
  const classes = useStyles();  
  let navigate = useNavigate();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [headerMenuHidden, setHeaderMenuHidden] = useState(true);
  const [toolbarHidden, setToolbarHidden] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = useState(0);  
  const [accountData, setAccountData] = useState({});

  ////////////////////message/////////////////////
  let debounceSearching;
  const [usersData, setUsersData] = useState([]);
  const [usersMessageList, setUsersMessageList] = useState([]);
  const [usersNotificationList, setUsersNotificationList] = useState([]);
  const [inboxMessageList, setInboxMessageList] = useState([]);
  const [sentMessageList, setSentMessageList] = useState([]);
  const [selectedUserRow, setSelectedUserRow] = useState('');
  const [selectedUserMessage, setSelectedUserMessage] = useState({});
  const [message, setMessage] = useState('');
  const [lastVisit, setLastVisit] = useState('');
  const [lastSeen, setLastSeen] = useState('');
  let clickAttempts = 1;
  ////////////////////message END/////////////////////

  ////////////////////chat group/////////////////////
  const [chatText, setChatText] = useState('');
  const [chatRoom, setChatRoom] = useState('');
  const [roomPassword, setRoomPassword] = useState('');
  const [roomIdDisabled, setRoomIdDisabled] = useState(false);  
  const [hintMessage, setHintMessage] = useState('');
  const [hintMessageColor, setHintMessageColor] = useState(themeHlp().palette.primary.light);
  const [chatPart, setChatPart] = useState(false);
  const [refreshRoomVisibled, setRefreshRoomVisibled] = useState(false);
  const [emojiTabValue, setEmojiTabValue] = useState(0);
  const emojis = ['0x1F600', '0x1F603', '0x1F604', '0x1F601', '0x1F606', '0x1F605', '0x1F923', '0x1F602', '0x1F642', '0x1F643', '0x1FAE0', '0x1F609', '0x1F60A', '0x1F607',
                  '0x1F970', '0x1F60D', '0x1F929', '0x1F618', '0x1F617', '0x1F61A', '0x1F619', '0x1F972', '0x1F60B', '0x1F61B', '0x1F61C', '0x1F92A', '0x1F61D', '0x1F911', 
                  '0x1F917', '0x1F92D', '0x1FAE2', '0x1FAE3', '0x1F92B', '0x1F914', '0x1FAE1', '0x1F910', '0x1F928', '0x1F610', '0x1F611', '0x1F636', '0x1FAE5', '0x1F636',
                  '0x1F60F', '0x1F612', '0x1F644', '0x1F62C', '0x1F62E', '0x1F925', '0x1F60C', '0x1F614', '0x1F62A', '0x1F924', '0x1F634', '0x1F637', '0x1F912', '0x1F915', 
                  '0x1F922', '0x1F92E', '0x1F927', '0x1F975', '0x1F976', '0x1F974', '0x1F635', '0x1F635', '0x1F92F', '0x1F920', '0x1F973', '0x1F978', '0x1F60E', '0x1F913',
                  '0x1F9D0', '0x1F615', '0x1FAE4', '0x1F61F', '0x1F641', '0x1F62E', '0x1F62F', '0x1F632', '0x1F633', '0x1F97A', '0x1F979', '0x1F625', '0x1F622', '0x1F62D',
                  '0x1F61E', '0x1F971', '0x1F624', '0x1F621', '0x1F620', 
                 ];
  let callChatLimit = 1;
  let connectedUsersChat = [];
  const [connectedUsersChatList, setConnectedUsersChatList] = useState([]);
  let usersChat = [];
  const [usersChatText, setUsersChatText] = useState([]);
  const [chatTextOnlyFor, setChatTextOnlyFor] = useState('');
  ////////////////////chat group END/////////////////////

  ////////////////////Video meeting/////////////////////
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [callStream, setCallStream]= useState(false);
	const [callEnded, setCallEnded] = useState(2);
  const [peerId, setPeerId] = useState(null);
  const [remotePeerIdValue, setRemotePeerIdValue] = useState('');
  const [demoMode, setDemoMode] = useState('camera');
  const [cameraScreenMode, setCameraScreenMode] = useState(1);
  const [cameraScreenMenuPosition, setCameraScreenMenuPosition] = useState(1);
  const [myVideoWidth, setMyVideoWidth] = useState(null);
  const [myVideoHeight, setMyVideoHeight] = useState(null);
  const [myVideoPositions, setMyVideoPositions] = useState({position: 'relative', top: 0, left: 0, zIndex: 91, opacity: 1});
  const [userVideoWidth, setUserVideoWidth] = useState(null);
  const [userVideoHeight, setUserVideoHeight] = useState(null);
  const [userVideoPositions, setUserVideoPositions] = useState({position: 'relative', top: 0, left: 0, zIndex: 92, opacity: 1});
  const [usersForMeeting, setUsersForMeeting] = useState([]);
  const [calledUser, setCalledUser] = useState('');
  const [partnerEmail, setPartnerEmail] = useState('');
  const cameraMaxHeight = 600;
  const titleDisplaySize = 480;
  let debounceMyCameraSize;

  const peerInstance = useRef();
	const myVideo = useRef();
	const userVideo = useRef();
  const [newPeerIdStatus, setNewPeerIdStatus] = useState(false);


  const [
    cameraScreenMenuAnchorEl,
    setCameraScreenMenuAnchorEl
  ] = React.useState(null);
  const cameraScreenMenuOpen = Boolean(cameraScreenMenuAnchorEl);
  const cameraScreenMenuClick = (event) => {
    setCameraScreenMenuAnchorEl(event.currentTarget);
  };
  const cameraScreenMenuClose = () => {
    setCameraScreenMenuAnchorEl(null);
  };

  const cameraScreenMenuPositionFnc = (key) => {
    switch(key){
      case 1:
        if(cameraScreenMode === 2){
          setMyVideoPositions({position: 'absolute', top: 0, left: 0, zIndex: 92, opacity: 1});
        }else if(cameraScreenMode === 3){
          setUserVideoPositions({position: 'absolute', top: 0, left: 0, zIndex: 92, opacity: 1});
        }        
        break;
      case 2:
        if(cameraScreenMode === 2){          
          setMyVideoPositions({position: 'absolute', top: 0, left: (userVideoWidth * 80) / 100, zIndex: 92, opacity: 1});
        }else if(cameraScreenMode === 3){
          setUserVideoPositions({position: 'absolute', top: 0, left: (myVideoWidth * 80) / 100, zIndex: 92, opacity: 1});
        }
        break;
      case 3:
        if(cameraScreenMode === 2){          
          setMyVideoPositions({position: 'absolute', top: userVideoHeight - myVideoHeight, left: 0, zIndex: 92, opacity: 1});
        }else if(cameraScreenMode === 3){
          setUserVideoPositions({position: 'absolute', top: myVideoHeight - userVideoHeight, left: 0, zIndex: 92, opacity: 1});
        }
        break;
      case 4:
        if(cameraScreenMode === 2){          
          setMyVideoPositions({position: 'absolute', top: userVideoHeight - myVideoHeight, left: (userVideoWidth * 80) / 100, zIndex: 92, opacity: 1});
        }else if(cameraScreenMode === 3){
          setUserVideoPositions({position: 'absolute', top: myVideoHeight - userVideoHeight, left: (myVideoWidth * 80) / 100, zIndex: 92, opacity: 1});
        }
        break;
      case 5:
        if(cameraScreenMode === 2){          
          setMyVideoPositions({position: 'absolute', top: 0, left: 0, zIndex: 92, opacity: 0});
        }else if(cameraScreenMode === 3){
          setUserVideoPositions({position: 'absolute', top: 0, left: 0, zIndex: 92, opacity: 0});
        }
        break;
      default:
        break;
    }
    setCameraScreenMenuPosition(key);
    setCameraScreenMenuAnchorEl(null);
  };

  const cameraScreenOptions = [
    <Avatar component="span" variant="square" alt="Contact camera top left" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/contact/contact_camera_top_left.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />,
    <Avatar component="span" variant="square" alt="Contact camera top right" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/contact/contact_camera_top_right.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />,
    <Avatar component="span" variant="square" alt="Contact camera bottom left" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/contact/contact_camera_bottom_left.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />,
    <Avatar component="span" variant="square" alt="Contact camera bottom right" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/contact/contact_camera_bottom_right.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />,
    <Avatar component="span" variant="square" alt="Contact camera hidden" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/contact/contact_camera_hidden.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />,
  ];
  ////////////////////Video meeting END/////////////////////

  const tabMessageFnc = () => {
    turnOffCamera();
  }

  const tabChatgroupFnc = () => {
    turnOffCamera();
  }

  const tabVideomeetingFnc = () => {    
    clearInterval(chatInterval); 
    setChatPart(false);
    turnOnCamera(); 
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  const handleClickOpen = () => {
    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;

    if(allApps.indexOf('contact') === -1 && JSON.parse(localStorage.getItem('movwin')).appCount <2){
      allApps.push('contact');
      setOpen(true);
      
      localStorage.setItem('movwin', JSON.stringify({
        windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
        apps: allApps,
        appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount + 1
      }));

      setTimeout(() => {
        appsInWindows('load');
      },100);
    }
  };
  
  const handleClose = () => {
    peerInstance.current.destroy();    
    setValue(0);
    turnOffCamera();
    setOpen(false);
    setNewPeerIdStatus(true);
    clearInterval(chatInterval);
    setChatPart(false);

    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;
    if(allApps.indexOf('contact') !== -1){
      allApps.splice(allApps.indexOf('contact'), 1);
    }

    localStorage.setItem('movwin', JSON.stringify({
      windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
      apps: allApps,
      appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount - 1
    }));
  };

  const appsInWindows = (event) => {
    if(event === 'change'){
      if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).windowMode < 4){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode + 1,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount
        }));
      }else if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).windowMode === 4){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: 0,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount
        }));
      }

      if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).appCount === 0){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: 1
        }));
      }
    }

    setWindowWidth(windowWidth - 1);
    appResizeWindowHlp('contactDialogBox');
  }

/*Message Part*/

  
  

  const selectUserMessage = (param) => {
    clickAttempts++;

    if(clickAttempts === 2){
      setSelectedUserRow(param.email);
      setSelectedUserMessage(param);
      goToGetUserMessageService(param.user_id);
      goToGetLastVisitService(param.email);

      setTimeout(() => {
        clickAttempts = 1;
      }, 1200);
    }
    
  }

  const goToGetUserMessageService = (user_id) => {
    getusermessageSrv(user_id).then(response => {
      if(user_id === 'all'){
        const messageToGroup = [];
        const messageFromGroup = [];
        setUsersMessageList([]);
        setUsersNotificationList([]);
        setUsersForMeeting([]);


        for(let i=0; i<response.data.length; i++){
          if(response.data[i].messageTo){
            messageToGroup.push(response.data[i].messageTo);

            setUsersMessageList(oldUsersMessageList => [...oldUsersMessageList,
              <li key={i+1} className={classes.added_users_data_row}>         
                {clickAttempts === 1  &&   
                  <><div title="Remove User" className={classes.text_icon} style ={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/delete.png)'}} onClick={() => removePersonMessage(response.data[i].messageTo)}></div>
                    <div style={{whiteSpace: 'nowrap'}} onClick={() =>  selectUserMessage({email: response.data[i].email, user_id: response.data[i].messageTo})}>
                    <Typography style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: selectedUserRow === response.data[i].email ? 'bold' : 'normal', color: selectedUserRow === response.data[i].email ? 'white': themeHlp().palette.secondary.light, textShadow: selectedUserRow === response.data[i].email ? '0px 0px 2px ' + themeHlp().palette.secondary.main : 'unset'}}>{response.data[i].email} </Typography>              
                </div></>
                }
              </li> 
            ]);            
            setUsersForMeeting(oldUsersMessageList => [...oldUsersMessageList, response.data[i]]);
          }else if(response.data[i].messageFrom){
            messageFromGroup.push(response.data[i]);
            if(messageFromGroup[i].newMessageCount > 0){
              setUsersNotificationList(oldUsersNotificationList => [...oldUsersNotificationList,
                <Chip key={i+1} title={messageFromGroup[i].messageFrom.email} label={messageFromGroup[i].newMessageCount} size="small" style={{background: themeHlp().palette.error.main, color: themeHlp().palette.secondary.light, height:'18px'}} onClick={(e) => {
                  if(parseInt(e.target.innerHTML) < 1000){
                    e.target.innerHTML = messageFromGroup[i].messageFrom.email + ' (' + messageFromGroup[i].newMessageCount + ')'
                  }else{
                    e.target.innerHTML = messageFromGroup[i].newMessageCount
                  }
                  }} 
                />
              ]);
            }
          }
        }      
        for(let j=0; j<messageFromGroup.length; j++){
          if(!messageToGroup.includes(messageFromGroup[j].messageFrom._id)){
            setUsersMessageList(oldUsersMessageList => [...oldUsersMessageList,
              <li key={j+1} className={classes.added_users_data_row}>            
                <div className={classes.text_icon} style ={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/connected_user.png)'}}></div>
                {messageToGroup.length !== 0 && <div style={{whiteSpace: 'nowrap'}} onClick={() => selectUserMessage({email: messageFromGroup[j].messageFrom.email, user_id: messageFromGroup[j].messageFrom._id})}>
                  <Typography style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{messageFromGroup[j].messageFrom.email}</Typography>              
                </div>}
              </li>
            ]);            
          }
        }


      }else if(user_id.length === 24){
        setSentMessageList([]);
        setInboxMessageList([]);          
        for(let i=0; i<response.data.length; i++){
          if(response.data[i].messageTo === user_id){
            setSentMessageList(oldSentMessageList => [...oldSentMessageList,
              <TableContainer key={i+1} className={classes.messages_container}>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell align="left"  style={{color: themeHlp().palette.primary.main, padding: '2px'}} >
                        {response.data[i].message}
                      </TableCell>
                      <TableCell align="right">
                        <span title="Remove Message" className={classes.text_icon} style ={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/delete.png)'}} onClick={() => removeMessage({id: response.data[i]._id, user_id: response.data[i].messageTo})}></span>
                        <span>
                          {new Date(response.data[i].date).toLocaleString('en-US', {timeZone: timeZone,
                            dateStyle: 'short',//full, long, medium, short
                            timeStyle: 'medium', //full, long, medium, short
                            hourCycle: 'h12', //h12, h24
                          })}
                        </span>
                      </TableCell>
                    </TableRow>       
                  </TableBody>
                </Table>
              </TableContainer>
            ]);
          }else{
            setInboxMessageList(oldInboxMessageList => [...oldInboxMessageList,
              <TableContainer key={i+1} className={classes.messages_container} >
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell align="left"  style={{color: themeHlp().palette.primary.main, padding: '2px'}} >
                        {response.data[i].message}
                      </TableCell>
                      <TableCell align="right">                        
                        <span>
                          {new Date(response.data[i].date).toLocaleString('en-US', {timeZone: timeZone,
                            dateStyle: 'short',//full, long, medium, short
                            timeStyle: 'medium', //full, long, medium, short
                            hourCycle: 'h12', //h12, h24
                          })}
                        </span>
                      </TableCell>
                    </TableRow>       
                  </TableBody>
                </Table>
              </TableContainer>
            ]);
          }
        }
      }      
    }).catch(err => {
      console.log(err);
    })
  }

  const goToGetLastVisitService = (email) => {
    const getlastvisitParams = {
      user: email
    }
    getlastvisitSrv(getlastvisitParams)
    .then(response => {
      if(response && response.statusCode === 200){
       setLastVisit(response.data.lastVisit);
       setLastSeen(response.data.lastSeen);
      }
    }).catch(err => {
      console.log(err);
    })
  }

  const goToIndicateLastVisitService = () => {
    const indicatelastvisitParams = {
      user: selectedUserRow
    }
    indicatelastvisitSrv(indicatelastvisitParams)    
    .then(response => {     
    }).catch(err => {
      console.log(err);
    })
  }

  const userSearch = (event) => {
    if(event.target.value !== ''){
      if(debounceSearching !== undefined){
        clearTimeout(debounceSearching);
      }
      debounceSearching = setTimeout(function() {
        searchUser(event);
      },800);    
    }else {
      setUsersData([]);
    }
  }

  const addPersonMessage = (sendmessageParams) => {
    sendmessageSrv(sendmessageParams)    
    .then(response => {
      goToGetUserMessageService('all');
    }).catch(err => {
      console.log(err);
    })
  }

  const removePersonMessage = (user_id) => {
    removeusermessageSrv(user_id)    
    .then(response => {
      if(response  && response.statusCode === 200){
        goToGetUserMessageService('all');
      }     
    }).catch(err => {
      console.log(err);
    })
  }

  const removeMessage = (param) => {
    removemessageSrv(param.id)  
    .then(response => {
      goToGetUserMessageService(param.user_id);
    }).catch(err => {
      console.log(err);
    })
  }

  const sendMessage = () => {
    const sendmessageParams = {
      message_to: selectedUserMessage.user_id,
      email: selectedUserMessage.email,
      message: message
    }
  
    sendmessageSrv(sendmessageParams)    
    .then(response => {
      setMessage('');
      goToGetUserMessageService(selectedUserMessage.user_id);
    }).catch(err => {
      console.log(err);
    })
  }

  const searchUser = (event) => {
    const searchusermessageParams = {
      keyword: event.target.value
    }

    searchusermessageSrv(searchusermessageParams)
    .then(response => {     
      setUsersData([]);
          
      for(let i=0; i<response.users_data.length; i++){
        setUsersData(oldUsersData => [...oldUsersData,
          <li key={i+1} className={classes.found_users_data_row}>
            <div>            
              <div title="Add">
                <PersonAddIcon style ={{width:'30px', float:'right', marginTop: '2px', marginLeft: '10px'}} onClick={() => addPersonMessage({message_to: response.users_data[i]._id, email: response.users_data[i].email})} />          
              </div>
              <div style={{whiteSpace: 'nowrap'}}>
                <Typography title={response.users_data[i].email} style={{ fontWeight:'bold', paddingLeft: '4px', color: themeHlp().palette.primary.dark, lineHeight: '2', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                  {response.users_data[i].firstName + ' ' + response.users_data[i].lastName + ' (' + response.users_data[i].email + ')'}
                </Typography>                
              </div>            
            </div>
          </li>
        ]);
      }
    }).catch(err => {
      console.log(err)
      navigate('/login');
    })
  }
/*Message Part END*/


/*Chat Group Part*/
  function plain2html(text){
    text = (text || "");
    return text
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/\t/g, "    ")
      .replace(/ /g, "&#8203;&nbsp;&#8203;")
      .replace(/\r\n|\r|\n/g, "<br />");
  }
/*Compare two arrays of objects*/
  const compareChatLiveUsers = (
    oldArray,
    newArray
  ) => {
    return (
      oldArray.length === newArray.length &&
      oldArray.every((el1) =>
        newArray.some((el2) => el1.room === el2.room && el1.user === el2.user)
      )
    );
  };

  const compareChatMessages = (
    oldArray,
    newArray
  ) => {
    return (
      oldArray.length === newArray.length &&
      oldArray.every((el1) =>
        newArray.some((el2) => el1.room === el2.room && el1.message === el2.message)
      )
    );
  };
/*Compare two arrays of objects END*/
  const EmojiHandleChange = (event, newEmojiTabValue) => {
    setEmojiTabValue(newEmojiTabValue);
  };

  const connectRoom = () => {
      const connectroomchatParams = {
        room: chatRoom.trim(),
        password: roomPassword.trim(),
        user: accountData.account_email
      }
      connectroomchatSrv(connectroomchatParams)
      .then(response => {       
        if(response && (response.statusCode === 200 || response.statusCode === 201)){
          setChatPart(true);
          setHintMessage('');
          setRoomIdDisabled(true);
          if(compareChatLiveUsers(connectedUsersChat, response.data[0].dataLiveUsers) === false ){
            connectedUsersChat = response.data[0].dataLiveUsers;            
            setConnectedUsersChatList(connectedUsersChat);
          }
          if(compareChatMessages(usersChat, response.data[0].dataMessages) === false){  
            usersChat = response.data[0].dataMessages;
            setUsersChatText(usersChat);
          }        
        }else if(response.error){
          setHintMessageColor(themeHlp().palette.error.main);
          setHintMessage(response.error.message);
        }        
      }).catch(err => {
        console.log(err);
      })
  }

  const disconnectRoom = async() => {
    const disconnectroomchatParams = {
      room: chatRoom.trim(),
    }
    disconnectroomchatSrv(disconnectroomchatParams)
    .then(response => {
      if(response && (response.statusCode === 200 || response.statusCode === 201)){
        setChatPart(false);
        setRoomIdDisabled(false);
        clearInterval(chatInterval);        
      }      
    }).catch(err => {
      console.log(err);
    })
  }

  const sendText = async () => {
    const sendtextchatParams = {
      room: chatRoom.trim(),
      password: roomPassword,
      from: accountData.account_email,
      to: chatTextOnlyFor,
      message: chatText
    }
    sendtextchatSrv(sendtextchatParams)
    .then(response => {
      if(response && (response.statusCode === 201)){
        setUsersChatText(response.data[0].dataMessages);
        connectRoom();        
      }else if(response.error){
        console.log(response.error);
      }      
    }).catch(err => {
      console.log(err);
    })
  }

  const deleteText = async(param) => {
    const deletetextchatParams = {
      id: param
    }
    deletetextchatSrv(deletetextchatParams)  
    .then(response => {
      if(response && (response.statusCode === 200)){
        setUsersChatText(response.data[0].dataMessages);
        connectRoom();   
      }else if(response.error){
        console.log(response.error);
      }
    }).catch(err => {
      console.log(err);
    })
  }

/*Chat Group Part END*/


/*Video Meeting Part*/

function turnOnCamera(){
  let getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

  getUserMedia({video: true, audio: true}, function(mediaStream) {
    myVideo.current.srcObject = mediaStream;
    myVideo.current.play();
  }, function(err) {
    console.log('Failed to get local stream' ,err);
  });

  if(remotePeerIdValue && callEnded === false){
    callUser(remotePeerIdValue);
  }
}

function turnOffCamera(){
  if (myVideo.current && myVideo.current.srcObject) {
    myVideo.current.srcObject.getTracks().forEach( (track) => {
      track.stop();
    });
  }

  if (userVideo.current && userVideo.current.srcObject) {
    userVideo.current.srcObject.getTracks().forEach( (track) => {
      track.stop();
    });
  }
}



function cameraScreenModeFnc(val){

  if(debounceMyCameraSize !== undefined){
    if(val === 2){
      setMyVideoPositions({ zIndex: 0, opacity: 0});
    }else if(val === 3){
      setUserVideoPositions({ zIndex: 0, opacity: 0});
    }
    clearTimeout(debounceMyCameraSize);
  }else{
    debounceMyCameraSize = setTimeout(() => {
      let myCamera = document.getElementById('myCamera');
      let myCameraGroup = document.getElementById('myCameraGroup');
      let userCamera = document.getElementById('userCamera');
      let userCameraGroup = document.getElementById('userCameraGroup');

      if(val === 1){
        myCameraGroup.appendChild(myCamera);
        userCameraGroup.appendChild(userCamera);
        setMyVideoPositions({position: 'relative', top: 0, left: 0, zIndex: 91, opacity: 1});
        setUserVideoPositions({position: 'relative', top: 0, left: 0, zIndex: 92, opacity: 1});
        if(myCamera.videoWidth >= myCamera.videoHeight ){
          if(windowWidth / (windowHeight - 170)  >= myCamera.videoWidth/myCamera.videoHeight){
            if(windowWidth-28 <= cameraMaxHeight){
              setMyVideoWidth(`${windowWidth-28}`);
              setMyVideoHeight(`${(windowWidth-28) * (myCamera.videoHeight/myCamera.videoWidth)}`);
              setUserVideoWidth(`${windowWidth-28}`);
              setUserVideoHeight(`${(windowWidth-28) * (myCamera.videoHeight/myCamera.videoWidth)}`);
            }else{
              setMyVideoWidth(`${cameraMaxHeight}`);
              setMyVideoHeight(`${cameraMaxHeight / (myCamera.videoWidth/myCamera.videoHeight)}`);
              setUserVideoWidth(`${cameraMaxHeight}`);
              setUserVideoHeight(`${cameraMaxHeight / (myCamera.videoWidth/myCamera.videoHeight)}`);
            }
          }else{
          //  console.log('no')         
            if(windowWidth-28 <= cameraMaxHeight){
           //   console.log('here')
              setMyVideoWidth(`${windowWidth-28}`);
              setMyVideoHeight(`${(windowWidth-28) * (myCamera.videoHeight/myCamera.videoWidth)}`);
              setUserVideoWidth(`${windowWidth-28}`);
              setUserVideoHeight(`${(windowWidth-28) * (myCamera.videoHeight/myCamera.videoWidth)}`);
            }else{
            //  console.log('there')
              setMyVideoWidth(`${cameraMaxHeight}`);
              setMyVideoHeight(`${cameraMaxHeight / (myCamera.videoWidth/myCamera.videoHeight)}`);
              setUserVideoWidth(`${cameraMaxHeight}`);
              setUserVideoHeight(`${cameraMaxHeight / (myCamera.videoWidth/myCamera.videoHeight)}`);
            }
          }
        }else{
          if((windowHeight - 170) / windowWidth >= myCamera.videoHeight/myCamera.videoWidth){
          //  console.log('uuu');
            setMyVideoWidth(`${windowWidth-28}`);
            setMyVideoHeight(`${(windowWidth-28) * (myCamera.videoHeight/myCamera.videoWidth)}`);
            setUserVideoWidth(`${windowWidth-28}`);
            setUserVideoHeight(`${(windowWidth-28) * (myCamera.videoHeight/myCamera.videoWidth)}`);
          }else{
          //  console.log('look2')
            if(((windowWidth / (myCamera.videoHeight/myCamera.videoWidth))-28) * (myCamera.videoHeight/myCamera.videoWidth) <= cameraMaxHeight){
              setMyVideoWidth(`${(windowWidth / (myCamera.videoHeight/myCamera.videoWidth))-28}`);
              setMyVideoHeight(`${((windowWidth / (myCamera.videoHeight/myCamera.videoWidth))-28) * (myCamera.videoHeight/myCamera.videoWidth)}`);
              setUserVideoWidth(`${(windowWidth / (myCamera.videoHeight/myCamera.videoWidth))-28}`);
              setUserVideoHeight(`${((windowWidth / (myCamera.videoHeight/myCamera.videoWidth))-28) * (myCamera.videoHeight/myCamera.videoWidth)}`);
            }else{
              setMyVideoHeight(`${cameraMaxHeight}`);
              setMyVideoWidth(`${(cameraMaxHeight / (myCamera.videoHeight/myCamera.videoWidth))}`);
              setUserVideoHeight(`${cameraMaxHeight}`);
              setUserVideoWidth(`${(cameraMaxHeight / (myCamera.videoHeight/myCamera.videoWidth))}`);
            }
          }
        }
      }else if(val === 2){
        userCameraGroup.appendChild(userCamera);
        userCameraGroup.appendChild(myCamera);
        setUserVideoPositions({position: 'relative', top: 0, left: 0, zIndex: 91, opacity: 1});

        if(userCamera.videoWidth >= userCamera.videoHeight ){
          if((windowWidth + 170) / windowHeight  >= userCamera.videoWidth/userCamera.videoHeight){
          //  console.log('yes')
            setUserVideoWidth(`${(windowHeight-174) * (userCamera.videoWidth/userCamera.videoHeight)}`);
            setUserVideoHeight(`${(windowHeight-174)}`);
          }else{
         //   console.log('no')
            setUserVideoWidth(`${windowWidth-28}`);
            setUserVideoHeight(`${(windowWidth-28) * (userCamera.videoHeight/userCamera.videoWidth)}`);
          }
        }else{
          if(windowHeight / (windowWidth + 170) >= userCamera.videoHeight/userCamera.videoWidth){
          //  console.log('uuu');
            setUserVideoWidth(`${windowWidth-28}`);
            setUserVideoHeight(`${(windowWidth-28) * (userCamera.videoHeight/userCamera.videoWidth)}`);
          }else{
         //   console.log('look2')
           setUserVideoWidth(`${(windowHeight-170) / (userCamera.videoHeight/userCamera.videoWidth)}`);           
           setUserVideoHeight(`${(windowHeight-170)}`);
          }
        }
        
        setTimeout(() => {
          setMyVideoPositions({position: 'absolute', top: 0, left: 0, zIndex: 92, opacity: 1});
          setMyVideoWidth((parseFloat(userCamera.style.width) * 20) / 100);          

          if(myCamera.videoWidth >= myCamera.videoHeight ){
          //  console.log(1)
            setMyVideoHeight(((parseFloat(userCamera.style.width) * 20) / 100) / (myCamera.videoWidth/myCamera.videoHeight));            
          }else{
         //   console.log(2)
            setMyVideoHeight(((parseFloat(userCamera.style.width) * 20) / 100) * (myCamera.videoHeight/myCamera.videoWidth));            
          }
        },400)
      }else if(val === 3){
        myCameraGroup.appendChild(userCamera);
        myCameraGroup.appendChild(myCamera);          
        setMyVideoPositions({position: 'relative', top: 0, left: 0, zIndex: 91, opacity: 1});        
        
        if(myCamera.videoWidth >= myCamera.videoHeight ){
          if((windowWidth + 170) / windowHeight  >= myCamera.videoWidth/myCamera.videoHeight){
          //  console.log('yes');
            setMyVideoWidth(`${(windowHeight-174) * (myCamera.videoWidth/myCamera.videoHeight)}`);
            setMyVideoHeight(`${(windowHeight-174)}`);
          }else{
          //  console.log('no');
            setMyVideoWidth(`${windowWidth-28}`);
            setMyVideoHeight(`${(windowWidth-28) * (myCamera.videoHeight/myCamera.videoWidth)}`);
          }
        }else{
          if(windowHeight / (windowWidth + 126) >= myCamera.videoHeight/myCamera.videoWidth){
          //  console.log('uuu');
            setMyVideoWidth(`${windowWidth-28}`);
            setMyVideoHeight(`${(windowWidth-28) * (myCamera.videoHeight/myCamera.videoWidth)}`);
          }else{
          //  console.log('look2');
            setMyVideoWidth(`${(windowHeight-170) / (myCamera.videoHeight/myCamera.videoWidth)}`);           
            setMyVideoHeight(`${(windowHeight-170)}`);
          }
        }

        setTimeout(() => {
          setUserVideoPositions({position: 'absolute', top: 0, left: 0, zIndex: 92, opacity: 1});
          setUserVideoWidth((parseFloat(myCamera.style.width) * 20) / 100);

          if(userCamera.videoWidth >= userCamera.videoHeight ){
          //  console.log(1)
            setUserVideoHeight(((parseFloat(myCamera.style.width) * 20) / 100) / (userCamera.videoWidth/userCamera.videoHeight));
          }else{
          //  console.log(2)
            setUserVideoHeight(((parseFloat(myCamera.style.width) * 20) / 100) * (userCamera.videoHeight/userCamera.videoWidth));
          }
        },400)    
      }
    
      setCameraScreenMode(val);
    },400)
  }
}



function demonstration (remotePeerId){
  if(screenStream){
    const mediaStreamConstraints = { 
      video: {      
        width: 1920, 
        height: 1280,   
      },
      audio: {
        echoCancellation: true,
        noiseSuppression: true,
        sampleRate: 44100,
      } 
    }
    let getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
    getUserMedia(mediaStreamConstraints, function(mediaStream) {
      myVideo.current.srcObject = mediaStream;
      myVideo.current.play();    
      peerInstance.current.call(remotePeerId, mediaStream);
    }, function(err) {
      console.log('Failed to get local stream', err);
    });
  }else{
    callUser(remotePeerId);
  }
}

const cameraDemonstration = (remotePeerId) => {
  
  leaveCall(remotePeerId);
  const mediaStreamConstraints = { 
    video: {      
      width: 1920, 
      height: 1280,   
    },
    audio: {
      echoCancellation: true,
      noiseSuppression: true,
      sampleRate: 44100,
    } 
  }
  let getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
  getUserMedia(mediaStreamConstraints, function(mediaStream) {
    myVideo.current.srcObject = mediaStream;
    myVideo.current.play();    

    const call = peerInstance.current.call(remotePeerId, mediaStream);
    call.on('stream', (remoteStream) => {
        setDemoMode('camera');
      userVideo.current.srcObject = remoteStream;
      setTimeout(() => {
        userVideo.current.play();
      },1000);      
    });
  }, function(err) {
    console.log('Failed to get local stream', err);
  });
}

const screenDemonstration = async(remotePeerId) => {
  screenStream = await navigator.mediaDevices.getDisplayMedia(
  {
    video: {
      cursor: "pointer",
      width: 1920, 
      height: 1280,        
    },
    audio: {
      echoCancellation: true,
      noiseSuppression: true,
      sampleRate: 44100,
    },
  })    

  myVideo.current.srcObject = screenStream;
  myVideo.current.play();

  const call = peerInstance.current.call(remotePeerId, screenStream);
  call.on('stream', (remoteStream) => {
    if(userVideo.current && userVideo.current.srcObject){
     // console.log('stream')
      setCallEnded(0);
      setDemoMode('screen');
      userVideo.current.srcObject = remoteStream;
      setTimeout(() => {
        userVideo.current.play();          
      },1000)
    }    
  });
}

const myCameraChengeSound = (event, newValue) => {
  const myCameraElm = document.getElementById('myCamera');
  myCameraElm.volume = newValue;
}

const userCameraChengeSound = (event, newValue) => {
  const userCameraElm = document.getElementById('userCamera');
  userCameraElm.volume = newValue;
}


const callUser = async(remotePeerId) => {
  let getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
  getUserMedia({video: true, audio: true}, function(mediaStream) {
    setCallStream(true);
    
    myVideo.current.srcObject = mediaStream;
  ///\\\  myVideo.current.srcObject = mediaStream;
    myVideo.current.play();
    const call = peerInstance.current.call(remotePeerId, mediaStream);
    call.on('stream', (remoteStream) => {
      setCallEnded(0);
      setWindowWidth(windowWidth - 1);
      userVideo.current.srcObject = remoteStream;      
      setTimeout(() => {
        userVideo.current.play();
        userVideo.current.volume = 0;
      },1000);      
    });
  }, function(err) {
    console.log('Failed to get local stream', err);
  });
}

const leaveCall = (remotePeerId) => {
  const tracks = [];
  setCallStream(false);
  cameraScreenModeFnc(1);
  
  if(userVideo.current && userVideo.current.srcObject) {    
    userVideo.current.srcObject.getTracks().forEach( (track) => {
      track.stop();
      tracks.push(track);
    });
  } 

  if(tracks.length === 1){
    demonstration(remotePeerId);
  }

  setTimeout(() => {    
    let getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
    
    getUserMedia({video: true, audio: false}, function(mediaStream) {    
      setCallStream(false);      
      myVideo.current.srcObject = mediaStream;    
      myVideo.current.play();
      
      setCallEnded(1);
      setDemoMode('camera');        
      peerInstance.current.destroy();
      
    }, function(err) {
      console.log('Failed to get local stream', err);
    });
  },700)
}

function sendCallRequestFnc(user){
  const callRequestParams = {
    message_to: user.messageTo,
    email: user.email,
    message: `__callto__${user.messageTo}__connectionid__${peerId}`
  }
  sendusercallrequestSrv(callRequestParams)    
  .then(response => {
    if(response  && response.statusCode === 201){
      setCalledUser(user.messageTo);
    }
    //goToGetUserMessageService(selectedUserMessage.user_id);
  }).catch(err => {
    console.log(err);
  })
}

function removeCallRequestFnc(){
  removeusercallrequestSrv()    
  .then(response => {
    if(response  && response.statusCode === 200){
      setCalledUser('');
    }
  }).catch(err => {
    console.log(err);
  })
}

const sendAndRemoveCallRequest = (user) => {
  if(user.messageTo !== calledUser){
    sendCallRequestFnc(user);
  }else{
    removeCallRequestFnc();
  }
}

const getCallRequest = () => {
  getusercallrequestSrv()
  .then(response => {
    if(response  && response.statusCode === 200){      
      //console.log(response.data);
     // setPartnerConnectionId(response.data)
     setRemotePeerIdValue(response.data.connectionId);
     setPartnerEmail(response.data.requestFrom);
    }
    //goToGetUserMessageService(selectedUserMessage.user_id);
  }).catch(err => {
    console.log(err);
  })
}

/*Video Meeting Part END*/

useLayoutEffect(() => {
    if(value === 2){
      if(cameraScreenMenuPosition !== 1){
        setCameraScreenMenuPosition(1);
      }

      cameraScreenModeFnc(cameraScreenMode);
    }

    function updateSize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => {
      window.removeEventListener('resize', updateSize); 
    }  
},[value, windowWidth, windowHeight])

useEffect(() => {
  goToGetUserMessageService('all');  
  goToIndicateLastVisitService();
},[selectedUserRow, lastSeen])

useEffect(() => {
  clearInterval(chatInterval);
  if(chatPart){
    chatInterval = setInterval(() =>{
      callChatLimit +=1;
      connectRoom();
      if(callChatLimit >= 100){
        clearInterval(chatInterval);
        setRefreshRoomVisibled(true);
      }
    }, 5000);
  }

  if(!localStorage.getItem('movacc')){
    getAccountDataSrv().then(response => {
      localStorage.setItem('movacc', JSON.stringify(response));  
      setAccountData(response);
    }).catch(err => {
      console.log(err);
    })
  }else{
    setAccountData(JSON.parse(localStorage.getItem('movacc')));
  }

  if(!localStorage.getItem('movwin')){
    localStorage.setItem('movwin', JSON.stringify({
      windowMode: 0,
      apps: [],
      appCount: 1
    }));
  } 

  let allApps = JSON.parse(localStorage.getItem('movwin')).apps;
   
  if(allApps.indexOf('contact') === -1 && JSON.parse(localStorage.getItem('movwin')).appCount < 2){
    setOpen(true);
    setTimeout(() => {
      const muiBackdrop = document.querySelectorAll('.MuiBackdrop-root');    
      let newApp = JSON.parse(localStorage.getItem('movwin')).apps;
      newApp.push('contact');

      if(muiBackdrop[2]){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
          apps: newApp,
          appCount: 2
        })); 
      }else if(muiBackdrop[2] === undefined && muiBackdrop[1]) {
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
          apps: newApp,
          appCount: 1
        }));        
      }else if(muiBackdrop[2] === undefined && muiBackdrop[1] === undefined) {        
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
          apps: [],
          appCount: 0,
        }));        
      }

      appsInWindows('load');
    },100);
  }

},[chatPart])



useEffect(() => {  
  const peer = new Peer();
  if(callEnded !== 0){
    peer.on('open', (id) => {
      setPeerId(id);      
      getCallRequest();
    });    
  }

  peer.on('call', (call) => {    
    let getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
    getUserMedia({video: true, audio: true}, function(mediaStream) {
    call.answer(mediaStream);
    call.on('stream', (remoteStream) =>{
      setRemotePeerIdValue(call.peer);
      setCallEnded(0);
    
      userVideo.current.srcObject = remoteStream;
      setTimeout(() => {        
        userVideo.current.play();        
        userVideo.current.volume = 0;        
      },700)      
    })
    }, function(err) {
      console.log('Failed to get local stream' ,err);
    });
  })

  if(callEnded !== 0){ 
    peerInstance.current = peer;
  }
  setNewPeerIdStatus(false);
},[newPeerIdStatus, callEnded])

return (
  <>
    <Avatar title="Contact" component="span" variant="rounded" alt="Contact" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/contact.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto', right: props.onHeader && '10px'}} onClick={handleClickOpen} />
    {props.onHeader === false && <Typography title="Contact" variant="subtitle1" component="span" style={{ whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', overflow: 'hidden', textOverflow: 'ellipsis'}} gutterBottom>Contact</Typography>}
    <Dialog id="contactDialogBox" fullScreen onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle
        style={{
          padding: '8px 16px',
          color:'#ffffff', 
          background:'linear-gradient(90deg, rgba(38,93,140,1) 0%, rgba(38,93,140,1) 15%, rgba(0,140,183,1) 100%)'
        }}
        id="customized-dialog-title" onClose={handleClose}
      >
        <Avatar component="span" variant="rounded" style={{width:'24px', height: '24px', display: 'inline-block', marginRight: '8px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/contact.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
        <span>{(windowWidth < titleDisplaySize && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) !== 0) || (windowWidth < (titleDisplaySize/2) && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) === 0) ? '' : 'Contact'}</span>

        <IconButton
          aria-label="AppsInWindowsIcon"
          onClick={() => appsInWindows('change')}
          size="small"
          style={{
            background: themeHlp().palette.blueGrey.dark,
            color:'white',          
            margin:'2px 42px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px',
            opacity: document.getElementById('shutdownDialog') ? '1' : '0.3'
          }}
        >
          <BorderInnerIcon style={{padding: '2px'}} />          
        </IconButton>
        <IconButton 
          aria-label="UnfoldLessMoreIcon"
          title={!toolbarHidden ? 'Hide Toolbar' : 'Show Toolbar'}
          onClick={()=> setToolbarHidden(current => !current)}
          size="small"
          style={{
            width: '18px',
            height: '18px',
            background: themeHlp().palette.blueGrey.dark,
            color:'white',  
            margin:'2px 74px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px'
          }}
        >
          {!toolbarHidden ? <UnfoldLessIcon style={{padding: '4px'}} /> : <UnfoldMoreIcon style={{padding: '4px'}} />}
        </IconButton>
        <IconButton 
          aria-label="CancelSharpIcon"
          title="Close"
          onClick={handleClose}
          size="small"
          style={{
            background: themeHlp().palette.blueGrey.dark,
            color:'white', 
            margin:'2px 10px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px'
          }}
        >
          <CancelSharpIcon style={{padding: '2px'}} /> 
        </IconButton>
      </DialogTitle>      
      <DialogContent id="p0" className={classes.dialog_content}>
        <div id="p1" className={classes.root}>
          {!toolbarHidden &&
          <AppBar id="p2" position="static" color="default">
            <Tabs id="p3"      
              className={classes.tab_group}
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="on"
              indicatorColor="primary"         
              aria-label="scrollable force tabs example"
            >
              <Tab label="Message" icon={<Avatar component="span" variant="rounded" style={{  color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_message.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />} {...a11yProps(2)} onClick={tabMessageFnc} />
              <Tab label="Chat Group" icon={<Avatar component="span" variant="rounded" style={{  color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_chat.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />} {...a11yProps(3)} onClick={tabChatgroupFnc} />
              <Tab label="Video Meeting" icon={<Avatar component="span" variant="rounded" style={{  color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_video_meeting.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />} {...a11yProps(4)} onClick={tabVideomeetingFnc} />
            </Tabs>
          </AppBar>
          }
          <TabPanel value={value} index={0}>
            <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3 }}> 
              <Grid container align = "center" style={{ marginTop:'4px'}}>
                <Grid container spacing={0} align = "center" > 
                  <Grid item xs={12}>          
                    <TextField
                      label="Type to search"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" onClick={() => {alert('sd')}}>
                            <SearchIcon className={classes.search_icon} />
                          </InputAdornment>
                        ),
                      }}
                      type="text"
                      name="search"
                      variant="standard"
                      className={classes.text_box}            
                      onChange={userSearch}
                    /> 
                    <nav
                      style={{
                        overflowX: 'hidden',
                        overflowY: 'auto',
                        width: '100%',
                        maxHeight: '210px',
                        textAlign: 'left'                              
                      }}
                    >
                      <ul style={{padding: 0}}>
                        {
                          usersData.map((data) => {
                            return data;
                          })
                        }                  
                      </ul>
                    </nav>
                  </Grid>
                  <Grid item xs={12}>
                  <>
                    <Typography variant="subtitle1" component="div" align="left" style={{ fontWeight: 'bold', paddingLeft: '10px'}}>Users List</Typography>
                    <Box component="div" sx={{width: '100%', margin:'4px 0', textAlign: 'right'}}>
                      {usersNotificationList.length > 0 && <Typography variant="inherit" component="span" align="left" style={{  paddingLeft: '10px'}}>New Messages </Typography>}
                      {
                        usersNotificationList.map((data) => {
                          return data;
                        })
                      }
                    </Box>
                    <ul
                      style={{ 
                        listStyleType: "none",
                        maxHeight: '200px',
                        overflowY: 'auto',                      
                        margin: 0,
                        padding: '0 0 10px 0'
                      }}
                    >
                      {
                        usersMessageList.map((data) => {
                          return data;
                        })
                      }                  
                    </ul>        
                  </>
                  </Grid>
                  <Grid item xs={12}> 
                    <Box>
                    {lastVisit && <Typography variant="subtitle2" component="div" align="left" style={{ paddingLeft: '10px'}}>Last Visit {new Date(lastVisit).toLocaleString('en-US', {timeZone: timeZone,
                        dateStyle: 'short',//full, long, medium, short
                        timeStyle: 'medium', //full, long, medium, short
                        hourCycle: 'h12', //h12, h24
                      })}</Typography>
                      }
                      {lastSeen && <Typography variant="subtitle2" component="div" align="left" style={{ paddingLeft: '10px'}}>Last Seen {new Date(lastSeen).toLocaleString('en-US', {timeZone: timeZone,
                        dateStyle: 'short',//full, long, medium, short
                        timeStyle: 'medium', //full, long, medium, short
                        hourCycle: 'h12', //h12, h24
                      })}</Typography>
                      }
                    </Box> 
                  </Grid>
                  {selectedUserMessage.hasOwnProperty('email') &&
                  <>           
                    <Grid item xs={12} md={6}>         
                      <Typography variant="subtitle2" component="div" align="left" style={{ fontWeight: 'bold', marginTop: '10px', paddingLeft: '10px'}}>Inbox</Typography>
                      <ul                  
                        style={{ 
                          listStyleType: 'none',
                          height: '207px',
                          overflowY: 'auto',
                          border: '1px solid ' + themeHlp().palette.blackText.light,
                          margin: 0,
                          padding: '0 10px'
                        }}
                      >
                      {
                        inboxMessageList.map((data) => {
                          return data;
                        })
                      } 
                      </ul>
                    </Grid>
                    <Grid item xs={12} md={6}>           
                      <Typography variant="subtitle2" component="div" align="left"  style={{fontWeight: 'bold', marginTop: '10px', paddingLeft: '10px'}}>Sent</Typography>
                      <ul                    
                        style={{ 
                          listStyleType: 'none',
                          height: '207px',
                          overflowY: 'auto',
                          border: '1px solid ' + themeHlp().palette.blackText.light,
                          margin: 0,
                          padding: '0 10px'
                        }}
                      >
                        {
                          sentMessageList.map((data) => {
                            return data;
                          })
                        } 
                      </ul>                
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <TextField
                        label="Type message"
                        type="text"
                        size="small"
                        variant="standard"
                        className={classes.text_box}
                        value={message}
                        onChange={(event) => setMessage(event.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <IconButton title="Send Message" size="small" aria-label="directions" style={{float:"right"}} onClick={sendMessage}>
                        <DirectionsIcon className={classes.iconbutton_connect} />
                      </IconButton>
                    </Grid>
                  </>
                  }
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>  
            <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3 }}> 
             <Grid container align = "center" style={{ marginTop:'4px'}}>
                <Grid container spacing={0} align = "center" > 
                  <Grid item xs={12} sm={chatPart=== false ? 5 : 9}>
                    <TextField
                      label="Room ID"
                      type="text" 
                      size="small"
                      variant="standard" 
                      className={classes.text_box}
                      value={chatRoom}
                      disabled={roomIdDisabled}
                      onChange={(event) => setChatRoom(event.target.value)}
                    />
                  </Grid>
                  {chatPart === false &&
                    <Grid item xs={12} sm={5}>                 
                      <TextField
                        label="Password"
                        type="text" 
                        size="small"
                        variant="standard" 
                        className={classes.text_box}
                        value={roomPassword}
                        onChange={(event) =>  setRoomPassword(event.target.value) }
                      />                  
                    </Grid>
                  }
                  {chatPart === false ?
                    <Grid item xs={12} sm={2}>
                      <IconButton title="Connect" size="small" aria-label="directions" style={{float:"right"}} onClick={() => connectRoom()}>
                        <DirectionsIcon className={classes.iconbutton_connect} />
                      </IconButton>
                    </Grid>
                    :
                    <Grid item xs={12} sm={3}>
                      <IconButton title="Disconnect" size="small" aria-label="directions" style={{float:"right"}} onClick={disconnectRoom}>
                        <DirectionsIcon className={classes.iconbutton_disconnect} />
                      </IconButton>
                      {refreshRoomVisibled && 
                        <IconButton title="Refresh" size="small" aria-label="directions" style={{float:"right"}} onClick={() => {connectRoom(); setChatPart(false)}}>
                          <RefreshIcon className={classes.iconbutton_connect} />
                        </IconButton>
                      }
                    </Grid>
                  }              
                  <Grid item xs={12}>
                    <Typography variant="inherit" component="span" align="left"
                      style={{
                        color: hintMessageColor,
                        border: '0px',
                        fontSize: '16px'
                      }}
                    >{hintMessage}</Typography>
                  </Grid>
                </Grid>
                {chatPart === true &&
                <Grid container spacing={0} align = "center" > 
                  <Grid item xs={12}>
                    <ul 
                      style={{ listStyleType: 'none',
                        margin: 0,
                        padding: 0
                      }}
                    >
                      {
                        connectedUsersChatList.map((data, key) => {
                          return (                              
                            <li key={key} style={{cursor: 'pointer'}} onClick={() => setChatTextOnlyFor(data.user)}>
                              {data.user}
                            </li>
                          )                          
                        })
                      }    
                    </ul>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <TextField
                      InputProps={{
                        endAdornment: chatTextOnlyFor !=='' &&(
                          <InputAdornment title="Remove" position="end" onClick={() => setChatTextOnlyFor('')}>
                            <CancelPresentation style={{color: themeHlp().palette.error.light, cursor: 'pointer'}}/>
                          </InputAdornment>
                        ),
                      }}
                      disabled              
                      label="Message only for"
                      type="text"                      
                      variant="standard" 
                      className={classes.text_box} 
                      value={chatTextOnlyFor}
                    />
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <TextareaAutosize
                      placeholder="Send message"
                      className={classes.textarea_box}
                      value={chatText}
                      onChange={(event) => setChatText(event.target.value)}
                    />
                    <Box style={{ width: '100%'}}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={emojiTabValue} onChange={EmojiHandleChange} aria-label="emoji tabs" variant="fullWidth">
                          <Tab wrapped style={{fontSize: '24px',  display:'none'}} label={String.fromCodePoint('0x1F600')} {...EmojiA11yProps(0)} />
                          <Tab wrapped style={{fontSize: '24px'}} label={String.fromCodePoint('0x1F600')} {...EmojiA11yProps(1)} />
                        </Tabs>
                      </Box>
                      <TabPanel value={emojiTabValue} index={1}>
                        <Box>
                          {emojis.map((el, key) => 
                            <Typography key={key} variant="inherit" component="span" className={classes.emoji_button}
                              onClick={() => setChatText(chatText + String.fromCodePoint(el))}>{String.fromCodePoint(el)}</Typography>
                          )}
                          <Button  
                            className={classes.narrow_button}        
                            onClick={() => EmojiHandleChange(0)}
                            startIcon={                     
                              <ExpandLessIcon />
                            }
                          >
                          </Button>
                        </Box>
                      </TabPanel>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <IconButton title="Send" size="small" aria-label="directions" style={{float:"right"}} onClick={sendText}>
                      <DirectionsIcon className={classes.iconbutton_connect} />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12}>
                      <Box style={{
                        overflow: "hidden", 
                        overflowY: "auto",
                        height: "400px",
                        width: "100%",                        
                      }}>
                      <TableContainer  className={classes.chat_group_container}>
                        <Table size="small">
                          <TableBody>
                            {
                              usersChatText.map((data, key) => {
                                return (
                                  <TableRow key={key}>
                                    <TableCell align="left" style={{color: themeHlp().palette.primary.main, padding: '2px', width: '40px'}} >
                                      <img alt="Chat User" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_user.png'}`} style ={{width:'30px', float:'left', marginRight: '10px', background: '#' + md5(data.chatFrom).substring(0, 6)}}/>
                                    </TableCell>
                                    <TableCell align="left" style={{color: '#' + md5(data.chatFrom).substring(0, 6), padding: '2px'}} >
                                      <span>{data.chatFrom}</span>
                                      <div style={{color: themeHlp().palette.primary.main }} dangerouslySetInnerHTML={{__html: plain2html(data.message)}}></div>
                                    </TableCell>
                                    <TableCell align="right">
                                      {data.chatFrom === accountData.account_email ? 
                                        <span title="Remove Message" className={classes.text_icon} style ={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/delete.png)'}} onClick={() => deleteText(data._id)}></span>
                                        :
                                        <span className={classes.empty_icon}></span>
                                      }
                                      <span>
                                        {new Date(data.date).toLocaleString('en-US', {timeZone: timeZone,
                                          dateStyle: 'short',//full, long, medium, short
                                          timeStyle: 'medium', //full, long, medium, short
                                          hourCycle: 'h12', //h12, h24
                                        })}
                                      </span>
                                    </TableCell>
                                </TableRow>
                                )
                              })
                            }                  
                          </TableBody>    
                        </Table>
                      </TableContainer>                      
                      </Box>
                  </Grid>
                </Grid>
                }
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={2} className={classes.video_meeting_panel}>            
            <Box sx={{ flexGrow: 1}}> 
              <Grid container align="center">
                <Grid container alignItems="center" justify="center">
                  <Grid item xs={12}> 
                  <Box style={{position: 'relative' }}>
      
                  
                    {/*  <video controls playsInline muted ref={myVideo} style={{width:'100%', maxWidth: '360px', height: '270px'}} />*/}
                    <div id="myCameraGroup" 
                      style={{                                            
                        display: 'inline-block',
                        position: `${myVideoPositions.position}`,
                        top: `${myVideoPositions.top}px`,
                        left: `${myVideoPositions.left}px`,                        
                        width: `${myVideoWidth}px`, 
                        height: `${myVideoHeight}px`,
                      }}>
                      <video id="myCamera" 
                        autobuffer="true" 
                        /*src={process.env.REACT_APP_URL_CLIENTPORT + '/video/videoplayback.mp4'} */
                        ref={myVideo} 
                        x-webkit-airplay="allow" 
                        controlsList="nodownload" 
                        disablePictureInPicture=""  
                        controls="" 
                        playinfullscreen="false" 
                        playsInline={true} 
                        onContextMenu={e => e.preventDefault()}
                        style={{
                        /*  border: '2px solid red',*/
                          position: `${myVideoPositions.position}`,
                          top: `${myVideoPositions.top}px`,
                          left: `${myVideoPositions.left}px`,
                          zIndex: `${myVideoPositions.zIndex}`, 
                          opacity: `${myVideoPositions.opacity}`,                     
                          width: `${myVideoWidth}px`, 
                          height: `${myVideoHeight}px`,
                        }}></video>
                    </div> 
                    <div id="userCameraGroup" 
                      style={{
                        display: 'inline-block',
                        position: `${userVideoPositions.position}`,
                        top: `${userVideoPositions.top}px`,
                        left: `${userVideoPositions.left}px`,
                        width: callEnded === 0 ? `${userVideoWidth}px` : `${0}px`, 
                        height: callEnded === 0 ? `${userVideoHeight}px` : `${0}px`,
                      }}>
                      <video id="userCamera"
                      /*src={process.env.REACT_APP_URL_CLIENTPORT + '/video/videoplayback.mp4'}*/
                      autobuffer="true" 
                    /*  src={process.env.REACT_APP_URL_CLIENTPORT + '/video/bella.mp4'} */
                      ref={userVideo} 
                      x-webkit-airplay="allow" 
                      controlsList="nodownload" 
                      disablePictureInPicture=""  
                      controls="" 
                      playinfullscreen="false" 
                      playsInline={true} 
                      onContextMenu={e => e.preventDefault()}
                      style={{
                     /*   border: '2px solid yellow',*/
                        position: `${userVideoPositions.position}`,
                        top: `${userVideoPositions.top}px`,
                        left: `${userVideoPositions.left}px`,
                        zIndex: `${userVideoPositions.zIndex}`,
                        opacity: `${userVideoPositions.opacity}`,
                        width: callEnded === 0 ? `${userVideoWidth}px` : `${0}px`, 
                        height: callEnded === 0 ? `${userVideoHeight}px` : `${0}px`,
                      }}></video>              
                    </div>
                    {callEnded === 0 &&
                    <div style={{top: 0, left: '4px', position: 'absolute'}}>
                      {(cameraScreenMode === 1) && <div  className={classes.camera_screenmode_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/contact/contact_camera_mode1.png)', position: 'absolute'}} onClick={() => cameraScreenModeFnc(2)}></div>}
                      {(cameraScreenMode === 2) && <div className={classes.camera_screenmode_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/contact/contact_camera_mode2.png)', position: 'absolute'}} onClick={() => cameraScreenModeFnc(3)}></div>}
                      {(cameraScreenMode === 3) && <div className={classes.camera_screenmode_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/contact/contact_camera_mode3.png)', position: 'absolute'}} onClick={() => cameraScreenModeFnc(1)}></div>}
                    </div>                        
                    }
                    {(cameraScreenMode === 2 || cameraScreenMode === 3) &&
                      <div style={{top: '30px', left: 0, position: 'absolute', zIndex: 101}}>
                        <IconButton
                          size="small"
                          aria-label="more"
                          id="long-button"
                          aria-controls={cameraScreenMenuOpen ? "long-menu" : undefined}
                          aria-expanded={cameraScreenMenuOpen ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={cameraScreenMenuClick}
                          className={classes.iconbutton_connect}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            "aria-labelledby": "long-button"
                          }}
                          anchorEl={cameraScreenMenuAnchorEl}
                          open={cameraScreenMenuOpen}
                          onClose={cameraScreenMenuClose}
                          PaperProps={{
                            style: {            
                              width: '54px'
                            }
                          }}
                        >
                          {cameraScreenOptions.map((option, key) => (
                            <MenuItem
                              key={key+1}
                              selected={option === "Pyxis"}
                              onClick={() => cameraScreenMenuPositionFnc(key+1)}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </Menu>
                      </div>
                    }
                    </Box>
                  </Grid>         
                  


                  <Grid item sm={callEnded === 0 ? 3 : 5}></Grid>
                  <Grid item xs={12} sm={2}>
                    <div style={{position: 'relative', marginTop: '20px', width: '96px', height: '48px', display: 'inline-block'}}>
                      <Typography variant="inherit" component="span" className={classes.camera_volume_label}>Your volume slider</Typography>
                      <VolumeUp className={classes.camera_volume_icon} />
                      <Slider                         
                        className={classes.camera_volume_slider}
                        min={0}
                        step={0.1}
                        max={1}                          
                        defaultValue={1}
                        aria-label="small"
                        valueLabelDisplay="auto"
                        onChange={myCameraChengeSound}                          
                      />
                    </div>
                    </Grid>  
                    {callEnded === 0 &&
                    <>
                    <Grid item xs={12} sm={2}>
                    {demoMode === 'camera' ?
                    <div title="Show Screen" className={classes.video_meeting_button} style={
                      {
                        backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/contact_screen_demo.png)'
                      }}
                      onClick={() => screenDemonstration(remotePeerIdValue)}
                    ></div> 
                    :
                    <div title="Show Camera" className={classes.video_meeting_button} style={
                      {
                        backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/contact_cam_demo.png)'
                      }}     
                      onClick={() => cameraDemonstration(remotePeerIdValue)}                   
                    ></div>
                    }
                    </Grid>
                    <Grid item xs={12} sm={2}>
                    <div style={{position: 'relative', marginTop: '20px', width: '96px', height: '48px', display: 'inline-block'}}>
                      <Typography variant="inherit" component="span" className={classes.camera_volume_label}>Partner volume slider</Typography>
                      <VolumeUp className={classes.camera_volume_icon} />
                      <Slider                   
                        className={classes.camera_volume_slider}
                        min={0}
                        step={0.1}
                        max={1}                          
                        defaultValue={0}                          
                        aria-label="small"
                        valueLabelDisplay="auto"
                        onChange={userCameraChengeSound}
                      />
                    </div>
                    </Grid>
                    </>
                    }
                  <Grid item sm={callEnded === 0 ? 3 : 5}></Grid>




                  <Grid item sm={1} md={2} lg={3} xl={4}></Grid>
                  <Grid item xs={12} sm={9} md={7} lg={5} xl={3}>
                    <TextField
                      label="Your Connection ID"
                      type="text" 
                      size="small"
                      variant="standard" 
                      disabled
                      className={classes.text_box}
                      value={peerId}                          
                      style={{ marginBottom: "10px" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={1} md={1} lg={1} xl={1}>
                    <CopyToClipboard text={peerId}>
                      <IconButton title="Copy" size="small" aria-label="filecopy" style={{float:'right' }}>
                        <FileCopyIcon className={classes.iconbutton_connect} />
                      </IconButton>
                    </CopyToClipboard>
                  </Grid>
                  <Grid item sm={1} md={2} lg={3} xl={4}></Grid>
                  {/*temporary closed<Grid item xs={12} sm={10}>
                    <TextField
                      label="Your Name"
                      type="text" 
                      size="small"
                      variant="standard" 
                      disabled
                      className={classes.text_box}
                      value={accountData.first_name}                          
                      style={{ marginBottom: "10px" }}
                    />
                    </Grid>*/}
                      
                  <Grid item sm={1} md={2} lg={3} xl={4}></Grid>                    
                  <Grid item xs={12} sm={9} md={7} lg={5} xl={3}>
                    <TextField
                      label={`Partner Connection ID ${partnerEmail}`}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment title="Refresh Partner Connection ID" position="start" onClick={() => {getCallRequest()}}>
                            <AutorenewIcon className={classes.iconbutton_reload} />
                          </InputAdornment>
                        ),
                      }}
                      type="text" 
                      size="small"
                      variant="standard" 
                      className={classes.text_box}
                      value={remotePeerIdValue}
                      onChange={(e) => setRemotePeerIdValue(e.target.value)}
                    />	
                  </Grid>
                  <>
                  <Grid item xs={12} sm={1} md={1} lg={1} xl={1}>

                    {(callEnded === 2 || callEnded === 1) && 
                  
                      <IconButton title="Connect" size="small" aria-label="directions" style={{float:"right"}} onClick={() => callUser(remotePeerIdValue)}>
                        <PhoneForwardedIcon className={classes.iconbutton_connect} />
                      </IconButton>
                  
                  
                    }
                    {(callEnded === 0) && 
          
                      <IconButton title="Disconnect" size="small" aria-label="directions" style={{float:"right"}} onClick={() => leaveCall(remotePeerIdValue)}>
                        <CallEndIcon className={classes.iconbutton_disconnect} />
                      </IconButton>
                  
                    }


                      </Grid>
                  </>
                  <Grid item sm={1} md={2} lg={3} xl={4}></Grid>



                  <Grid item sm={1} md={2} lg={3} xl={4}></Grid>
                  <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
                  <Typography variant="subtitle1" component="div" align="left" style={{ fontWeight: 'bold', paddingLeft: '14px', marginTop: '30px'}}>Users List</Typography>
                  </Grid>
                  <Grid item sm={1} md={2} lg={3} xl={4}></Grid>
                  {
                  usersForMeeting.map((data, key) => {
                    return (
                      <Box key={key} className={classes.users_list_container}>
                        <Grid container align="center">
                          <Grid container spacing={0} align="center">
                            <Grid item sm={1} md={2} lg={3} xl={4}></Grid>
                            <Grid item xs={12} sm={9} md={7} lg={5} xl={3}>
                              <TextField         
                                type="text" 
                                size="small"
                                variant="standard" 
                                disabled
                                className={classes.text_list}
                                value={data.email}                          
                                style={{ marginBottom: "10px" }}
                              />
                            </Grid>        
                            <Grid item xs={12} sm={1} md={1} lg={1} xl={1}>          
                              <IconButton title="Video Call" size="small" aria-label="videocall" style={{float:'right', background: data.messageTo === calledUser ? themeHlp().palette.secondary.main : 'white'}} onClick={() => sendAndRemoveCallRequest(data)}>
                                <PhoneIcon className={classes.iconbutton_connect} />
                              </IconButton>     
                            </Grid>
                            <Grid item sm={1} md={2} lg={3} xl={4}></Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    )                          
                  })
                  }
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
        </div>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>    
  </>
);
}
 
export default ContactApp;
