export const gettopicnotesSrv = () => {
  async function gettopicnotesService() {
    const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/user/gettopicnotes', {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
      credentials: "include", // include, *same-origin, omit
    });
     return await rawResponse.json();
  };

  return gettopicnotesService()
}
