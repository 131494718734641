import React, { useState, useLayoutEffect, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import LinkMui from '@material-ui/core/Link';
//import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { accountActivationSrv } from '../Service/AccountActivationService.js';
import { themeHlp } from '../Helper/ThemeHelper.js';

const useStyles = makeStyles((theme) => ({
  text_box: {
    width:'92%',
    margin: '4px 4%',
    backgroundColor: 'white',
    borderBottom: '1px solid ' + themeHlp().palette.blackText.light,
    '&:hover': {      
        border: '1px solid ' + themeHlp().palette.primary.light,
        borderStyle: 'none none solid none',
    }
  },
  casual_text: {
    fontFamily: 'Helvetica',
    fontSize: '0.875rem'
  },
  button_witout_icon: {
    width:'92%',
    margin: '4px 4%',
    background: themeHlp().palette.primary.light,
    textTransform: 'capitalize',
    border: '1px solid ' + themeHlp().palette.secondary.light,
    '&:hover' : {
      background: themeHlp().palette.primary.main,
      color: themeHlp().palette.secondary.light,
    },
    '&:active' : {
      background: themeHlp().palette.primary.dark,
      color: themeHlp().palette.secondary.light,
    }
  },
  account_activation_box:{
    width: '100%',
    maxWidth: '360px',
    padding: '0px 0px 20px 0px',
    marginBottom: '40px',
    background: themeHlp().palette.primary.main,
  },
  login_link:{
    color: themeHlp().palette.whiteText.main,
    textDecoration: 'none',
    cursor:'pointer',
    '&:focus, &:hover, &:visited, &:link, &:active' : {
      color: themeHlp().palette.secondary.light,
      textDecoration: 'none',
    } 
  },
  error_message: {
    fontFamily: 'Helvetica',
    fontSize: '0.875rem',   
    fontWeight: 'bold',
    width: '92%',
    margin: '4px 4%',
    padding: '4px 0',
    backgroundColor: themeHlp().palette.error.main,
    color: themeHlp().palette.secondary.light,
    border: '0px',
    borderRadius: '0px'
  },
  linear_progress_box: {
    width: '92%',
    margin: '4px 4%',
    padding: '4px 0',
    '& .MuiLinearProgress-root': {
      backgroundColor: themeHlp().palette.secondary.light
    },
    '& .MuiLinearProgress-bar': {
      backgroundColor: themeHlp().palette.primary.light
    }   
  },
  circular_progress_box: {
    '& .MuiCircularProgress-root': {
      color: themeHlp().palette.primary.light,
      margin: '26px 45%',
    } 
  }
}));

const ActivationBar = () => {  
  const classes = useStyles();
  let navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [activationDone, setActivationDone] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const gotoLogin = () => {
    navigate('/login');
  }

  useLayoutEffect(() => {

    document.getElementsByTagName("BODY")[0].style.overflowY = 'auto';

    function updateSize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => {
      window.removeEventListener('resize', updateSize); 
    }
  }, []);


  useEffect(() => {
    accountActivationSrv(window.location.pathname.split('/').pop())
    .then(response => {
      if(response && (response.statusCode === 200 && response.status === 'Success')){  
        setSuccessMessage(response.message);  
        setActivationDone(true);
      }else {
        setErrorMessage(response.error.message);
        setActivationDone(false);
      }
    }).catch(err => {
      console.log('error: ' + err);
    })
  },[]);

  return (
    <Box sx={{ flexGrow: 1, overflow: "hidden", px:2, py:6}}> 
      <Grid container align="center">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} align={windowWidth >= 600 ? "right" : "center"}>
            <Box component="div" sx={{width: "100%", maxWidth: "360px", verticalAlign: "middle"}}>
              <Box sx={{position: "relative", verticalAlign: "middle"}}>
                <Typography variant="h6" component="span" gutterBottom style={{ fontSize: "14px", position: "absolute", right:"6px"}}>
                  This
                </Typography>
              </Box>
              <img src={process.env.REACT_APP_URL_CLIENTPORT+ '/icon/movesas_text.png'} alt="Moves as logo" style={{width: "100%", maxWidth: "360px"}} />
              <Box sx={{position: "relative"}}>
                <Typography variant="h6" component="span" gutterBottom style={{ fontSize: "14px", position: "relative", float:"right", paddingRight:"6px"}}>
                  easily as possible
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} align={windowWidth >= 600 ? "left" : "center"}>
            <Box className={classes.account_activation_box}>
              <Typography variant="h5" component="h5" gutterBottom align = "center" style={{paddingTop:"20px"}}>
                Account activation
              </Typography>
              {errorMessage === '' && successMessage === '' 
                ?
                  <Box className={classes.linear_progress_box}>
                    <LinearProgress value={10} />
                  </Box>
                :
                  <>
                    { activationDone === false &&                
                      <Card className={classes.error_message}>
                        <Typography variant="inherit" component="div" align="center">{errorMessage}</Typography>
                      </Card>
                    }
                    { activationDone &&
                      <Card className={classes.error_message} style={{backgroundColor: themeHlp().palette.success.dark}}>                        
                        <Typography variant="inherit" component="div" align = "center">{successMessage}</Typography>
                      </Card>
                    }    
                  </>
              }          
              <Typography variant="inherit" component="div" align = "center" className={classes.casual_text} style={{paddingTop:'20px'}}>
                Already have an account?
                <LinkMui className={classes.login_link} onClick={gotoLogin}> Log In</LinkMui>
              </Typography>
              
            </Box>
          </Grid>
        </Grid>
      </Grid>   
    </Box>
  )
}

export default ActivationBar;
