export const getlastvisitSrv = (param) => {
  async function getlastvisitService() {
    const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/user/getlastvisit/' + param.user, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
      credentials: "include", // include, *same-origin, omit
    });
     return await rawResponse.json();
  };

  return getlastvisitService()
}
