export const edittopicnotesSrv = (param) => {
  async function edittopicnotesService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/user/edittopicnotes', {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        case_id: param.caseId,
        topic: param.topic
      }),
      credentials: "include", // include, *same-origin, omit
    });
     return await rawResponse.json();
  };

  return edittopicnotesService()
}
