import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import FooterBar  from '../Bars/FooterBar';
import SignoutOfDevicestBar from '../Bars/SignoutOfDevicesBar';

const useStyles = makeStyles(() => ({
  page_container: {
    position: 'relative',
    minHeight: '100vh',
  }
}));

const SignoutOfDevices = () => {
  const classes = useStyles();
  window.history.forward();

  useEffect(() => {
    let propertyBoxGroup = document.getElementById('propertyBoxGroup');
    let dialogBox = document.getElementById('dialogBox');
    if(propertyBoxGroup){
      propertyBoxGroup.remove();
    }
    if(dialogBox){
      dialogBox.remove();
    }
  }, []);

  return (
    <>
      <Box className={classes.page_container}>
        <SignoutOfDevicestBar />
        <FooterBar />
      </Box>       
    </>
  );
}

export default SignoutOfDevices;
