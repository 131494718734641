import React from 'react';
//import ReactDOM from 'react-dom';
import  { createRoot }  from 'react-dom/client';
import './index.css';
import App from './App';



//before
/*ReactDOM.render(
  <App />,
  document.getElementById('root')
);*/
//after

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);



/*
  <React.StrictMode>
    <App />
  </React.StrictMode>
  document.getElementById('root')
  */