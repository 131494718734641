export const logoutSrv = (param) => {
  async function logoutService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/api/user/logout/', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        logout_for: param
      }),  
    //   mode: 'no-cors', // no-cors, *cors, same-origin
    //   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "include", // include, *same-origin, omit
    //  body: JSON.stringify({email: param.email, password: param.password})
    });
      return await rawResponse.json();
  };

  return logoutService()
}
