export const searchsharedfilesSrv = (param) => {

  async function searchsharedfilesService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/api/user/searchsharedfiles/', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    //  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "include", // include, *same-origin, omit
      body: JSON.stringify({
        keyword: param.keyword,
        own: param.own
      })
    });
     return await rawResponse.json();
  };

  return searchsharedfilesService()
}
