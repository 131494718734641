import React, {useLayoutEffect, useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import  { createRoot }  from 'react-dom/client';

import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

import { loadfilesSrv } from './Service/LoadFilesService.js'
import { updatefilesSrv } from './Service/UpdateFilesService.js'

import Properties from './Properties'
import { themeHlp } from './Helper/ThemeHelper.js';

export const PropertiesContext = React.createContext()

const useStyles = makeStyles((theme) => ({
  pagination_x24: {
    position: 'absolute',
    width: '100%',
    bottom: '10px',  
    left: '50%',
      '& .MuiPagination-ul': {
        '& li button': {
          backgroundColor: themeHlp().palette.primary.light,
          color: themeHlp().palette.secondary.main,
          fontSize: '9px',
          width: '20px',
          minWidth: '20px',
          height: '20px',
        },
        '& li button.Mui-selected': {
          backgroundColor: themeHlp().palette.primary.dark,
        }
      },  
    // transform: `translate(50%, 0)`,
  },
  pagination_x48: {
    position: 'absolute',

    bottom: '10px',  
    left: '50%',
      '& .MuiPagination-ul': {
        '& li button': {
          backgroundColor: themeHlp().palette.primary.light,
          color: themeHlp().palette.secondary.main,
          fontSize: '9px',
          width: '20px',
          minWidth: '20px',
          height: '20px',
        },
        '& li button.Mui-selected': {
          backgroundColor: themeHlp().palette.primary.dark,
        }
      },  
      width: '48px',
    // transform: `translate(50%, 0)`,
  },
  pagination_y24: {
    position: 'absolute',   
    top: '50%', 
      '& li button': {
        backgroundColor: themeHlp().palette.primary.light,
        color: themeHlp().palette.secondary.main,
        fontSize: '9px',
        width: '20px',
        minWidth: '20px',
        height: '20px',
      },
      '& li button.Mui-selected': {
        backgroundColor: themeHlp().palette.primary.dark,
      },
    right: '10px',
    width: '24px',
  //  transform: 'translate(0%, -50%)',
  },
  pagination_y48: {
    position: 'absolute',   
    top: '50%', 
      '& li button': {
        backgroundColor: themeHlp().palette.primary.light,
        color: themeHlp().palette.secondary.main,
        fontSize: '9px',
        width: '20px',
        minWidth: '20px',
        height: '20px',
      },
      '& li button.Mui-selected': {
        backgroundColor: themeHlp().palette.primary.dark,
      },
    right: '10px',
    width: '48px',
  //  transform: 'translate(0%, -50%)',
  },
}));



let fadeOutProcessFile;
let property = null;

//\start main function
const Fields = (props) => {
  let navigate = useNavigate();
  let location = useLocation();  
  let num = 1;
  let itemTouched = false;

  const [paginatePageX, setPaginatePageX] = useState(1);
  const [paginatePageY, setPaginatePageY] = useState(1);

  const headerBarSize = 50;
  const sideBarSize = 60;
  let fieldBoxSize = 64;
  

  const fieldsRef = useRef();
  const movesRef = useRef();
  const touchRef = useRef();

  const paginationXRef = useRef();
  const paginationYRef = useRef();

  const classes = useStyles();






  const [size, setSize] = useState([0, 0]);
  const [fieldsCount, setFieldsCount] = useState({});


  const [moved, setMoved] = useState(false)







  const [changeProperties, setChangeProperties] = useState('load')

  //document.body.style.overflowX = 'hidden';





  //\paginateX
  const paginateX = (event, value) => {
    setPaginatePageX(value);
    ShowFieldsDimensions();
  };
  //\\end paginateX

  //\paginateY
  const paginateY = (event, value) => {
    setPaginatePageY(value);  
    ShowFieldsDimensions();
  };
  //\\end paginateY

  
  const fadeOutProcessFileFnc = () => {
    
    fadeOutProcessFile = setTimeout(
      () => {
        props.process({state: '', message: '', type: ''});
      }, 4000);
  }

  //\change properties
  const changePropertiesFnc = (key, value, response) => {

    
    
    if(key !== 'open'){
      property.unmount();
      
      if(response === 'processing'){
        props.process({state: 'loading', message: '', type: 'linearProgress'});
      }else if(response !== 'processing' && response.statusCode && !response.error){
        props.process({state: 'loaded', message: response.message, type: 'report'});
      }else if(response !== 'processing' && !response.statusCode && response.error){
        props.process({state: 'loaded', message: response.error.message, type: 'error'});
      }

      clearTimeout(fadeOutProcessFile)  
      fadeOutProcessFileFnc();
    }

    setChangeProperties(key);
    //console.log(key)
    switch(key){
      case 'update': 
        const updatedUserFiles = userFiles.map(obj => {
          if(obj.caseId === value.item_id)
          obj.name = value.item_name;
          return obj;
        });
        setUserFiles(updatedUserFiles);
        break;
      case 'moveTo':         
        break;
      case 'copyTo': 
        break;
      case 'delete':
        const deletedUserFiles = userFiles.filter(function( obj ) {
          return obj.caseId !== value.item_id;
        });
        setUserFiles(deletedUserFiles);
        break;
      case 'open':
        navigate(value);
      
      //  setUserFiles(userFiles)
        break;
      case 'compress':
        break;
      case 'decompress':
        break;
      case 'lock':
        break;
      case 'unlock':
        break;
      case 'shareWith':
        break;
      case 'updateWith':
        break;
      case 'deleteWith':
        break;
      default:
      //  console.log('There is not a such command');
        break;
    }
  }
  //\\end change properties




  //\touch functions
  function touchStart(event) {
      touchRef.current = event.target;
  }  

  function touchMove(event) {    
    itemTouched = true;

    if (event.targetTouches[0].clientX > 56 + (parseFloat(touchRef.current.style.width) / 2)
      && event.targetTouches[0].clientX < 8 + (fieldsRef.current.clientWidth + fieldsRef.current.offsetLeft) - (parseFloat(touchRef.current.style.width) / 2)
      && event.targetTouches[0].clientY > 48 + (parseFloat(touchRef.current.style.width) / 2)
      && event.targetTouches[0].clientY < 24 + (fieldsRef.current.clientHeight + fieldsRef.current.offsetTop) - (parseFloat(touchRef.current.style.width) / 2)
    ) {
      document.getElementById(touchRef.current.id).style.position = 'absolute'
      document.getElementById(touchRef.current.id).style.top = (event.targetTouches[0].clientY - (parseFloat(touchRef.current.style.width) / 2)) + 'px'
      document.getElementById(touchRef.current.id).style.left = (event.targetTouches[0].clientX - (parseFloat(touchRef.current.style.width) / 2)) + 'px'
    }
  }

  function touchEnd(event) {    
    if(itemTouched){
      let paramX = Math.round((event.changedTouches[0].clientX - ((size[0] - (fieldBoxSize * fieldsCount.width)) / 2)) / fieldBoxSize);
      let paramY = Math.round((event.changedTouches[0].clientY - ((size[1] - (fieldBoxSize * fieldsCount.height)) / 2)) / fieldBoxSize);
      let id = 'x' + paramX + 'y' + paramY
      if((event.target.parentElement.id !== 'square') && (paramX > 0 && paramX <= fieldsCount.width) && (paramY > 0 && paramY <= fieldsCount.height)){
        if (document.getElementById(id) !== null && document.getElementById(id).hasChildNodes() === false) {
          document.getElementById(id).appendChild(touchRef.current);
          
          const updatefilesParams = {
            item_id: touchRef.current.getAttribute('item_id'),
            id: '_desktop_' + id,
            item_location: location.pathname,
            item_name: touchRef.current.getAttribute('item_name'),
            item_ext: touchRef.current.getAttribute('item_ext'),
            item_coords: id,
            update_type: 'position'
          }
          updatefilesSrv(updatefilesParams)
            .then(response => {
              touchRef.current.id = updatefilesParams.id
              touchRef.current.setAttribute('item_coords', id)
              touchRef.current.style.position = 'relative'
              touchRef.current.style.top = '0px'
              touchRef.current.style.left = '0px'
              itemTouched = false
            })
            .catch(err => {
              console.log(err)
            })            
        } else {
          touchRef.current.style.position = 'relative'
          touchRef.current.style.top = '0px'
          touchRef.current.style.left = '0px'
        }
      }  
    }
  }
  //\\end touch functions



  //\drag functions  
  function dragStart(event) {
    movesRef.current = event.target;
  }

  function dragging(event) {
    event.preventDefault();
  }

  function allowDrop(event) {
    event.preventDefault();
  }

  function drop(event) {   

    event.preventDefault();
    const id = event.target.id

    if (movesRef.current !== undefined) {
      if (event.target.getAttribute('item_name') === null) {

        event.target.appendChild(movesRef.current)

        const updatefilesParams = {
          item_id: movesRef.current.getAttribute('item_id'),
          id: '_desktop_' + id,
         //\\//\\ email: '61833e1b5c7a2724ce4cd86a',
          item_location: location.pathname,
          item_name: movesRef.current.getAttribute('item_name'),
          item_ext: movesRef.current.getAttribute('item_ext'),
          item_coords: id,
          update_type: 'position'
        }
        
        updatefilesSrv(updatefilesParams)
          .then(response => {
           movesRef.current.id = updatefilesParams.id
           movesRef.current.setAttribute('item_coords', id)

          /*  const coordinates = JSON.parse(localStorage.getItem('coordinates'))

            for (let i = 0; i < coordinates.length; i++) {
              if (coordinates[i].caseId === movesRef.current.getAttribute('item_id')) {
                coordinates[i].id = '_desktop_' + id
                coordinates[i].location = movesRef.current.getAttribute('item_location')
                coordinates[i].name = movesRef.current.getAttribute('item_name')
                coordinates[i].extension = movesRef.current.getAttribute('item_ext')
                coordinates[i].coords = id
              }
            }*/

       //\\     localStorage.setItem('coordinates', JSON.stringify(coordinates))

     





       

       setMoved(true)





          })
          .catch(err => {
            console.log('err')
          })
      }
    }
  }
  //\\end drop functions






   
  

  //\toggledrawer
  const toggleDrawer = ((item_id, name, id, item_private, item_location, item_location_name, item_ext, item_coords, created_at, updated_at, open_method) => {
    
    num += 1;
    
    const elementProperties = React.createElement(PropertiesContext.Provider,
      {
        value: { item_id, name, id, item_private, item_location, item_location_name, item_ext, item_coords, num, item_coords, created_at, updated_at, open_method  /* aud: props.aud*/ }
      //}, <Properties changePropertiesFnc={changePropertiesFnc} history={history} location={location} />
    }, <Properties changePropertiesFnc={changePropertiesFnc} history={navigate} location={location} />
    )

    const container = document.getElementById('propertiesWindow');
    if(container.firstElementChild){
      if(property){        
        property.unmount();
      }
    }
    property= createRoot(container);
    property.render(<>{elementProperties}</>);   
  })
  //\\end toggledrawer





  const [maxCoordX, setMaxCoordX] = useState(0)
  const [paginatePageCountX, setPaginatePageCountX] = useState(1)
  const [maxCoordY, setMaxCoordY] = useState(1)
  const [paginatePageCountY, setPaginatePageCountY] = useState(1)

  

  const [fieldsWidthCount, fieldHeightCount] = useFieldsSize();
  let countWt = parseInt((fieldsWidthCount - sideBarSize) /64);
  let countHt = parseInt((fieldHeightCount - headerBarSize) /64);





function useFieldsSize() {

  useLayoutEffect(() => {

    document.getElementsByTagName("BODY")[0].style.overflow = 'hidden';

    function updateSize() {
      if(fieldsCount.width !==  parseInt((window.innerWidth - sideBarSize) /64 ) || fieldsCount.height !==  parseInt(((window.innerHeight - headerBarSize)- 8) /64)){
        setSize([window.innerWidth, window.innerHeight -8]);
        setFieldsCount({ 
          width: parseInt((window.innerWidth - sideBarSize) /64 ), 
          height: parseInt(((window.innerHeight - headerBarSize)-8) /64)
        })
      }   
    }

    window.addEventListener('resize', updateSize);

    updateSize();
    setPaginatePageCountX(Math.ceil(maxCoordX/fieldsCount.width));
    setPaginatePageCountY(Math.ceil(maxCoordY/fieldsCount.height));







   

    setMoved(true)



    



    return () => {
      window.removeEventListener('resize', updateSize);
 
    }
  }, [fieldsCount, maxCoordX, maxCoordY]);

  return size;
}








const ShowFieldsDimensions = () => {
  const elements = [];
  const fieldsItems = [];
  const filesItems = [];
  const filesExtensions = [
    'desktop', 
    'folder',
    'avif',
    'ico',
    'cur',
    'bmp',
    'gif',
    'png',
    'jpeg',
    'jpg',
    'svg',
    'webp',
    'aac',
    'm4a',
    'mp3',
    'oga',
    'ogg',
    'opus',
    '3gp',
    'wav',
    'mkv',
    'mov',
    'mp4',             
    'webm',
    'txt', 
    'log',
    'pdf',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'ppt',
    'pptx',
    'odt',
    'ott',
    'ods',
    'ots',
    'odp',
    'otp',
    'rtf',
    'mvsa',
    'mvsls',
    'mvsc',
    'mvsl',
    'html',
    'htm',
    'css', 
    'js', 
    'jsx',
    'json',
    '7z',
    'gz',
    'gzip',
    'rar',
    'tar',
    'zip'
  ];


   //useStyle.pagination_y.width = '48px'
 // console.log(paginatePageX)

  for(let i=((countWt*countHt)) - (countWt*countHt); i< ((countWt*countHt)); i++){
    if(userFiles.length > 0){



      const result = userFiles.find(element => element.coords === `x${((i + 1 + (countWt * paginatePageX) - countWt)-(countWt*(parseInt(i/countWt))))}y${parseInt(i/countWt) + 1 + ((countHt * paginatePageY) - countHt)}`);
      result !== undefined ? filesItems.push(result) : filesItems.push(null);
    }
    elements.push(i);    
  }

  for (const [index] of elements.entries()) {
    fieldsItems.push(
      <div
        ref={fieldsRef}
       // id={ `x${((index+1)-(countWt*(parseInt((index)/countWt))))}y${parseInt(index/countWt)+1}`}
       id={ `x${((index + 1 + (countWt * paginatePageX) - countWt)-(countWt*(parseInt(index/countWt))))}y${parseInt(index/countWt) + 1 + ((countHt * paginatePageY) - countHt)}`}
        key={index + (((countWt*countHt) * paginatePageX)- (countWt*countHt))} 
        style={{
          display: "inline-block",
          width:"60px",
          height: "60px",
          background: "#fee5ce",
          border: "1px solid black",
          margin:"0 2px",
          overflow:"hidden"
        }}        
        onDrop={(e) => drop(e)}
        onDragOver={(e) => allowDrop(e)}
        onTouchEnd={(e) => touchEnd(e)} 



        >
        
      {/*index+1}{`x${((index +1 )-(countWt*(parseInt((index)/countWt))))}y${parseInt(index/countWt)+1}`*/}
    
    
      {filesItems[index] &&  
        <div
     //   ref={fieldsRef}
          id={filesItems[index].id}
          item_id =  {filesItems[index].caseId}
          item_location = {filesItems[index].location}
          item_name = {filesItems[index].name}
          item_ext = {filesItems[index].extension}
          item_coords = {filesItems[index].coords}
          style =  {{
          //  width: (window.innerWidth - 120) / (Math.floor((window.innerWidth - 60) / 64)) + "px",
          //  height: (window.innerWidth - 120) / (Math.floor((window.innerWidth - 60) / 64)) + "px",
            //backgroundImage : 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/' + files[index].extension + '_file.png)',
            width: '58px',
            height: '58px',
            backgroundImage : filesExtensions.find(element => element === filesItems[index].extension.toLowerCase()) ? `url(${process.env.REACT_APP_URL_CLIENTPORT}/icon/type/${filesItems[index].extension.toLowerCase()}_file.png)`: `url(${process.env.REACT_APP_URL_CLIENTPORT}/icon/type/unknown_file.png)`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            verticalAlign: 'bottom',
            textAlign: 'center',
            fontSize: '13.5px',
            lineHeight: '7.5',
            overflow: 'hidden',
            position: 'relative'
          }}
          draggable = {true}
          onDragStart = {(e) => dragStart(e)}
          onDrag = {(e) => dragging(e)}
          onTouchStart = {(e) => touchStart(e)}
          onTouchMove = {(e) => touchMove(e)}          
          onClick = {() => toggleDrawer(filesItems[index].caseId, filesItems[index].name, filesItems[index].id, filesItems[index].private, filesItems[index].location, filesItems[index].location + '/' + filesItems[index].name, filesItems[index].extension, filesItems[index].coords, filesItems[index].createdAt, filesItems[index].UpdatedAt, 'openProperty')}
        >{filesItems[index].name}  
       
       {filesItems[index].extension === 'folder' &&
        <div
           style =  {{
          //  width: (window.innerWidth - 120) / (Math.floor((window.innerWidth - 60) / 64)) + "px",
          //  height: (window.innerWidth - 120) / (Math.floor((window.innerWidth - 60) / 64)) + "px",
            //backgroundImage : 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/' + files[index].extension + '_file.png)',
            width: '20px',
            height: '20px',
            backgroundImage : `url(${process.env.REACT_APP_URL_CLIENTPORT}/icon/goto_folder.png)`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',            
            overflow: 'hidden',
            position: 'absolute',
            top: '0',
            right: '0',
          }}
          onClick={(e) => {e.stopPropagation(); toggleDrawer(filesItems[index].caseId, filesItems[index].name, filesItems[index].id, filesItems[index].private, filesItems[index].location, filesItems[index].location + '/' + filesItems[index].name, filesItems[index].extension, filesItems[index].coords, filesItems[index].createdAt, filesItems[index].UpdatedAt, 'openDirectly')}}
          >
        </div>
    }


        </div>
      }
    </div>
    )
  }



  return (
    <> 
      {fieldsItems}      
    </>
  )
  
}






const [userFiles, setUserFiles] = useState([]);


useMemo(() => {

 //\\   if(!JSON.parse(localStorage.getItem('userFiles'))){
  const loadfilesParams = {
    location: location
  }
  loadfilesSrv(loadfilesParams)
  .then(response => {
    let finalMaxX = 0;
    let finalMaxY = 0;

    if(response && response.statusCode === 200){
     // if(response.data.length > 0){
     // console.log(response)
      setChangeProperties('load');

      if(response.data.length === 0){
        response.data = [{coords: 'x0y0'}];
      }

      response.data.forEach(element => {
        const maxX = element.coords.match(new RegExp('x' + "(.*)" + 'y'));
        const maxY = element.coords.match(new RegExp('y' + "(.*)" + ''));

        if(parseInt(maxX[1]) && parseInt(maxX[1]) > finalMaxX){
          finalMaxX = parseInt(maxX[1]);
        }
        if(parseInt(maxY[1]) && parseInt(maxY[1]) > finalMaxY){
          finalMaxY = parseInt(maxY[1]);
        }
        setUserFiles(response.data);

        setMaxCoordX(finalMaxX);
        setMaxCoordY(finalMaxY);

        setMoved(false)
      });
   // }
    }
  })
  .catch(err => {
  //  console.log(err)
    if(document.getElementById('propertiesWindow')){
      document.getElementById('propertiesWindow').style.display = 'none';
    }
    //history.push('/login')
    window.location.href = '/login';

  })
  
//},[props.buildFl, props.buildCnt, moved])
},[props, moved])



  useEffect(() => {
    
   // console.log('get for server');
    if(paginatePageCountX < paginatePageX && paginatePageX !==1){
      paginationXRef.current?.lastElementChild?.lastElementChild?.lastElementChild?.click();
      setPaginatePageX(paginatePageX - 1);
    }else if(paginatePageCountX > paginatePageX && paginatePageX !==1){
      paginationXRef.current.lastElementChild.lastElementChild.lastElementChild.click();
      setPaginatePageX(paginatePageX + 1);
    }else if(paginatePageCountY < paginatePageY && paginatePageY !==1){
      paginationYRef.current?.lastElementChild?.lastElementChild?.lastElementChild?.click();
      setPaginatePageY(paginatePageY - 1);
    }else if(paginatePageCountY > paginatePageY && paginatePageY !==1){
      paginationYRef.current.lastElementChild.lastElementChild.lastElementChild.click();
      setPaginatePageY(paginatePageY + 1);
    }
    
  }, [paginatePageCountX, paginatePageCountY])






  //\render 
  return (
    <>
        <div 
          id='square' 
          style={{textAlign: 'center', width: '100%', height:'100%'}}
          
        >
          <ShowFieldsDimensions />
        </div>


 


      <div id="propertiesWindow"></div>
      <Pagination
        ref={paginationXRef}
        size='small'
        className={countWt < 3 ? classes.pagination_x48 : classes.pagination_x24}
        hideNextButton
        hidePrevButton
        count={paginatePageCountX}
        onChange={paginateX}
     
      />
      <Pagination
        ref={paginationYRef}
        size='small'
        className={countHt < 5 ? classes.pagination_y48 : classes.pagination_y24}
        hideNextButton
        hidePrevButton
        count={paginatePageCountY}
        onChange={paginateY}
  
      />
     
    </>
  )
  //\\end render    

}

export default Fields;
