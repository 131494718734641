export const deletetextchatSrv = (param) => {
  async function deletetextchatService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/user/deletetext/' + param.id, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: "include", // include, *same-origin, omit
    });
     return await rawResponse.json();
  };

  return deletetextchatService()
}
