export const unlockfilesSrv = (param) => {

  async function unlockfilesService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/api/user/unlockfiles/' + param.item_id, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },    
      credentials: "include", // include, *same-origin, omit
    });
     return await rawResponse.json();
  };

  return unlockfilesService()
}
