import React, { useLayoutEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LinkMui from '@material-ui/core/Link';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { registerSrv } from '../Service/RegisterService.js';
import { themeHlp } from '../Helper/ThemeHelper.js';

const useStyles = makeStyles((theme) => ({
  registerbar_container: {
    '& .MuiGrid-root': {
      paddingBottom: '20px'
    }
  },
  text_box: {
    width:'92%',
    margin: '4px 4%',
    backgroundColor: 'white',
    borderBottom: '1px solid ' + themeHlp().palette.blackText.light,
    '&:hover': {      
        border: '1px solid ' + themeHlp().palette.primary.light,
        borderStyle: 'none none solid none',
    }
  },
  casual_text: {
    fontFamily: 'Helvetica',
    fontSize: '0.875rem'
  },
  button_witout_icon: {
    width:'92%',
    margin: '4px 4%',
    background: themeHlp().palette.primary.light,
    textTransform: 'capitalize',
    border: '1px solid ' + themeHlp().palette.secondary.light,
    '&:hover' : {
      background: themeHlp().palette.primary.main,
      color: themeHlp().palette.secondary.light,
    },
    '&:active' : {
      background: themeHlp().palette.primary.dark,
      color: themeHlp().palette.secondary.light,
    }
  },
  register_box:{
    width: '100%',
    maxWidth: '360px',
    padding: '0px 0px 20px 0px',
    background: themeHlp().palette.primary.main,
  },
  login_link:{
    color: themeHlp().palette.whiteText.main,
    textDecoration: 'none',
    cursor:'pointer',
    '&:focus, &:hover, &:visited, &:link, &:active' : {
      color: themeHlp().palette.secondary.light,
      textDecoration: 'none',
    } 
  },
  error_message: {    
    fontFamily: 'Helvetica',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    width: '92%',
    margin: '4px 4%',
    padding: '4px 0',
    backgroundColor: themeHlp().palette.error.main,
    color: themeHlp().palette.secondary.light,
    border: '0px',
    borderRadius: '0px'
  },
  linear_progress_box: {
    width: '92%',
    margin: '4px 4%',
    padding: '4px 0',
    '& .MuiLinearProgress-root': {
      backgroundColor: themeHlp().palette.secondary.light
    },
    '& .MuiLinearProgress-bar': {
      backgroundColor: themeHlp().palette.primary.light
    }   
  },
}));

const RegisterBar = () => {
  const classes = useStyles();
  let navigate = useNavigate();
  const [errorFirstName, setErrorFirstName] = useState(false);
  const [errorLastName, setErrorLastName] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [registerFirstname, setRegisterFirstname] = useState('');
  const [registerLastname, setRegisterLastname] = useState('');
  const [registerEmail, setRegisterEmail] = useState('');
  const [registerPassword, setRegisterPassword] = useState('');
  const [registerConfirmPassword, setRegisterConfirmPassword] = useState('');
  const [signupDone, setSignupDown] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const gotoRegister = () => {
    if(registerPassword === registerConfirmPassword){
      const registerParams = {
        firstname: registerFirstname,
        lastname: registerLastname,
        email: registerEmail,
        password: registerPassword
      }

      registerSrv(registerParams)
      .then(response => {
        if(response && (response.statusCode === 201 && response.status === 'Created')){    
          setErrorFirstName(false);
          setErrorLastName(false);
          setErrorEmail(false);
          setErrorPassword(false);
          setErrorConfirmPassword(false);
          setSuccessMessage(response.message);
          setSignupDown(true);
        }else {
          setErrorFirstName(true);
          setErrorLastName(true);
          setErrorEmail(true);
          setErrorPassword(true);
          setErrorMessage(response.error.message);
        }
      }).catch(err => {
        console.log('error: ' + err);
      })
    }else{
      setErrorPassword(true);
      setErrorConfirmPassword(true);
      setErrorMessage('"password" and "confirm password" do not match.');
    }
  }

  const gotoLogin = () => {
    navigate('/login');    
  }

  useLayoutEffect(() => {

    document.getElementsByTagName("BODY")[0].style.overflowY = 'auto';
    
    function updateSize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => {
      window.removeEventListener('resize', updateSize); 
    }
  }, []);

  return (
    <Box className={classes.registerbar_container} sx={{ flexGrow: 1, overflow: "hidden", px:2, py:6}}> 
      <Grid container align="center">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} align={windowWidth >= 600 ? "right" : "center"}>
            <Box component="div" sx={{width: "100%", maxWidth: "360px", verticalAlign: "middle"}}>
              <Box sx={{position: "relative", verticalAlign: "middle"}}>
                <Typography variant="h6" component="span" gutterBottom style={{ fontSize: '14px', position: 'absolute', right:'6px', cursor: 'default'}}>
                  This
                </Typography>
              </Box>              
              <Link to="/" ><img src={process.env.REACT_APP_URL_CLIENTPORT+ '/icon/movesas_text.png'} alt="Moves as logo" style={{width: '100%', maxWidth: '360px'}} /></Link>
              <Box sx={{position: "relative"}}>
                <Typography variant="h6" component="span" gutterBottom style={{ fontSize: '14px', position: 'relative', float:'right', paddingRight:'6px', cursor: 'default'}}>
                  easily as possible
                </Typography>  
              </Box>
            </Box>
          </Grid>           
          <Grid item xs={12} sm={6} align={windowWidth >= 600 ? "left" : "center"}>
            <Box className={classes.register_box}>   
              {signupDone === false &&
                <>
                  <Typography  variant="h5" component="h5" gutterBottom align = "center" style={{paddingTop:"20px"}}>
                    Create a new account
                  </Typography>
                  <TextField
                    error={errorFirstName}
                    label="First name" 
                    variant="standard" 
                    className={classes.text_box}
                    onChange={e => setRegisterFirstname(e.target.value)}                  
                  />
                  <TextField 
                    error={errorLastName}
                    label="Last name"
                    variant="standard"
                    className={classes.text_box}
                    onChange={e => setRegisterLastname(e.target.value)} 
                  />         
                  <TextField
                    error={errorEmail}
                    type="email" 
                    label="Email (username)" 
                    variant="standard"
                    className={classes.text_box}
                    onChange={e => setRegisterEmail(e.target.value)} 
                  />       
                  <TextField
                    error={errorPassword}
                    type="password"
                    label="Password"
                    variant="standard"
                    className={classes.text_box}
                    onChange={e => setRegisterPassword(e.target.value)} 
                    /> 
                  <TextField 
                    error={errorConfirmPassword}
                    type="password"
                    label="Confirm password"
                    variant="standard"
                    className={classes.text_box}
                    onChange={e => setRegisterConfirmPassword(e.target.value)}
                  />
                  {(errorFirstName === true || errorLastName === true || errorEmail === true || errorPassword === true || errorConfirmPassword === true) &&
                    <Card className={classes.error_message}>                   
                        <Typography variant="inherit" component="div" align="center">{errorMessage}</Typography>                      
                    </Card>
                  }  
                  <Button variant="contained" onClick={gotoRegister} className={classes.button_witout_icon}>Sign Up</Button>
                  <Typography variant="inherit" component="div" align = "center" className={classes.casual_text} style={{paddingTop:'20px'}}>
                    Already have an account?
                    <LinkMui className={classes.login_link} onClick={gotoLogin}> Log In</LinkMui>
                  </Typography>
                </>
              }
              {signupDone &&
                <>
                  {successMessage === ''
                  ?             
                    <Box className={classes.linear_progress_box}>
                      <LinearProgress value={10} />
                    </Box>
                  :
                    <>
                      <Typography variant="h5" component="h5" gutterBottom align="center" style={{paddingTop:'20px'}}>
                        Thank you for signing up!
                      </Typography>
                      <Card className={classes.error_message} style={{backgroundColor: themeHlp().palette.success.dark}}>
                        <Typography variant="inherit" component="div" align="center">
                          {successMessage}
                        </Typography>
                      </Card>
                    </>
                  }
                </>
              }
            </Box>
          </Grid>
        </Grid>
      </Grid>   
    </Box>
  )
}

export default RegisterBar;
