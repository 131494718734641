import axios from 'axios'

export const createfilesSrv = (param) => {

    // create a promise for the axios request
    const promise = axios({
      url: process.env.REACT_APP_URL_SERVERPORT + '/api/user/createfiles/',
      method: 'POST',
      data: {
        item_location: param.item_location,
        item_ext: param.item_ext,
      },
      withCredentials: true,
    })
  
    const dataPromise = promise.then((response) => response.data)

    return dataPromise
}
