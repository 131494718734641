import React, { useState, useEffect, createContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
//import { makeStyles } from '@material-ui/core/styles';
import { tokenSrv } from './Service/TokenService.js';
import { createfilesSrv } from './Service/CreateFilesService.js';
import { uploadfilesSrv } from './Service/UploadFilesService.js';
import { getAccountDataSrv } from './Service/GetAccountDataService.js';

import { themeHlp } from './Helper/ThemeHelper.js';
import  HeaderBar  from './Bars/HeaderBar';
import  SideBar  from './Bars/SideBar';
import Field  from './Fields';
import '../App.css';

export const nameContext = createContext();

let fadeOutProcessFile;

const Desktop = (props) => {
  let navigate = useNavigate();
  let location = useLocation();
  let builtFile = 0;
  const [buildCnt, setBuildCnt] = useState(0);
  const [accountData, setAccountData] = useState({});
  const [processFile, setProcessFile] = useState({state: '', message: '', type: ''});

  const fadeOutProcessFileFnc = () => {    
    fadeOutProcessFile = setTimeout(
      () => {
        setProcessFile({state: '', message: '', type: ''});
      }, 4000);
  }

  const builderFile = (value) =>{
    if(value === 'folder'){
      setProcessFile({state: 'loading', message: '', type: 'linearProgress'});
      
      const createfilesParams = {
        item_location: location.pathname,
        item_ext: value,
      }

      createfilesSrv(createfilesParams)
      .then(response => {
        if(response.statusCode === 201){
          setBuildCnt(() => buildCnt + 1);        
          setProcessFile({state: 'loaded', message: response.message, type: 'report'});
        }else if(response.statusCode === 403){
          setProcessFile({state: 'loaded', message: response.message, type: 'error'});
        }
        clearTimeout(fadeOutProcessFile)  
        fadeOutProcessFileFnc();

      })
      .catch(err => {
        console.log(err)
      })
    }else {     
      setProcessFile({state: 'loading', message: '', type: 'linearProgress'});
      
      if(value !== undefined){
        const uploadfilesParams = new FormData();
        uploadfilesParams.append('item_location', location.pathname)
        uploadfilesParams.append('name', value.name)
        uploadfilesParams.append('size', value.size)
        uploadfilesParams.append('type', value.type)
        uploadfilesParams.append('file', value)

        uploadfilesSrv(uploadfilesParams)
        .then(response => {
          if(response.statusCode === 201){
            setBuildCnt(() => buildCnt + 1);
            setProcessFile({state: 'loaded', message: response.message, type: 'report'});
          }else if(response.error && response.error.statusCode === 202){
            setProcessFile({state: 'loaded', message: response.error.message, type: 'error'});
          }
          clearTimeout(fadeOutProcessFile)  
          fadeOutProcessFileFnc();
          
        })
        .catch(err => {
          console.log(err);
          navigate('/login');          
        })
      }
    }
  }
 
  useEffect(() => { 

    tokenSrv().then(response => {  
    }).catch(err => {
      console.log(err);
    })

    if(!localStorage.getItem('movacc')){
      getAccountDataSrv().then(response => {
        localStorage.setItem('movacc', JSON.stringify(response));  
        setAccountData(response);   
      }).catch(err => {
        console.log(err);
      })
    }else{
      setAccountData(JSON.parse(localStorage.getItem('movacc')));
    }
    
    if(localStorage.getItem('movwin')){
      localStorage.setItem('movwin', JSON.stringify({
        windowMode: 0,
        apps: [],
        appCount: 0
      }));
    }
  },[]);

  return (
    <>
      <HeaderBar process={processFile} />
      <SideBar builderFile={builderFile} />
      <div style={{ margin:'50px 0 0 60px'}}>
        <Field buildFl={builtFile} buildCnt={buildCnt} process={(processFile) => setProcessFile(processFile)} />
      </div>    
    </>      
  )
} 
 
export default Desktop;
