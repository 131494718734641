import axios from 'axios'

export const loadfilesSrv = (param) => {


    // create a promise for the axios request
    const promise = axios({
      url: process.env.REACT_APP_URL_SERVERPORT + '/api/user/loadfiles/',
      method: 'POST', 
      data: param,
      withCredentials: true
    })
  
    // using .then, create a new promise which extracts the data
    const dataPromise = promise.then((response) => response.data)
    
    // return it
    return dataPromise


/*
    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        param
      }),
    //  mode: 'same-origin',
      credentials: 'include',
     // referrerPolicy: 'no-referrer',
    };
    const promise = fetch(process.env.REACT_APP_URL_SERVERPORT + '/api/user/loadfiles/', requestOptions)

    const dataPromise = promise.then((response) => {
      console.log(response)
      return response.json()
    })

    return dataPromise
*/








}







  
  



