import React, {useState, useEffect } from 'react';
import {useNavigate} from "react-router-dom";
import { makeStyles, createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import HomeIcon from '@material-ui/icons/Home';


import ComputerIcon from '@material-ui/icons/Computer';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import FolderIcon from '@material-ui/icons/Folder';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import AppsIcon from '@material-ui/icons/Apps';
import ListAltIcon from '@material-ui/icons/ListAlt';

import { themeHlp } from '../Helper/ThemeHelper.js';

import './Guide.css';

const theme = createMuiTheme({
  typography:{
    fontFamily: [
      'Inter', 
      'Helvetica',
      'Arial',
      'Sans-Serif'
    ]
  }
});



const useStyles = makeStyles(() => ({
  page_container: {
    position: 'relative',
    height: '0px',
    minHeight: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  topic_box_group: {
    height: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  topic_box: {
    width: '100%',
    height: '80px',
    overflow: 'hidden',   
    backgroundColor: themeHlp().palette.secondary.light,
    border: '2px solid ' + themeHlp().palette.primary.light,
    borderRadius: '6px',
    cursor: 'pointer',
    '&:hover': {   
      boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
      opacity: 0.8,
    },
  },
  headline: {
    fontSize: '28px',
    textAlign: 'center'
  },
  headline_logo: {
    width:'54px',
    height: '54px',
    display: 'inline-block',
    marginBottom: '-10px',
    cursor: 'default',
    color:'transparent',
    backgroundColor:'transparent',
    backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/movesas.png)', 
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  helpButtonStyle: {
    width: '30px',
    height: '30px',
    margin: '10px 2px 0 2px',
    color: '#fff',
    background: themeHlp().palette.primary.gradientRadial,
    '&:hover': {
      background: themeHlp().palette.primary.gradientRadialHover,
   },
  },
  helpMenuIconStyle: {
    width: '40px',
    height: '40px',
    margin: '20px 10px 0 10px',
    color: '#fff',
    float: 'left',
    background: themeHlp().palette.primary.gradientRadial,
  },



  cube_group:{
    width: '100px',
    height: '100px',
    position: 'relative',
    margin: '-80px auto 30px auto'
  },

  cube_box:{
    display: 'block',
    width: '50.5%',
    height: '16px',
    border: '1px solid ' + themeHlp().palette.greyText.light,
    margin: '1px'
  },




}))




const Guide = () => {
  const classes = useStyles();
  let navigate = useNavigate();
  const [topicDesktop, setTopicDesktop] = useState(false);
  const [topicUsers, setTopicUsers] = useState(false);
  const [topicFiles, setTopicFiles] = useState(false);
  const [topicFeatures, setTopicFeatures] = useState(false);  
  const [topicApps, setTopicApps] = useState(false);
  const [topicGetMore, setTopicGetMore] = useState(false);

  const selectMenu = (topic) => {
    switch(topic){
      case 'desktop': setTopicDesktop(true); 
        break;
      case 'users': setTopicUsers(true); 
        break;
      case 'files': setTopicFiles(true); 
        break;
      case 'features': setTopicFeatures(true); 
        break;
      case 'apps': setTopicApps(true); 
        break;
      case 'more': setTopicGetMore(true); 
        break;
    }

    setTimeout(() => { 
      navigate('/help/' + topic);
    },900);
  }


  const MovesAaAnimation = () => {
    return (
      <div className={classes.cube_group}>
        <div id="cube" className={classes.cube}>
          <div id="cubeFront" className={classes.cube_front}>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
            </div>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
            </div>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
            </div>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
            </div>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
            </div>
          </div>
          <div id="cubeBack" className={classes.cube_back}>
          <div style={{display: 'flex'}}>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
            </div>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
            </div>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
            </div>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
            </div>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
            </div>
          </div>
          <div id="cubeRight" className={classes.cube_right}>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
            </div>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
            </div>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
            </div>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
            </div>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
            </div>
          </div>
          <div id="cubeLeft" className={classes.cube_left}>
          <div style={{display: 'flex'}}>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
            </div>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
            </div>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
            </div>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
            </div>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
            </div>
          </div>
          <div id="cubeTop" className={classes.cube_top}>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
            </div>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
            </div>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
            </div>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
            </div>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
              <span className={classes.cube_box} style={{background: themeHlp().palette.primary.main}}></span>
            </div>
          </div>
          <div id="cubeBottom" className={classes.cube_bottom}>
          <div style={{display: 'flex'}}>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
            </div>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
            </div>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
            </div>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
            </div>
            <div style={{display: 'flex'}}>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
              <span className={classes.cube_box}></span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    let propertyBoxGroup = document.getElementById('propertyBoxGroup');
    let dialogBox = document.getElementById('dialogBox');
    if(propertyBoxGroup){
      propertyBoxGroup.remove();
    }
    if(dialogBox){
      dialogBox.remove();
    }
  }, []);

  return (
    <>
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }} className={classes.page_container}>
        <Grid container>
          <Grid container align="center" spacing={2} justify="center" style={{margin: 'auto'}}>
            <Grid item xs={12} style={{ backgroundImage: 'linear-gradient(to bottom, rgba(255,0,0,0), rgba(255,255,255,1))'}}>
              {/*<Avatar component="span" alt="MovesAs Logo" className={classes.headline_logo} />*/}
              <Box>
                <IconButton title="Back" aria-label="fingerprint" color="secondary" className={classes.helpButtonStyle} onClick={() => navigate(-1)}>
                  <ArrowBackIcon />
                </IconButton>
                <IconButton title="Home" aria-label="fingerprint" color="secondary" className={classes.helpButtonStyle} onClick={() => navigate('/help')}>
                  <HomeIcon />
                </IconButton>
                <IconButton title="Forward" aria-label="fingerprint" color="secondary" className={classes.helpButtonStyle} onClick={() => navigate(1)}>
                  <ArrowForwardIcon />
                </IconButton>
              </Box>
              <Typography className={classes.headline}  variant="subtitle1" component="span">
                MovesAs Guide
              </Typography>
            </Grid>
          </Grid>
      
          <Grid container spacing={4} justify="center" style={{margin: 'auto', background: 'white'}}>
            <Grid item xs={12} sm={6} md={4} lg={3} sx={2}>          
              <Box className={classes.topic_box_group} onClick={() => selectMenu('desktop')}>
                {!topicDesktop &&
                  <Box className={`${classes.topic_box}`}>
                      <IconButton aria-label="fingerprint" color="secondary" className={classes.helpMenuIconStyle} >
                        <ComputerIcon/>
                      </IconButton>
                      <Box sx={{ mx: 2, mt: 2}}>
                        <Typography variant="subtitle2" component="span">Desktop</Typography>  
                      </Box>
                      <Box sx={{ mx: 2}}>
                        <Typography variant="inherit" component="span" style={{lineHeight: '1.3', fontSize: '15px', color: themeHlp().palette.greyText.main}}>See all your files and manage them.</Typography>  
                      </Box>
                  </Box>
                }
              </Box>              
              {topicDesktop && <MovesAaAnimation/>}
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3} sx={2}>
              <Box className={classes.topic_box_group} onClick={() => selectMenu('users')}>
                {!topicUsers &&
                  <Box className={classes.topic_box}>
                    <IconButton aria-label="fingerprint" color="secondary" className={classes.helpMenuIconStyle} >
                      <SupervisedUserCircleIcon />
                    </IconButton>
                    <Box sx={{ mx: 2, mt: 2}}>
                      <Typography variant="subtitle2" component="span">Users</Typography>  
                    </Box>
                    <Box sx={{ mx: 2}}>
                      <Typography variant="inherit" component="span" style={{lineHeight: '1.3', fontSize: '15px', color: themeHlp().palette.greyText.main}}>Share your files and ideas with other users.</Typography>
                    </Box>
                  </Box>
                }
              </Box>
              {topicUsers && <MovesAaAnimation/>}
            </Grid>      
          </Grid>
          <Grid container spacing={4} justify="center" style={{margin: 'auto', background: 'white'}}>
            <Grid item xs={12} sm={6} md={4} lg={3} sx={2}>
              <Box className={classes.topic_box_group} onClick={() => selectMenu('files')}>
                {!topicFiles &&
                  <Box className={classes.topic_box}>
                    <IconButton aria-label="fingerprint" color="secondary" className={classes.helpMenuIconStyle} >
                      <FolderIcon />
                    </IconButton>
                    <Box sx={{ mx: 2, mt: 2}}>
                      <Typography variant="subtitle2" component="span">Files and folders</Typography>  
                    </Box>
                    <Box sx={{ mx: 2}}>
                      <Typography variant="inherit" component="span" style={{lineHeight: '1.3', fontSize: '15px', color: themeHlp().palette.greyText.main}}>Store, move, and share all your files and folders.</Typography>  
                    </Box>
                  </Box>
                }
              </Box>
              {topicFiles && <MovesAaAnimation/>}
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3} sx={2}>
              <Box className={classes.topic_box_group} onClick={() => selectMenu('features')}>
                {!topicFeatures &&
                  <Box className={classes.topic_box}>
                      <IconButton aria-label="fingerprint" color="secondary" className={classes.helpMenuIconStyle} >
                        <ListAltIcon />
                      </IconButton>
                      <Box sx={{ mx: 2, mt: 2}}>
                        <Typography variant="subtitle2" component="span">Features</Typography>  
                      </Box>
                      <Box sx={{ mx: 2}}>
                        <Typography variant="inherit" component="span" style={{lineHeight: '1.3', fontSize: '15px', color: themeHlp().palette.greyText.main}}>Work efficiently with your files wherever you are.</Typography>  
                      </Box>
                  </Box>
                }
              </Box>
              {topicFeatures && <MovesAaAnimation/>}
            </Grid>       
          </Grid>
          <Grid container spacing={4} justify="center" style={{margin: 'auto', background: 'white'}}>
            <Grid item xs={12} sm={6} md={4} lg={3} sx={2}>
              <Box className={classes.topic_box_group} onClick={() => selectMenu('apps')}>
                {!topicApps &&
                  <Box className={classes.topic_box}>
                    <IconButton aria-label="fingerprint" color="secondary" className={classes.helpMenuIconStyle} >
                      <AppsIcon />
                    </IconButton>
                    <Box sx={{ mx: 2, mt: 2}}>
                      <Typography variant="subtitle2" component="span">Apps</Typography>  
                    </Box>
                    <Box sx={{ mx: 2}}>
                      <Typography variant="inherit" component="span" style={{lineHeight: '1.3', fontSize: '15px', color: themeHlp().palette.greyText.main}}>Use integrated applications to find and read your files.</Typography>  
                    </Box>
                  </Box>
                }
              </Box>
              {topicApps && <MovesAaAnimation/>}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} sx={2}>
              <Box className={classes.topic_box_group} onClick={() => selectMenu('more')}>
                {!topicGetMore &&
                  <Box className={classes.topic_box}>
                    <IconButton aria-label="fingerprint" color="secondary" className={classes.helpMenuIconStyle} >
                      <MoreIcon />
                    </IconButton>
                    <Box sx={{ mx: 2, mt: 2}}>
                      <Typography variant="subtitle2" component="span">Get more help</Typography>  
                    </Box>
                    <Box sx={{ mx: 2}}>
                      <Typography variant="inherit" component="span" style={{lineHeight: '1.3', fontSize: '15px', color: themeHlp().palette.greyText.main}}>Get more help about MovesAs and take part to improve this guide.</Typography>  
                    </Box>
                  </Box>
                }
              </Box>
              {topicGetMore && <MovesAaAnimation/>}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{margin: 'auto'}}>
          <Grid item xs={12} style={{height: '70px',  backgroundImage: 'linear-gradient(to top, rgba(255,0,0,0), rgba(255,255,255,1))'}}></Grid>
        </Grid>
      </Box>
    </ThemeProvider>
    </>
  )
}

export default Guide;
