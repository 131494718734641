import React, { useState, useEffect } from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import Typography from '@material-ui/core/Typography';
import LinkMui from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import HomeIcon from '@material-ui/icons/Home';
import { themeHlp } from '../Helper/ThemeHelper.js';

const theme = createMuiTheme({
  typography:{
    fontFamily: [
      'Inter', 
      'Helvetica',
      'Arial',
      'Sans-Serif'
    ]
  }
});

const useStyles = makeStyles(() => ({
  page_container: {
    position: 'relative',        
    height: '0px',
    minHeight: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  page_container_for_welcomepage: {
    position: 'relative',
  },
  headline: {
    fontSize: '28px'
  },
  subHeadline: {
    color: themeHlp().palette.primary.dark
  },
  screenshot: {
    paddingTop: '15px', 
    width:'90%', 
    maxWidth: '960px', 
    display: 'block', 
    margin: 'auto auto'
  },  
  casual_text: {
    fontFamily: 'Helvetica',
    fontSize: '1.065rem'
  },
  helpButtonStyle: {
    width: '30px',
    height: '30px',
    margin: '10px 2px 0 2px',
    color:'#fff',
    background: themeHlp().palette.primary.gradientRadial,
    '&:hover': {
      background: themeHlp().palette.primary.gradientRadialHover,
   },
  } 
}))


const DesktopGuide = () => {
  const classes = useStyles();
  let navigate = useNavigate();
  let location = useLocation();  
  const [expanded, setExpanded] = useState('');
  const [historyButtons, setHistoryButtons] = useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
 
  useEffect(() => {
    if(location.pathname.includes('help')){
      setHistoryButtons(true);
    }
  },[])

  return (
    <>
      <ThemeProvider theme={theme} >
        <Box sx={{ flexGrow: 1 }} className={historyButtons ? classes.page_container : classes.page_container_for_welcomepage}>
          <Grid container>
            <Grid container align="center" spacing={2} justify="center" style={{margin: 'auto'}}>
              <Grid item xs={12} style={{ backgroundImage: 'linear-gradient(to bottom, rgba(255,0,0,0), rgba(255,255,255,1))'}}>
              {historyButtons && 
                  <Box>
                    <IconButton title="Back" aria-label="fingerprint" color="secondary" className={classes.helpButtonStyle} onClick={() => navigate(-1)}>
                      <ArrowBackIcon />
                    </IconButton>
                    <IconButton title="Home" aria-label="fingerprint" color="secondary" className={classes.helpButtonStyle} onClick={() => navigate('/help')}>
                      <HomeIcon />
                    </IconButton>
                    <IconButton title="Forward" aria-label="fingerprint" color="secondary" className={classes.helpButtonStyle} onClick={() => navigate(1)}>
                      <ArrowForwardIcon />
                    </IconButton>
                  </Box>
                }
                <Typography className={classes.headline}  variant="subtitle1" component="span">
                  Desktop
                </Typography>
              </Grid>
            </Grid>
            <Accordion expanded={expanded === 'panel1'} style={{margin:0, width:'100%'}} onChange={handleChange('panel1')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"          
              >
                <Typography className={classes.subHeadline}>
                  Sidebar and header bar
                </Typography>          
              </AccordionSummary>
              <AccordionDetails>
              <Box sx={{width:'100%'}}>
                <Typography variant="inherit" className={classes.casual_text} >
                  By entering to the MovesAs, you will see two important panels (Sidebar and header bar).
                  <br />
                  Let's go over and see what features each of them have.
                  <br />
                </Typography>                
                <Typography variant="subtitle2" component="span">Sidebar</Typography>                
                <ol>                  
                  <li>Show Application</li>
                  <li>Help</li>
                  <li>Go to</li>
                  <li>Create Folder</li>
                  <li>Upload File</li>
                </ol>
                <Typography variant="subtitle2" component="span">Header bar</Typography>                
                <ol>                  
                  <li>Show and hide "Log Out" section</li>
                  <li>Log Out</li>
                  <li>Reload Page</li>
                  <li>Message</li>
                </ol>
                <Divider light />
                <img className={classes.screenshot} alt="desktop screenshot 01" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/desktop/desktop_screenshot_01.jpg'}`}/>
              </Box>
              </AccordionDetails>
            </Accordion>      
            <Accordion expanded={expanded === 'panel2'} style={{margin:0, width:'100%'}} onChange={handleChange('panel2')}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography className={classes.subHeadline}>
                  Drag and Drop with Touch Events
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{width:'100%'}}>
                  <Typography variant="inherit" className={classes.casual_text}>
                    The title speaks for itself! Drag and drop(moving items from one position to another) are fully supported on touch devices to make it convenient for the users.
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel3'} style={{margin:0, width:'100%'}} onChange={handleChange('panel3')}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography className={classes.subHeadline}>
                  Page pagination
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{width:'100%'}}>
                  <Typography variant="inherit" className={classes.casual_text}>
                    the Page pagination is responsible for displaying the screen fields.
                    If a file's position doesn't allow to be visible on the screen, page pagination automatically creates a new page to reach the file whether vertical or horizontal.
                    The count of pages depend on screen sizes.
                  </Typography>
                  <Divider light />
                  <img className={classes.screenshot} style={{maxWidth: '1200px'}} alt="desktop screenshot 03" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/desktop/desktop_screenshot_03.jpg'}`}/>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel4'} style={{margin:0, width:'100%'}} onChange={handleChange('panel4')}>
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography className={classes.subHeadline}>
                  Properties
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{width:'100%'}}>
                  <Typography variant="inherit" className={classes.casual_text}>
                    Almost every item needs editing. In the "Properties" panel users can see, move, share the selected item and do more.
                    <br />
                    Let's take a quick look at the commands that are allowed.
                  </Typography>
                  <ol>                  
                    <li>Shows the application with which the item (file or folder) will be opened.</li>
                    <li>Shows file information.</li>
                    <li>Renames the name of the selected item.</li>
                    <li>Moves the item to another directory.</li>
                    <li>Copies the item to another directory.</li>
                    <li>Shares the item with other users.</li>
                    <li>Creates a zip file in the same directory.</li>
                    <li>Permanently deletes the items with its subitems from the storage.</li>
                    <li>Locks the file from outside reading.</li>
                    <li>Copies the link to the file.</li>
                    <li>Downloads the file to your current device.</li>
                  </ol>
                  <Divider light />
                  <img className={classes.screenshot} style={{width: '40%', maxWidth: '360px'}} alt="desktop screenshot 04" src={`${process.env.REACT_APP_URL_CLIENTPORT + '/image/guide/desktop/desktop_screenshot_04.jpg'}`}/>
                  <Divider light />
                  Learn more about the "Properties" panel commands 
                  <LinkMui href="/help/files" underline="none"> here</LinkMui>.
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {historyButtons &&
          <Grid container spacing={2} style={{margin: 'auto'}}>
            <Grid item xs={12} style={{height: '76px',  backgroundImage: 'linear-gradient(to top, rgba(255,0,0,0), rgba(255,255,255,1))'}}></Grid>
          </Grid>
          }
        </Box>
        
      </ThemeProvider>
    </>      
  )
} 
 
export default DesktopGuide;
