import React, { useLayoutEffect, useState, useEffect } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CancelSharpIcon from '@material-ui/icons/Close';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import BorderInnerIcon from '@material-ui/icons/BorderInner';
import CheckIcon from '@material-ui/icons/Check';
import SlideshowIcon from '@material-ui/icons/Slideshow';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import YouTubeIcon from '@material-ui/icons/YouTube';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TelegramIcon from '@material-ui/icons/Telegram';
import TwitterIcon from '@material-ui/icons/Twitter';
import { styled } from '@material-ui/core/styles';
import { makeStyles} from '@material-ui/core/styles';
import { themeHlp }  from '../Helper/ThemeHelper.js';
import  appResizeWindowHlp  from '../Helper/AppResizeWindowHelper.js';
import { createmvsfileSrv } from '../Service/CreateMvsFileService.js';
import { addlinksSrv } from '../AdtService/AddLinksService.js';
import { getlinksSrv } from '../AdtService/GetLinksService.js';
import { searchlinksSrv } from '../AdtService/SearchLinksService.js';
import { editlinksSrv } from '../AdtService/EditLinksService.js';
import { deletelinksSrv } from '../AdtService/DeleteLinksService.js';
import { getsharedfilesSrv } from '../Service/GetSharedFilesService.js';
import { downwardlinksSrv } from '../AdtService/DownwardLinksService.js';
import { upwardlinksSrv } from '../AdtService/UpwardLinksService.js';

import '../../App.css';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: themeHlp().palette.blackText.light,
    backgroundColor: themeHlp().palette.secondary.light,  
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 16,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        color: themeHlp().palette.blackText.main,        
      },
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: themeHlp().palette.secondary.light,
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialog_action:{
    padding: '0px',
  },
  dialog_content: {
    padding: '0px',
  },
  text_box_new_link: {  
    width: '96%',
    borderBottom: '1px solid ' + themeHlp().palette.blackText.light,
    backgroundColor: 'white',
    '&:hover': {  
        border: '1px solid ' + themeHlp().palette.primary.light,
        borderStyle: 'none none solid none'        
    },
  },
  text_box_new_comment: {
    width: '96%',
    borderBottom: '1px solid ' + themeHlp().palette.blackText.light,
    backgroundColor: 'white',
    '&:hover': {  
        border: '1px solid ' + themeHlp().palette.primary.light,
        borderStyle: 'none none solid none'        
    },
    '& .MuiInputBase-input': {
      color: themeHlp().palette.greyText.light,    
    },
  },
  text_box_links: {
    width: '96%',
  },
  text_box_comments: {
    width: '96%',
    '& .MuiInputBase-input': {
      color: themeHlp().palette.greyText.light,     
    }
  },
  iconbutton: {
    width:'36px',
    height: '36px',
    color: themeHlp().palette.primary.light,
    '&:hover' : {
      color: themeHlp().palette.primary.main
    },
    '&:active' : {
      color: themeHlp().palette.primary.dark,    
    }
  },
  iconbutton_content: {
    width:'22px',
    height: '22px',    
    color: themeHlp().palette.primary.light,
    '&:hover' : {
      color: themeHlp().palette.primary.main
    },
    '&:active' : {
      color: themeHlp().palette.primary.dark,    
    }
  },
  file_icon: {
    width: '24px',
    height: '24px',     
    backgroundRepeat: 'no-repeat',
    backgroundSize: '22px',
    backgroundPosition: '0px 2px',
    padding: '0 24px 0 0',
  },
  process_dialog_content: { 
    overflow: 'hidden', 
    border: `1px solid ${ themeHlp().palette.greyText.light}`, 
    borderStyle:'solid none solid none', 
    backgroundColor: themeHlp().palette.secondary.light,
    '&.MuiDialogContent-root':{
      flex: 'none'
    }
  },
  process_messages_box: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'left',
    flexWrap: 'wrap',
    maxWidth: '1800px'
  },
  process_messages_effect:  {
    position: 'relative',
    padding: '0px',
    overflow: 'hidden',
  },
  play_list: {
    width: '100%',
    float: 'left',
    padding: '0 4px 0 0',
    margin: '0 auto',
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'auto'
    }
  },
  menu_button: {
    width: 24, 
    height: 24, 
    color:'transparent', 
    backgroundColor: themeHlp().palette.secondary.dark, 
    backgroundRepeat: 'no-repeat', 
    backgroundSize: 'cover',
    '&:hover': {      
      backgroundColor: themeHlp().palette.primary.light, 
    },
    '& svg': {
      display: 'none'
    }
  }, 
  frame_text: {
    fontSize: '20px', 
    fontWeight: 'bold',
    padding: '40px 20px 20px 20px', 
    color: themeHlp().palette.greyText.main
  }
}));

let timeoutAlert = {};

const LinkStorageApp = (props) => {
  const classes = useStyles();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [frameWidth, setFrameWidth] = useState(1280);
  const [frameHeight, setFrameHeight] = useState(720);

  const [open, setOpen] = React.useState(false);
  const [toolbarHidden, setToolbarHidden] = useState(false);
  const [responseType, setResponseType] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [responseTime, setResponseTime] = useState(4);

  const [linkAskForDelete, setLinkAskForDelete] = useState(false);

  const [links, setLinks] = useState([]);
  const [newLink, setNewLink] = useState('');
  const [newComment, setNewComment] = useState('');
  const [mediaSrc, setMediaSrc] = useState('');
  const [selectedLinkId, setSelectedLinkId] = useState(null);
  const [selectedOldLinkId, setSelectedOldLinkId] = useState(null);
  const [selectedLink, setSelectedLink] = useState('');
  const [selectedComment, setSelectedComment] = useState('');
  const [websiteName, setWebsiteName] = useState('');

  const [loadingFrom, setLoadingFrom] = useState('local');
  const [linkStatus, setLinkStatus] = useState('load');
  const [orientationPortrait, setOrientationPortrait] = useState(false);
  const [writeMode, setWriteMode] = useState('initial'); //initial, started, stopped
  const [searchMode, setSearchMode] = useState(false);
  const [frameSizesLock, setFrameSizesLock] = useState(false);
  let debounceSearching;
  const mediaAspectRatio = {
    youtube: 1.777777778
  }
  const titleDisplaySize = 566;

  const [anchorResizeEl, setAnchorResizeEl] = React.useState(null);
  const openResize = Boolean(anchorResizeEl);
  const openResizeMenu = (event) => {
    setAnchorResizeEl(event.currentTarget);   
  };
  const closeResizeMenu = () => {
    setAnchorResizeEl(null);
  };

  const handleClickOpen = () => {
    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;

    if(allApps.indexOf('linkstorage') === -1 && JSON.parse(localStorage.getItem('movwin')).appCount <2){
      allApps.push('linkstorage');
      setOpen(true);    
    
      if(props.fileInfo?.shared_with){
        const getsharedfilesParams = {
          item_id: props.fileInfo?.item_id
        }
        getsharedfilesSrv(getsharedfilesParams)
        .then(async response => {        
          setLoadingFrom('shared');
          setLinks(response.data);
        }).catch(err => {
          console.log(err);
        })
      }else{
        if(!searchMode){   
          setLoadingFrom('local');     
        //  goToGetLinks();
        }
      }
      
      localStorage.setItem('movwin', JSON.stringify({
        windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
        apps: allApps,
        appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount + 1
      }));    
      
      setTimeout(() => {
        appsInWindows('load');
      },100);
    }
  };

  const handleClose = () => {
    setOpen(false);
    if(props.appCloses){
      props.appCloses(true);
    }

    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;
    if(allApps.indexOf('linkstorage') !== -1){
      allApps.splice(allApps.indexOf('linkstorage'), 1);
    }

    localStorage.setItem('movwin', JSON.stringify({
      windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
      apps: allApps,
      appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount - 1
    }));
  };

  const appsInWindows = (event) => {
    if(event === 'change'){
      if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).windowMode < 4){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode + 1,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount
        }));
      }else if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).windowMode === 4){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: 0,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: (JSON.parse(localStorage.getItem('movwin'))).appCount
        }));
      }

      if(localStorage.getItem('movwin') && (JSON.parse(localStorage.getItem('movwin'))).appCount === 0){
        localStorage.setItem('movwin', JSON.stringify({
          windowMode: (JSON.parse(localStorage.getItem('movwin'))).windowMode,
          apps: JSON.parse(localStorage.getItem('movwin')).apps,
          appCount: 1
        }));
      }
    }

    setWindowWidth(windowWidth - 1);  
    appResizeWindowHlp('linkStorageDialogBox');
  }

  const debounceFrameSizeFnc = () => {

    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);

    if(window.innerWidth < 600){
      setFrameWidth(window.innerWidth);
      setFrameHeight(window.innerWidth);
    }else if(window.innerWidth >= 600){      
      if(websiteName === 'youtube' || websiteName === 'facebook'){
        if(orientationPortrait === false){
          setFrameWidth(((window.innerWidth-30)/3) * 2);    
          setFrameHeight((((window.innerWidth-30)/3) * 2) / mediaAspectRatio.youtube);
        }else{      
          setFrameWidth(window.innerHeight-136)
          setFrameHeight((window.innerWidth-136 ) / mediaAspectRatio.youtube);
        }
      }else if(websiteName === 'instagram' || websiteName === 'twitter' || websiteName === 'telegram'){
        if(orientationPortrait === false){
          setFrameWidth(((window.innerWidth-30)/3) * 2);    
          setFrameHeight(((((window.innerWidth-30)/3) * 2) / mediaAspectRatio.youtube) + 46);
        }else{      
          setFrameWidth(window.innerHeight-136)
          setFrameHeight(((window.innerWidth-136 ) / mediaAspectRatio.youtube) + 72);
        }
      }else if(websiteName === 'newwebsite'){
        setFrameWidth(((window.innerWidth-30)/3) * 2); 
        setFrameHeight(((window.innerWidth-30)/3) * 2);
      }    
    }
  }

  const resizeFrame = (widthHeight, direction, size) => {
    if(widthHeight === 'width'){
      !orientationPortrait && (size <= ((window.innerWidth-30)/3) * 2) && direction === 'up10' && setFrameWidth(size + 10);
      !orientationPortrait && size > 200 && direction === 'down10' && setFrameWidth(size - 10);
      !orientationPortrait && (size <= ((window.innerWidth-30)/3) * 2) && direction === 'up50' && setFrameWidth(size + 50);
      !orientationPortrait && size > 200  && direction === 'down50' && setFrameWidth(size - 50);      
      orientationPortrait && (size <= ((window.innerWidth-30)/3) * 2) && direction === 'up10' && setFrameHeight(size + 10);
      orientationPortrait && size > 200  && direction === 'down10' && setFrameHeight(size - 10);
      orientationPortrait && (size <= ((window.innerWidth-30)/3) * 2) && direction === 'up50' && setFrameHeight(size + 50);
      orientationPortrait && size > 200  && direction === 'down50' && setFrameHeight(size - 50);
    }else if(widthHeight === 'height'){
      !orientationPortrait && size <= (window.innerHeight-186) && direction === 'up10' && setFrameHeight(size + 10);
      !orientationPortrait && size > 200 && direction === 'down10' && setFrameHeight(size - 10);
      !orientationPortrait && size <=(window.innerHeight-186) && direction === 'up50' && setFrameHeight(size + 50);
      !orientationPortrait && size > 200 && direction === 'down50' && setFrameHeight(size - 50);
      orientationPortrait && size <=(window.innerHeight-186) && direction === 'up10' && setFrameWidth(size + 10);
      orientationPortrait && size > 200 && direction === 'down10' && setFrameWidth(size - 10);
      orientationPortrait && size <=(window.innerHeight-186) && direction === 'up50' && setFrameWidth(size + 50);
      orientationPortrait && size > 200 && direction === 'down50' && setFrameWidth(size - 50);
    }
  }

  const portraitLandscapeOrientation = () => {
    orientationPortrait ? setOrientationPortrait(false) : setOrientationPortrait(true); 
    closeResizeMenu();
  }

  const resetSettings = () => {
    debounceFrameSizeFnc();
    closeResizeMenu();
  }

  const goToCreateMvs = (fileExtension) => {
    const createmvsfileParams = {
      item_location: window.location.pathname,
      item_ext: fileExtension,
      content: []
    }
    createmvsfileSrv(createmvsfileParams)
    .then(response => {
      if(response && response.statusCode === 201){          
        window.location.reload();
      }else if(response.error){
        setResponseTime(responseTime + 1);
        setResponseType('error');
        setResponseMessage(response.error.message);
      }
    }).catch(err => {
      console.log(err);
    })
  }

  const goToGetLinks = () => {
    if(props.fileInfo?.item_id !== undefined){
      const getlinksParams = {
        item_id: props.fileInfo?.item_id
      }
      getlinksSrv(getlinksParams)
      .then(response => {
        console.log(response.data)
        setLinks(response.data);
      }).catch(err => {
        console.log(err)
      }) 
    }
  }

  const goToAddLinks = () => {    

    setLinkAskForDelete(false);
    const addlinksParams = {
      item_id: props.fileInfo?.item_id,
      content: {
        link: newLink,
        comment: newComment
      }
    }
    addlinksSrv(addlinksParams)
    .then(response => {
      if(response.statusCode === 201){        
        setLinkStatus('add-' + Math.random());
      }else if(response.error){       
        setResponseTime(responseTime + 1);
        setResponseType('error');
        setResponseMessage(response.error.message);
      }
    })
    .catch(err => {
      console.log(err)
    })
  } 

  const goToEditLinks = (caseId, link, comment) => {
    setLinkAskForDelete(false);
    const editlinksParams = {
      item_id: props.fileInfo?.item_id,
      case_id: caseId,
      link: selectedLink.trim() === '' ? link : selectedLink,
      comment: selectedComment.trim() === '' ? comment : selectedComment
    }
    editlinksSrv(editlinksParams)
    .then(response => {
      if(response.statusCode === 200){        
        setWriteMode('initial');
        setSelectedLink('');
        setSelectedComment('');
        setLinkStatus('edit-' + Math.random());
      }
    })
    .catch(err => {
      console.log(err)
    })
  }

  const goToDeleteLinks = (caseId) => {
    setLinkAskForDelete(false);
    setLinks([]);

    const deletelinksParams = {
      item_case_id: props.fileInfo?.item_id + '-' + caseId,
    }
    deletelinksSrv(deletelinksParams)
    .then(response => {
      if(response.statusCode === 200){    
        setLinkStatus('delete-' + Math.random());
      }
    })
    .catch(err => {
      console.log(err)
    })
  }

  const goToSearchLinks = (keyword) => {
    setLinks([]);   
    const searchlinksParams = {
      item_id: props.fileInfo?.item_id,
      keyword: keyword
    }
    searchlinksSrv(searchlinksParams)
    .then(response => {
      if(response && response.statusCode === 200){
        setLinks(response.data);
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const linkSearch = (value) => {
    if(value !== ''){
      if(debounceSearching !== undefined){
        clearTimeout(debounceSearching);
      }
      debounceSearching = setTimeout(function() {
        goToSearchLinks(value);
      },800);
    }else {
      setLinks([]);
    }
  }

  const goToMoveDownwardLink = (caseId) => {  
    setLinks([]);

    const downwardlinksParams = {
      item_case_id: props.fileInfo?.item_id + '-' + caseId,
    }
    downwardlinksSrv(downwardlinksParams)
    .then(response => {
      if(response.statusCode === 200){
        setLinkStatus('downward-' + Math.random());
      }else if(response.error){
        setLinkStatus('downward-' + Math.random());
      }
    })
    .catch(err => {
      console.log(err)
    })
  }

  const goToMoveUpwardLink = (caseId) => {
    setLinks([]);

    const upwardlinksParams = {
      item_case_id: props.fileInfo?.item_id + '-' + caseId,
    }
    upwardlinksSrv(upwardlinksParams)
    .then(response => {
      if(response.statusCode === 200){    
        setLinkStatus('upward-' + Math.random());
      }else if(response.error){
        setLinkStatus('downward-' + Math.random());
      }
    })
    .catch(err => {
      console.log(err)
    })
  }

/////////////////////////////////////////////////playLinkFnc
  function playLinkFnc(caseId, link) {
    setWindowWidth(1280);
    setWindowHeight(720);
    setLinkAskForDelete(false);
    setLinkStatus('play-' + Math.random());
    setWriteMode('initial');
    setResponseType('');
    setResponseMessage('');  

    const checkWebsite = checkWebsiteFnc(link);
    setMediaSrc(checkWebsite.src)
    setWebsiteName(checkWebsite.name);
    setOrientationPortrait(checkWebsite.orientation === 'portrait' ? true : false);

    if(checkWebsite.name === 'newwebsite'){
      setOrientationPortrait(false);
    }else if(checkWebsite.name === 'instagram' || checkWebsite.name === 'twitter' || checkWebsite.name === 'telegram'){
      setOrientationPortrait(true);
    }

    if(caseId !== null){
      setSelectedLinkId(caseId);
      setSelectedOldLinkId(caseId);
    }else{
      setSelectedLinkId(null);
      setSelectedOldLinkId(null);
    }
  }

//////////////////////////////////////////////checkWebsiteFnc
  function checkWebsiteFnc(link){
    if(link.includes('t.me/')){
      return {
        src: link + '?embed=1',
        name: 'telegram'
      }
    }else if(link.includes('youtu')){
      let url = '';
      if(link.slice(-1) === '/'){
        url = Array(link.substring(0, link.length - 1));        
      }else{
        url = Array(link);
      }
      let r, rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
      
      for (let i = 0; i < [url].length; ++i) {
        r = url[i].match(rx);   
        if(r !== null && r[1] !== null){
          return {
            src: 'https://youtube.com/embed/' + r[1],// + '?rel=0&autoplay=1&showinfo=0&start=8&end=10'
            name: 'youtube',
            orientation: link.includes('/shorts/') ? 'portrait' : 'landscape'
          }
        }else{
          return {
            src: '',
            name: 'youtube',
            orientation: link.includes('/shorts/') ? 'portrait' : 'landscape'
          }
        }
      }
    }else if(link.includes('insta')){
      let urlId = link.split('/');      
        return {
          src: 'https://instagram.com/reel/' + urlId[4] + '/embed',        
          name: 'instagram',
          orientation: orientationPortrait ? 'portrait' : 'landscape'
         }      
      //src="https://scontent.cdninstagram.com/v/t66.30100-16/310517448_338795405228202_110821364091311561_n.mp4?_nc_ht=scontent.cdninstagram.com&_nc_cat=106&_nc_ohc=pQrTevuw8X8AX8kqAL2&edm=APs17CUBAAAA&ccb=7-5&oh=00_AfCTIVVa4hqooQOY8sHjZY8Zva7o6bpA-Mwu10xla8epGQ&oe=654D6DE1&_nc_sid=10d13b"
    }else if(link.includes('facebook')){
      let urlId = '';
      if(link.includes('/videos/')){
        urlId = link.split('/videos/').pop();
        return {
          src: 'https://www.facebook.com/v18.0/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fimanunyhaye%2Fvideos%2F' + urlId,
          name: 'facebook',
          orientation: link.includes('/reel/') ? 'portrait' : 'landscape'
        }
      //  setMediaSrc('https://www.facebook.com/v18.0/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fimanunyhaye%2Fvideos%2F' + urlId);
      }else if(link.includes('/reel/')){
        urlId = link.split('/reel/').pop();
        return {
          src: 'https://www.facebook.com/v18.0/plugins/video.php?app_id=&channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df362997bbc2e02%26domain%3Diframely.net%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fiframely.net%252Ff1517e8e00a5598%26relation%3Dparent.parent&container_width=424&href=https%3A%2F%2Fwww.facebook.com%2FhumoriLiga4%2Fvideos%2Frealitycomedy-%25D5%25BC%25D5%25A5%25D5%25A1%25D5%25AC%25D5%25AB%25D5%25A9%25D5%25AB%25D6%2584%25D5%25B8%25D5%25B4%25D5%25A5%25D5%25A4%25D5%25AB%2F' + urlId,
          name: 'facebook',
          orientation: link.includes('/reel/') ? 'portrait' : 'landscape'
        }
        // setMediaSrc('https://www.facebook.com/v18.0/plugins/video.php?app_id=&channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df362997bbc2e02%26domain%3Diframely.net%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fiframely.net%252Ff1517e8e00a5598%26relation%3Dparent.parent&container_width=424&href=https%3A%2F%2Fwww.facebook.com%2FhumoriLiga4%2Fvideos%2Frealitycomedy-%25D5%25BC%25D5%25A5%25D5%25A1%25D5%25AC%25D5%25AB%25D5%25A9%25D5%25AB%25D6%2584%25D5%25B8%25D5%25B4%25D5%25A5%25D5%25A4%25D5%25AB%2F' + urlId);
      }else if(link.includes('watch?v=')){
        urlId = link.split('watch?v=').pop();
        return {
          src: 'https://www.facebook.com/v18.0/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fimanunyhaye%2Fvideos%2F' + urlId,
          name: 'facebook',
          orientation: link.includes('/reel/') ? 'portrait' : 'landscape'
        }
        // setMediaSrc('https://www.facebook.com/v18.0/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fimanunyhaye%2Fvideos%2F' + urlId);
      }
     // setWebsiteName('facebook');
      //https://www.facebook.com/v18.0/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fimanunyhaye%2Fvideos%2F1401210620466850
    }else if(link.includes('twitter')){
      let urlId = link.split('/status/').pop();
      return {
        src: 'https://platform.twitter.com/embed/Tweet.html?dnt=true&embedId=twitter-widget-0&id=' + urlId,
        name: 'twitter'
      }      
    }else if(link.startsWith('https://') || link.startsWith('http://')){
      return {
        src: link,
        name: 'newwebsite',
        orientation: 'landscape'
      }
    }else{ 
      return {
        src: link,
        name: 'notawebsite',
        orientation: 'landscape'
      }
    }
  }

  const cancelSaving = () => {
    setLinks([]); 
    setWriteMode('initial'); 
    setSelectedLink(''); 
    setSelectedComment(''); 
    setLinkAskForDelete(false); 
    setLinkStatus('cancelsaving-' + Math.random());
  }

  const AlertBox = () => {
    clearTimeout(timeoutAlert);
    if(responseType === 'error'){
      timeoutAlert = setTimeout(() => { setResponseType('errorFinished') }, 4000);
    }else if(responseType === 'warning'){
      timeoutAlert = setTimeout(() => { setResponseType('warningFinished') }, 4000);
    }else if(responseType === 'report'){
      timeoutAlert = setTimeout(() => { setResponseType('reportFinished') }, 4000);
    }

    if(responseType === 'warning'){
      return (
        <DialogContent className={classes.process_dialog_content}>
          <Box className={classes.process_messages_box}>          
            <div className={classes.process_messages_effect}>
              <div style={{
                position: 'absolute',
                inset: '0',
                background: themeHlp().palette.secondary.light,
                borderLeft: '2px solid ' + themeHlp().palette.secondary.light,
                borderRadius: '10px',
                top:'6px',
                height: '14px',
                transform: 'translateX(0%)',
                animation:  'translateOverlay 0.4s forwards cubic-bezier(0.03, 0, 0.15, 1)',
              }}></div>
              <Avatar component="span" alt="Warning" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/warning.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} /> 
              <Typography variant="subtitle1" component="span" style={{ cursor: 'default'}}>{responseMessage}</Typography>
            </div>
          </Box>
        </DialogContent>
      )
    }else if(responseType === 'error'){
      return (
        <DialogContent className={classes.process_dialog_content}>
          <Box className={classes.process_messages_box}>          
            <div className={classes.process_messages_effect}>
              <div style={{
                position: 'absolute',
                inset: '0',
                background: themeHlp().palette.secondary.light,
                borderLeft: '2px solid ' + themeHlp().palette.secondary.light,
                borderRadius: '10px',
                top:'6px',
                height: '14px',
                transform: 'translateX(0%)',
                animation:  'translateOverlay 0.4s forwards cubic-bezier(0.03, 0, 0.15, 1)',
              }}></div>
              <Avatar component="span" alt="Error" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/error.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} /> 
              <Typography variant="subtitle1" component="span" style={{ cursor: 'default'}}>{responseMessage}</Typography>
            </div>
          </Box>
        </DialogContent>
      )
    }else if(responseType === 'report'){
      return (
        <DialogContent className={classes.process_dialog_content}>
          <Box className={classes.process_messages_box}>
            <div className={classes.process_messages_effect}>
              <div style={{
                position: 'absolute',
                inset: '0',
                background: themeHlp().palette.secondary.light,
                borderLeft: '2px solid ' + themeHlp().palette.secondary.light,
                borderRadius: '10px',
                top:'6px',
                height: '14px',
                transform: 'translateX(0%)',
                animation:  'translateOverlay 0.4s forwards cubic-bezier(0.03, 0, 0.15, 1)',
              }}></div>
              <Avatar component="span" alt="Report" style={{width: 24, height: 24, float: 'left', marginRight: '4px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/report.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} /> 
              <Typography variant="subtitle1" component="span" style={{ cursor: 'default'}}>{responseMessage}</Typography>
            </div>
          </Box>
        </DialogContent>
      )
    }
  }

  useLayoutEffect(() => {
    function updateSize() {
      if(!frameSizesLock){
        debounceFrameSizeFnc();
      }
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => {     
      window.removeEventListener('resize', updateSize); 
    }
  }, [windowWidth, windowHeight, orientationPortrait, frameSizesLock]);

  useEffect(() => { 
    handleClickOpen();
    goToGetLinks();   

    if(!localStorage.getItem('movwin')){
      localStorage.setItem('movwin', JSON.stringify({
        windowMode: 0,
        apps: [],
        appCount: 1
      }));
    }     
    
    let allApps = JSON.parse(localStorage.getItem('movwin')).apps;

    if(allApps.indexOf('linkstorage') === -1 && JSON.parse(localStorage.getItem('movwin')).appCount < 2){
      setOpen(true);
      setTimeout(() => {
        const muiBackdrop = document.querySelectorAll('.MuiBackdrop-root');
        let newApp = JSON.parse(localStorage.getItem('movwin')).apps;
        newApp.push('linkstorage');
        
        if(muiBackdrop[2]){
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: newApp,
            appCount: 2
          })); 
        }else if(muiBackdrop[2] === undefined && muiBackdrop[1]) {          
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: newApp,
            appCount: 1
          }));        
        }else if(muiBackdrop[2] === undefined && muiBackdrop[1] === undefined) {        
          localStorage.setItem('movwin', JSON.stringify({
            windowMode: JSON.parse(localStorage.getItem('movwin')).windowMode,
            apps: [],
            appCount: 0,
          }));        
        }
        appsInWindows('load');   
        
      },100);
    }
  },[linkStatus])

return (
  <>
    <Avatar title="Link Storage" component="span" variant="rounded" alt="Link Storage" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/linkstorage.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: 'auto'}} onClick={handleClickOpen} />
    <Typography title="Link Storage" variant="subtitle1" component="span" style={{ whiteSpace: 'nowrap', display: 'block', margin: 'auto auto', textAlign:'center', textOverflow: 'ellipsis'}} gutterBottom>Link Storage</Typography>
    <Dialog id="linkStorageDialogBox" fullScreen onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle
        style={{
          padding: '8px 16px',
          color:'#ffffff', 
          background:'linear-gradient(90deg, rgba(38,93,140,1) 0%, rgba(38,93,140,1) 15%, rgba(0,140,183,1) 100%)'
        }}
        id="customized-dialog-title" onClose={handleClose}
      >
        <Avatar component="span" variant="rounded" style={{width:'24px', height: '24px', display: 'inline-block', marginRight: '8px', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/linkstorage.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} />
        {windowWidth >264 && <span>{(windowWidth < titleDisplaySize && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) !== 0) || (windowWidth < (titleDisplaySize/2) && (localStorage.getItem('movwin') && JSON.parse(localStorage.getItem('movwin'))?.windowMode) === 0) ? '' : 'Link Storage'}</span> }
        <IconButton
          disabled={document.getElementById('shutdownDialog') ? false : true}
          aria-label="AppsInWindowsIcon"
          onClick={() => appsInWindows('change')}
          size="small"
          style={{
            background: themeHlp().palette.blueGrey.dark,
            color:'white',          
            margin:'2px 42px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px',
            opacity: document.getElementById('shutdownDialog') ? '1' : '0.3'        
          }}
        >
          <BorderInnerIcon style={{padding: '2px'}} />          
        </IconButton>
        <IconButton 
          aria-label="UnfoldLessMoreIcon"
          title={!toolbarHidden ? 'Hide Toolbar' : 'Show Toolbar'}
          onClick={()=> setToolbarHidden(current => !current)}
          size="small"
          style={{
            width: '18px',
            height: '18px',
            background: themeHlp().palette.blueGrey.dark,
            color:'white',  
            margin:'2px 74px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px'
          }}
        >
          {!toolbarHidden ? <UnfoldLessIcon style={{padding: '4px'}} /> : <UnfoldMoreIcon style={{padding: '4px'}} />}
        </IconButton>
        <IconButton 
          aria-label="CancelSharpIcon"
          title="Close"
          onClick={handleClose}
          size="small"
          style={{
            background: themeHlp().palette.blueGrey.dark,
            color:'white', 
            margin:'2px 10px 0 0',
            padding:'2px',
            position: 'absolute',
            right: '0px'
          }}
        >
          <CancelSharpIcon style={{padding: '2px'}} /> 
        </IconButton>
      </DialogTitle>
      {!toolbarHidden && 
      <DialogActions id="p0" className={classes.dialog_action}>
        <div id="p1" className={classes.root}>          
          <List style={{ width: '100%', height: '64px', padding: '0px', overflowY: 'auto'}}>
            <ListItem disabled={props.fileInfo?.item_id !== undefined ? true : false} button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
              <label>
                <Avatar title="Create File" component="span" variant="rounded" alt="Create File" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_save_mvs.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={() => goToCreateMvs('mvsls')} />
              </label>
            </ListItem> 
            <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
              <label>
                <Avatar title="Resize" component="span" variant="rounded" alt="Resize" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_resize.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={openResizeMenu}/>
              </label>
            </ListItem>
            <div>
              <StyledMenu
                id="video-resize-menu"
                MenuListProps={{
                  'aria-labelledby': 'video-resize-menu',
                }}
                anchorEl={anchorResizeEl}
                open={openResize}
                onClose={closeResizeMenu}
              >
                <MenuItem style={{ pointerEvents: 'none' }}>
                  <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_resize_by_width.png)'}}></span>
                  Frame Width:&nbsp;
                  <span style={{color: themeHlp().palette.primary.light}}> {orientationPortrait ? Number.parseFloat(frameHeight).toFixed(1) : Number.parseFloat(frameWidth).toFixed(1)}px&nbsp;</span>
                  <IconButton size="small" aria-label="slideshow" style={{ pointerEvents: 'auto'}} onClick={() => resizeFrame('width', 'up10', orientationPortrait ? frameHeight : frameWidth)}>
                    <Avatar component="span" alt="Ten Plus" className={classes.menu_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_ten_plus.png)'}} />
                  </IconButton>
                  <IconButton size="small" aria-label="slideshow" style={{ pointerEvents: 'auto'}} onClick={() => resizeFrame('width', 'up50', orientationPortrait ? frameHeight : frameWidth)}>
                    <Avatar component="span" alt="Fifty Plus" className={classes.menu_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_fifty_plus.png)'}} />
                  </IconButton>
                  <IconButton size="small" aria-label="slideshow" style={{ pointerEvents: 'auto'}} onClick={() => resizeFrame('width', 'down50', orientationPortrait ? frameHeight : frameWidth)}>
                    <Avatar component="span" alt="Fifty Minus" className={classes.menu_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_fifty_minus.png)'}} />
                  </IconButton>
                  <IconButton size="small" aria-label="slideshow" style={{ pointerEvents: 'auto'}} onClick={() => resizeFrame('width', 'down10', orientationPortrait ? frameHeight : frameWidth)}>
                    <Avatar component="span" alt="Ten Minus" className={classes.menu_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_ten_minus.png)'}} />
                  </IconButton>

                  <IconButton size="small" aria-label="slideshow" style={{ pointerEvents: 'auto'}} onClick={() => resizeFrame('width', 'up25', orientationPortrait ? frameHeight : frameWidth)}>
                    <Avatar component="span" alt="Fifty Plus" className={classes.menu_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_fifty_plus.png)'}} />
                  </IconButton>

                </MenuItem>
                <MenuItem style={{ pointerEvents: 'none' }}>
                  <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_resize_by_height.png)'}}></span>
                  Frame Height:&nbsp;
                  <span style={{color: themeHlp().palette.primary.light}}> {orientationPortrait ? Number.parseFloat(frameWidth).toFixed(1) : Number.parseFloat(frameHeight).toFixed(1)}px&nbsp;</span>
                  <IconButton size="small" aria-label="slideshow" style={{ pointerEvents: 'auto'}} onClick={() => resizeFrame('height', 'up10', orientationPortrait ? frameWidth : frameHeight)}>
                    <Avatar component="span" alt="Ten Plus" className={classes.menu_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_ten_plus.png)'}} />
                  </IconButton>
                  <IconButton size="small" aria-label="slideshow" style={{ pointerEvents: 'auto'}} onClick={() => resizeFrame('height', 'up50', orientationPortrait ? frameWidth : frameHeight)}>
                    <Avatar component="span" alt="Fifty Plus" className={classes.menu_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_fifty_plus.png)'}} />
                  </IconButton>
                  <IconButton size="small" aria-label="slideshow" style={{ pointerEvents: 'auto'}} onClick={() => resizeFrame('height', 'down50', orientationPortrait ? frameWidth : frameHeight)}>
                    <Avatar component="span" alt="Fifty Minus" className={classes.menu_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_fifty_minus.png)'}} />
                  </IconButton>
                  <IconButton size="small" aria-label="slideshow" style={{ pointerEvents: 'auto'}} onClick={() => resizeFrame('height', 'down10', orientationPortrait ? frameWidth : frameHeight)}>
                    <Avatar component="span" alt="Ten Minus" className={classes.menu_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_ten_minus.png)'}} />
                  </IconButton>
                </MenuItem>
                <MenuItem style={{ pointerEvents: 'none' }}>
                  <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_lock_unlock.png)'}}></span>
                  Lock/Unlock Frame Sizes&nbsp; 
                  {!frameSizesLock ?
                  <IconButton size="small" aria-label="slideshow" style={{ pointerEvents: 'auto'}} onClick={() => {setFrameSizesLock(true)}}>
                    <Avatar component="span" alt="Lock" className={classes.menu_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_lock.png)'}} />
                  </IconButton>
                  :
                  <IconButton size="small" aria-label="slideshow" style={{ pointerEvents: 'auto'}} onClick={() => {setFrameSizesLock(false)}}>
                    <Avatar component="span" alt="Unlock" className={classes.menu_button} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_unlock.png)'}} />
                  </IconButton>
                  }
                </MenuItem>
                <MenuItem onClick={portraitLandscapeOrientation}>
                  <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_change_orientation.png)'}}></span>
                  Portrait/Landscape Orientation
                </MenuItem>
                <MenuItem onClick={resetSettings}>
                  <span className={classes.file_icon} style={{backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_frame_sizes.png)'}}></span>
                  Reset Frame Sizes
                </MenuItem>
              </StyledMenu>
            </div>
            { loadingFrom === 'local' &&
            <ListItem button style={{width:"64px", height: '64px', float:"left", padding:'0px 12px'}}>
              <label>
                <Avatar title="Search" component="span" variant="rounded" alt="Search" style={{cursor: 'pointer', color:'transparent', backgroundColor:'transparent', backgroundImage: 'url(' + process.env.REACT_APP_URL_CLIENTPORT + '/icon/panel/panel_search.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} onClick={() => {setSearchMode(true)}}/>
              </label>
            </ListItem>
            }            
          </List>
        </div>
      </DialogActions>
      }
      {(responseType !== '' && responseType !== 'errorFinished') &&  <AlertBox />}
      <DialogContent className={classes.dialog_content}>
        <Box style={{ margin: '8px 0px'}}> 
          <Grid container align="center">
            <Grid container spacing={0} align="center" justify="center">           
              <Grid item xs={12} sm={8} md={8} lg={8} xl={8} style={{padding: '0px 0px', overflow: 'auto'}}>
                {(websiteName === 'youtube' || 
                  websiteName === 'facebook' ||
                  websiteName === 'telegram' ||
                  websiteName === 'instagram' ||
                  websiteName === 'twitter') &&
                  <iframe
                    src={mediaSrc}
                    frameBorder="0" 
                    allowFullScreen="1" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    style={{
                      maxWidth: '96%',       
                      width: orientationPortrait ? frameHeight + 'px' : frameWidth + 'px',
                      height: orientationPortrait ?  frameWidth + 'px' : frameHeight + 'px',
                      overflow: 'auto'      
                    }}
                  >
                  </iframe>
                }
                {websiteName === 'newwebsite' &&
                  <iframe src={mediaSrc}
                    frameBorder="0" 
                    allowFullScreen="1" 
                    style={{ 
                      maxWidth: '96%', 
                      width: orientationPortrait ? frameHeight + 'px' : frameWidth + 'px', 
                      height: orientationPortrait ?  frameWidth + 'px' : frameHeight + 'px',        
                      overflow: 'auto'
                    }}>
                  </iframe>
                }
                {websiteName === 'notawebsite' &&
                  <Box component="div">
                    <div className={classes.frame_text}>This text does not meet the link requirements.</div>
                  </Box>
                }
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4} style={{padding: '0px 0px 0px 0px'}}>
                {!searchMode &&
                <div style={{ textAlign: 'left'}}>
                  <TextField
                    label="URL"
                    type="text"
                    size="small"
                    variant="standard"
                    className={classes.text_box_new_link}
                    style={{marginBottom: newLink.trim() === '' ? '42px' : '0px', marginLeft: window.innerWidth < 600 ? '8px' : '0px'}}
                    value={newLink}
                    onChange={(event) => {setNewLink(event.target.value); setResponseType('reportFinished')}}
                  />
                  {(newLink.trim() !== '') &&
                  <TextField
                    InputProps={{
                      startAdornment: (       
                        <IconButton disabled={true} title="Play Link" size="small" aria-label="slideshow" style={{color: themeHlp().palette.greyText.light}}>
                          {websiteName === 'youtube' && selectedLinkId === null && <YouTubeIcon />}
                          {websiteName === 'telegram' && selectedLinkId === null && <TelegramIcon  />}
                          {websiteName === 'instagram' && selectedLinkId === null && <InstagramIcon />}
                          {websiteName === 'facebook' && selectedLinkId === null && <FacebookIcon  />}
                          {websiteName === 'twitter' && selectedLinkId === null && <TwitterIcon  />}
                        </IconButton>
                      )
                    }}
                    label="Comment"
                    type="text"
                    size="small"
                    variant="standard"
                    className={classes.text_box_new_comment}
                    style={{marginLeft: window.innerWidth < 600 ? '8px' : '0px'}}
                    value={newComment}
                    onChange={(event) => setNewComment(event.target.value)}
                  />
                  }
                  <div style={{width: '100%', height: '64px'}}>
                    {(newLink.trim() !== '') &&
                      <IconButton  title="Play Link" size="small" aria-label="slideshow" style={{margin: '2px 4px'}} onClick={() => playLinkFnc(null, newLink)}>
                        <SlideshowIcon className={classes.iconbutton_content} style={{color: (selectedLinkId === null && newLink.trim() !== '' && websiteName === 'newwebsite') ? themeHlp().palette.success.dark : themeHlp().palette.primary.light}}/>
                      </IconButton>
                    }
                    {(props.fileInfo?.item_id !== undefined && newLink.trim() !== '' && loadingFrom === 'local') &&
                      <>
                        <div style={{width: '1px', height: '24px', background: themeHlp().palette.greyText.light, display: 'inline-block', paddingTop: '4px', margin: '0 3px -7px 0'}}></div>
                        <IconButton  title="Add Link" size="small" aria-label="slideshow" style={{margin: '2px 4px'}} onClick={() => goToAddLinks()}>
                          <ControlPointIcon className={classes.iconbutton_content} />
                        </IconButton>
                      </>
                    }
                  </div>
                </div>
                }
                {(searchMode && loadingFrom === 'local') &&
                  <div style={{ textAlign: 'left'}}>
                    <TextField
                      id="searchField"
                      label="Type to search"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className={classes.search_icon} />
                          </InputAdornment>
                        )
                      }}
                      type="text"
                      size="small"
                      variant="standard"
                      className={classes.text_box_new_link}      
                      onChange={(e) => linkSearch(e.target.value)}
                    />
                    <div style={{width: '100%', height: '106px'}}>
                      <IconButton title="Search" size="small" aria-label="slideshow" style={{margin: '2px 4px'}} onClick={() => linkSearch(document.getElementById('searchField').value)}>
                        <SearchIcon className={classes.iconbutton_content} />
                      </IconButton>                  
                      <div style={{width: '1px', height: '24px', background: themeHlp().palette.greyText.light, display: 'inline-block', paddingTop: '4px', margin: '0 3px -7px 0'}}></div>
                      <IconButton title="Cancel" size="small" aria-label="slideshow" style={{margin: '2px 4px'}} onClick={() => {linkSearch(''); setSearchMode(false); goToGetLinks();}}>
                        <ClearIcon className={classes.iconbutton_content} />
                      </IconButton>
                    </div>
                  </div>
                }   
                {links.length > 0 &&            
                  <Box 
                    component="div" 
                    className={classes.play_list} 
                    style={{height: (windowHeight - 292) + 'px'}}
                  >                        
                    {links.map((data, key) => {               
                      return (
                        <div style={{ textAlign: 'left' }}  key={key+1}>
                          <Divider light style={{width:'96%'}} /> 
                          <Box component="div">
                            <TextField
                              disabled={((writeMode === 'initial') || (data.caseId === selectedLinkId && writeMode === 'started')) ? false : true}
                              className={classes.text_box_links}
                              defaultValue={data.link}
                              onChange={(event) => {setSelectedLinkId(data.caseId); setSelectedLink(event.target.value); setWriteMode('started');}}
                            />                
                          </Box>
                          <Box component="div">
                            <TextField
                              InputProps={{  
                                startAdornment: (
                                  <IconButton disabled={true} size="small" aria-label="slideshow" style={{color: themeHlp().palette.blackText.light}}>
                                    {linkStatus.includes('play-') && websiteName === 'youtube' && data.caseId === selectedLinkId && !writeMode && <YouTubeIcon />}
                                    {linkStatus.includes('play-') && websiteName === 'telegram' && data.caseId === selectedLinkId && !writeMode && <TelegramIcon  />}
                                    {linkStatus.includes('play-') && websiteName === 'instagram' && data.caseId === selectedLinkId && !writeMode && <InstagramIcon />}
                                    {linkStatus.includes('play-') && websiteName === 'facebook' && data.caseId === selectedLinkId && !writeMode && <FacebookIcon  />}
                                    {linkStatus.includes('play-') && websiteName === 'twitter' && data.caseId === selectedLinkId && !writeMode && <TwitterIcon  />}
                                  </IconButton>
                                )  
                              }}
                              disabled={((writeMode === 'initial') || (data.caseId === selectedLinkId && writeMode === 'started')) ? false : true}
                              placeholder='Comment'
                              className={classes.text_box_comments}                  
                              defaultValue={data.comment}
                              onChange={(event) => {setSelectedLinkId(data.caseId); setSelectedComment(event.target.value); setWriteMode('started');}}
                            />
                          </Box>
                          <div style={{width: '100%', height: '32px', }}>
                            <IconButton  title="Play Link" size="small" aria-label="slideshow" style={{ margin: '2px 4px'}} onClick={() => {playLinkFnc(data.caseId, data.link)}}>
                              <SlideshowIcon className={classes.iconbutton_content} style={{color: data.caseId === selectedOldLinkId ? themeHlp().palette.success.dark : themeHlp().palette.primary.light}} />
                            </IconButton>
                            {(data.caseId === selectedLinkId && writeMode === 'started' && loadingFrom === 'local') &&
                            <>
                              <div style={{width: '1px', height: '24px', background: themeHlp().palette.greyText.light, display: 'inline-block', paddingTop: '4px', margin: '0 3px -7px 0'}}></div>
                              <IconButton  title="Save" size="small" aria-label="check" style={{margin: '2px 4px'}} onClick={() => goToEditLinks(data.caseId, data.link, data.comment)}>
                                <CheckIcon className={classes.iconbutton_content} />
                              </IconButton>
                              <IconButton title="Cancel Saving" size="small" aria-label="directions" style={{margin: '2px 4px'}} onClick={() => cancelSaving()}>
                                <ClearIcon className={classes.iconbutton_content} />
                              </IconButton>
                            </>
                            }
                            <div style={{width: '1px', height: '24px', background: themeHlp().palette.greyText.light, display: 'inline-block', paddingTop: '4px', margin: '0 3px -7px 0'}}></div>
                            <CopyToClipboard text={data.link}>
                              <IconButton title="Copy Link" size="small" aria-label="filecopy" style={{border: '1x solid red'}}>
                                <FileCopyIcon className={classes.iconbutton_content} style={{margin: '2px 4px'}} />
                              </IconButton>
                            </CopyToClipboard>
                            {(writeMode === 'initial' && loadingFrom === 'local') && <div style={{width: '1px', height: '24px', background: themeHlp().palette.greyText.light, display: 'inline-block', paddingTop: '4px', margin: '0 3px -7px 0'}}></div>}
                            {(!linkAskForDelete && writeMode === 'initial' && loadingFrom === 'local') 
                              ?
                              <IconButton title="Delete Link" size="small" aria-label="directions" style={{margin: '2px 4px'}} onClick={() => {setSelectedLinkId(data.caseId);setLinkAskForDelete(true)}}>
                                <DeleteIcon className={classes.iconbutton_content} />
                              </IconButton>
                              :
                              <>
                                {(data.caseId === selectedLinkId && writeMode === 'initial' && loadingFrom === 'local') &&
                                <>
                                  <IconButton title="Delete Forever" size="small" aria-label="directions" style={{margin: '2px 4px'}} onClick={() => goToDeleteLinks(data.caseId)}>
                                    <DeleteForeverIcon className={classes.iconbutton_content} />
                                  </IconButton>
                                  <IconButton title="Cancel Deletion" size="small" aria-label="directions" style={{margin: '2px 4px'}} onClick={() => setLinkAskForDelete(false)}>
                                    <ClearIcon className={classes.iconbutton_content} />
                                  </IconButton>                                
                                </>
                                }
                              </>                              
                            }
                          </div>
                          <div style={{width: '100%', height: '32px', }}>
                            <IconButton  title="Move Downward" size="small" aria-label="slideshow" style={{ margin: '2px 4px'}} onClick={() => {goToMoveDownwardLink(data.caseId)}}>
                              <ArrowDownwardIcon className={classes.iconbutton_content} />
                            </IconButton>
                            <div style={{width: '1px', height: '24px', background: themeHlp().palette.greyText.light, display: 'inline-block', paddingTop: '4px', margin: '0 3px -7px 0'}}></div>
                            <IconButton  title="Move Upward" size="small" aria-label="slideshow" style={{ margin: '2px 4px'}} onClick={() => {goToMoveUpwardLink(data.caseId)}}>
                              <ArrowUpwardIcon className={classes.iconbutton_content}  />
                            </IconButton>
                          </div>
                        </div>
                      )  
                    })}     
                  </Box>
                }     
              </Grid>
            </Grid>  
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  </>
);
}
 
export default LinkStorageApp;
