export const sendtextchatSrv = (param) => {
  async function sendtextchatService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/user/sendtext', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        room: param.room,
        password: param.password,
        from: param.from,
        to: param.to,
        message: param.message
      }),
      credentials: "include", // include, *same-origin, omit
    });
     return await rawResponse.json();
  };

  return sendtextchatService()
}
