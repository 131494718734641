export const setPasswordResetSrv = (param) => {
  async function setpasswordresetService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/api/user/password-reset/' +  param.password_reset_code, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        password: param.password,
      }),
   //   mode: 'no-cors', // no-cors, *cors, same-origin
   //   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "include", // include, *same-origin, omit
   //   body: JSON.stringify(param)
    //  body: JSON.stringify({caseId: param.item_id, name: param.name, location: param.item_location})
    });
     return await rawResponse.json();
  };

  return setpasswordresetService()
}
