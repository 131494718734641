export const searchcontentnotesSrv = (param) => {
  async function searchcontentnotesService() {
      const rawResponse = await fetch(process.env.REACT_APP_URL_SERVERPORT + '/user/searchcontentnotes', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        topic_id: param.topicId,
        keyword: param.keyword
      }),
      credentials: "include", // include, *same-origin, omit
    });
     return await rawResponse.json();
  };

  return searchcontentnotesService()
}
