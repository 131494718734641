import React, { useState, useLayoutEffect, useEffect } from 'react';
import {Link, useNavigate } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { getPasswordResetSrv } from '../Service/GetPasswordResetService.js';
import { setPasswordResetSrv } from '../Service/SetPasswordResetService.js';
import { themeHlp } from '../Helper/ThemeHelper.js';

const useStyles = makeStyles((theme) => ({
  text_box: {
    width:'92%',
    margin: '4px 4%',
    backgroundColor: 'white',
    borderBottom: '1px solid ' + themeHlp().palette.blackText.light,
    '&:hover': {      
        border: '1px solid ' + themeHlp().palette.primary.light,
        borderStyle: 'none none solid none',
    }
  },
  casual_text: {
    fontFamily: 'Helvetica',
    fontSize: '0.875rem'
  },
  button_witout_icon: {
    width:'92%',
    margin: '4px 4%',
    background: themeHlp().palette.primary.light,
    textTransform: 'capitalize',
    border: '1px solid ' + themeHlp().palette.secondary.light,
    '&:hover' : {
      background: themeHlp().palette.primary.main,
      color: themeHlp().palette.secondary.light,
    },
    '&:active' : {
      background: themeHlp().palette.primary.dark,
      color: themeHlp().palette.secondary.light,
    }
  },
  account_activation_box:{
    width: '100%',
    maxWidth: '360px',
    padding: '0px 0px 20px 0px',
    marginBottom: '40px',
    background: themeHlp().palette.primary.main,
  },
  login_link:{
    color: themeHlp().palette.whiteText.main,
    textDecoration: 'none',
    cursor:'pointer',
    '&:focus, &:hover, &:visited, &:link, &:active' : {
      color: themeHlp().palette.secondary.light,
      textDecoration: 'none',
    } 
  },
  error_message: {
    fontFamily: 'Helvetica',
    fontSize: '0.875rem',   
    fontWeight: 'bold',
    width: '92%',
    margin: '4px 4%',
    padding: '4px 0',
    backgroundColor: themeHlp().palette.error.main,
    color: themeHlp().palette.secondary.light,
    border: '0px',
    borderRadius: '0px'
  },
  linear_progress_box: {
    width: '92%',
    margin: '4px 4%',
    padding: '4px 0',
    '& .MuiLinearProgress-root': {
      backgroundColor: themeHlp().palette.secondary.light
    },
    '& .MuiLinearProgress-bar': {
      backgroundColor: themeHlp().palette.primary.light
    }   
  },
  circular_progress_box: {
    '& .MuiCircularProgress-root': {
      color: themeHlp().palette.primary.light,
      margin: '26px 45%',
    } 
  }
}));

const PasswordResetBar = () => {
  const classes = useStyles();
  let navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [getPasswordResetDone, setGetPasswordResetDone] = useState(false);
  const [setPasswordResetDone, setSetPasswordResetDone] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [errorPassword, setErrorPassword] = useState(false);
  const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);
  const [passwordResetPassword, setPasswordResetPassword] = useState('');
  const [passwordResetConfirmPassword, setPasswordResetConfirmPassword] = useState('');

  window.history.forward();

  const gotoSetPasswordReset = () => {
    if(passwordResetPassword === passwordResetConfirmPassword){
      const setPasswordResetParams = {
        password_reset_code: window.location.pathname.split('/').pop(),
        password: passwordResetPassword
      }

      setPasswordResetSrv(setPasswordResetParams)
      .then(response => {
        if(response && (response.statusCode === 200 && response.status === 'Success')){
          setErrorPassword(false);
          setErrorConfirmPassword(false);
          setSuccessMessage(response.message);
          setSetPasswordResetDone(true);
       //  setGetPasswordResetDone(false);
        }else {          
          setErrorPassword(true);
          setErrorMessage(response.error.message);
          setSetPasswordResetDone(false);
        }
      }).catch(err => {
        console.log('error: ' + err);
      })
    }else{
      setErrorPassword(true);
      setErrorConfirmPassword(true);
      setErrorMessage('"New password" and "Confirm new password" do not match.');
    }
  }

  useLayoutEffect(() => {

    document.getElementsByTagName("BODY")[0].style.overflowY = 'auto';

    function updateSize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => {
      window.removeEventListener('resize', updateSize); 
    }
  }, []);

// (window.location.pathname.split('/').pop()
  useEffect(() => {
    const getPasswordParams = {
      password_reset_code: window.location.pathname.split('/').pop()
    }

    getPasswordResetSrv(getPasswordParams)
    .then(response => {
      if(response && (response.statusCode === 200 && response.status === 'Success')){  
        setSuccessMessage(response.message);  
        setGetPasswordResetDone(true);
      }else {
        setErrorMessage(response.error.message);
        setGetPasswordResetDone(false);
      }
    }).catch(err => {
      console.log('error: ' + err);
    })
  },[]);

  return (
    <Box sx={{ flexGrow: 1, overflow: "hidden", px:2, py:6}}> 
      <Grid container align="center">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} align={windowWidth >= 600 ? "right" : "center"}>
            <Box component="div" sx={{width: "100%", maxWidth: "360px", verticalAlign: "middle"}}>
              <Box sx={{position: "relative", verticalAlign: "middle"}}>
                <Typography variant="h6" component="span" gutterBottom style={{ fontSize: "14px", position: "absolute", right:"6px"}}>
                  This
                </Typography>
              </Box>
              <Link to="/" ><img src={process.env.REACT_APP_URL_CLIENTPORT+ '/icon/movesas_text.png'} alt="Moves as logo" style={{width: "100%", maxWidth: "360px"}} /></Link>
              <Box sx={{position: "relative"}}>
                <Typography variant="h6" component="span" gutterBottom style={{ fontSize: "14px", position: "relative", float:"right", paddingRight:"6px"}}>
                  easily as possible
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} align={windowWidth >= 600 ? "left" : "center"}>
            <Box className={classes.account_activation_box}>
              <Typography variant="h5" component="h5" gutterBottom align = "center" style={{paddingTop:"20px"}}>
                Password Reset
              </Typography>
              {getPasswordResetDone && !setPasswordResetDone &&
                <>
                  <TextField
                    error={errorPassword}
                    type="password"
                    label="New password"
                    variant="standard"
                    className={classes.text_box}
                    onChange={e => setPasswordResetPassword(e.target.value)} 
                    /> 
                  <TextField 
                    error={errorConfirmPassword}
                    type="password"
                    label="Confirm new assword"
                    variant="standard"
                    className={classes.text_box}
                    onChange={e => setPasswordResetConfirmPassword(e.target.value)}
                  />  
                  {(errorPassword === true || errorConfirmPassword === true) &&
                    <Card className={classes.error_message}>                   
                        <Typography variant="inherit" component="div" align="center">{errorMessage}</Typography>                      
                    </Card>
                  }  
                  <Button variant="contained" onClick={gotoSetPasswordReset} className={classes.button_witout_icon}>Save New Password</Button>
                  </>
                }







              {errorMessage === '' && successMessage === '' 
                ?
                  <Box className={classes.linear_progress_box}>
                    <LinearProgress value={10} />
                  </Box>
                :
                  <>
                    { getPasswordResetDone === false &&                
                      <Card className={classes.error_message}>
                        <Typography variant="inherit" component="div" align="center">{errorMessage}</Typography>
                      </Card>
                    }
                    { setPasswordResetDone &&
                      <Card className={classes.error_message} style={{backgroundColor: themeHlp().palette.success.dark}}>                        
                        <Typography variant="inherit" component="div" align = "center">{successMessage}</Typography>
                      </Card>
                    }    
                  </>
              }          

              
            </Box>
          </Grid>
        </Grid>
      </Grid>   
    </Box>
  )
}

export default PasswordResetBar;
